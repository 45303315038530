import styled from "styled-components";
import img from "../../assets/img/new-background-dh.jpg";

export const NewPersonRegisterContainer = styled.div`
  display: flex;
  background-image: url(${img});
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 150px 500px 150px 500px;
 

  @media (max-width: 1680px) {
    display: flex;
    padding: 100px 400px 100px 400px;
  }
  @media (max-width: 1480px) {
    display: flex;
    padding: 100px 300px 100px 300px;
  }
  @media (max-width: 1290px) {
    display: flex;
    padding: 50px 150px 50px 150px;
  }
  @media (max-width: 980px) {
    display: flex;
    height: 100%;
    padding: 50px 150px 50px 150px;
  }
  @media (max-width: 780px) {
    display: flex;
    padding: 50px 150px 50px 150px;
  }
  @media (max-width: 480px) {
    padding: 30px 15px 20px 15px;
  }

  h1{
    font-size: 42px;
    font-weight: lighter;
    padding: 0px;
    margin: 0px;
  }
  h3{
    font-size: 28px;
    padding: 0px;
    margin: 0px 0px 20px 0px;
  }
`;
