import styled, { css } from "styled-components";
import img from "../../../assets/img/new-background-dh.jpg";

export const BigContainerLogin = styled.div`
  display: flex;
  background-image: url(${img});
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-size: 12px;
  padding: 200px;
  @media (max-width: 1280px) {
    padding: 100px;
  }
  @media (max-width: 980px) {
    padding: 30px;
  }
  @media (max-width: 780px) {
    padding: 10px;
  }
  @media (max-width: 630px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
  }

  .container-reset-passoword {
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    @media (max-width: 630px) {
      margin-top: 50px;
    }
    input {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid gray;
    }
    button {
      border: none;
      background-color: #e07c2c;
      border-radius: 10px;
      width: 100%;
      height: 35px;
      color: #fff;
      margin-bottom: 10px;
    }
    span {
      color: red;
    }
  }

  .container-terms-conditions {
    padding: 30px;
    background-color: #fff;
    border-radius: 15px;
    .checkTerms {
      display: flex;
      justify-content: space-between;
      input {
        width: 20px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid gray;
      }
    }
    @media (max-width: 630px) {
      margin-top: 50px;
    }
    input {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid gray;
    }
    button {
      border: none;
      background-color: #e07c2c;
      border-radius: 10px;
      width: 100%;
      height: 35px;
      color: #fff;
      margin-bottom: 10px;
    }
    span {
      color: red;
    }
  }

  //TEXTO BIENVENIDA
  .welcome-login-text-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    color: #fff;
    padding-left: 50px;
    h1 {
      font-size: 35px;
      margin: 0;
      padding: 0;
      color: #fff;
    }
    h3 {
      color: #fff;
    }
    @media (max-width: 980px) {
      width: 100%;
      align-items: center;
    }
    @media (max-width: 780px) {
      padding-left: 10px;
    }
    @media (max-width: 630px) {
    }
  }

  //LOGIN FORM CONTAINER
  .login-card-container {
    display: flex;
    width: 300px;
    flex-direction: column;
    margin-top: 20px;
  }

  .login-card-subcontainer {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding: 30px;
    height: 50%;
    margin-bottom: 5px;
    border-radius: 10px;
    @media (max-width: 980px) {
      height: 100%;
    }
  }

  .container-logo-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    img {
      width: 60%;
    }
  }

  .login-card-footer {
    font-size: 11.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 50px;
    color: #000;
    border-radius: 10px;
    .text1 {
      margin-left: 5px;
      color: #2873b7;
      cursor: pointer;
    }
  }

  .login-card-footer-text {
    display: flex;
    color: #fff;
    justify-content: center;
    margin-top: 5px;
  }
`;

export const ContainerInfoTextLeft = styled.div`
  display: flex;
  color: #fff;
  width: 70%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 13px;
  padding-left: 50px;
  text-align: left;
  h1 {
    font-size: 45px;
    color: #fff;
    font-weight: 600;
    @media (max-width: 630px) {
      display: none;
    }
  }
  h3 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 25px;
    @media (max-width: 630px) {
      display: none;
    }
  }
  @media (max-width: 1280px) {
    padding-left: 50px;
  }
  @media (max-width: 980px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 780px) {
    padding-left: 10px;
  }
  @media (max-width: 630px) {
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 980px) {
      display: none;
    }
  }
  li {
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 5px 12px 5px 12px;
    margin-right: 10px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }
`;

export const ContainerPopUpLogin = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 15px;
  width: 15%;
  right: 15px;
  top: 15px;
  background-color: #fff;
  border-radius: 15px;
  z-index: 999999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  @media (max-width: 1280px) {
    left: 15px;
    top: 15px;
    width: 25%;
  }
  @media (max-width: 800px) {
    left: 15px;
    top: 15px;
    width: 35%;
  }
  @media (max-width: 630px) {
    left: 25%;
    top: 20%;
    width: 55%;
  }
  @media (max-width: 480px) {
    left: 15%;
    top: 25%;
    width: 75%;
  }
  .btnPortalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: 12px;
  }
  h4 {
    color: blue;
    h4 {
      font-weight: bold;
    }
  }
  button {
    background-color: orange;
    color: #fff;
    border-radius: 15px;
    border: none;
    width: 60%;
    padding: 5px;
  }
  span {
    margin-top: 7px;
    text-align: center;
    :hover {
      cursor: pointer;
    }
  }
`;
