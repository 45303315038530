/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import { toast } from "react-toastify";
import Logo from "../../../assets/img/logo/logo5-3.png";
import { Button } from "reactstrap";

export const Demo = (props) => {
  const { closeModal, item, onChangeFileCertification, view } = props;
  // console.log(item);
  const [image, setImage] = useState(Logo);
  const [cropData, setCropData] = useState(Logo);
  const [cropper, setCropper] = useState();
  const [recortado, setRecortado] = useState(false);
  const [widthToCrop, setWidthToCrop] = useState();
  const [heightToCrop, setHeightToCrop] = useState();
  const [initialAspectRatioToCrop, setInitialAspectRatioToCrop] =
    useState(null);
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    onChangeFileCertification(e, item);
  };

  const getCropData = () => {
    setRecortado(true);
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const finishCrop = () => {
    if (recortado) {
      toast.success("Imagen guardada", {
        position: toast.POSITION.TOP_RIGHT,
      });
      closeModal();
    } else {
      toast.error("Por favor seleccione una imagen", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (item?.order === "5") {
      setWidthToCrop(300);
      setHeightToCrop(300);
      setInitialAspectRatioToCrop(1);
    }
    if (item?.order === "7") {
      setWidthToCrop(400);
      setHeightToCrop(160);
      setInitialAspectRatioToCrop(4 / 2);
    }
    if (item?.order === "8") {
      setWidthToCrop(480);
      setHeightToCrop(160);
      setInitialAspectRatioToCrop(4 / 2);
    }
    //675 - 188
  }, [props]);

  return (
    <div className="container-modal-total">
      <div className="container-modal-crop">
        <div
          className={
            view === "Mobile" ? "container-btns-mobile" : "container-btns"
          }
        >
          <label className="custom-file-upload">
            <input
              type="file"
              className="input"
              onChange={onChange}
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <i
              style={{ marginRight: "5px" }}
              className="fa fa-cloud-upload"
            ></i>{" "}
            Seleccione la imagen que desea cargar
          </label>
          <button
            onClick={finishCrop}
            className={view === "Mobile" ? "buttonDefMobile" : "buttonDef"}
          >
            <i style={{ marginRight: "5px" }} className="fa fa-check"></i>
            Guardar Imagen
          </button>
          <button className="buttonClose" onClick={closeModal}>
            x
          </button>
        </div>
        <br />
        <br />
        {initialAspectRatioToCrop && (
          <Cropper
            className="cropper-box-container"
            // zoomTo={0.5}
            initialAspectRatio={initialAspectRatioToCrop}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={heightToCrop}
            minCropBoxWidth={widthToCrop}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        )}
      </div>
      {/* <div className="box" style={{ width: "50%", float: "right" }}>
          <h1>Vista previa</h1>
          <div
            className="img-preview"
            style={{ width: "100%", float: "left", height: "200px" }}
          />
        </div> */}
      <div className={view === "Mobile" ? "box-mobile" : "box"}>
        <div style={{ width: "30%" }}>
          <h1>
            <button
              className={view === "Mobile" ? "btnCut-mobile" : "btnCut"}
              onClick={getCropData}
            >
              Recortar imagen
            </button>
          </h1>
        </div>
        <div className="result-cropper">
          <img className="image-cropped" src={cropData} alt="cropped" />
        </div>
      </div>

      <br style={{ clear: "both" }} />
    </div>
  );
};

export default Demo;
