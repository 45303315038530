/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
moment.locale("es");


class AdmonTable extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.cellIcons = this.cellIcons.bind(this);
    this.dataFormatDate = this.dataFormatDate.bind(this);
    this.dataFormatDateTxt = this.dataFormatDateTxt.bind(this);
    this.dataFormatDateFull = this.dataFormatDateFull.bind(this);
    this.dataFormatHour = this.dataFormatHour.bind(this);


    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      /*       paginationSize: 3, */
      hidePageListOnlyOnePage: true,
      /*  clearSearch: true, */
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: 'Aún no tienes ninguna cita en espera de una oferta '
    };
  }

  componentWillMount() {

  }

  dataFormatDateTxt(cell) {
    return moment(cell).format("MMMM Do YYYY, h:mm:ss a");
  }

  dataFormatDate(cell) {
    return moment(cell).format("DD/MM/YYYY");
  }

  dataFormatDateFull(cell) {
    return moment(cell).format("dd/MM/YYYY, h:mm:ss a");
  }

  dataFormatHour(cell) {

    let date = new Date(2021, 1, 1, 1, 1, 1, 1);
    let x = cell.split(":");
    date.setHours(x[0]);
    date.setMinutes(x[1]);

    return moment(date).format("h:mm a");
  }


  cellIcons(cell, row) {

    //  if (row.is_parent) return <font color="#f86c6b">Padre</font>;
    //  else return <font color="#20a8d8">Hijo</font>;
    if (row.estado == 'P') {
      return (<font color="#ffc107">Pendiente</font>);
    } else if (row.estado == 'A') {
      return (<font color="#4dbd74">Aceptada</font>);
    } else {
      return (<font color="#f86c6b">Rechazada</font>);
    }

  }


  render() {
    return (
      <div>
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />*/}
        <BootstrapTable
          data={this.props.data}
          version="4"
          bordered = {false}
          striped = {false}
          hover
          responsive={true}
          pagination
          search={true}
          options={this.options}
          searchPlaceholder={"Buscar"}

        >
          <TableHeaderColumn dataField="id" isKey hidden>

          </TableHeaderColumn>
          <TableHeaderColumn dataField="fecha_solicitud"
            width='150'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatDate.bind(this)}
            hidden={false}
            expandableRow={true}
            expandComponent={true}
            dataSort
          >
            Fecha Solicitud
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fecha_inicial"
            width='100'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatDate.bind(this)}
            hidden={false}
            expandableRow={true}
            expandComponent={true}
            dataSort
          >
            Desde
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fecha_final"
            width='100'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatDate.bind(this)}
            hidden={false}
            expandableRow={true}
            expandComponent={true}
            dataSort
          >
            Hasta
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="hora_inicial"
            width='150'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatHour.bind(this)}
            hidden={false}
            expandableRow={true}
            expandComponent={true}
            dataSort

          >
            Hora inicial
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="hora_final"
            width='100'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatHour.bind(this)}
            hidden={false}
            dataSort

          >
            Hora final
          </TableHeaderColumn>


          <TableHeaderColumn
            width='100'
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.cellIcons.bind(this)}
            hidden={false}
            dataSort
          >
            Estado
          </TableHeaderColumn>

        </BootstrapTable>
      </div>
    );
  }
}

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  {
  }
)(AdmonTable);
