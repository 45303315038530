const styles = {
  doctorProfileContainer: {
    position: 'relative',
  },
  avatar: {
    borderRadius: '10%',
    width: '95%',
    height: '95%',
    backgroundColor: '#6d6dbd',
    objectFit: 'contain',
	  maxHeight: '239px'
  },
  logoSt: {
    width: 100,
    marginTop: 10,
  },
  title: {
    fontSize: 30,
    marginBottom: 10,
    color: '#1f6b8d',
  },
  especialities: {
    fontSize: 17,
    marginBottom: 10,
  },
  star: {
    color: '#f3cc10'
  },
  nameEspecialityContainer: {
    marginLeft: 10,
    marginBottom: 10,
  },
  separatorSection: {
    borderTopWidth: 1,
    borderTopColor: 'lightgray',
    borderTopStyle: 'solid',
  },
  section: {
    color: '#13273D',
    width: '100%',
    padding: 8,
    fontSize: 18,
    fontWeight: 'bold'
  },
  minorTitle: {
    marginRight: 10,
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  listIndicator: {
    backgroundColor: '#f3cc10',
    width: 6,
    minWidth: 6,
    height: 6,
    borderRadius: 3,
    marginRight: 5,
  },
  certificateImg: {
    width: 80,
    height: 80,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'lightgray',
    borderRadius: 15,
    objectFit: 'contain',
  }
};

export default styles;