/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import StickyHeader from "react-sticky-header";

// Assets
import "react-sticky-header/styles.css";
import logoDh1 from "../../assets/img/soydoctorhoy.png";
import { siteHost } from "../../enviroment";

function Header({ props, nombreCiudadGps }) {
  // let history = useHistory();

  const goIndex = () => {
    const userPatientId = localStorage.getItem("idUser1");
    if (userPatientId) {
      props.history.push("/Dashboard");
    } else {
      props.history.push("/CitasMedico/MisCitas");
    }
  };

  return (
    <StickyHeader
      header={
        <header className="header_sticky">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-6">
                <div id="logo_home">
                  <h1>
                    <a
                      onClick={goIndex}
                      style={{ cursor: "pointer" }}
                      title="DoctorHoy"
                    >
                      DoctorHoy
                    </a>
                  </h1>
                </div>
              </div>

              <nav className="col-lg-10 col-6">
                <a
                  className="cmn-toggle-switch cmn-toggle-switch__htx open_close"
                  href="#0"
                >
                  <span>Menu mobile</span>
                </a>
                <ul id="top_access">
                  {/* <li>
                    <a href="/#/Dashboard">
                      <img
                        style={{ marginTop: "-17px" }}
                        src={logoDh1}
                        alt=""
                      />
                    </a>
                  </li> */}
                  <li>
                    <a href="/#/login">
                      <i className="pe-7s-user"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/#/NewPersonRegister">
                      <i className="pe-7s-add-user"></i>
                    </a>
                  </li>
                </ul>

                <div className="main-menu">
                  <ul>
                    <li>
                      <a href="#0" className="" hidden>
                        Inicio
                      </a>
                    </li>
                    <li className="submenu" hidden>
                      <a href="list-2.html" className="show-submenu">
                        Médicos
                      </a>
                    </li>
                    <li className="submenu" hidden>
                      <a href="grid-list.html" className="show-submenu">
                        Laboratorios
                      </a>
                    </li>
                    <li className="submenu" hidden>
                      <a href="#0" className="show-submenu">
                        Procedimientos
                      </a>
                    </li>
                    <li className="submenu" hidden>
                      {nombreCiudadGps}
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </header>
      }
    />
  );
}

Header.propTypes = {
  nombreCiudadGps: PropTypes.string,
};

export default Header;
