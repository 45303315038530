import styled from "styled-components";
import img from "../../assets/img/new-background-dh.jpg";

export const NewDoctorRegisterContainer = styled.div`
  display: flex;
  background-image: url(${img});
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 200px;

  @media (max-width: 1280px) {
    display: flex;
    padding: 70px;
  }
  @media (max-width: 980px) {
    display: flex;
    padding: 70px;
  }
  @media (max-width: 780px) {
    display: flex;
    height: 100%;
    padding: 30px 70px 20px 70px;
  }
  @media (max-width: 480px) {
    padding: 30px 15px 20px 15px;
  }
`;
