import {
  SET_CURRENT_USER,
  SET_CURRENT_ERROR_USER,
  SET_CURRENT_ERROR_USER_API,
  FORGET_PASSWORD,
} from "../actions/types";

import isEmpty from "lodash/isEmpty";

const initialState = {
  isAuthenticated: false,
  messageError: "",
  stateLoading: false,
  forgetPasswordResponse: {},
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.payload.access_token),
        messageError: "",
        stateLoading: true,
      };
    case FORGET_PASSWORD:
      return {
        forgetPasswordResponse: action,
        messageError: "",
        isAuthenticated: false,
      };
    case SET_CURRENT_ERROR_USER:
      return {
        isAuthenticated: !isEmpty(action.payload.access_token),
        messageError: action.payload.message,
        stateLoading: true,
      };
    case SET_CURRENT_ERROR_USER_API:
      return {
        isAuthenticated: !isEmpty(action.payload.access_token),
        messageError: action.payload.error_description,
        stateLoading: true,
      };
    default:
      return state;
  }
}
