/* eslint-disable react/prop-types */
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import LogoDH from "../../assets/img/logo/logo.png";
import moment from "moment";

const ConsInformadoPDF = ({ userFullName, DHusername }) => {
  const date = new Date();
  const dateFormat = moment(date).format("L");

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      fontSize: "12px",
    },
    section: { textAlign: "center", margin: 30 },
    firmas: { display: "flex", flexDirection: "row", margin: 30 },
    footer: {
      position: "absolute",
      top: "85vh",
      textAlign: "center",
      margin: 30,
    },
    sectionJustify: { textAlign: "justify", margin: 50 },
    image: { maxWidth: "150px", maxHeight: "50px", margin: 30 },
  });

  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={styles.sectionJustify}>
            <Text style={styles.section}>
              CONSENTIMIENTO INFORMADO TELECONSULTA{"\n"}
              {"\n"}
            </Text>
            Yo {userFullName?.toUpperCase()}, identificado (a) con cedula de
            ciudadanía N° {DHusername}, en pleno uso de mis facultades,
            comprendo Y certifico que me explicaron en que consistente la
            atención en salud por medio de la{"\n"} TELECONSULTA: La Tele
            consulta Implica el uso de comunicaciones electrónicas para permitir
            que el médico tratante realice una atención mediante el uso de
            equipos de telecomunicaciones interactivos que incluyen, como
            mínimo, equipos de audio y video que permiten la comunicación
            interactiva bidireccional en tiempo real con el paciente. Con lo
            anterior conozco los Beneficios esperados que incluyen los
            siguientes: • Mejora del acceso a la atención al permitir que un
            paciente permanezca en un sitio remoto mientras recibe la atención
            por el profesional de la salud en tiempo real, llegando a un
            diagnóstico o tratamiento bajo el respaldo de la historia clínica de
            la atención, órdenes médicas, exámenes a realizar y/o formulación
            médica. • Evaluación y gestión médica más eficientes. • Los
            pacientes pueden ser diagnosticados y tratados antes, lo que puede
            contribuir a mejores resultados y tratamientos.{"\n"}
            {"\n"} PROCEDIMIENTOS A SEGUIR POR FALLAS TECNOLÓGICAS (INCLUIDAS
            LAS DE COMUNICACIÓN): En la totalidad de la consulta médica, se
            utilizarán las herramientas de comunicación definidas por DoctorHoy®
            S.A.S., pero pude ocurrir en casos excepcionales donde no sea
            posible por fallas que sean irreparables en el momento de la
            prestación, en el cual se buscará la forma de contacto con el
            paciente por celular. En el usuario paciente, el paciente encontrara
            en Mis Historias Clínicas, los soportes de la prestación tales como
            ordenamientos, incapacidades y la historia clínica, la prestación
            del servicio de salud, será tratada conforme a altos estándares de
            Privacidad y Confidencialidad, conforme a los protocolos definidos
            en la plataforma e informará al paciente cualquier sospecha,
            pérdida, fuga o violación a la seguridad que pueda afectar la
            información personal que se tenga en custodia como consecuencia de
            la prestación del servicio. Al firmar este consentimiento, entiendo
            lo siguiente:{"\n"}
            {"\n"} Doy mi consentimiento para que el profesional de la salud
            trate mis datos personales y/o los del paciente que represento, de
            forma confidencial y segura, conforme a la ley 1581 de 2012 y demás
            decretos reglamentarios y a la Política y Procedimientos para el
            tratamiento de datos personales, en Doctorhoy® S.A.S., la cual me
            dieron a conocer en el momento que otorgué la autorización para el
            tratamiento de mis datos personales. Paciente:
            {userFullName?.toUpperCase()} Fecha: {dateFormat} Por todo lo anterior expuesto,
            autorizo a DoctorHoy a realizar la Tele consulta en el servicio de
            cualquier especialidad, (Acuerdo verbal).
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ConsInformadoPDF;
