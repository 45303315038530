/* eslint-disable */

import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";
// import Ofertar from "./views/CitaEspera/Ofertar";

function Loading() {
  return <div>Loading...</div>;
}

const BodySytem = Loadable({
  loader: () => import("./views/BodySytem/bodySytem"),
  loading: Loading,
});

const TypesDataxExams = Loadable({
  loader: () => import("./views/TypesDataxExams/typesDataxExams"),
  loading: Loading,
});

const ListsSearchxExams = Loadable({
  loader: () => import("./views/ListsSearchxExams/listsSearchxExams"),
  loading: Loading,
});

const VariableUnitsxExams = Loadable({
  loader: () => import("./views/VariableUnitsxExams/variableUnitsxExams"),
  loading: Loading,
});

const DiagnosticosCie = Loadable({
  loader: () => import("./views/DiagnosticosCie/diagnosticosCie"),
  loading: Loading,
});
const PhysicalRegion = Loadable({
  loader: () => import("./views/PhysicalRegion/physicalRegion"),
  loading: Loading,
});
const AnthropometricaMeasures = Loadable({
  loader: () =>
    import("./views/AnthropometricaMeasures/anthropometricaMeasures"),
  loading: Loading,
});
const Background = Loadable({
  loader: () => import("./views/Background/background"),
  loading: Loading,
});
const AntecedentGroup = Loadable({
  loader: () => import("./views/AntecedentGroup/antecedentGroup"),
  loading: Loading,
});
const Symptom = Loadable({
  loader: () => import("./views/Symptom/symptom"),
  loading: Loading,
});
const ValuesxSearchList = Loadable({
  loader: () => import("./views/ValuesxSearchList/valuesxSearchList"),
  loading: Loading,
});
const CupsTecnica = Loadable({
  loader: () => import("./views/CupsTecnica/cupsTecnica"),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const ProfileByPage = Loadable({
  loader: () => import("./views/ConfigMenu/ProfileByPage/ProfileByPage"),
  loading: Loading,
});

const AdmonProfile = Loadable({
  loader: () => import("./views/ConfigMenu/Profile/AdmonProfile"),
  loading: Loading,
});

const AdmonGroups = Loadable({
  loader: () => import("./views/ConfigMenu/Groups/AdmonGroups"),
  loading: Loading,
});

const BuildMenu = Loadable({
  loader: () => import("./views/ConfigMenu/BuildMenu/BuildMenu"),
  loading: Loading,
});

const AdmonUsers = Loadable({
  loader: () => import("./views/Users/AdmonUsers"),
  loading: Loading,
});

const RecoveryPassword = Loadable({
  loader: () => import("./views/RecoveryPassword/RecoveryPassword"),
  loading: Loading,
});

const AdmonCategory = Loadable({
  loader: () => import("./views/Categories/AdmonCategory"),
  loading: Loading,
});

const CompanyInfo = Loadable({
  loader: () => import("./views/Company/CompanyInfo"),
  loading: Loading,
});

const schedule = Loadable({
  // loader: () => import("./views/schedule/schedule"),
  loader: () => import("./views/Agendas/ViewAgendas"),
  loading: Loading,
});

const speciality = Loadable({
  loader: () => import("./views/Speciality/speciality"),
  loading: Loading,
});

const subSpeciality = Loadable({
  loader: () => import("./views/subSpeciality/subSpeciality"),
  loading: Loading,
});

const ConsultingRoom = Loadable({
  loader: () => import("./views/ConsultingRoom/ConsultingRoom"),
  loading: Loading,
});

const Country = Loadable({
  loader: () => import("./views/Country/Country"),
  loading: Loading,
});

const HealthEntities = Loadable({
  loader: () => import("./views/HealthEntities/HealthEntities"),
  loading: Loading,
});

const Agreement = Loadable({
  loader: () => import("./views/Agreement/Agreement"),
  loading: Loading,
});

const Patient = Loadable({
  loader: () => import("./views/Patient/Patient"),
  loading: Loading,
});

const Calendar = Loadable({
  loader: () => import("./views/Calendar/Calendar"),
  loading: Loading,
});

const Appointment = Loadable({
  loader: () => import("./views/Appointment/Appointment"),
  loading: Loading,
});

const Doctor = Loadable({
  loader: () => import("./views/Doctor/RegisterDoctor"),
  loading: Loading,
});

const ValidateDocuments = Loadable({
  loader: () => import("./views/Doctor/ValidateDocuments"),
  loading: Loading,
});

const Banks = Loadable({
  loader: () => import("./views/Banks/Bank"),
  loading: Loading,
});

const Cies = Loadable({
  loader: () => import("./views/Cie/cie"),
  loading: Loading,
});

const Cups = Loadable({
  loader: () => import("./views/Cup/cup"),
  loading: Loading,
});

const CiexMedico = Loadable({
  loader: () => import("./views/CiexMedico/ciexMedico"),
  loading: Loading,
});

const CupxMedico = Loadable({
  loader: () => import("./views/CupxMedico/CupxMedico"),
  loading: Loading,
});

const BusquedaMedico = Loadable({
  loader: () => import("./views/BusquedaMedico/BusquedaMedico"),
  loading: Loading,
});

const Plan = Loadable({
  loader: () => import("./views/Plan/plan"),
  loading: Loading,
});

const Rango = Loadable({
  loader: () => import("./views/Rango/rango"),
  loading: Loading,
});

const Afiliacion = Loadable({
  loader: () => import("./views/Afiliacion/afiliacion"),
  loading: Loading,
});

const Escolaridad = Loadable({
  loader: () => import("./views/Escolaridad/escolaridad"),
  loading: Loading,
});

const Ocupacion = Loadable({
  loader: () => import("./views/Ocupacion/ocupacion"),
  loading: Loading,
});

const OrigenContacto = Loadable({
  loader: () => import("./views/OrigenContacto/origenContacto"),
  loading: Loading,
});

const Parentesco = Loadable({
  loader: () => import("./views/Parentesco/parentesco"),
  loading: Loading,
});

const NewPersonRegister = Loadable({
  loader: () => import("./views/NewPersonRegister/NewPersonRegister"),
  loading: Loading,
});

const FinishPersonRegister = Loadable({
  loader: () => import("./views/NewPersonRegister/FinishPersonRegister"),
  loading: Loading,
});

const NewDoctorRegister = Loadable({
  loader: () => import("./views/NewPersonRegister/NewDoctorRegister"),
  loading: Loading,
});

const Payment = Loadable({
  loader: () => import("./views/Payment/Payment"),
  loading: Loading,
});

const EspecialidadesxMedico = Loadable({
  loader: () => import("./views/EspecialidadesxMedico/EspecialidadesxMedico"),
  loading: Loading,
});

const SetPermission = Loadable({
  loader: () => import("./views/SetPermission/SetPermission"),
  loading: Loading,
});

const ConvenioXPaciente = Loadable({
  loader: () => import("./views/ConvenioXPaciente/convenioXPaciente"),
  loading: Loading,
});
const ConvenioXMedico = Loadable({
  loader: () => import("./views/ConvenioXMedico/convenioXMedico"),
  loading: Loading,
});

const MedidasAntropometricasXMedico = Loadable({
  loader: () =>
    import("./views/MedidasAntropometricasXMedico/antropometricasXMedico"),
  loading: Loading,
});

const SignosVitalesXMedico = Loadable({
  loader: () => import("./views/SignosVitalesXMedico/signosVitalesXMedico"),
  loading: Loading,
});

const DiagnosticosCIEXMedico = Loadable({
  loader: () => import("./views/DiagnosticosCieXMedico/diagnosticosCieXMedico"),
  loading: Loading,
});

const VariablesBySpecializedExam = Loadable({
  loader: () => import("./views/VariablesBySpecializedExam"),
  loading: Loading,
});

const DiagnosticosCUPSXMedico = Loadable({
  loader: () =>
    import("./views/DiagnosticosCupsXMedico/diagnosticosCupsXMedico"),
  loading: Loading,
});

const PlantillasXMedico = Loadable({
  loader: () => import("./views/PlantillasXMedico/PlantillasXMedico"),
  loading: Loading,
});

const MedicamentosXMedico = Loadable({
  loader: () => import("./views/MedicamentosXMedico/medicamentosXMedico"),
  loading: Loading,
});

const ConvenioXPacienteHabilitar = Loadable({
  loader: () =>
    import("./views/ConvenioXPacienteHabilitar/convenioXPacienteHabilitar"),
  loading: Loading,
});

const ConsultorioXMedico = Loadable({
  loader: () => import("./views/ConsultorioXMedico/ConsultorioXMedico"),
  loading: Loading,
});

const UnidadesMedida = Loadable({
  loader: () => import("./views/UnidadesMedida/UnidadesMedida"),
  loading: Loading,
});

const DoctorPublicProfile = Loadable({
  loader: () => import("./views/Doctor/DoctorPublicProfile"),
  loading: Loading,
});

const CitaEsperaSolicitud = Loadable({
  loader: () => import("./views/CitaEspera/Solicitud"),
  loading: Loading,
});

const SolicitudVideoConsulta = Loadable({
  loader: () => import("./views/CitaEspera/SolicitudVideoConsulta"),
  loading: Loading,
});
const SolicitudTipos = Loadable({
  loader: () => import("./views/CitaEspera/SolicitudTipos"),
  loading: Loading,
});
const IndexBuscador = Loadable({
  loader: () => import("./views/Buscador/BusquedaMedico/BusquedaMedico"),
  loading: Loading,
});
const BuscadorEntidades = Loadable({
  loader: () => import("./views/Buscador/BusquedaEntidades"),
  loading: Loading,
});
const BuscadorPagos = Loadable({
  loader: () => import("./views/Buscador/Summary"),
  loading: Loading,
});
const PerfilMedico = Loadable({
  loader: () => import("./views/Buscador/PerfilMedico"),
  loading: Loading,
});
const BusquedaMedicos = Loadable({
  loader: () => import("./views/Buscador/BusquedaMedico/BusquedaMedico"),
  loading: Loading,
});

const OfertarCitaEspera = Loadable({
  loader: () => import("./views/CitaEspera/Ofertar"),
  loading: Loading,
});

const SeleccionCitaEspera = Loadable({
  loader: () => import("./views/CitaEspera/Seleccion"),
  loading: Loading,
});

const BusquedaEntidades = Loadable({
  loader: () => import("./views/BusquedaMedico/BusquedaEntidades"),
  loading: Loading,
});

const PayAppointment = Loadable({
  loader: () => import("./views/PayAppointmentConsultingRoom/PayAppointment"),
  loading: Loading,
});

const TarifaProductos = Loadable({
  loader: () => import("./views/TarifaProductos/TarifaProductos"),
  loading: Loading,
});

const EditProfilePatient = Loadable({
  loader: () => import("./views/Patient/EditProfilePatient"),
  loading: Loading,
});

const VideoComp = Loadable({
  loader: () => import("./views/Video/VideoComp"),
  loading: Loading,
});

const EditProfileDoctor = Loadable({
  loader: () => import("./views/Doctor/EditProfileDoctor"),
  loading: Loading,
});

const Reporte1 = Loadable({
  loader: () => import("./views/Reportes/Reporte1"),
  loading: Loading,
});

const Certifications = Loadable({
  loader: () => import("./views/Certifications/Certifications"),
  loading: Loading,
});

const Documentos = Loadable({
  loader: () => import("./views/Documentos/Documentos"),
  loading: Loading,
});

const Generos = Loadable({
  loader: () => import("./views/Generos/Generos"),
  loading: Loading,
});

const GruposEtnicos = Loadable({
  loader: () => import("./views/GruposEtnicos/GruposEtnicos"),
  loading: Loading,
});

const OrientacionesSexuales = Loadable({
  loader: () => import("./views/OrientacionesSexuales/OrientacionesSexuales"),
  loading: Loading,
});

const CitasPaciente = Loadable({
  loader: () => import("./views/CitasPaciente/CitasPaciente"),
  loading: Loading,
});

const IniciarVideoConsulta = Loadable({
  loader: () => import("./views/CitasPaciente/IniciarVideoConsulta"),
  loading: Loading,
});
const IniciarVideoConsultaMedico = Loadable({
  loader: () => import("./views/CitasMedico/IniciarVideoConsulta"),
  loading: Loading,
});
const InterConsultas = Loadable({
  loader: () => import("./views/CitasMedico/InterConsultas"),
  loading: Loading,
});
const ExamenesProcedimientos = Loadable({
  loader: () => import("./views/CitasMedico/ViewsExamenesProcedimientos"),
  loading: Loading,
});
const ReprogramarCitas = Loadable({
  loader: () => import("./views/CitasPaciente/ReprogramarCitas"),
  loading: Loading,
});
const ConfigsMedico = Loadable({
  loader: () => import("./views/configsMedico/index"),
  loading: Loading,
});
const ConfigsHC = Loadable({
  loader: () => import("./views/configsMedico/indexCHC"),
  loading: Loading,
});
const MisConsultas = Loadable({
  loader: () => import("./views/CitasMedico/MisConsultas"),
  loading: Loading,
});

const CitasDoctor = Loadable({
  loader: () => import("./views/CitasDoctor/CitasDoctor"),
  loading: Loading,
});

const CitasMedico = Loadable({
  loader: () => import("./views/CitasMedico/CitasMedico"),
  loading: Loading,
});

const TutorialesMedico = Loadable({
  loader: () => import("./views/TutorialesMedico/"),
  loading: Loading,
});

const LoadExams = Loadable({
  loader: () => import("./views/LoadExams/"),
  loading: Loading,
});

const HistoryPatient = Loadable({
  loader: () => import("./views/HistoryPatient/HistoryPatient.jsx"),
  loading: Loading,
});

const AuthorizationHistoryPatient = Loadable({
  loader: () => import("./views/HistoryPatient/AuthHistoryPatient.jsx"),
  loading: Loading,
});
const RatingComponent = Loadable({
  loader: () => import("./views/Video/components/RatingComponent/index.jsx"),
  loading: Loading,
});

//const Summary = Loadable({
//  loader: () => import("./views/Payment/Summary"),
//  loading: Loading
//});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  { path: "/dashboard", name: "", component: Dashboard },
  {
    path: "/ConfigMenu",
    name: "Configuración Menú",
    component: ProfileByPage,
    exact: true,
  },
  {
    path: "/ConfigMenu/ProfileByPage",
    name: "Accesos X Perfil",
    component: ProfileByPage,
  },
  {
    path: "/SistemaCuerpo/sistemas",
    name: "Sistema Cuerpo",
    component: BodySytem,
  },
  {
    path: "/TipoExamenes/tipoExamenes",
    name: "Tipo de Datos Examenes",
    component: TypesDataxExams,
  },
  {
    path: "/ListaExamenes/listaExamenes",
    name: "Lista Busqueda Examenes",
    component: ListsSearchxExams,
  },
  {
    path: "/UnidadMedidaExamenes/unidadMedidaExamenes",
    name: "Unidad de Medida Variables x Examenes",
    component: VariableUnitsxExams,
  },
  {
    path: "/DiagnosticosCie/diagnosticosCie",
    name: "Diagnosticos Cie",
    component: DiagnosticosCie,
  },
  {
    path: "/PhysicalRegion/physicalRegion",
    name: "Region Examen Fisico",
    component: PhysicalRegion,
  },
  {
    path: "/Antecedente/antecedente",
    name: "Antecedente",
    component: Background,
  },
  {
    path: "/AnthropometricaMeasures/anthropometricaMeasures",
    name: "Medidas Antropometricas",
    component: AnthropometricaMeasures,
  },
  {
    path: "/GrupoAntecedente/Grupo",
    name: "Grupo Antecedente",
    component: AntecedentGroup,
  },
  {
    path: "/Sintoma/sintomas",
    name: "Sintomas",
    component: Symptom,
  },
  {
    path: "/BusquedaExamenes/busquedaExamenes",
    name: "Valores Listas De Busquedas Examenes",
    component: ValuesxSearchList,
  },
  {
    path: "/CupsTecnica/cupsTecnica",
    name: "Cups Tecnicas",
    component: CupsTecnica,
  },

  {
    path: "/ConfigMenu/Profile",
    name: "Administrar Perfiles",
    component: AdmonProfile,
  },
  {
    path: "/ConfigMenu/Groups",
    name: "Administrar Grupos",
    component: AdmonGroups,
  },
  {
    path: "/ConfigMenu/BuildMenu",
    name: "Administrar Menú",
    component: BuildMenu,
  },
  {
    path: "/Users/AdmonUsers",
    name: "Administrar Usuarios",
    component: AdmonUsers,
  },
  {
    path: "/RecoveryPassword/RecoveryPassword",
    name: "Generar Contraseña",
    component: RecoveryPassword,
  },
  {
    path: "/Categories/AdmonCategory",
    name: "Administrar Categorias",
    component: AdmonCategory,
  },

  {
    path: "/Company/CompanyInfo",
    name: "Información Empresa",
    component: CompanyInfo,
  },

  {
    path: "/schedule/schedule",
    name: "Agendas Médicas",
    component: schedule,
  },
  {
    path: "/Speciality/speciality",
    name: "Especialidades Médicas",
    component: speciality,
  },
  {
    path: "/subSpeciality/subSpeciality",
    name: "Subespecialidades Médicas",
    component: subSpeciality,
  },
  {
    path: "/ConsultingRoom/ConsultingRoom",
    name: "Consultorios",
    component: ConsultingRoom,
  },
  {
    path: "/Country/Country",
    name: "Países",
    component: Country,
  },
  {
    path: "/HealthEntities/HealthEntities",
    name: "Entidades de Salud",
    component: HealthEntities,
  },
  {
    path: "/Agreement/Agreement",
    name: "Convenios",
    component: Agreement,
  },
  {
    path: "/Patient/Patient",
    name: "Pacientes",
    component: Patient,
  },
  {
    path: "/Calendar/Calendar",
    name: "Calendario",
    component: Calendar,
  },
  {
    path: "/Appointment/Appointment",
    name: "Consulta tus citas",
    component: Appointment,
  },
  {
    path: "/Doctor/EditDoctor",
    name: "Actualiza tus datos",
    component: Doctor,
  },
  {
    path: "/Doctor/ValidateDocuments",
    name: "Validación Documentos Médico",
    component: ValidateDocuments,
  },
  {
    path: "/Banks/Bank",
    name: "Bancos",
    component: Banks,
  },
  {
    path: "/Cie/cie",
    name: "Cie",
    component: Cies,
  },
  {
    path: "/Cup/cup",
    name: "Cup",
    component: Cups,
  },
  {
    path: "/CiexMedico/ciexMedico",
    name: "Clasificacion internacional de enfermedades",
    component: CiexMedico,
  },
  {
    path: "/CupxMedico/CupxMedico",
    name: "Procedimientos",
    component: CupxMedico,
  },
  {
    path: "/BusquedaMedico/BusquedaMedico",
    name: "Busqueda Médico",
    component: BusquedaMedico,
  },
  {
    path: "/BusquedaMedico/BusquedaEntidades",
    name: "Busqueda Entidades",
    component: BusquedaEntidades,
  },
  {
    path: "/Plan/plan",
    name: "Planes",
    component: Plan,
  },
  {
    path: "/Rango/rango",
    name: "Rangos",
    component: Rango,
  },
  {
    path: "/Afiliacion/afiliacion",
    name: "Afiliación",
    component: Afiliacion,
  },
  {
    path: "/Escolaridad/escolaridad",
    name: "Escolaridad",
    component: Escolaridad,
  },
  {
    path: "/Ocupacion/ocupacion",
    name: "Ocupación",
    component: Ocupacion,
  },
  {
    path: "/OrigenContacto/origenContacto",
    name: "Origen Contacto",
    component: OrigenContacto,
  },
  {
    path: "/Parentesco/parentesco",
    name: "Parentesco",
    component: Parentesco,
  },
  {
    path: "/NewPersonRegister/NewPersonRegister",
    name: "Nuevo Registro",
    component: NewPersonRegister,
  },
  {
    path: "/NewPersonRegister/FinishPersonRegister",
    name: "Finalizar Registro",
    component: FinishPersonRegister,
  },

  {
    path: "/NewPersonRegister/NewDoctorRegister",
    name: "Nuevo Médico Registro",
    component: NewDoctorRegister,
  },

  {
    path: "/Payment/Payment",
    name: "Pasarela de Pagos",
    component: Payment,
  },
  {
    path: "/EspecialidadesxMedico/EspecialidadesxMedico",
    name: "Tus especialidades",
    component: EspecialidadesxMedico,
  },
  {
    path: "/SetPermission/SetPermission",
    name: "Asignación de Permisos",
    component: SetPermission,
  },
  {
    path: "/ConvenioXPaciente/convenioXPaciente",
    name: "Convenios de Pacientes",
    component: ConvenioXPaciente,
  },
  {
    path: "/ConvenioXMedico/ConvenioXMedico",
    name: "Mis Convenios",
    component: ConvenioXMedico,
  },
  {
    path: "/ClinicHistory/ProceduresCUPS",
    name: "Administración de Medidas Antropométricas por Médico",
    component: MedidasAntropometricasXMedico,
  },
  {
    path: "/ClinicHistory/DiagnosesCIES",
    name: "Administración de Diagnósticos CIE por Médico",
    component: DiagnosticosCIEXMedico,
  },
  {
    path: "/ClinicHistory/DiagnosticCUPS",
    name: "Administración de Diagnósticos CUPS por Médico",
    component: DiagnosticosCUPSXMedico,
  },
  {
    path: "/ClinicHistory/Templates",
    name: "Configuración de plantillas por médico",
    component: PlantillasXMedico,
  },
  {
    path: "/ClinicHistory/VitalSigns",
    name: "Administración de Signos vitales por Médico",
    component: SignosVitalesXMedico,
  },
  {
    path: "/ClinicHistory/Medicines",
    name: "Configuración de Medicamentos por medico",
    component: MedicamentosXMedico,
  },
  {
    path: "/ConvenioXPacienteHabilitar/convenioXPacienteHabilitar",
    name: "Administración de Convenios de Pacientes",
    component: ConvenioXPacienteHabilitar,
  },
  {
    path: "/ConsultorioXMedico/ConsultorioXMedico",
    name: "Mis consultorios",
    component: ConsultorioXMedico,
  },
  {
    path: "/UnidadesMedida/UnidadesMedida",
    name: "Administración de Unidades de Medida",
    component: UnidadesMedida,
  },
  {
    path: "/Doctor/DoctorPublicProfile",
    name: "Perfil Publico",
    component: DoctorPublicProfile,
  },
  {
    path: "/CitaEspera/Solicitud",
    name: "",
    component: CitaEsperaSolicitud,
  },
  {
    path: "/CitaEspera/SolicitudVideoConsulta",
    name: "",
    component: SolicitudVideoConsulta,
  },
  {
    path: "/CitaEspera/SolicitudTipos",
    name: "",
    component: SolicitudTipos,
  },
  {
    path: "/Buscador/Busqueda/:esp/:city/:type",
    name: "",
    component: IndexBuscador,
  },
  {
    path: "/Buscador/BuscadorEntidades",
    name: "",
    component: BuscadorEntidades,
  },
  {
    path: "/Buscador/Pagos",
    name: "Tu consulta presencial programada",
    component: BuscadorPagos,
  },
  {
    path: "/Buscador/PerfilMedico",
    name: "",
    component: PerfilMedico,
  },
  {
    path: "/Buscador/BusquedaMedico",
    name: "",
    component: BusquedaMedicos,
  },
  {
    path: "/CitaEspera/Ofertar",
    name: "Ofertar tus citas",
    component: OfertarCitaEspera,
  },
  {
    path: "/CitaEspera/Seleccion",
    name: "Selección de citas en oferta",
    component: SeleccionCitaEspera,
  },
  {
    path: "/PayAppointmentConsultingRoom/PayAppointment",
    name: "Pago de citas en consultorio",
    component: PayAppointment,
  },
  {
    path: "/TarifaProductos/TarifaProductos",
    name: "Tarifa de productos",
    component: TarifaProductos,
  },
  {
    path: "/Patient/EditProfilePatient",
    name: "Actualiza tus datos",
    component: EditProfilePatient,
  },
  {
    path: "/Video/VideoComp",
    name: "Componente de video",
    component: VideoComp,
  },
  {
    path: "/Doctor/EditProfileDoctor",
    name: "Perfil del Médico",
    component: EditProfileDoctor,
  },
  {
    path: "/Reportes/Reporte1",
    name: "Reporte 1",
    component: Reporte1,
  },
  {
    path: "/Certifications/Certifications",
    name: "Certificaciones",
    component: Certifications,
  },
  {
    path: "/Documentos/Documentos",
    name: "Documentos",
    component: Documentos,
  },
  {
    path: "/Generos/Generos",
    name: "Géneros",
    component: Generos,
  },
  {
    path: "/GruposEtnicos/GruposEtnicos",
    name: "Grupos Étnicos",
    component: GruposEtnicos,
  },
  {
    path: "/OrientacionesSexuales/OrientacionesSexuales",
    name: "Orientaciones Sexuales",
    component: OrientacionesSexuales,
  },
  {
    path: "/CitasPaciente/CitasPaciente",
    name: "Inicia tu video consulta / Reprograma o Cancela",
    component: CitasPaciente,
  },
  {
    path: "/CitasPaciente/IniciarVideoConsulta",
    name: "Inicia tu video consulta",
    component: IniciarVideoConsulta,
  },
  {
    path: "/CitasPaciente/ReprogramarCitas",
    name: "Reprograma tus citas",
    component: ReprogramarCitas,
  },
  {
    path: "/Medico/ConfigsMedico",
    name: "Reprograma tus citas",
    component: ConfigsMedico,
  },
  {
    path: "/Medico/ConfigsHC",
    name: "Reprograma tus citas",
    component: ConfigsHC,
  },
  {
    path: "/CitasMedico/MisCitas",
    name: "Reprograma tus citas",
    component: MisConsultas,
  },
  {
    path: "/MisVideoConsultas",
    name: "Reprograma tus citas",
    component: IniciarVideoConsultaMedico,
  },
  {
    path: "/InterConsultas",
    name: "InterConsultas",
    component: InterConsultas,
  },
  {
    path: "/ExamenesProcedimientos",
    name: "ExamenesProcedimientos",
    component: ExamenesProcedimientos,
  },
  {
    path: "/CitasDoctor/CitasDoctor",
    name: "Citas del Médico",
    component: CitasDoctor,
  },
  {
    path: "/CitasMedico/CitasMedico",
    name: "Tus video consultas",
    component: CitasMedico,
  },
  {
    path: "/tutorialMedico/",
    name: "Tutoriales Médico",
    component: TutorialesMedico,
  },
  {
    path: "/medicalExam/loadMedicalExam/",
    name: "Cargar Examenes",
    component: LoadExams,
  },
  {
    path: "/ClinicHistory/VariablesBySpecializedExams",
    name: "Administración de Variables por examen especializado",
    component: VariablesBySpecializedExam,
  },
  {
    path: "/ClinicHistory/patient",
    name: "Historia Clinica Paciente",
    component: HistoryPatient,
  },
  {
    path: "/ClinicHistory/Authorization/Patient",
    name: "Autorización Historia Clinica Paciente",
    component: AuthorizationHistoryPatient,
  },
  // {
  //   path: "/RatingVideoCall",
  //   name: "Calificación Video Consulta Paciente",
  //   component: RatingComponent,
  // },

  //{
  //  path: "/Payment/Summary",
  //  name: "Resumen Compra",
  //  component: Summary
  //},
];

export default routes;
