import {
  GET_PROFILES,
  GET_PAGES,
  GET_PROFILES_BY_PAGE,
  GET_GROUPS,
  GET_GROUPS_BY_PARENTS,
  GET_ICONS,
  GET_MASTER_PAGES,
  GET_USER_MENU,
  GET_DOCUMET_TYPES,
  ERROR_API,
  GET_DISCOUNTS_TYPES,
  GET_CITIES,
  GET_DEPARTMENTS,
  GET_COUNTRY,
  GET_CONTACTS,
  GET_PAYMENT_FORMS,
  GET_CURRENCIES,
  GET_REGIME_TYPE,
  GET_PERSON_TYPE,
  GET_VALUES,
  GET_CONFIG_CALENDAR,
  GET_PATIENTS,
  GET_PATIENT_LARAVEL,
  GET_SOCIAL_NETWORKS,
  GET_ETHNIC_GROUPS,
  GET_SEXUAL_ORIENTATION,
  GET_GENDER,
  GET_BANK,
  GET_FINDDOCTOR,
  GET_PLAN,
  GET_RANGO,
  GET_AFILIACION,
  GET_ESCOLARIDAD,
  GET_OCUPACION,
  GET_ORIGENCONTACTO,
  GET_PARENTESCO,
  GET_FUNCIONALIDAD,
  GET_FUNCIONALIDAD_X_USUARIO,
  GET_UNIDADES_MEDIDA,
  GET_REPORTE_1,
  SIGNALRCON,
  SIGNALRPROXY,
  SET_LAST_ACTION,
  GET_BODY_SYSTEMS,
  GET_TYPES_DATAXEXAMS,
  GET_LISTS_SEARCHXEXAMS,
  GET_VARIABLE_UNITSXEXAMS,
  GET_SYMPTOMS,
  GET_VALUESXSEARCHLIST,
  SET_EXECUTING_REQUEST_GENERIC,
  GET_GROUP_CUPS,
  GET_CUPSTECNICA,
  GET_DIAGNOSTICOS_CIE,
  GET_BACKGROUND,
  GET_ANTECEDENT_GROUP,
  GET_PHYSICAL_REGION,
  GET_ANTHROPOMETRICA_MEASURES,
  GET_MARITAL_STATUS,
  GET_BLOOD_TYPES,
  GET_AGREEMENTS,
  GET_HEALTH_ENTITIES,
} from "../actions/types";

const initialState = {
  profiles: [],
  pages: [],
  profileByPage: [],
  Groups: [],
  GroupsByParents: [],
  Icons: [],
  MasterPages: [],
  userMenu: [],
  documentTypes: [],
  maritalStatus: [],
  errorCode: {},
  discountTypes: [],
  cities: [],
  departments: [],
  countries: [],
  contactsType: [],
  paymentForms: [],
  stateInvoice: [],
  currencies: [],
  regimeTypes: [],
  personTypes: [],
  getResponse: false,
  pdfTemplatesInvoice: [],
  orientationReportInvoice: [],
  creditNoteConcepts: [],
  values: {},
  configCalendar: {},
  patients: [],
  patient_laravel: [],
  ethnicGroups: [],
  sexualOrientation: [],
  gender: [],
  banks: [],
  findDoctors: [],
  plans: [],
  rangos: [],
  afiliaciones: [],
  escolaridades: [],
  ocupaciones: [],
  origenContactos: [],
  parentescos: [],
  funcionalidad: [],
  funcionalidadXUsuario: [],
  unidadesMedida: [],
  datosCompra: {},
  datosReporte1: [],
  lastAction: null,
  signalRConexion: null,
  signalRProxy: null,
  bodySystems: [], // key del reducer donde se almacenaran los datos de sistemas del cuerpo
  typesDataxExams: [],
  listsSearchxExams: [],
  variableUnitsxExams: [],
  symptoms: [],
  valuesxSearchList: [],
  executingRequest: false,
  groupCups: [],
  cupsTecnica: [],
  diagnosticosCie: [],
  physicalRegion: [],
  background: [], // key del reducer donde se almacenaran los datos del antecedente
  antecedentGroup: [],
  anthropometricaMeasures: [],
  bloodTypes: [],
  agreements: [],
  healthEntities: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_API:
      return {
        ...state,
        profiles: [],
        pages: [],
        profileByPage: [],
        Groups: [],
        GroupsByParents: [],
        Icons: [],
        MasterPages: [],
        userMenu: [],
        documentTypes: [],
        discountTypes: [],
        socialNetworks: [],
        errorCode: action.payload,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: action.payload.data,
      };
    case GET_PAGES:
      return {
        ...state,
        pages: action.payload.data,
      };
    case GET_PROFILES_BY_PAGE:
      return {
        ...state,
        profileByPage: action.payload.data.profile_by_page,
        pages:
          action.payload.data.pages === null ? [] : action.payload.data.pages,
      };
    case GET_GROUPS:
      return {
        ...state,
        Groups: action.payload.data,
      };
    case GET_GROUPS_BY_PARENTS:
      return {
        ...state,
        GroupsByParents: action.payload.data.groups_by_parents,
        userMenu: action.payload.data.menu,
        pages: action.payload.data.pages,
        MasterPages: action.payload.data.master_pages,
      };
    case GET_ICONS:
      return {
        ...state,
        Icons: action.payload.data,
      };
    case GET_MASTER_PAGES:
      return {
        ...state,
        MasterPages: action.payload.data,
      };
    case GET_USER_MENU:
      return {
        ...state,
        userMenu:
          action.payload.data.menu === null ? [] : action.payload.data.menu,
        GroupsByParents:
          action.payload.data.groups_by_parents === null
            ? []
            : action.payload.data.groups_by_parents,
        pages:
          action.payload.data.pages === null ? [] : action.payload.data.pages,
      };
    case GET_DOCUMET_TYPES:
      return {
        ...state,
        documentTypes: action.payload.data,
      };
    case GET_MARITAL_STATUS:
      return {
        ...state,
        maritalStatus: action.payload.data,
      };
    case GET_BLOOD_TYPES:
      return {
        ...state,
        bloodTypes: action.payload.data,
      };
    case GET_AGREEMENTS:
      return {
        ...state,
        agreements: action.payload.data,
      };
    case GET_HEALTH_ENTITIES:
      return {
        ...state,
        healthEntities: action.payload.data,
      };
    case GET_DISCOUNTS_TYPES:
      return {
        ...state,
        discountTypes: action.payload.data,
      };
    case GET_CITIES:
      return {
        ...state,
        cities: action.payload.data,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload.data,
      };
    case GET_COUNTRY:
      return {
        ...state,
        countries: action.payload.data,
      };
    case GET_CONTACTS:
      return {
        ...state,
        contactsType: action.payload.data,
      };
    case GET_PAYMENT_FORMS:
      return {
        ...state,
        paymentForms: action.payload.data,
      };
    case GET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload.data,
      };
    case GET_REGIME_TYPE:
      return {
        ...state,
        regimeTypes: action.payload.data,
      };
    case GET_PERSON_TYPE:
      return {
        ...state,
        personTypes: action.payload.data,
      };

    case GET_VALUES:
      return {
        ...state,
        values: action.payload.data,
      };
    case GET_CONFIG_CALENDAR:
      return {
        ...state,
        configCalendar: action.payload.data,
      };
    case GET_PATIENTS:
      return {
        ...state,
        patients: action.payload.data,
      };
    case GET_PATIENT_LARAVEL:
      return {
        ...state,
        patient_laravel: action.payload.data,
      };
    case GET_SOCIAL_NETWORKS:
      return {
        ...state,
        socialNetworks: action.payload.data,
      };
    case GET_ETHNIC_GROUPS:
      return {
        ...state,
        ethnicGroups: action.payload.data,
      };
    case GET_SEXUAL_ORIENTATION:
      return {
        ...state,
        sexualOrientation: action.payload.data,
      };
    case GET_GENDER:
      return {
        ...state,
        gender: action.payload.data,
      };
    case GET_BANK:
      return {
        ...state,
        banks: action.payload.data,
      };
    case GET_FINDDOCTOR:
      return {
        ...state,
        findDoctors: action.payload.data,
      };
    case GET_PLAN:
      return {
        ...state,
        plans: action.payload.data,
      };
    case GET_RANGO:
      return {
        ...state,
        rangos: action.payload.data,
      };
    case GET_AFILIACION:
      return {
        ...state,
        afiliaciones: action.payload.data,
      };
    case GET_ESCOLARIDAD:
      return {
        ...state,
        escolaridades: action.payload.data,
      };
    case GET_OCUPACION:
      return {
        ...state,
        ocupaciones: action.payload.data,
      };
    case GET_ORIGENCONTACTO:
      return {
        ...state,
        origenContactos: action.payload.data,
      };
    case GET_PARENTESCO:
      return {
        ...state,
        parentescos: action.payload.data,
      };
    case GET_FUNCIONALIDAD:
      return {
        ...state,
        funcionalidad: action.payload.data,
      };
    case GET_FUNCIONALIDAD_X_USUARIO:
      return {
        ...state,
        funcionalidadXUsuario: action.payload.data,
      };
    case GET_UNIDADES_MEDIDA:
      return {
        ...state,
        unidadesMedida: action.payload.data,
      };
    case GET_REPORTE_1:
      return {
        ...state,
        datosReporte1: action.payload.data,
      };
    case SIGNALRCON:
      return {
        ...state,
        signalRConexion: action.payload,
      };
    case SIGNALRPROXY:
      return {
        ...state,
        signalRProxy: action.payload,
      };
    case SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case GET_BODY_SYSTEMS: // el tipo de la accion getBodySystems el cual nos permite colocar los datos en el respectivo key del reducer
      return {
        ...state,
        bodySystems: action.payload.data, // los datos que son enviados en la accion getBodySystems
      };
    case GET_LISTS_SEARCHXEXAMS: // el tipo de la accion getBodySystems el cual nos permite colocar los datos en el respectivo key del reducer
      return {
        ...state,
        listsSearchxExams: action.payload.data, // los datos que son enviados en la accion getBodySystems
      };
    case GET_TYPES_DATAXEXAMS: // el tipo de la accion getBodySystems el cual nos permite colocar los datos en el respectivo key del reducer
      return {
        ...state,
        typesDataxExams: action.payload.data, // los datos que son enviados en la accion getBodySystems
      };
    case GET_VARIABLE_UNITSXEXAMS: // el tipo de la accion getBodySystems el cual nos permite colocar los datos en el respectivo key del reducer
      return {
        ...state,
        variableUnitsxExams: action.payload.data, // los datos que son enviados en la accion getBodySystems
      };
    case GET_SYMPTOMS:
      return {
        ...state,
        symptoms: action.payload.data,
      };
    case GET_VALUESXSEARCHLIST:
      return {
        ...state,
        valuesxSearchList: action.payload.data,
      };
    case SET_EXECUTING_REQUEST_GENERIC:
      return {
        ...state,
        executingRequest: action.value,
      };
    case GET_GROUP_CUPS:
      return {
        ...state,
        groupCups: action.payload.data,
      };
    case GET_CUPSTECNICA:
      return {
        ...state,
        cupsTecnica: action.payload.data,
      };
    case GET_DIAGNOSTICOS_CIE: //
      return {
        ...state,
        diagnosticosCie: action.payload.data, // l
      };
    case GET_BACKGROUND:
      return {
        ...state,
        background: action.payload.data,
      };
    case GET_ANTECEDENT_GROUP:
      return {
        ...state,
        antecedentGroup: action.payload.data,
      };
    case GET_PHYSICAL_REGION:
      return {
        ...state,
        physicalRegion: action.payload.data,
      };
    case GET_ANTHROPOMETRICA_MEASURES:
      return {
        ...state,
        anthropometricaMeasures: action.payload.data, // los datos que son enviados en la accion getBodySystems
      };
    default:
      return state;
  }
}
