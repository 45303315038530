/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import moment from "moment";
import MenuIcon from "../../assets/img/icons/square-dots-menu-icon.png";
import {
  ContainerBotonesMenu,
  OnTopBarSwitchAndHour,
  ContainerWelcomeName,
  OnTopBarSwitchAndHourPatient,
  ContainerBotonesMenuPatient,
} from "./BotonesMenu.style";
import { set } from "core-js/core/dict";

function BotonesMenu({
  userInfo,
  onRedirect,
  onGoUrl,
  goToFinancial,
  idMedico,
  receiveOffersState,
  handleChangeSwitch,
  today,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isSelected, setIsSelected] = useState(null);

  const handleSelection = (key) => {
    setIsSelected(key);
  };

  const handleMenuOff = () => {
    const match = window.matchMedia("(pointer:coarse)");
    if (match && match.matches) {
      // setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (window.location?.hash) {
      const siteHash = window.location?.hash;
      if (siteHash.includes("/CitasMedico/MisCitas")) {
        setIsSelected("MisConsultas");
      }
      if (siteHash.includes("/ClinicHistory/Authorization/Patient")) {
        setIsSelected("AutHC");
      }
      if (siteHash.includes("/Dashboard")) {
        setIsSelected("Agendar");
      }
      if (siteHash.includes("/ClinicHistory/patient")) {
        setIsSelected("MisHC");
      }
      // console.log(window.location?.hash);
    }
  }, [window.location?.hash]);

  return (
    <>
      {userInfo.profile.profile_name == "Paciente" && (
        <>
          <ContainerWelcomeName>
            <span>Hola, {userInfo.full_name}</span>
          </ContainerWelcomeName>
          <OnTopBarSwitchAndHourPatient>
            <div className="container-date-hour">
              <div>
                <i
                  className="fa fa-calendar-o text-primary"
                  aria-hidden="true"
                />
                <span>{moment().format("MM/DD/YYYY")}</span>
              </div>
              <div>
                <i className="fa fa-clock-o text-primary" aria-hidden="true" />
                <span>{today}</span>
              </div>
            </div>
            <div
              className="container-open-menu-btn"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <i
                className="fa fa-bars fa-2x"
                aria-hidden="true"
                style={{ color: isMenuOpen ? "darkgray" : "orange" }}
              />
            </div>
          </OnTopBarSwitchAndHourPatient>
          {isMenuOpen && (
            <ContainerBotonesMenuPatient>
              <button
                onClick={() => {
                  onRedirect("/Dashboard");
                  handleSelection("Agendar");
                }}
                style={{
                  borderBottom:
                    isSelected === "Agendar" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-calendar-plus-o fa-3x"></i>
                <span>Agendar mi Cita</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/CitasPaciente/IniciarVideoConsulta");
                  handleSelection("VideoConsulta");
                }}
                style={{
                  borderBottom:
                    isSelected === "VideoConsulta"
                      ? "orange 4px solid"
                      : "none",
                }}
              >
                <i className="fa fa-laptop fa-3x"></i>
                <span>Iniciar mi Videoconsulta</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/CitasPaciente/CitasPaciente");
                  handleSelection("Citas");
                }}
                style={{
                  borderBottom:
                    isSelected === "Citas" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-calendar fa-3x"></i>
                <span>Mis Citas</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/CitasPaciente/ReprogramarCitas");
                  handleSelection("Reprogramar");
                }}
                style={{
                  borderBottom:
                    isSelected === "Reprogramar" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-calendar-check-o fa-3x"></i>
                <span>Reprogramar mi cita</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/medicalExam/loadMedicalExam/");
                  handleSelection("MisExamenes");
                }}
                style={{
                  borderBottom:
                    isSelected === "MisExamenes" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-file-text-o fa-3x"></i>
                <span>Mis exámenes</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/ClinicHistory/patient");
                  handleSelection("MisHC");
                }}
                style={{
                  borderBottom:
                    isSelected === "MisHC" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-files-o fa-3x"></i>
                <span>Mis historias clínicas</span>
              </button>
              <button
                onClick={() => {
                  onRedirect("/ClinicHistory/Authorization/Patient");
                  handleSelection("AutHC");
                }}
                style={{
                  borderBottom:
                    isSelected === "AutHC" ? "orange 4px solid" : "none",
                }}
              >
                <i className="fa fa-check-square-o fa-3x"></i>
                <span>Autorización HC</span>
              </button>
            </ContainerBotonesMenuPatient>
          )}
        </>
      )}
      {userInfo.profile.profile_name == "Medico" &&
        userInfo.state_create !== "PV" && (
          <>
            <ContainerWelcomeName>
              <span>Hola, {userInfo.full_name}</span>
            </ContainerWelcomeName>
            <OnTopBarSwitchAndHour>
              {idMedico && receiveOffersState !== "na" && (
                <div className="container-switch">
                  <Switch
                    height={20}
                    onChange={handleChangeSwitch}
                    checked={receiveOffersState}
                  />
                  <span>Recibir Ofertas</span>
                </div>
              )}
              <div className="container-date-hour">
                <div>
                  <i
                    className="fa fa-calendar-o text-primary"
                    aria-hidden="true"
                  />
                  <span>{moment().format("MM/DD/YYYY")}</span>
                </div>
                <div>
                  <i
                    className="fa fa-clock-o text-primary"
                    aria-hidden="true"
                  />
                  <span>{today}</span>
                </div>
              </div>
              <div
                className="container-open-menu-btn"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <i
                  className="fa fa-bars fa-2x"
                  aria-hidden="true"
                  style={{ color: isMenuOpen ? "darkgray" : "orange" }}
                />
              </div>
            </OnTopBarSwitchAndHour>
            {isMenuOpen && (
              <ContainerBotonesMenu>
                <button
                  onClick={() => {
                    onRedirect("/CitasMedico/MisCitas");
                    handleSelection("MisConsultas");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "MisConsultas"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-stethoscope fa-3x"></i>
                  <span>Mis Consultas</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/schedule/schedule");
                    handleSelection("MisAgendas");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "MisAgendas" ? "orange 4px solid" : "none",
                  }}
                >
                  <i className="fa fa-calendar-o fa-3x"></i>
                  <span>Mis Agendas</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/medicalExam/loadMedicalExam");
                    handleSelection("VerCargarExamenes");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "VerCargarExamenes"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-file-text-o fa-3x"></i>
                  <span>Ver y Cargar Examenes</span>
                </button>
                <button
                  onClick={() => {
                    onGoUrl();
                    handleSelection("AtencionPacientes");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "AtencionPacientes"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-user-o fa-3x"></i>
                  <span>Atención Pacientes</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/MisVideoConsultas");
                    handleSelection("MedicoVideoConsulta");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "MedicoVideoConsulta"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-laptop fa-3x"></i>
                  <span>Iniciar mi Videoconsulta</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/InterConsultas");
                    handleSelection("InterConsultas");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "InterConsultas"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-user-md fa-3x" />
                  <span>InterConsultas</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/ExamenesProcedimientos");
                    handleSelection("ExamenesProcedimientos");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "ExamenesProcedimientos"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-medkit fa-3x" />
                  <span>Exámenes y Procedimientos</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/Medico/ConfigsMedico");
                    handleSelection("ConfigsMedico");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "ConfigsMedico"
                        ? "orange 4px solid"
                        : "none",
                  }}
                >
                  <i className="fa fa-cog fa-3x" />
                  <span>Mis Config Medicas</span>
                </button>
                <button
                  onClick={() => {
                    onRedirect("/Medico/ConfigsHC");
                    handleSelection("ConfigsHC");
                    handleMenuOff();
                  }}
                  style={{
                    borderBottom:
                      isSelected === "ConfigsHC" ? "orange 4px solid" : "none",
                  }}
                >
                  <i className="fa fa-pencil-square-o fa-3x" />
                  <span>Mis Config Hist Clinica</span>
                </button>
                {/* <button
                 onClick={() => {
                  goToFinancial();
                  handleSelection("Financiero");
                  handleMenuOff();
                }}
                  style={{
                    borderBottom:
                      isSelected === "ConfigsHC" ? "orange 4px solid" : "none",
                  }}
                >
                  <i className="fa fa-credit-card fa-3x" />
                  <span>Financiero</span>
                </button> */}
              </ContainerBotonesMenu>
            )}
          </>
        )}
    </>
  );
}

export default BotonesMenu;
