/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Button, Col, Row, Card, CardBody } from "reactstrap";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import metodosdepago from "../../assets/img/metodos-pago.png";
import DocHoyLoading from "../../assets/img/DrHoy_loading.gif";

import ManitoLogo from "../../assets/img/manito.jpeg";

import Ofertas from "../../Components/Contents/CitaEspera/Ofertas";
/**
 * @author Pedro Camilo Males
 * @file OfertasASolicitud.jsx
 * @description muestra las ofertas para consultas hoy
 */

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #ff8b0d;
`;
function OfertasASolicitud({
  ofertas,
  loading,
  onSeleccionar,
  citaEsperaSelected,
  citas,
  specialities,
  confirmAndPay,
  newOfertEntry,
  cities,
  careType,
  nuevasCitasEnOferta,
}) {
  // console.log("nuevasCitasEnOferta", nuevasCitasEnOferta);
  // console.log("ofertas", ofertas);

  useEffect(() => {
    if (citas.length > 0) {
      onSeleccionar(citas[0]);
    }
  }, [citas]);

  const onRedirect = () => {
    window.history.go(0);
  };

  const getTipoConsulta = (cita) => {
    var claseAtencion = "";
    switch (cita.clase_atencion) {
      case "0":
        claseAtencion = "Consultorio";
        break;
      case "1":
        claseAtencion = "Videoconsulta";
        break;
      case "2":
        claseAtencion = "Atención en casa";
        break;
    }

    return (
      <Row className={`p-3`}>
        <Col md={12}>
          <span className="span-bold-blue">Especialidad: </span>
          <p>
            <span className="strong-blue">{cita.especialidad}</span>
          </p>
        </Col>
        <Col md={12}>
          <span className="span-bold-blue">Tipo de atención:</span>
          <p>
            <span className="strong-blue">{claseAtencion}</span>
          </p>
        </Col>
        <Col md={12}>
          <span className="span-bold-blue">Motivo:</span>
          <p>
            <span className="strong-blue">{cita.observaciones}</span>
          </p>
        </Col>
        {cita.id !== citaEsperaSelected && (
          <Col md={12} className=" mt-2">
            <Button color="primary" onClick={() => onSeleccionar(cita)}>
              Revisar
            </Button>
          </Col>
        )}
      </Row>
    );
  };
  return (
    <>
      {citas.length > 1 && (
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <h3 className="span-warning">Historial de solicitudes</h3>
              </Col>
              {citas.length > 0 &&
                citas.map((cita, index) => (
                  <Col
                    key={index}
                    md={3}
                    className={
                      cita.id == citaEsperaSelected
                        ? "border  box-success-card"
                        : ""
                    }
                  >
                    {getTipoConsulta(cita)}
                  </Col>
                ))}
            </Row>
          </CardBody>
        </Card>
      )}
      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <Row>
                <Col md="12">
                  <h1 className="span-warning">
                    Ofertas médicas a tu solicitud para hoy mismo
                  </h1>
                  {nuevasCitasEnOferta.length == 0 ? (
                    <strong>
                      Te notificaremos cuando tengamos alguna oferta
                    </strong>
                  ) : (
                    <strong className="strong-blue">
                      Selecciona el médico de tu preferencia, agenda y paga
                    </strong>
                  )}
                </Col>
                {/* {loading && <ClipLoader color={color} loading={loading} css={override} size={50} /> } */}
                {/* {true && <div className="preloader-dochoy"><img src={DocHoyLoading} /></div> } */}
                {nuevasCitasEnOferta.length == 0 ? (
                  <>
                    <Col md={12} className="text-center">
                      <Row className="justify-content-center">
                        <Col md={6} className="mt-3 mb-3 text-center">
                          <img src={ManitoLogo} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="text-center">
                      <span
                        className="text-center"
                        style={{ color: "#569cbf", fontWeight: "bold" }}
                      >
                        Aún no tienes ofertas
                      </span>
                      {citas && citas.length > 0 && (
                        <p className="grey">
                          Si en 15 minutos no hay respuesta de algún médico, te
                          conectaremos con nuestro servicio de apoyo
                        </p>
                      )}
                    </Col>
                    {citas && citas.length > 0 && (
                      <Col md={12} className="text-center">
                        <Row className="justify-content-center">
                          <Col md={12}>
                            <Button
                              variant="primary"
                              color="primary"
                              className=" btn-lg  pt-2 pb-2"
                              // onClick={() =>
                              //   onSeleccionar({ id: citaEsperaSelected })
                              // }
                              onClick={onRedirect}
                            >
                              Actualizar resultados de mi busqueda
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="mt-3 col-md-12">
                    {nuevasCitasEnOferta?.map((oferta) => (
                      // eslint-disable-next-line react/jsx-key
                      <Ofertas
                        data={oferta}
                        imgMetodosPago={metodosdepago}
                        confirmAndPay={confirmAndPay}
                        countOferts={ofertas.length}
                        newOfertEntry={newOfertEntry}
                        cities={cities}
                        citaEsperaSelected={citaEsperaSelected}
                        careType={careType}
                      />
                    ))}
                    <Row>
                      <Col className="mt-5">
                        <Button
                          variant="primary"
                          color="primary"
                          className="mr-2 btn-lg pl-4 pr-4 pt-2 pb-2"
                          onClick={() =>
                            onSeleccionar({ id: citaEsperaSelected })
                          }
                        >
                          Ver mas resultados
                        </Button>
                      </Col>
                      <Col>
                        <p className="grey" style={{ fontWeight: "bolder" }}>
                          Recuerde que tiene 30 minutos para realizar el pago
                          después de recibir la oferta o su oferta será
                          cancelada.
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

OfertasASolicitud.propTypes = {
  ofertas: PropTypes.array,
  loading: PropTypes.bool,
  onSeleccionar: PropTypes.func,
  citaEsperaSelected: PropTypes.string,
  citas: PropTypes.array,
  specialities: PropTypes.array,
};

export default OfertasASolicitud;
