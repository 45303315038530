/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from "yup";

const schema = yup.object().shape({
  celular: yup.string().required("Telefono es requerido"),
  name: yup.string().required("Nombre es requerido"),
  document_type: yup.object().required('Tipo de documento es requerido').nullable(),
  document: yup.string().required("Documento es requerido"),
  gender: yup.object().required("Genero es requerido").nullable(),
  // race: yup.object().required("Etnia es requerida"),
  email: yup.string().email().required("Email es requerido"),
  country_birth: yup.object().required("Pais de nacimiento es requerido").nullable(),
  department_birth: yup.object().required("Departamento de nacimiento es requerido").nullable(),
  city_birth: yup.object().required("Ciudad de nacimiento es requerido").nullable(),
  // country_resident: yup.object().required("Pais de residencia es requerida").nullable(),
  // department_resident: yup.object().required("Departamento de residencia es requerida").nullable(),
  // city_resident: yup.object().required("Ciudad de nacimiento es requerida").nullable(),
});

export default schema;
