import React, { Component } from "react";
import { connect } from "react-redux";
import Geocode from 'react-geocode';
import StickyHeader from 'react-sticky-header';
import logoDh1 from "../../assets/img/icons/register_doctor.png";
import { UncontrolledTooltip } from 'reactstrap';
import { Badge } from 'reactstrap';
import PropTypes from "prop-types";
import { getDetalleCarroComprasUrl } from "../../redux/actions/types";
import { GetDetalleCarroCompras } from "../../redux/actions/DoctorAction";

import { siteHost } from "../../enviroment";

class BarraSuperior extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: "-",
      longitude: "-",
      nombreCiudadGps: '',
      canasta: <li id='menuCarroCompras'></li>,
      estaLogueado: false,
      formOption: ""
    };

    this.pintarCanasta = this.pintarCanasta.bind(this);
  }

  componentDidMount() {
    Geocode.setApiKey('AIzaSyDu6QvuLJZa4rgHMS0WHeW87Q8Qx0ubDhw');
    this.getMyLocation();
    this.pintarCanasta();
    this.getOption();
  }

  componentWillMount() {

  }

  getOption(){
     if(window.location.href.includes('Doctor')){
       this.state.formOption = "Doctor";
     }else{
       this.state.formOption = "Patient";
     }
  }


  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation;
    //  const location =  window;
    //  console.log('location');
    if (location) {
      location.getCurrentPosition((position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
          response => {
            //    console.log(response);
            var child = response.results.find(
              x => x.types[0] === 'administrative_area_level_2');


            if (child != null) {
              this.setState({ nombreCiudadGps: child.formatted_address });
            }
          },
          () => {
          }
        );
      }, () => {
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' });
      });
    } else {
      // console.log('Sin permisos');
      this.setState({ nombreCiudadGps: 'Sin Permiso' });
    }
  }

  pintarCanasta() {
    this.props.GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
      action_type: 0
    }).then(resp => {
      if (resp.code === 1001) {
        //   console.log('0000');
        this.setState({ canasta: <li id='menuCarroCompras'></li> });
        return;
      }
      if (resp.code === 2001) {
        //    console.log('1111');
        this.setState({ canasta: <li id='menuCarroCompras'></li> });
        return;
      }

      if (resp.data != null) {
        if (resp.data.length == 0) {
          //  console.log('2222');
          this.setState({ canasta: <li id='menuCarroCompras'></li> });
          this.setState({ estaLogueado: true });
        } else {
          //   console.log('3333');
          this.setState({ canasta: <li id='menuCarroCompras'><a href="/#/Summary"><i style={{ fontSize: 20 }} className="fa fa-shopping-basket fa-xs"></i></a> <Badge color="primary">{resp.data.length}</Badge></li> });
          this.setState({ estaLogueado: true });
        }

      } else {
        //  console.log('4444');
        this.setState({ canasta: <li id='menuCarroCompras'></li> });
      }
    });
  }

  render() {
    return (
      <StickyHeader

        header={
          <header className="header_sticky">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-2">
                  <div id="logo_home">
                    <h1><a href={siteHost}>DoctorHoy</a></h1>
                    <UncontrolledTooltip placement="bottom" target="logo_home">
                      Regresa a la pagina inicial
                        </UncontrolledTooltip>
                  </div>
                </div>

                {/* <nav className="col-lg-10 col-10">
                  <ul id="top_access">
                    <li>{(localStorage.token !== undefined && localStorage.name !== undefined) ? "Hola " + localStorage.name.replace('"', '').replace('"', '') : ''}</li>

                    {this.state.canasta}

                    {(localStorage.token !== undefined && localStorage.name !== undefined) ? <li id='menuIngresoLogin' style={{ "display": "none" }}></li> : <li id='menuIngresoLogin'><a href="/#/login"><i className="pe-7s-user"></i></a></li>}
                    {(localStorage.token !== undefined && localStorage.name !== undefined) ? <li id='menuRegistroPaciente' style={{ "display": "none" }}></li> : this.state.formOption === 'Doctor' ? <li id='menuRegistroPaciente'><a href="/#/NewPersonRegister" className="btn btn-warning" style={{ "marginTop": "-10px", "font-size": "18px" }}><i className="fa fa-user-plus" style={{ "font-size": "20px" }}></i> Regístrarme como paciente</a></li>:<li id='menuRegistroPaciente'><a href="/#/NewPersonRegister"><i className="pe-7s-add-user"></i></a></li>}
                    {(localStorage.token !== undefined && localStorage.name !== undefined) ? <li id='menuRegistraMedico' style={{ "display": "none" }}></li> : this.state.formOption === 'Patient' ? <li id='menuRegistraMedico'><a href="/#/NewDoctorRegister" className="btn btn-warning" style={{ "marginTop": "-10px", "font-size": "18px" }}><i className="fa fa-user-plus" style={{ "font-size": "20px" }}></i> Regístrarme como médico</a></li>:<li id='menuRegistraMedico'>
                      <a href="/#/NewDoctorRegister"><i className="pe-7s-add-user"></i></a></li>}
                    {(localStorage.token !== undefined && localStorage.name !== undefined) ? <li><a href="/#/Dashboard"><i className="pe-7s-more"></i></a></li> : ''}
                  </ul>
                  <UncontrolledTooltip placement="bottom" target="menuRegistraMedico">
                    Regístrame como médico
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="menuIngresoLogin">
                    Iniciar sesión
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="menuRegistroPaciente">
                    Registrarme como paciente
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="bottom" target="menuCarroCompras">
                    Ir al carro de compras
                  </UncontrolledTooltip>

                  <div className="main-menu">
                    <ul>
                      <li className="submenu">
                        {this.state.nombreCiudadGps}
                      </li>

                    </ul>
                  </div>
                </nav> */}
              </div>
            </div>
          </header>
        }
      >
      </StickyHeader>
    );
  }
}

BarraSuperior.propTypes = {
  GetDetalleCarroCompras: PropTypes.func,
};

const mapStateToProps = state => ({
  getAll: state.doctor.getAll,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  {
    GetDetalleCarroCompras
  }
)(BarraSuperior);
