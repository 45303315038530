/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/**
 * @author Intelisoft
 * @file NewPersonRegister.js
 * @description Person register
 */

ReactDOM.render(<App />, document.getElementById('root'));