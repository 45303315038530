/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import { each, cloneDeep, findIndex } from "lodash";
import Swal from "sweetalert2";
// Constants
import {
  GET_USER_INFO,
  GET_USER_INFO_API,
  GET_USERS,
  GET_USERS_SET_PASSWORD,
  RESEND_MAIL,
  GET_USER_SET_APPOINTMENT,
  SET_LAST_ACTION_USER,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_TAB_VALUE,
  SET_EXECUTING_REQUEST_USER,
  SET_SEARCH_TERM,
  GET_INFO_USER_BY_TYPE,
  GET_PENDING_APPOINTMENTS_PATIENT,
} from "./types";

// Utils
import axios from "../../Utils/Http";
import { logout } from "../../redux/actions/authAction";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file userAction.js
 * @description Users actions
 */

const urlApi = require("../../enviroment").api;
const urlApiLaravel = require("../../enviroment").apiLaravel;

export function loginLaravel(resource, params) {
  return (dispatch) => {
    return axios.post(`${urlApiLaravel}${resource}`, params).then((res) => {
      if (res !== null && res.status === 200) {
      }
      return res.data;
    });
  };
}

export const setLastActionUser = (action) => ({
  type: SET_LAST_ACTION_USER,
  value: action,
});

export const setNotificationTabValue = (action) => ({
  type: SET_NOTIFICATION_TAB_VALUE,
  value: action,
});

export const setSearchTerm = (action) => ({
  type: SET_SEARCH_TERM,
  value: action,
});

export function getInfoUserByType(resource, params) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`, params).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_INFO_USER_BY_TYPE,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}
export function getUserApi(resource) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}${resource}`)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_USER_INFO_API,
            payload: res.data,
          });
          return res.data;
        } else {
          Swal.fire({
            title: "La sesión ha expirado, por favor vuelva a iniciar sesión.",
            icon: `info`,
          });
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "login";
        }
      })
      .catch(() => {
        Swal.fire({
          title: "La sesión ha expirado, por favor vuelva a iniciar sesión.",
          icon: `info`,
        });
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        window.location = "login";
      });
  };
}

export function getUserInfo2(url) {
  return axios
    .get(`${urlApi}${url}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("Error get user info2 coredh", error);
    });
}

export function getAddressUser(user_id) {
  return axios
    .get(`${urlApiLaravel}api/v1/patients/${user_id}/addresses`)
    .then((res) => {
      return res;
    })
    .catch(() => {
      console.log("No fue posible obtener direccion de residencia del usuario");
      // Swal.fire({
      //   title: "No fue posible obtener direccion de residencia del usuario",
      //   icon: `info`,
      //   timer: 3000,
      //   timerProgressBar: true,
      // });
    });
}

export function getAddressUserLogin(user_id) {
  return (dispatch) => {
    return axios
      .get(`${urlApiLaravel}api/v1/patients/${user_id}/addresses`)
      .then((res) => {
        return res;
      })
      .catch(() => {
        console.log(
          "No fue posible obtener direccion de residencia del usuario"
        );
        // Swal.fire({
        //   title: "No fue posible obtener direccion de residencia del usuario",
        //   icon: `info`,
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
      });
  };
}

export function saveAddressUser(info) {
  return axios
    .post(`${urlApiLaravel}api/v1/patient-addresses`, info)
    .then((res) => {
      return res;
    })
    .catch(() => {
      Swal.fire({
        title: "Error guardando direccion de residencia del usuario",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    });
}

export async function deleteAddressUser(id_address) {
  try {
    const res = await axios
      .delete(`${urlApiLaravel}api/v1/patient-addresses/${id_address}`)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Dirección eliminada con exito!",
            icon: `success`,
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        }
      });
    return res;
  } catch {
    Swal.fire({
      title: "Error eliminando dirección del usuario",
      icon: `error`,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}

export async function editAddressUser(id_address, info) {
  const res = await axios
    .put(`${urlApiLaravel}api/v1/patient-addresses/${id_address}`, info)
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          title: "Dirección editada con exito!",
          icon: `success`,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }
    })
    .catch((err) => {
      console.log("error", err?.response?.data?.errors[0]);
      const errors = err?.response?.data?.errors;
      const errorList = errors
        ?.map(
          (error, index) => `
    <ol className="text-start">
        <i className="fas fa-angle-double-right"></i>
        ${error}
    </ol>`
        )
        .join("");
      Swal.fire({
        icon: "error",
        confirmButtonText: "Volver",
        html: `<ul class="list-unstyled">${errorList}</ul>`,
      });
    });
}

export function getUserInfo(resource, getNotifications) {
  return (dispatch) => {
    return axios
      .get(`${urlApi}${resource}`)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(
            "username",
            JSON.stringify(res.data.data.username)
          );
          localStorage.setItem("name", JSON.stringify(res.data.data.name));
          localStorage.setItem("phone", JSON.stringify(res.data.data.phone));

          dispatch({
            type: GET_USER_INFO,
            payload: res.data,
          });

          dispatch(setLastActionUser("userInfoGot"));
          if (getNotifications) {
            const userId = res.data.data.id;
            dispatch(getUserNotification(userId));
          }

          return res.data;
        }
      })
      .catch((error) => {
        console.log("Error get user info coredh", error);
        Swal.fire({
          title: "La sesión ha expirado, por favor vuelva a iniciar sesión.",
          icon: `info`,
        });
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        window.location = "login";
      });
  };
}

export function getUserSetAppointment(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res.data.code === 0) {
        dispatch({
          type: GET_USER_SET_APPOINTMENT,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getUsers(resource) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}`).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: GET_USERS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function createNewUser(resource, user) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, user).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: GET_USERS,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

export function getUsersRecoveryPassword(resource, token) {
  return (dispatch) => {
    return axios.get(`${urlApi}${resource}/${token}`).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: GET_USER_INFO,
          payload: res.data,
        });
      }
      return res.data;
    });
  };
}

/**
 * Api Laravel
 */
export function setApiPatientStore(patient) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/patients`, patient)
      .then((res) => {
        if (res.status === 401) {
          Swal.fire({
            title: "La sesión ha expirado, por favor vuelva a iniciar sesión.",
            icon: `error`,
          });
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          window.location = "login";
        }
        if (res.data.code === 0) {
          dispatch({
            type: GET_USERS,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function GetCitaEnEsperaLaravelPatient(userId) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `${urlApiLaravel}api/v1/patients/${userId}/pending-appointments?pending_appointment_type=1`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PENDING_APPOINTMENTS_PATIENT,
            payload: res.data,
          });
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export function GetCitaEnEsperaLaravelPatientHomeCare(userId) {
  return async (dispatch) => {
    const response = await axios
      .get(
        `${urlApiLaravel}api/v1/patients/${userId}/pending-appointments?pending_appointment_type=3`
      )
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_PENDING_APPOINTMENTS_PATIENT,
            payload: res.data,
          });
        }
        return res.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return response;
  };
}

export function setApiResetPassword(user) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/reset-password`, user)
      .then((res) => {
        if (res.data.code === 0) {
          dispatch({
            type: GET_USERS_SET_PASSWORD,
            payload: res.data,
          });
        }
        return res.data;
      });
  };
}

export function updatePassword(resource, user) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, user).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: GET_USERS_SET_PASSWORD,
          payload: res.data,
        });

        return res.data;
      }
    });
  };
}

export function reSendMailRecoveryPassword(resource, user) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, user).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: RESEND_MAIL,
          payload: res.data,
        });

        return res.data;
      }
    });
  };
}

export function manageCompanyInfo(resource, company) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, company).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: GET_USER_INFO,
          payload: res.data,
        });

        return res.data;
      }
    });
  };
}

export const getUserNotification = (userId) => {
  return async (dispatch, getState) => {
    const finalUserId = userId ? userId : getState().user.userInfo.id;
    let showError = false;
    try {
      const res = await axios.get(
        `${urlApiLaravel}api/v1/notifications/${finalUserId}/user`
      );
      if (res.status === 200) {
        dispatch({ type: SET_NOTIFICATIONS, value: res.data.data });
        dispatch({
          type: SET_LAST_ACTION_USER,
          value: "getNotificationsSuccess",
        });
      }
      return res.data.data;
    } catch (e) {
      showError = true;
    }
  };
};

export function markNotificationAsViewLaravel(notification) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/v1/notifications/${notification}/markAsRead`)
      .then((res) => {
        if (res.status === 200) {
          // dispatch({
          //   type: GET_USER_INFO,
          //   payload: res.data,
          // });

          return res.data;
        }
      });
  };
}

export const markNotificationAsView = (notification) => {
  return async (dispatch, getState) => {
    let notificationCopy = cloneDeep(getState().user.notifications);
    const index = findIndex(getState().user.notifications, {
      id: notification.id,
    });
    notificationCopy[index].executingRequest = true;

    dispatch({ type: SET_NOTIFICATIONS, value: notificationCopy });

    let showError = false;
    let res = null;
    const newStatus = true;
    try {
      res = await axios.post(
        `${urlApi}generic/actualizar_estado_notificacion`,
        {
          id: notification.id,
          status: newStatus,
        }
      );

      if (res.data.code !== 0) {
        showError = true;
      }
    } catch (e) {
      showError = true;
    }

    notificationCopy = cloneDeep(getState().user.notifications);
    if (!showError) {
      notificationCopy[index].status = newStatus;
    }

    notificationCopy[index].executingRequest = false;
    dispatch({ type: SET_NOTIFICATIONS, value: notificationCopy });

    if (!showError) {
      dispatch({
        type: SET_LAST_ACTION_USER,
        value: "notificationStatusUpdated",
      });
      return res.data.data;
    }

    Swal.fire({
      title: "Error actualizando estado de notificación",
      icon: `error`,
      timer: 3000,
      timerProgressBar: true,
    });
    return null;
  };
};

export const markAllAsView = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_EXECUTING_REQUEST_USER, value: true });

    let notifications = cloneDeep(getState().user.notifications);
    const notificationIds = [];

    each(notifications, (notification) => {
      if (!notification.executingRequest && !notification.status) {
        notification.status = true;
        notificationIds.push(notification.id);
      }
    });

    if (!notificationIds.length) {
      dispatch({ type: SET_EXECUTING_REQUEST_USER, value: false });

      return Swal.fire({
        title: "No hay notificaciones para marcar",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }

    let showError = false;
    try {
      const res = await axios.post(
        `${urlApi}generic/actualizar_multiples_notificaciones`,
        {
          ids: notificationIds,
        }
      );

      if (res.data.code !== 0) {
        showError = true;
      } else {
        dispatch({ type: SET_EXECUTING_REQUEST_USER, value: false });
        dispatch({ type: SET_NOTIFICATIONS, value: notifications });
        dispatch({
          type: SET_LAST_ACTION_USER,
          value: "notificationStatusUpdated",
        });

        Swal.fire({
          title: "Todas las notificaciones han sida marcadas como vistas",
          icon: `success`,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
    } catch (e) {
      showError = true;
    }

    dispatch({ type: SET_EXECUTING_REQUEST_USER, value: false });

    if (showError) {
      return Swal.fire({
        title: "Error marcando todas las notificaciones como vistas",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };
};

// export const simulateRequest = (response, timeout) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(response);
//     }, timeout);
//   });
// };

export async function saveUserAgreement(body) {
  try {
    const res = await axios.post(
      `${urlApiLaravel}api/v1/patient-agreements`,
      body
    );
    return res;
  } catch {
    Swal.fire({
      title: "Error guardando convenio",
      icon: `error`,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}

export async function getUserAgreements(id) {
  try {
    const res = await axios.get(
      `${urlApiLaravel}api/v1/patient-agreements/${id}/patient`
    );
    if (res.status === 200) {
      return res.data.data;
    } else {
      return res;
    }
  } catch {
    Swal.fire({
      title: "Error cargando convenios",
      icon: `error`,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}

export async function editUserAgreement(id, body) {
  try {
    const res = await axios.put(
      `${urlApiLaravel}api/v1/patient-agreements/${id}`, body,
      body
    );
    return res;
  } catch {
    Swal.fire({
      title: "Error guardando convenio",
      icon: `error`,
      timer: 3000,
      timerProgressBar: true,
    });
  }
}

