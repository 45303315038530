import styled, { css } from "styled-components";

export const TobarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .arrow-back-container {
    margin-right: 20px;
    i {
      font-size: 20px;
      color: #fff;
      margin-right: 10px;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }
  .account-container {
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 15px 0px 15px;
    font-size: 12px;
    background-color: #080b2b;
    span {
      margin-left: 8px;
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
  .logout-container {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    padding: 0px 0px 2px 15px;
    i {
      display: none;
      @media (max-width: 480px) {
        font-size: 20px;
        color: #fff;
        margin-right: 10px;
        display: inherit;
      }
    }
    span {
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
  .notification-bell-container {
    margin-right: 30px;
    @media (max-width: 480px) {
      margin-right: 20px;
    }
    @media (max-width: 300px) {
      display: none;
    }
  }
`;

export const LogoTopBarContainer = styled.div`
  img {
    width: 110px;
    margin-left: 20px;
  }
  span {
    color: #fff;
    margin-left: 10px;
    @media (max-width: 780px) {
      display: none;
    }
  }
`;
