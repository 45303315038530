import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const keyMaps = require("../../enviroment").apiKeyGoogleMaps;

class MapContainerGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlaceName: '',
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick(props, marker) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      selectedPlaceName: marker.name
    });
  }

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{
          lat: 3.42385,
          lng: -76.54264
        }}
        center={this.props.centerMap}
      >
        {
          this.props.markers.map((markTemp, index) => (
            <Marker
              key={index}
              title={markTemp.title}
              name={markTemp.name}
              position={markTemp.position}
              onClick={this.onMarkerClick}
            />
          ))
        }
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
          <div>
            <h5>{this.state.selectedPlaceName}</h5>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

MapContainerGeneric.propTypes = {
  google: PropTypes.object,
  centerMap: PropTypes.object,
  markers: PropTypes.array,
};

export default GoogleApiWrapper({
  apiKey: (keyMaps)
})(MapContainerGeneric);
