const styles = {
  resizeMode: {
    objectFit: "contain",
  },
  notificationContainer: {
    cursor: "pointer",
    position: "relative",    
  },
  notificationContainerFill: {
    cursor: "pointer",
    position: "relative",
    marginRight: 10,
  },
  bellSize: {
    fontSize: 20,
  },
  badge: {
    backgroundColor: "red",
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    top: 0,
    right: 0,
  },
  noInfo: {
    marginTop: 20,
  },
  title: {
    marginRight: 45,
  },
  titleDate: {
    marginRight: 62,
  },
  titleHour: {
    marginRight: 73,
  },
  titleText: {
    color: "#4e7fa5",
    fontWeight: "bolder",
  },
  titleSender: {
    marginRight: 18,
  },
  notificationRow: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "lightgray",
    backgroundColor: "white",
    borderRadius: "15px",
    marginBottom: "10px",
    paddingLeft: "15px",
    paddingTop: "8px",
    paddingBottom: "11px",
  },
  notificationRowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  notificationRowContainerBlock85: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 85,
    fontSize: "initial",
  },
  notificationRowContainerBlock15: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 15,
  },
  overrideSpinner: {
    position: "relative",
    top: 0,
    bottom: 0,
    width: 15,
    height: 15,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    left: 0,
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "gray",
    borderStyle: "solid",
  },
  searchIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  inputInput: {
    width: "100%",
  },
};

export default styles;
