import { useEffect, useState } from "react";
import { Manager } from "socket.io-client";
import Swal from "sweetalert2";
import ManitoLogo from "../assets/img/manito.jpeg";

const useSocket = (url) => {
  const [statusConnection, setStatusConnection] = useState("Disconnected");

  const userId =
    localStorage.getItem("profile") === "Medico"
      ? localStorage.getItem("DoctorId")
      : localStorage.getItem("idUser1");

  useEffect(() => {
    const manager = new Manager(url, {
      query: {
        userId: userId?.toUpperCase(),
      },
    });

    const socket = manager.socket("/");

    socket.on("connect", () => {
      console.log("Connected to socket server...");
      setStatusConnection("Connected");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from socket server");
      setStatusConnection("Disconnected");
    });

    socket.on("notification", (notification) => {
      console.log("NEW NOTIFICATION", notification);
      const channels = [
        "appointment.accepted",
        "appointment.offered",
        "pending-appointment.created",
      ];

      if (notification?.data?.channel === "appointment.accepted") {
        Swal.fire({
          title: notification?.data?.title,
          html: notification?.data?.message,
          imageUrl: notification.icon ? notification.icon : ManitoLogo,
          imageWidth: 200,
          imageHeight: 200,
        }).then(() => {
          if (
            window.location.href.includes("CitasMedico") ||
            window.location.href.includes("/InterConsultas")
          ) {
            window.location.reload();
          }
        });
      }
      if (notification?.data?.channel === "appointment.offered") {
        Swal.fire({
          title: notification?.data?.title,
          html: notification?.data?.message,
          imageUrl: notification.icon ? notification.icon : ManitoLogo,
          imageWidth: 200,
          imageHeight: 200,
        }).then(() => {
          if (
            window.location.href.includes("/Dashboard") ||
            window.location.href.includes("/InterConsultas")
          ) {
            window.location.reload();
          }
        });
      }
      if (notification?.data?.channel === "pending-appointment.created") {
        Swal.fire({
          title: notification?.data?.title,
          html: notification?.data?.message,
          imageUrl: notification.icon ? notification.icon : ManitoLogo,
          imageWidth: 200,
          imageHeight: 200,
        }).then(() => {
          if (window.location.href.includes("consultasSinOfertas")) {
            window.location.reload();
          }
        });
      }
      if (!channels.includes(notification?.data?.channel)) {
        Swal.fire({
          title: notification?.data?.title,
          html: notification?.data?.message,
          imageUrl: notification.icon ? notification.icon : ManitoLogo,
          imageWidth: 200,
          imageHeight: 200,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [url]);

  return statusConnection;
};

export default useSocket;
