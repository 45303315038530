import axios from "axios";
const urlMessagebird = require("../../enviroment").urlMessagebird;
const userMB = require("../../enviroment").userMB;
const passMB = require("../../enviroment").passMB;


function generatetoken() {

  let body = { user: userMB, password: passMB };

  return axios.post(urlMessagebird + '/auth', body)
    .then(function (response) {
      if (response.data.status === 200)
        return response.data.token;
    })
    .catch(function (error) {
      // console.log(error);
    });

}

export function consApi(body, type) {
  return new Promise((resolve, reject) => {
    if (!body.cellphone) {
      return reject('No es posible realizar la llamada ya que no se cuentan con el número celular del paciente');
    }

    return generatetoken().then(token => {
      return axios.post(urlMessagebird + type, body, {
        headers: {
          'Content-Type': 'application/json',
          'access-token': token,
        }
      }).then((response) => {
        if (response.data.status !== 200) {
          return reject('Respuesta erronea');
        }

        return resolve();
      }).catch((error) => {
        /*Manejo de error determinado por el cliente, se debería mostrar una alerta indicando la indisponibilidad*/
        // console.log(error);
        return reject('Un error ha ocurrido');
      });
    });
  });
}

export function sendSMS(body, type) {
  return new Promise((resolve, reject) => {
    if (!body.cellphone) {
      // console.log('No es posible enviar un sms ya que no se cuentan con el número celular del paciente');
      return reject('No es posible enviar un sms ya que no se cuentan con el número celular del paciente');
    }

    return generatetoken().then(token => {
      return axios.post(urlMessagebird + type, body, {
        headers: {
          'Content-Type': 'application/json',
          'access-token': token,
        }
      }).then((response) => {
        if (response.data.status !== 200) {
          return reject('Respuesta erronea');
        }

        return resolve();
      }).catch((error) => {
        /*Manejo de error determinado por el cliente, se debería mostrar una alerta indicando la indisponibilidad*/
        // console.log(error);
        return reject('Un error ha ocurrido');
      });
    });
  });
}