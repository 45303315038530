/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from "yup";

const schema = yup.object().shape({
  terminos_aceptados: yup
    .boolean()
    .oneOf([true], "Debes de aceptar terminos y condiciones"),
  name: yup.string().required("Nombre es requerido"),
  last_name: yup.string().required("Apellido es requerido"),
  username: yup.string().required("Username es requerido"),
  phone: yup.string().required("Telefono es requerido"),
  address: yup.string().required("Dirección es requerida"),
  document_type: yup.object().required("Tipo de documento es requerido"),
  document: yup.string().required("Documento es requerido"),
  email: yup.string().email().required("Email es requerido"),
  password: yup.string().required("Contraseña es requerido"),
  Pass2Paciente: yup
    .string()
    .oneOf([yup.ref("password"), null], "Debe coincidir con tu contraseña"),
  residence_country: yup.object().required("Pais es requerido"),
  residence_department: yup.object().required("Departamento es requerido"),
  residence_city: yup.object().required("Departamento es requerido"),
});

export default schema;
