import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { userInfo } from "../../redux/actions/types";
import { getUserInfo } from "../../redux/actions/userAction";
import PropTypes from "prop-types";

class ButtonDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillMount() {
    if (!this.props.skipActions) {
      this.props.getUserInfo(userInfo);
      this.props.onRef(undefined);
    }
  }

  render() {
    return (
      <div>
        <Button
          size="sm"
          color="danger"
          tooltip="Eliminar"
          onClick={this.props.delete.bind(this, this.props.row)}
        //    disabled={
        //      this.props.userInfo.access_profile !== undefined
        //        ? !this.props.userInfo.access_profile.delete
        //        : true
        //    }
        >
          <i className="fa icon-trash" />
        </Button>
      </div>
    );
  }
}

ButtonDelete.propTypes = {
  delete: PropTypes.func,
  getUserInfo: PropTypes.func,
  onRef: PropTypes.func,
  row: PropTypes.any, // pending
  skipActions: PropTypes.bool,
};

ButtonDelete.contextTypes = {};

const mapStateToProps = state => ({
  userInfo: state.user.userInfo
});

export default connect(
  mapStateToProps,
  {
    getUserInfo
  }
)(ButtonDelete);
