import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { each } from "lodash";

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import { connect } from "react-redux";
import { getUserMenu } from "../../redux/actions/GenericAction";
import { getUserInfo } from "../../redux/actions/userAction";
import { PendingAppointments } from "../../redux/actions/DoctorAction";
import { userMenu, userInfo } from "../../redux/actions/types";
import PropTypes from "prop-types";
import RefreshToken from "../../views/Generic/RefreshToken";
import { logout } from "../../redux/actions/authAction";
import ConnectSR from "../../views/Generic/Components/ConnectSR";
import "./WhatsApp.css";
import newBackground from "../../assets/img/new-background-dh.jpg";

import Menu from "../../Components/Templates/Menu";

import { objectNotEquals } from "../../Utils";
import ConnectToServer from "../../Components/SocketClient/socket-client";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendRequest: false,
      userMenu: [],
    };
    this.goRedirectPatient = this.goRedirectPatient.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentWillMount() {
    this.setState({ sendRequest: true });
    this.props.getUserMenu(userMenu).then((resp) => {
      this.setState({ sendRequest: false });
      if (resp.code === 1001) {
        this.RefreshToken.toggle();
      }
      if (resp.code === 2001) {
        this.props.logout();
        this.context.router.history.push("/login");
      }
    });
  }

  NameRouteLayout(routess, currents) {
    let titleRoute = "";
    let routes = routess;
    let current = currents;

    routes.map((route) => {
      if (current === route.path) {
        titleRoute = route.name;
      }
    });
    if (titleRoute !== "") {
      return <div className="title-route"> {titleRoute}</div>;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (objectNotEquals(this.props.userMenu, nextProps.userMenu)) {
      this.inyectUserTokenToHistoriaClinicaUrl(
        nextProps.userMenu,
        nextProps.userMenu
      );
    }
  }

  inyectUserTokenToHistoriaClinicaUrl(userMenu, originalNextProps) {
    if (!localStorage.token) {
      alert("Actualmente no hay sesión");
      return;
    }

    each(userMenu, (menu) => {
      if (menu.description === "redirect_to_history") {
        menu.url += `bearer/${localStorage.token.replace(/"/g, "")}`;
        return false;
      } else {
        if (menu.children) {
          this.inyectUserTokenToHistoriaClinicaUrl(
            menu.children,
            originalNextProps
          );
        }
      }
    });

    this.setState({ userMenu: originalNextProps });
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
  }
  goRedirectPatient(routeDir) {
    // console.log('route', routeDir)
    this.context.router.history.push(routeDir);
  }
  goBack() {
    this.context.router.history.goBack();
  }
  render() {
    const items = {
      items: this.props.userMenu,
    };
    const userInfo = this.props.user.userInfo;
    const userInfoApi = this.props.user.userInfoApi;
    const patientSupportPhone = 573160547579;
    const doctorSupportPhone = 573160104686;
    const showBtnWhatsapp = window.location.href.includes("video/videocomp")
      ? "none"
      : "inline";

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body" style={{ backgroundImage: newBackground }}>
          {userInfo &&
            userInfo.profile &&
            userInfo.profile.profile_name == "Medic" && (
              <AppSidebar fixed display="lg">
                {/*               
              <hr className="separator"></hr> */}
                {this.state.sendRequest ? (
                  <AppSidebarHeader>
                    <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25" />
                  </AppSidebarHeader>
                ) : (
                  <AppSidebarHeader />
                )}
                <AppSidebarForm />
                <AppSidebarNav navConfig={items} {...this.props} />
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
            )}
          {userInfo?.profile?.profile_name == "Administrador" && (
            <AppSidebar fixed display="lg">
              <hr className="separator"></hr>
              {this.state.sendRequest ? (
                <AppSidebarHeader>
                  <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25" />
                </AppSidebarHeader>
              ) : (
                <AppSidebarHeader />
              )}
              <AppSidebarForm />
              <AppSidebarNav navConfig={items} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}
          <main className="main" style={{ background: "transparent" }}>
            {this.props.location.pathname !== "/video/videocomp" &&
              this.props.location.pathname !== "/RatingVideoCall" && (
                <Menu
                  userInfo={userInfo}
                  userInfoApi={userInfoApi}
                  actions={{
                    goRedirectPatient: this.goRedirectPatient,
                    goBack: this.goBack,
                  }}
                />
              )}
            {/* {this.NameRouteLayout(routes,this.context.router.history.location.pathname)} */}
            <Container
              fluid
              style={{
                padding: "1px 0px 5px 0px",
                backgroundColor:
                  window.location.href.includes("/Dashboard") ||
                  window.location.href.includes("video/videocomp")
                    ? "transparent"
                    : "#fff",
              }}
              className="mt-3"
            >
              {/* <ConnectSR></ConnectSR> */}
              <ConnectToServer />
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/Dashboard" />
              </Switch>
              {userInfo && userInfo !== {} && (
                <button
                  style={{ display: showBtnWhatsapp }}
                  className="btnWhatsapp"
                  onClick={() =>
                    window.open(
                      `https://wa.me/${
                        userInfo.type_user === "0"
                          ? patientSupportPhone
                          : doctorSupportPhone
                      }?text=Hola%20quisiera%20Información%20sobre`,
                      "_blank"
                    )
                  }
                />
              )}
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter style={{ background: "transparent", display: "none" }}>
          <DefaultFooter />
        </AppFooter>
        <RefreshToken onRef={(ref) => (this.RefreshToken = ref)} />
      </div>
    );
  }
}

DefaultLayout.propTypes = {
  getUserMenu: PropTypes.func.isRequired,
  userMenu: PropTypes.array.isRequired,
  logout: PropTypes.func,
  getUserInfo: PropTypes.func,
  PendingAppointments: PropTypes.func,
  user: PropTypes.object,
};

DefaultLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userMenu: state.generic.userMenu,
  user: state.user,
});

export default connect(mapStateToProps, {
  getUserMenu,
  logout,
  getUserInfo,
  PendingAppointments,
})(DefaultLayout);
