import React from "react";
import { each, isEqual, map, toLower } from "lodash";
import DocHoyLoading from "./../assets/img/DrHoy_loading.gif";
import moment from "moment";

export const renderError = (error) => (
  <div className="text-danger">{error}</div>
);

export const PreloaderDocHoy = () => (
  <div className="preloader-dochoy" style={{ zIndex: "9999999999999" }}>
    <img src={DocHoyLoading} />
  </div>
);
export const searchDataInLists = (data, term, keysForSearch) => {
  const newArray = [];

  map(data, (row) => {
    map(keysForSearch, (key) => {
      if (row[key]) {
        row[key] = row[key].toString();
        if (row[key].toLowerCase().includes(term.toLowerCase())) {
          let isSaved = false;

          each(newArray, (rowSaved) => {
            if (isEqual(rowSaved, row)) {
              isSaved = true;
              return false;
            }
          });

          if (!isSaved) {
            newArray.push(row);
          }
        }
      }
    });
  });

  return newArray;
};

export const objectNotEquals = (obj1, obj2) => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

export const isNotPatient = (userInfo) => {
  if (userInfo && userInfo.profile && userInfo.profile.profile_name) {
    const patientProfileId = "50613943-3b2c-4b92-bdac-1be82f973585";
    return toLower(userInfo.profile.profile_id) !== patientProfileId;
  }

  return false; // Is Patient
};

export const formatCurrency = new Intl.NumberFormat("es-CO", {
  style: "decimal",
  currency: "COP",
  minimumFractionDigits: 0,
});

export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const numberFormatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};
