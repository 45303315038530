/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React from 'react';
import { Col, Row } from "reactstrap";

/**
 * @author Intelisoft
 * @file index.js
 * @description Application Footer
 */

function IntroRegisterDoctor() {
  return (
    <>
        <Col md="12" className="text-center">
          <h2 className="span-red-register text-white" style={{ fontSize: "24px" }}>Registrate y vinculate</h2>
          <h1 className="span-red-register" style={{ color: "#FF8B0D" }}>A DOCTORHOY COMO MÉDICO</h1>
        </Col>
        <Col md="12" style={{ textAlign: "center" }}>
          <p className="text-white">
          Forma parte de nuestra red integrada de servicios de salud sin costos de afiliación.
          </p>
        </Col>
        <Col md="12" style={{ textAlign: "center" }}>
          <p className="text-white">
          Migra tus servicios médicos a nuestra novedosa plataforma digital, incrementa tus pacientes particulares y tus ingresos.
          </p>
        </Col>
        <hr />
        <Col md="12" >
          <Row>
          <Col md="2" style={{textAlign: "center"}}>
            <i style = {{fontSize: "70px"}} className="fa fa-map-marker icons-register text-white" aria-hidden="true"></i>
          </Col>
          <Col md="10" >
            <Row>
              <Col md="12" >
                <span className="span-red-register">Deja que los pacientes lleguen a ti.</span>
              </Col>
              <Col md="12" >
                <p className="text-white text-justify">
                Los pacientes pueden encontrarte fácilmente gracias a
                nuestro posicionamiento en Google y nuestra actividad en redes
                sociales los 365 días del año.
                </p>
              </Col>
            </Row>
          </Col>
          </Row>
        </Col>
        <Col md="12" >
          <Row>
          <Col md="2" className="text-center">
            <i  style = {{fontSize: "55px"}} className="fa fa-calendar-o icons-register text-white" aria-hidden="true"></i>
          </Col>
          <Col md="10" >
            <Row>
              <Col md="12" >
                <span className="span-red-register">Maneja fácilmente tu agenda.</span>
              </Col>
              <Col md="12" >
                <p className="text-white text-justify">
                Nuestra agenda dual permite gestionar simultáneamente
                tus propios pacientes y los que ingresen a través de la plataforma sin generar cruce de
                horarios. Todo esto siendo flexibles a tu disponibilidad, en cualquier lugar y desde la
                comodidad de tus dispositivos electrónicos (celular, computador o tablet).
                </p>
              </Col>
            </Row>
          </Col>
          </Row>
        </Col>
        <Col md="12" >
          <Row>
          <Col md="2" className="text-center">
            <i className="fa fa-mobile icons-register text-white" aria-hidden="true"></i>
          </Col>
          <Col md="10" >
            <Row>
              <Col md="12" >
                <span className="span-red-register">Haz todo digital.</span>
              </Col>
              <Col md="12" >
                <p className="text-white text-justify">
                Con DoctorHoy transformarás digitalmente la forma en que interactúas
                con tus pacientes. Podrás programar consultas médicas presenciales o de orientación
                médica por videollamada, así como procesar y digitar las historias de tus pacientes a
                través de una plataforma sincronizada, amigable, ágil y confiable.
                </p>
              </Col>
            </Row>
          </Col>
          </Row>
        </Col>
        <Col md="12" style={{ textAlign: "center" }}>
          <h4>Ingresa tus datos a continuación para iniciar con DoctorHoy</h4>
        </Col>
    </>
  );
}

export default IntroRegisterDoctor;
