/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import { CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSpecialityUrl, getAgreementUrl, cities, getCitasEnEsperaUrl } from "../../redux/actions/types";
import { GetSpeciality, GetAgreements, GetCitaEnEspera } from "../../redux/actions/DoctorAction";
import { getCities } from "../../redux/actions/GenericAction";
import { toast } from "react-toastify";
import {sendSMS} from '../../Components/MessageBirdApi/MessageBirdApi'
// Components
import FormPresencial from '../../Components/Forms/CitaEspera/Solicitud';
import AdmonTable from "./AdmonTable";


// Assets
import logo from "../../assets/img/logo/logo.png";

/**
 * @author Intelisoft
 * @file FinishPersonRegister.js
 * @description Update person data
 */

let optionAttentionType = [
  { value: 0, label: "Consultorio" },
  { value: 2, label: "Atención en Casa" }
];

const minTime = new Date();
minTime.setHours(0, 0, 0);
const maxTime = new Date();
maxTime.setHours(23, 59, 0);

class Solicitud extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editObject: false,
      estado: true,
      fromTime: "",
      toTime: "",
      min: "00:00",
      max: "23:59",
      loading: false
    };

    this.guardarSolicitud = this.guardarSolicitud.bind(this);
    this.changeTypeAppointment = this.changeTypeAppointment.bind(this);
  }

  componentWillMount() {
    this.props.GetCitaEnEspera(getCitasEnEsperaUrl, { action_type: 6 });
    this.props.GetSpeciality(getSpecialityUrl, { especiality: "", action: 0 });
    this.props.GetAgreements(getAgreementUrl, { action: -1 });
    this.props.getCities(cities + "/-1");
  }

  changeTypeAppointment(e){
    let { value } = e.target;
    
    if(value == 2){
      this.context.router.history.push("/CitaEspera/SolicitudVideoConsulta");
    }
  }

  guardarSolicitud(data) {
    this.setState({ loading: true });

    data.action_type = 1;
    // data.fecha_inicial = new Date(data.range.startDate);
    // data.fecha_final = new Date(data.range.endDate);
    data.fecha_inicial = new Date();
    data.fecha_final = new Date();
    data.plan_id = '6c701785-34ac-47ed-ace2-d957894b183c';
    data.hora_inicial = this.state.min;
    data.hora_final = this.state.max;
    data.clase_atencion = data.speciality;
    delete data.range;
    console.log('data',data)
    return;

    this.props.GetCitaEnEspera(getCitasEnEsperaUrl, data).then((resp) => {
      this.setState({ loading: false });
      let phones = [];
      if(resp.data[1].length > 0){
        resp.data[1].forEach( function(valor, indice) {
          phones.push(valor.celular)
        });
      }
      //un vez se pase a produccion se debe quitar el numero de abajo y el array
      phones = [];
      phones.push('573154921664');
      //phones.push('573003438359');
      console.log('phones', phones);
      const body = {
        username: 'Oferta videoconsulta',
        cellphone: phones,
        msg: 'Buenos días, Tienes una nueva solicitud de consulta programada para ofertar en DoctorHoy.'
      };
    
      sendSMS(body, '/send-sms').then(() => {
        console.log('mensaje enviado');
      }).catch((errorMsg) => {
        console.log('Message bird Error', errorMsg);
      });
      toast.success('Buscamos un médico para tu atención, espera oferta o respuesta.', {
          position: toast.POSITION.BOTTOM_RIGHT
      });

      setTimeout(function () {
        this.context.router.history.push('/Citaespera/Seleccion');
      }.bind(this), 4000);
    });
  }

  render() {
    const { citas } = this.props;
    const { loading } = this.state;

    return (
      <div className="px-3 animated fadeIn">
        <Row>
          <Col md="4">
            <div>
              <span className="text-agenda">¡Agenda tu consulta hoy!&nbsp;</span>
              <img src={logo} width="100" height="35" alt="DoctorHoy" />
            </div>
            <Row className="section-types">
              <Col md="6" xs="6" xl="6" >
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="checkTypeCita" id="checkPresencial" value="1" checked onClick={this.changeTypeAppointment.bind(this)}/>
                  <label className="label-check-type btn btn-success" htmlFor="checkPresencial">
                    Consulta Presencial
                  </label>
                </div>
              </Col>
              <Col md="6" xs="6" xl="6" >
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="checkTypeCita" id="checkVideo" value="2" onClick={this.changeTypeAppointment.bind(this)} />
                  <label className="label-check-type btn btn-info" htmlFor="checkVideo">
                    Video Consulta
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormPresencial
                  optionAttentionType={optionAttentionType}
                  {...this.props}
                  submitForm={this.guardarSolicitud}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
          <Col md="8">
            <span style={{ fontSize: 18, color: '#03416e' }}>
              Tu historial de citas en espera
            </span>
            <CardBody>
              <Row>
                <Col md="12" align="center">
                    <AdmonTable
                      data={citas}
                    />
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </div>
    );
  }
}

Solicitud.propTypes = {
  citas: PropTypes.array.isRequired,
  GetCitaEnEspera: PropTypes.func.isRequired,
  GetSpeciality: PropTypes.func.isRequired,
  GetAgreements: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
};

Solicitud.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  speciality: state.doctor.speciality,
  agreements: state.doctor.agreements,
  cities: state.generic.cities,
  citas: state.doctor.citasEnEspera,
});

export default connect(mapStateToProps, {
  GetSpeciality,
  GetAgreements,
  getCities,
  GetCitaEnEspera
})(Solicitud);
