import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  CardGroup,
  FormGroup
} from "reactstrap";
import {
  validateTokenRecoveryPassword,
  updatePasswordUrl
} from "../../redux/actions/types";
import {
  getUsersRecoveryPassword,
  updatePassword,
  setApiResetPassword
} from "../../redux/actions/userAction";
import "spinkit/css/spinkit.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Recaptcha from "react-recaptcha";
// const tokenRecaptcha = require("../../enviroment").tokenRecaptcha;

class RecoveryPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onSavePassword = this.onSavePassword.bind(this);
    // this.recaptchaVerify = this.recaptchaVerify.bind(this);
    this.callback = this.callback.bind(this);
    this.state = {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      sendUpdate: false,
      stateUpper: false,
      stateLower: false,
      stateSpecial: false,
      stateMaxLength: false,
      // recaptcha: false,
      username: ""
    };
  }

  callback() {
    console.log("Done!!!");
  }

  // recaptchaVerify(response) {
  //   console.log("recaptchaVerify");
  //   if (response) {
  //     this.setState({ recaptcha: true });
  //   }
  // }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    this.props
      .getUsersRecoveryPassword(validateTokenRecoveryPassword, values.token)
      .then(resp => {
        if (resp.code !== 0) {
          this.context.router.history.push("/login");
          return;
        }
        this.setState({ name: `${this.props.userInfo.name} ${this.props.userInfo.second_name} ${this.props.userInfo.last_name} ${this.props.userInfo.second_last_name}` });
        this.setState({ email: this.props.userInfo.email });
        this.setState({ username: this.props.userInfo.username });
        // https://appdev.doctorhoy.com/index.html#/RecoveryPassword/RecoveryPassword?token=d40cb639-025a-45ff-ae7c-020e358282bd
      });
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSavePassword() {
    var Upper = true,
      Lower = true,
      Special = true,
      Length = true;

    if (this.state.password === "")
      return toast.error("Ingrese el Password!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    if (this.state.passwordConfirm === "")
      return toast.error("Ingrese confirmacion del Password!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });

    if (this.state.password !== this.state.passwordConfirm)
      return toast.error("La confirmacion de password Ingresado no coincide!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });

    var minMaxLength = /^[\s\S]{4,30}$/;
    // upper = /[A-Z]/,
    // lower = /[a-z]/,
    // special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    var password = this.state.password;
    //
    //   if (upper.test(password)) {
    //     this.setState({ password: password });
    //     this.setState({ stateUpper: true });
    //     Upper = true;
    //   } else Upper = false;
    //
    //   if (lower.test(password)) {
    //     this.setState({ password: password });
    //     this.setState({ stateLower: true });
    //     Lower = true;
    //   } else Lower = false;
    //
    //   if (special.test(password)) {
    //     this.setState({ password: password });
    //     this.setState({ stateSpecial: true });
    //     Special = true;
    //   } else Special = false;

    if (minMaxLength.test(password)) {
      this.setState({ password: password });
      this.setState({ stateMaxLength: true });
      Length = true;
    } else Length = false;

    // if (!this.state.recaptcha)
    //   return toast.error("Confirma que eres humano!", {
    //     position: toast.POSITION.BOTTOM_RIGHT
    //   });

    let user = {
      password: this.state.password,
      id: this.props.userInfo.id
    };

    if (Upper && Lower && Special && Length) {
      this.setState({ sendUpdate: true });

      this.props.setApiResetPassword(user).then(resp => {
        this.setState({ sendUpdate: false });
        if (resp !== undefined) {
          if (resp.message !== "")
            toast.success(resp.message, {
              position: toast.POSITION.BOTTOM_RIGHT
            });
          //aqui mando al login
          this.context.router.history.push("/login");
        }
      });

      // this.props.updatePassword(updatePasswordUrl, user).then(resp => {
      //   this.setState({ sendUpdate: false });
      //   if (resp !== undefined) {
      //     if (resp.message !== "")
      //       toast.success(resp.message, {
      //         position: toast.POSITION.BOTTOM_RIGHT
      //       });
      //     //aqui mando al login
      //     this.context.router.history.push("/login");
      //   }
      // });
    } else {
      return toast.error("La contraseña debe ser mayor a 3 caracteres.", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }

    /*   */
  }

  render() {
    let button;
    if (!this.props.response && !this.props.setPassword) {
      button = <div className="sk-spinner sk-spinner-pulse" />;
    } else if (!this.props.setPassword) {
      button = (
        <Button color="primary" block onClick={this.onSavePassword.bind(this)}>
          Generar Password
        </Button>
      );
    } else {
      button = (
        <i className="fa fa-check fa-lg mt-4" style={{ color: "#4dbd74" }} />
      );
    }
    const containerStyle = {
      zIndex: 1999
    };

    return (
      <div className="animated fadeIn">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
        <Container style={{ marginTop: 80 }}>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <Form style={{ textAlign: "center" }}>
                      <h1 style={{ color: 'balck' }}>{`Generación de Contraseña`}</h1>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Usuario"
                          autoComplete="username"
                          value={this.state.username}
                          disabled
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          autoComplete="email"
                          disabled
                          value={this.state.email}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Contraseña"
                          autoComplete="new-password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Confirmar Contraseña"
                          autoComplete="new-password"
                          name="passwordConfirm"
                          value={this.state.passwordConfirm}
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                      {/* <InputGroup className="mb-4">
                        <Recaptcha
                          sitekey={tokenRecaptcha}
                          render="explicit"
                          verifyCallback={this.recaptchaVerify}
                          onloadCallback={this.callback}
                        />
                      </InputGroup> */}
                    </Form>
                  </CardBody>
                  <CardFooter className="p-4" style={{ textAlign: "center" }}>
                    {!this.state.sendUpdate ? (
                      button
                    ) : (
                        <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25" />
                      )}
                  </CardFooter>
                </Card>
                <Card
                  className="text-blue bg-primary py-5 d-md-down-none"
                  style={{ width: 44 + "%" }}
                >
                  <CardBody className="text-center">
                    <div>
                      <h2>Recuerda</h2>

                      <h5>Tu contraseña debe tener:</h5>
                      <hr />
                      <FormGroup row>
                        {
                          /*
                        
                          <Col md="3" className="text-right">
                            {!this.state.stateUpper ? (
                              <i className="fa fa-certificate" />
                            ) : (
                                <i
                                  className="fa fa-check"
                                  style={{ color: "#4dbd74" }}
                                />
                              )}
                          </Col>
                          <Col md="9" className="text-left">
                            Al menos 1 Letra Mayúscula
                          </Col>
                          <Col md="12" className="text-left">
                            <hr />
                          </Col>
                          <Col md="3" className="text-right">
                            {!this.state.stateLower ? (
                              <i className="fa fa-certificate" />
                            ) : (
                                <i
                                  className="fa fa-check"
                                  style={{ color: "#4dbd74" }}
                                />
                              )}
                          </Col>
                          <Col md="9" className="text-left">
                            Al menos 1 Letra Minúscula
                          </Col>
                          <Col md="12" className="text-left">
                            <hr />
                          </Col>
                          <Col md="3" className="text-right">
                            {!this.state.stateSpecial ? (
                              <i className="fa fa-certificate" />
                            ) : (
                                <i
                                  className="fa fa-check"
                                  style={{ color: "#4dbd74" }}
                                />
                              )}
                          </Col>
                          <Col md="9" className="text-left">
                            Al menos 1 Caracter Especial
                          </Col>
                          <Col md="12" className="text-left">
                            <hr />
                          </Col>
                        
                          */
                        }

                        <Col md="3" className="text-right">
                          {!this.state.stateMaxLength ? (
                            <i className="fa fa-certificate" />
                          ) : (
                              <i
                                className="fa fa-check"
                                style={{ color: "#4dbd74" }}
                              />
                            )}
                        </Col>
                        <Col md="9" className="text-left">
                          Mayor a 3 caracteres
                        </Col>
                      </FormGroup>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

RecoveryPassword.propTypes = {
  location: PropTypes.object,
  userInfo: PropTypes.object,
  getUsersRecoveryPassword: PropTypes.func,
  updatePassword: PropTypes.func,
  setApiResetPassword: PropTypes.func,
  response: PropTypes.any,
  setPassword: PropTypes.any,
};

RecoveryPassword.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userInfo: state.user.userInfo,
  response: state.user.response,
  setPassword: state.user.setPassword
});

export default connect(
  mapStateToProps,
  { getUsersRecoveryPassword, updatePassword, setApiResetPassword }
)(RecoveryPassword);
