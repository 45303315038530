/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

/**
 * @author Intelisoft
 * @file enviroment.js
 * @description Enviroment
 */

export const apiLaravel = process.env.REACT_APP_API_LARAVEL;
export const zegoAppId = process.env.REACT_APP_ZEGO_APP_ID;
export const zegoServerSecret = process.env.REACT_APP_ZEGO_SERVER_SECRET;

export const apiKeyGoogleMaps = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const version = process.env.REACT_APP_VERSION;

export const pusherAppKey = process.env.REACT_APP_PUSHER_APP_KEY;
export const pusherHost = process.env.REACT_APP_PUSHER_HOST;
export const pusherPort = process.env.REACT_APP_PUSHER_PORT;
export const pusherAppCluster = process.env.REACT_APP_PUSHER_APP_CLUSTER;

export const api = process.env.REACT_APP_API;
export const tokenRecaptcha = process.env.REACT_APP_TOKEN_RECAPTCHA;

export const DoctorTermsConditions =
  "https://doctorhoystorage.blob.core.windows.net/00-terminos-condiciones/Contrato-de-intermediación-del-medico-Final.pdf";
export const PatientTermsConditions =
  "https://doctorhoystorage.blob.core.windows.net/00-terminos-condiciones/Política-de-Tratamiento-y-proteccion-de-datos-personales-de-DOCTORHOY-final.pdf";
export const SummaryTermsConditions =
  "https://www.doctorhoy.com/terminos-y-condiciones/";

export const urlMessagebird = process.env.REACT_APP_URL_MESSAGE_BIRD;
export const urlApiMessagebird = process.env.REACT_APP_URL_MESSAGE_API;
export const userMB = process.env.REACT_APP_USER_MB;
export const passMB = process.env.REACT_APP_PASS_MB;

// PayU
export const payuAction = process.env.REACT_APP_PAYU_ACTION;
export const payuConfirmation = process.env.REACT_APP_PAYU_CONFIRMATION;
export const payuResponse = process.env.REACT_APP_PAYU_RESPONSE;
export const payuAccountId = process.env.REACT_APP_PAYU_ACCOUNTID;
export const payuMerchantId = process.env.REACT_APP_PAYU_MERCHANTID;

export const siteHost = process.env.REACT_APP_SITE_URL;
export const urlHC = process.env.REACT_APP_URL_HC;

//Socket

export const urlSocketApi = process.env.REACT_APP_SOCKET_API;

//NewApiNest - Doctors

export const urlNewApiDoctors = process.env.REACT_APP_API_NEST;
