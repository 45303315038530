import qs from "qs";
// import { useEffect, useState } from "react"
import Swal from "sweetalert2";

// Constants
import {
  SET_CURRENT_USER,
  SET_CURRENT_ERROR_USER,
  SET_CURRENT_ERROR_USER_API,
  FORGET_PASSWORD,
} from "./types";

import { getUserInfo } from "../../redux/actions/userAction";

// Utils
import axios from "../../Utils/Http";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file authAction.js
 * @description Auth actions
 */

const urlApi = require("../../enviroment").api;
const urlApiLaravel = require("../../enviroment").apiLaravel;

export function login(data, resource) {
  // const [ sendRequest, setSendRequest ] = useState(false);

  data.name = "chrome";

  window.localStorage.clear();
  return (dispatch) => {
    return axios
      .post(
        `${urlApi}${resource}`,
        qs.stringify({
          grant_type: "password",
          username: data.username,
          password: data.password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res !== null && res.status === 200) {
          localStorage.setItem("token", JSON.stringify(res.data.access_token));
          dispatch({
            type: SET_CURRENT_USER,
            payload: res.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_CURRENT_ERROR_USER_API,
          payload: error.response !== undefined ? error.response.data : "",
        });
      });
  };
}

export function logout(userinfo) {
  let data = {
    username: localStorage.getItem("username"),
  };

  return async (dispatch) => {
    localStorage.clear();
    await axios.post(`${urlApiLaravel}api/logout`, data).then(() => {
      if (userinfo?.type_user === "0") {
        window.location.href = "/#/Login";
      }
      if (userinfo?.type_user === "1") {
        window.location.href = "/#/MedicalLogin";
      }
    });

    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        access_token: undefined,
        message: "",
      },
    });
  };
}

export function recoverPasswordLaravel(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/reset-password/check`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }
        return res.data;
      });
  };
}

export function resetPasswordLaravel(params) {
  return (dispatch) => {
    return axios
      .post(`${urlApiLaravel}api/reset-password`, params)
      .then((res) => {
        if (res.data.code === 0) {
          // dispatch({
          //   type: GET_DOCTOR,
          //   payload: res.data,
          // });
        }

        if (res.data.code === 1) {
          Swal.fire({
            title: "Hubo un error con tu petición",
            text: "Token no encontrado",
            icon: `error`,
          });
        }
        return res;
      })
      .catch(() => {
        Swal.fire({
          title: "Hubo un error",
          text: "Comunicate con soporte",
          icon: `error`,
        });
      });
  };
}

export function recoverySession(resource, userInfo) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, userInfo).then((res) => {
      if (res !== null && res.status === 200 && res.data.code === 0) {
        localStorage.removeItem("token");
        localStorage.setItem(
          "token",
          JSON.stringify(res.data.data.access_token)
        );
        dispatch({
          type: SET_CURRENT_USER,
          payload: res.data.data,
        });

        return res.data;
      } else {
        dispatch({
          type: SET_CURRENT_ERROR_USER,
          payload: res.data !== undefined ? res.data : "",
        });
      }
    });
  };
}

export function forgetPassword(resource, userInfo) {
  return (dispatch) => {
    return axios.post(`${urlApi}${resource}`, userInfo).then((res) => {
      if (res !== null && res.status === 200) {
        dispatch({
          type: FORGET_PASSWORD,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: SET_CURRENT_ERROR_USER,
          payload: res.data !== undefined ? res.data : "",
        });
      }
    });
  };
}
