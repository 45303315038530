/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";

// Components
import DoctorProfileDetails from "../../Components/DoctorProfileDetails";
import { formatNumber } from "../../Utils";

import moment from "moment";
moment.locale("es");

class AdmonTableResultOferta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: null,
      buildDoctorProfile: false,
    };

    this.cellBtnSeleccionar = this.cellBtnSeleccionar.bind(this);
    this.dataFormatDate = this.dataFormatDate.bind(this);
    this.dataFormatHour = this.dataFormatHour.bind(this);
    this.dataFormatHourFin = this.dataFormatHourFin.bind(this);
    this.dataFormatDateTxt = this.dataFormatDateTxt.bind(this);
    this.dataFormatDateFull = this.dataFormatDateFull.bind(this);
    this.dataFormatHours = this.dataFormatHours.bind(this);
    this.dataEspecialidad = this.dataEspecialidad.bind(this);
    this.dataFormatoInfo = this.dataFormatoInfo.bind(this);
    this.onClickViewDoctorProfile = this.onClickViewDoctorProfile.bind(this);

    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      /*       paginationSize: 3, */
      hidePageListOnlyOnePage: true,
      /*  clearSearch: true, */
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: "Aún no tienes ninguna oferta a tu consulta",
    };
  }

  componentWillMount() {}

  dataFormatDateTxt(cell) {
    return moment(cell).format("MMMM Do YYYY, h:mm:ss a");
  }

  dataFormatDate(cell, row) {
    return moment(row.fecha).format("DD/MM/YYYY");
  }

  dataFormatDateFull(cell) {
    return moment(cell).format("DD/MM/YYYY, h:mm a");
  }

  dataFormatHour(cell, row) {
    return moment(row.fecha).format("h:mm a");
  }

  dataFormatHourFin(cell, row) {
    return moment(row.fechafin).format("h:mm a");
  }

  dataFormatHours(cell, row) {
    //"hora_inicial" hora_final
    let date1 = new Date(2021, 1, 1, 1, 1, 1, 1);
    let x1 = row.hora_inicial.split(":");
    date1.setHours(x1[0]);
    date1.setMinutes(x1[1]);

    let date2 = new Date(2021, 1, 1, 1, 1, 1, 1);
    let x2 = row.hora_final.split(":");
    date2.setHours(x2[0]);
    date2.setMinutes(x2[1]);

    return (
      moment(date1).format("h:mm a") + "<br/>" + moment(date2).format("h:mm a")
    );
  }

  dataEspecialidad(cell) {
    var espeTemp = this.props.especialidades.find((x) => x.id == cell);
    if (espeTemp != undefined) {
      // return this.props.especialidades.find(x => x.id == cell).especiality;
    }
    return "[Sin registro]";
  }

  cellBtnSeleccionar(cell, row) {
    const id = `chooseBestOption${row.id}`;

    return (
      <div>
        <div>
          <Button
            id={id}
            // color="primary"
            size="sm"
            tooltip="Seleccionar"
            className="btn-danger"
            onClick={this.props.onSeleccionar.bind(this, row)}
          >
            {/* <i className="cui-check icons"></i> */}
            {/* <img src={pagar} /> */}
            <i className="cui-cart icons"></i>
          </Button>
          <UncontrolledTooltip placement="bottom" target={id}>
            {`Elige y paga Aquí`}
          </UncontrolledTooltip>
        </div>
        {this.props.doctor.executingRequest ? (
          <div data-loader="circle-side-summary" />
        ) : (
          <div>
            {/* <div id={'viewProfile'} style={{ cursor: 'pointer' }} onClick={() => { this.onClickViewDoctorProfile(row); }}>
                
                <i className="fa fa-user-o" style={{ marginLeft: 10 }} aria-hidden="true"></i>
              </div> */}
            <Button
              id={"viewProfile"}
              // color="primary"
              size="sm"
              tooltip="Seleccionar"
              className="btn-info"
              style={{ marginTop: "10px", background: "#03416e" }}
              onClick={() => {
                this.onClickViewDoctorProfile(row);
              }}
            >
              {/* <i className="cui-check icons"></i> */}
              {/* <img src={pagar} /> */}
              <i className="fa fa-user-o" aria-hidden="true"></i>
            </Button>
            <UncontrolledTooltip placement="bottom" target={"viewProfile"}>
              {`Ver perfil de ${row.medico_nombre}`}
            </UncontrolledTooltip>
          </div>
        )}
      </div>
    );
  }

  onClickViewDoctorProfile(row) {
    this.setState({
      doctorId: row.medico_id,
      buildDoctorProfile: true,
    });
  }

  dataFormatoInfo(cell, row) {
    console.log(row);
    var fecha = this.dataFormatDate(cell, row);
    var hora = this.dataFormatHour(cell, row);
    var horaFin = this.dataFormatHourFin(cell, row);

    var cadenaConsultorio = "";
    if (row.consultorio_id != "00000000-0000-0000-0000-000000000000") {
      cadenaConsultorio = " Consultorio:" + row.consultorio_nombre;
    }

    var claseAtencion = "";
    switch (row.clase_atencion) {
      case 0:
        claseAtencion = "En consultorio";
        break;
      case 1:
        claseAtencion = "Videoconsulta";
        break;
      case 2:
        claseAtencion = "Atención en casa";
        break;
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
          <strong>{`Médico: `}</strong>
          {row.medico_nombre}
        </div>
        <strong>{`Fecha: `}</strong>
        {fecha}
        <br />
        <strong>{`Hora Inicial: `}</strong>
        {hora}
        <br />
        <strong>{`Hora Final: `}</strong>
        {horaFin}
        <br />
        <strong>{`Tarifa: `}</strong>
        {`${formatNumber(row.tarifa)} ${cadenaConsultorio}`}
        <br />
        <strong>{`Clase atención: `}</strong>
        {claseAtencion}
      </div>
    );
  }

  render() {
    return (
      <div>
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />*/}
        <BootstrapTable
          data={this.props.data}
          bordered={false}
          version="4"
          striped
          hover
          responsive
          pagination
          search={false}
          options={this.options}
          searchPlaceholder={"Buscar"}
        >
          <TableHeaderColumn dataField="id" isKey hidden></TableHeaderColumn>
          <TableHeaderColumn
            tdStyle={{ whiteSpace: "normal" }}
            thStyle={{ whiteSpace: "normal" }}
            dataFormat={this.dataFormatoInfo.bind(this)}
            width="80%"
            dataSort
          >
            {`Ofertas a tu consulta`}
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            dataField="fecha"
            hidden
            dataFormat={this.dataFormatDateFull.bind(this)}
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Fecha
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="medico_id"
            hidden
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Médico
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="tarifa"
            hidden
            dataSort
          >
            Tarifa
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultorio_id"
            hidden
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Consultorio
          </TableHeaderColumn> */}
          <TableHeaderColumn
            dataFormat={this.cellBtnSeleccionar.bind(this)}
          ></TableHeaderColumn>
        </BootstrapTable>
        {this.state.buildDoctorProfile && (
          <DoctorProfileDetails
            doctorId={this.state.doctorId}
            // doctorId={'2252B1E2-B376-4955-B5A5-28C17FFD89A9'}
            closeDoctorModal={() => {
              this.setState({ buildDoctorProfile: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  doctor: state.doctor,
});

export default connect(mapStateToProps, {})(AdmonTableResultOferta);
