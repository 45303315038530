/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import {
  getCities,
  getCitiesSelect,
  getStates,
} from "../../../redux/actions/GenericAction";
import { useForm, Controller } from "react-hook-form";
import {
  saveAddressUser,
  editAddressUser,
} from "../../../redux/actions/userAction";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";

// schema
import schema from "./formConfigPatientAddress";
import { renderError } from "../../../Utils";

function AddressModal({ countries, person, action, info, closeModal }) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  const [stateSelected, setStateSelected] = useState(null);
  const [citySelected, setCitySelected] = useState(null);
  const [address, setAddress] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(false);

  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: info,
  });

  const onSubmit = (form) => {
    const data = {
      address: form?.address,
      country_id: form?.country_id,
      state_id: form?.state_id,
      city_id: form?.city_id,
      default_address: defaultAddress,
      user_id: person?.id_user,
    };

    if (action && action === "crear") {
      console.log("creando", data);
      saveAddressUser(data).then((res) => {
        if (res?.status === 200) {
          Swal.fire({
            title: "Dirección creada con exito!",
            icon: `success`,
          }).then((result) => {
            if (result.isConfirmed) {
              closeModal();
              window.location.reload();
            }
          });
        }
      });
    }
    if (action && action === "editar" && info) {
      console.log("editando", data);
      editAddressUser(info.id, data)
        .then((res) => {
          console.log("respuesta", res);
        })
        .catch((error) => {
          // console.log("error: ", error.response.status);
        });
    }
  };

  const handleChangeDefaultAddress = () => {
    setDefaultAddress(!defaultAddress);
  };

  useEffect(async () => {
    if (action && action === "editar" && info) {
      reset(info);

      const states = await getStates(info.country_id);
      setStates(states);

      const cities = await getCitiesSelect(info.state_id);
      setCities(cities);

      setAddress(info.address);

      if (info.default_address === "1") {
        setDefaultAddress(true);
      } else {
        setDefaultAddress(false);
      }
    }
  }, [action, info]);

  return (
    <form className="">
      <Row style={{ marginBottom: "15px" }}>
        <Col md="12">
          <Label>
            <strong>Pais</strong>
            <span className="text-danger">*</span>
          </Label>
          <Controller
            name="country_id"
            control={control}
            render={({ onChange, value }) => {
              return (
                <Select
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Seleccione un Pais"
                  onChange={async (country) => {
                    const response = await getStates(country.id);
                    setCountry(country.id);
                    setStates(response);
                    onChange(country.id);
                    reset({ address: "" });
                  }}
                  value={
                    value && countries.find((country) => country.id === value)
                  }
                  isClearable
                />
              );
            }}
          />
          {errors.country_id && renderError(errors.country_id.message)}
        </Col>
      </Row>
      <Row style={{ marginBottom: "15px" }}>
        <Col md="12">
          <FormGroup>
            <Label>
              <strong>Departamento/Estado</strong>
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="state_id"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    options={states}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Seleccione un Departamento"
                    onChange={async (state) => {
                      const response = await getCitiesSelect(state.id);
                      setStateSelected(state);
                      setCities(response);
                      onChange(state.id);
                      reset({ address: "" });
                    }}
                    value={value && states?.find((state) => state.id === value)}
                    isClearable
                    isDisabled={!country}
                  />
                );
              }}
            />
            {errors.state_id && renderError(errors.state_id.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginBottom: "15px" }}>
        <Col md="12">
          <Label>
            <strong>Ciudad</strong>
            <span className="text-danger">*</span>
          </Label>

          <Controller
            name="city_id"
            control={control}
            render={({ onChange, value }) => {
              return (
                <Select
                  options={cities}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Seleccione una Ciudad"
                  onChange={(city) => {
                    setCitySelected(city);
                    onChange(city.id);
                    reset({ address: "" });
                  }}
                  value={value && cities?.find((city) => city.id === value)}
                  isClearable
                  isDisabled={!stateSelected}
                />
              );
            }}
          />
          {errors.city_id && renderError(errors.city_id.message)}
        </Col>
      </Row>
      <Row style={{ marginBottom: "15px" }}>
        <Col md="12">
          <FormGroup>
            <Label>
              Dirección Residencia <span className="text-danger">*</span>
            </Label>
            <input
              type="text"
              name="address"
              className={`form-control  form-control-dochoy ${
                errors && errors.address && "is-invalid"
              }`}
              ref={register}
              defaultValue={address}
            />
            {errors.address && renderError(errors.address.message)}
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginBottom: "15px" }}>
        <Col md="12" style={{ display: "flex", flexDirection: "column" }}>
          <Label>
            <strong>Establecer como principal</strong>
          </Label>
          <Switch
            onChange={handleChangeDefaultAddress}
            checked={defaultAddress}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" style={{ display: "flex", justifyContent: "center" }}>
          <Button
            tooltip="Guardar Direccion"
            title="Guardar Direccion"
            className="btn-primary mb-4"
            // type="submit"
            // onClick={onSubmit}
            onClick={handleSubmit(onSubmit)}
          >
            <i className="fa fa-check " style={{ fontSize: "large" }}></i>
            {action && action === "crear" && "Crear Direccion"}
            {action && action === "editar" && "Editar Direccion"}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default AddressModal;
