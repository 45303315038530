import {
  GET_USER_INFO,
  GET_USER_INFO_API,
  GET_USERS,
  GET_USERS_SET_PASSWORD,
  RESEND_MAIL,
  ERROR_API,
  GET_USER_SET_APPOINTMENT,
  SET_LAST_ACTION_USER,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION_TAB_VALUE,
  SET_EXECUTING_REQUEST_USER,
  SET_SEARCH_TERM,
  GET_PENDING_APPOINTMENTS_PATIENT,
} from "../actions/types";

const initialState = {
  userInfo: {},
  users: [],
  userssetappointment: [],
  response: false,
  setPassword: false,
  errorCode: {},
  lastAction: null,
  tabValue: 0,
  executingRequest: false,
  notifications: [],
  searchTerm: null,
  pendingAppointmentsPatient: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_API:
      return {
        ...state,
        userInfo: {},
        users: [],
        response: false,
        setPassword: false,
        errorCode: action.payload,
      };
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload.data,
        response: true,
      };
    case GET_USER_INFO_API:
      return {
        ...state,
        userInfoApi: action.payload.data,
        response: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload.data,
      };
    case GET_USERS_SET_PASSWORD:
      return {
        ...state,
        users: action.payload.data,
        setPassword: true,
        response: true,
      };
    case RESEND_MAIL:
      return {
        ...state,
      };
    case GET_USER_SET_APPOINTMENT:
      return {
        ...state,
        userssetappointment: action.payload.data,
      };
    case SET_LAST_ACTION_USER:
      return {
        ...state,
        lastAction: action.value,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.value,
      };
    case SET_NOTIFICATION_TAB_VALUE:
      return {
        ...state,
        tabValue: action.value,
      };
    case SET_EXECUTING_REQUEST_USER:
      return {
        ...state,
        executingRequest: action.value,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.value,
      };
    case GET_PENDING_APPOINTMENTS_PATIENT:
      return {
        ...state,
        pendingAppointmentsPatient: action.payload.data,
      };
    default:
      return state;
  }
}
