import * as yup from "yup";

const schema = yup.object().shape({
  country_id: yup.string().required("El país es requerido."),
  state_id: yup.string().required("El departamento es requerido."),
  city_id: yup.string().required("La ciudad es requerida."),
  address: yup.string().required("La dirección es requerida."),
});

export default schema;
