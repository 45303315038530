import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import {
  DeleteProfileByPages,
  getPages,
} from "../../redux/actions/GenericAction";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ButtonDelete from "../Generic/ButtonDelete";
import moment from "moment";
import NumberFormat from "react-number-format";
import { TableSummaryContainer } from "./SummaryTable.style";
moment.locale("es");

class SummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      large: false,
      small: false,
      primary: false,
      success: false,
      warning: false,
      danger: false,
      info: false,
      idProfileByPage: "",
      specialityId: "",
      sendRequest: false,
    };

    this.cellButtons = this.cellButtons.bind(this);
    this.formatIcon = this.formatIcon.bind(this);
    this.formatTypeNode = this.formatTypeNode.bind(this);
    this.dataFormatDateFull = this.dataFormatDateFull.bind(this);
    this.dataFormatMoney = this.dataFormatMoney.bind(this);

    this.options = {
      sortIndicator: false,
      hideSizePerPage: true,
      /*       paginationSize: 3, */
      hidePageListOnlyOnePage: true,
      /*  clearSearch: true, */
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: "No existen Datos a mostrar",
    };
  }

  componentWillMount() {
    // this.props.getPages(PagesAll);
  }

  dataFormatDateFull(cell) {
    return moment(cell).format("DD/MM/YYYY h:mm a");
  }

  dataFormatMoney(cell) {
    return (
      <NumberFormat
        value={cell}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
    //return moment(cell).format("dd/MM/YYYY, h:mm:ss a");
  }

  cellButtons(cell, row) {
    return (
      <div>
        <ButtonDelete
          delete={this.props.delete.bind(this)}
          onRef={(ref) => (this.ButtonDelete = ref)}
          row={row}
        />
      </div>
    );
  }

  formatTypeNode(cell, row) {
    if (row.is_parent) return <font color="#f86c6b">Padre</font>;
    else return <font color="#20a8d8">Hijo</font>;
  }

  formatIcon(cell) {
    return `( <i class='${cell}'></i> ) ${cell}`;
  }
  render() {
    const data = this.props.data;
    return (
      <TableSummaryContainer>
        <table>
          <tr>
            <th>Medico</th>
            <th>Fecha</th>
            <th>Valor</th>
            <th>Acciones</th>
          </tr>
          {data?.length > 0 &&
            data[0]?.purchase_order_details.map((cita, index) => {
              return (
                <tr key={index}>
                  <td>{cita.patient_appointment?.doctor}</td>
                  <td>
                    {this.dataFormatDateFull(
                      cita.patient_appointment?.start_date
                    )}
                  </td>
                  <td>{this.dataFormatMoney(cita.unit_value)}</td>
                  <td>
                    <div>
                      <ButtonDelete
                        delete={this.props.delete.bind(this)}
                        onRef={(ref) => (this.ButtonDelete = ref)}
                        row={cita}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </table>
      </TableSummaryContainer>
    );
  }
}

SummaryTable.propTypes = {
  DeleteProfileByPages: PropTypes.func.isRequired,
  delete: PropTypes.func,
  pages: PropTypes.array.isRequired,
  data: PropTypes.array,
};

SummaryTable.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pages: state.generic.pages,
  profileByPage: state.generic.profileByPage,
});

export default connect(mapStateToProps, {
  DeleteProfileByPages,
  getPages,
})(SummaryTable);
