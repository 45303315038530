import React from 'react';
import { each, map } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Rating from 'react-rating';

// Material Comps
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';

// User Actions
import { getDoctorProfile } from "../../redux/actions/DoctorAction";

// Selector
import { doctorDetails } from '../../Selectors/doctorSelector';

// Constants

// Images
import logo from '../../assets/img/logo/logo.png';

// Styles
import styles from './Styles';

class DoctorProfileDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openModal : true
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.props.getDoctorProfile(this.props.doctorId);
  }

  //#region Build Functions
  buildConcatenatedString(data) {
    let parsedToString = '';
    each(data, (eachData, index) => {
      parsedToString += eachData;

      const isSecondLast = index === data.length - 2;
      const isTheLast = index === data.length - 1;

      if (isSecondLast) {
        parsedToString += ' y ';
      } else if (!isTheLast) {
        parsedToString += ', ';
      }
    });

    return parsedToString;
  }

  buildList(data) {
    return map(data, (eachData, index) => {
      return (
        <div key={index} style={styles.contentList}>
          <div key={index} style={styles.listIndicator} />
          <span>{eachData}</span>
        </div>
      );
    });
  }

  buildSection(title, direction, data, typeContent) {
    if (!data || !data.length) {
      return null;
    }

    let content = null;

    switch (typeContent) {
      case 'concatString':
        content = this.buildConcatenatedString(data);
        break;
      case 'certificates':
        content = this.buildCertificatesSection(data);
        break;
      default:
        content = this.buildList(data);
        break;
    }

    return (
      <Grid item xs={12} container>
        <Grid item xs={12} container style={styles.separatorSection}>
          <strong style={styles.section}>{title}</strong>
        </Grid>
        <Grid style={styles.nameEspecialityContainer} container direction={direction} alignItems={'flex-start'}>
          {content}
        </Grid>
      </Grid>
    );
  }

  buildCertificatesSection(data) {
    return map(data, (file, index) => {
      return (
        <div key={index} style={styles.contentList}>
          <img src={`data:image/png;base64,${file.binary}`} style={styles.certificateImg} />
        </div>
      );
    });
  }

  closeModal() {
    this.setState({
      openModal: false,
    });
    this.props.closeDoctorModal(); 
  }
  render() {
    if (this.props.doctor.executingRequest) {
      return null;
    }

    const doctor = this.props.doctorProfile;
    const photoSource = !doctor.profilePhoto ? '/assets/img/avatars/doctor.png' : `data:image/png;base64,${doctor.profilePhoto}`;
    
    return (
      <Modal
        isOpen={this.state.openModal}
        size={'lg'}
        toggle={() => { this.props.closeDoctorModal(); }}
      >
        <ModalBody>
          <ModalHeader toggle={() => { this.props.closeDoctorModal(); }}>
            <img src={logo} data-retina="true" style={styles.logoSt} />
          </ModalHeader>
          <Grid container direction={'row'} justify={'space-between'}>
            <Grid item sm={3} container alignItems={'center'} justify={'center'}>
              <img className="img-thumbnail" style={styles.avatar} src={photoSource} />
            </Grid>
            <Grid item sm={9}>
              <Grid style={styles.nameEspecialityContainer} container direction={'column'} alignItems={'center'} justify={'center'}>
                <strong style={styles.title}>{`Dr. ${doctor.doctorName || ''}`}</strong>
                <span style={styles.especialities}>{this.buildConcatenatedString(doctor.specialities)}</span>
                <Rating
                  emptySymbol="fa fa-star-o fa-2x"
                  fullSymbol="fa fa-star fa-2x"
                  onClick={(value)=>console.log('rating', value)}
                />
              </Grid>
            </Grid>
            {this.buildSection(`Tratamientos y Procedimientos:`, 'row', doctor.expertIn)}
            <Grid item xs={12} container style={styles.separatorSection}>
              <strong style={styles.section}>{`Curriculum y resultados del proceso de selección`}</strong>
            </Grid>
            <Grid style={styles.nameEspecialityContainer} container item xs={12}>
              <Grid container item xs={12}>
                <strong style={styles.minorTitle}>{`Tarjeta profesional: `}</strong>
                <span>{doctor.professionalCard}</span>
              </Grid>
            </Grid>
            {this.buildSection(`Experiencia Profesional`, 'column', doctor.review ? [doctor.review] : [])}
            {this.buildSection(`Subespecialización`, 'row', doctor.subEspecialities, 'concatString')}
            {this.buildSection(`Docencia`, 'row', doctor.teaching)}
            {this.buildSection(`Logros académicos destacados`, 'row', doctor.academicAchievements)}
            {this.buildSection(`Miembro de asociaciones médicas privadas`, 'column', doctor.privateMedicalAssociations)}
            {this.buildSection(`Idiomas`, 'row', doctor.languages)}
            {this.buildSection(`Enfermedades, Pruebas y Tratamientos`, 'row', doctor.sicknessTest)}
            {/* {this.buildSection(`Certificados`, 'row', doctor.certificates, 'certificates')} */}
          </Grid>
        </ModalBody>
      </Modal>
    );
  }
}

DoctorProfileDetails.propTypes = {
  user: PropTypes.object,
  // width: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  width: PropTypes.string,
  doctorId: PropTypes.string,
  doctorProfile: PropTypes.object,
  doctor: PropTypes.object,
  closeDoctorModal: PropTypes.func,
  getDoctorProfile: PropTypes.func,
};

const mapStateToProps = state => ({
  user: state.user,
  doctor: state.doctor,
  doctorProfile: doctorDetails(state.doctor),
});

export default connect(
  mapStateToProps,
  {
    getDoctorProfile
  }
)(withWidth()(DoctorProfileDetails));
