import {
  GET_SPECIALITY_BY_DOCTOR,
  GET_SPECIALITY,
  GET_SUB_SPECIALITY,
  ERROR_API,
  GET_CONSULTNG_ROOM,
  GET_SCHEDULERS,
  GET_HEALTH_ENTITIES,
  GET_AGREEMENTS,
  GET_HOLIDAYS,
  GET_CERTIFICATIONS,
  GET_DOCTOR,
  GET_APPOINTMENTS,
  GET_ANNOTATIONS,
  GET_CIE,
  GET_CUP,
  GET_CIEXMEDICO,
  GET_CUPXMEDICO,
  GET_ESPXMEDICO_BUSQUEDA,
  GET_DOCTOR_PERMISSION,
  GET_USER_AGREEMENT_BUSQUEDA,
  GET_USER_AGREEMENT,
  GET_DOCTOR_CONSULTING_ROOM,
  GET_CITAS_EN_ESPERA,
  GET_PENDING_APPOINTMENTS,
  GET_CITAS_EN_OFERTA,
  GET_ALL,
  GET_QUERY_INDEX,
  GET_CITAS_SIN_PAGO,
  GET_TARIFA_PRODUCTOS,
  GET_PRODUCTOS_SERVICIOS,
  GET_DETALLE_CARRO_COMPRAS,
  GET_DATOS_COMPRA,
  GET_REPORTE_1,
  GET_CONSULTORIOS_POR_CITA_ESPERA,
  GET_CITAS_PACIENTE_ACTIVAS,
  GET_CITAS_PROXIMAS_DOCTOR,
  GET_CITAS_MEDICO_ACTIVAS,
  SET_EXECUTING_REQUEST,
  SET_LAST_ACTION,
  SET_CONSULTATION_PATIENT,
  GET_DATOS_VIDEO,
  GET_ANTHROPOMETRIC_MEASURES,
  GET_ANTHROPOMETRICS_DOCTOR,
  GET_DIAGNOSES_CIE,
  GET_DIAGNOSES_DOCTOR,
  GET_DIAGNOSES_CUPS,
  GET_DIAGNOSES_CUPS_DOCTOR,
  GET_HISTORY_SECTIONS,
  GET_TEMPLATES_DOCTOR,
  GET_VITAL_SIGNS,
  GET_VITAL_SIGNS_DOCTOR,
  GET_MEDICINES,
  GET_MEDICINES_DOCTOR,
  SET_PATIENT_EXAMS,
  SET_MEDICAL_SPECIALITIES,
  SET_SPECIALIZED_EXAMS,
  SET_SEARCH_LISTS,
  SET_DATA_TYPES,
  SET_VARIABLE_MEASURES,
  GET_CONSULTATION_TYPES,
} from "../actions/types";

const initialState = {
  consultationTypes: [],
  specialityByDoctor: [],
  speciality: [],
  subSpeciality: [],
  consultingRoom: [],
  schedulers: [],
  agreements: [],
  healthEntities: [],
  holiDays: [],
  certifications: [],
  appointments: [],
  doctors: [],
  annotations: [],
  cies: [],
  cups: [],
  ciesxmedico: [],
  cupsxmedico: [],
  espxmedicobusqueda: [],
  doctorPermission: [],
  userAgreementsBusqueda: [],
  userAgreements: [],
  consultingRoomByDoctor: [],
  citasEnEspera: [],
  pendingAppointments: [],
  citasEnOferta: [],
  getAll: [],
  getQueryIndex: [],
  citasSinPago: [],
  tarifaProductos: [],
  productosServicios: [],
  detalleCarroCompras: [],
  detalleReporte1: [],
  consultoriosPorCitaEspera: [],
  citasPacienteActivas: [],
  citasDoctorProximas: [],
  citasDoctorActivas: [],
  executingRequest: false,
  lastAction: null,
  consultationPatient: null,
  datosVideo: null,
  medidasAntropometricas: [],
  medidasAntropometricasDoctor: [],
  diagnosticosCie: [],
  diagnosticosCieDoctor: [],
  diagnosticosCups: [],
  diagnosticosCupsDoctor: [],
  seccionesHistoria: [],
  plantillasDoctor: [],
  signosVitales: [],
  signosVitalesDoctor: [],
  medicamentos: [],
  medicamentosDoctor: [],
  patientExams: [],
  medicalSpecialities: [],
  specializedExams: [],
  variableMeasures: [],
  dataTypes: [],
  searchLists: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_API:
      return {
        ...state,
        specialityByDoctor: [],
        errorCode: action.payload,
      };
    case GET_SPECIALITY_BY_DOCTOR:
      return {
        ...state,
        specialityByDoctor: action.payload.data,
      };
    case GET_SPECIALITY:
      // eslint-disable-next-line no-case-declarations
      const dataSorted = action.payload.data.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      return {
        ...state,
        speciality: dataSorted,
      };
    case GET_SUB_SPECIALITY:
      return {
        ...state,
        subSpeciality: action.payload.data,
      };
    case GET_CONSULTNG_ROOM:
      return {
        ...state,
        consultingRoom: action.payload.data,
      };
    case GET_SCHEDULERS:
      return {
        ...state,
        schedulers: action.payload[0]?.scheduler,
      };
    case GET_HEALTH_ENTITIES:
      return {
        ...state,
        healthEntities: action.payload.data,
      };
    case GET_AGREEMENTS:
      return {
        ...state,
        agreements: action.payload.data,
      };
    case GET_HOLIDAYS:
      return {
        ...state,
        holiDays: action.payload.data,
      };
    case GET_CERTIFICATIONS:
      return {
        ...state,
        certifications: action.payload.data,
      };
    case GET_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload.data,
      };
    case GET_DOCTOR:
      return {
        ...state,
        doctors: action.payload.data,
      };
    case GET_ANNOTATIONS:
      return {
        ...state,
        annotations: action.payload.data,
      };
    case GET_CIE:
      return {
        ...state,
        cies: action.payload.data,
      };
    case GET_CUP:
      return {
        ...state,
        cups: action.payload.data,
      };
    case GET_CIEXMEDICO:
      return {
        ...state,
        ciesxmedico: action.payload.data,
      };
    case GET_CUPXMEDICO:
      return {
        ...state,
        cupsxmedico: action.payload.data,
      };
    case GET_ESPXMEDICO_BUSQUEDA:
      return {
        ...state,
        espxmedicobusqueda: action.payload.data,
      };
    case GET_DOCTOR_PERMISSION:
      return {
        ...state,
        doctorPermission: action.payload.data,
      };
    case GET_USER_AGREEMENT_BUSQUEDA:
      return {
        ...state,
        userAgreementsBusqueda: action.payload.data,
      };
    case GET_USER_AGREEMENT:
      return {
        ...state,
        userAgreements: action.payload.data,
      };
    case GET_DOCTOR_CONSULTING_ROOM:
      return {
        ...state,
        consultingRoomByDoctor: action.payload.data,
      };

    case GET_CITAS_EN_ESPERA:
      return {
        ...state,
        citasEnEspera: action.payload.data[0],
      };
    case GET_PENDING_APPOINTMENTS:
      return {
        ...state,
        pendingAppointments: action.payload.data,
      };
    case GET_CITAS_EN_OFERTA:
      return {
        ...state,
        citasEnOferta: action.payload.data,
      };
    case GET_ALL:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case GET_QUERY_INDEX:
      return {
        ...state,
        getQueryIndex: action.payload.data,
      };
    case GET_CITAS_SIN_PAGO:
      return {
        ...state,
        citasSinPago: action.payload.data,
      };
    case GET_TARIFA_PRODUCTOS:
      return {
        ...state,
        tarifaProducto: action.payload.data,
      };

    case GET_PRODUCTOS_SERVICIOS:
      return {
        ...state,
        productosServicios: action.payload.data,
      };

    case GET_DETALLE_CARRO_COMPRAS:
      return {
        ...state,
        detalleCarroCompras: action.payload.data,
      };

    case GET_DATOS_COMPRA:
      return {
        ...state,
        datosCompra: action.payload.data,
      };

    case GET_REPORTE_1:
      return {
        ...state,
        detalleReporte1: action.payload.data,
      };

    case GET_CONSULTORIOS_POR_CITA_ESPERA:
      return {
        ...state,
        consultoriosPorCitaEspera: action.payload.data,
      };

    case GET_CITAS_PACIENTE_ACTIVAS:
      return {
        ...state,
        citasPacienteActivas: action.payload.data,
      };

    case GET_CITAS_PROXIMAS_DOCTOR:
      return {
        ...state,
        citasDoctorProximas: action.payload.data,
      };

    case GET_CITAS_MEDICO_ACTIVAS:
      return {
        ...state,
        citasDoctorActivas: action.payload.data,
      };
    case SET_EXECUTING_REQUEST:
      return {
        ...state,
        executingRequest: action.value,
      };
    case GET_DATOS_VIDEO:
      return {
        ...state,
        datosVideo: action.payload,
      };
    case SET_LAST_ACTION:
      return {
        ...state,
        lastAction: action.value,
      };
    case SET_CONSULTATION_PATIENT:
      return {
        ...state,
        consultationPatient: action.value,
      };
    case GET_ANTHROPOMETRIC_MEASURES:
      return {
        ...state,
        medidasAntropometricas: action.payload.data,
      };
    case GET_ANTHROPOMETRICS_DOCTOR:
      return {
        ...state,
        medidasAntropometricasDoctor: action.payload.data,
      };
    case GET_DIAGNOSES_CIE:
      return {
        ...state,
        diagnosticosCie: action.payload.data,
      };
    case GET_DIAGNOSES_DOCTOR:
      return {
        ...state,
        diagnosticosCieDoctor: action.payload.data,
      };
    case GET_DIAGNOSES_CUPS:
      return {
        ...state,
        diagnosticosCups: action.payload.data,
      };
    case GET_DIAGNOSES_CUPS_DOCTOR:
      return {
        ...state,
        diagnosticosCupsDoctor: action.payload.data,
      };
    case GET_HISTORY_SECTIONS:
      return {
        ...state,
        seccionesHistoria: action.payload.data,
      };
    case GET_TEMPLATES_DOCTOR:
      return {
        ...state,
        plantillasDoctor: action.payload.data,
      };
    case GET_VITAL_SIGNS:
      return {
        ...state,
        signosVitales: action.payload.data,
      };
    case GET_VITAL_SIGNS_DOCTOR:
      return {
        ...state,
        signosVitalesDoctor: action.payload.data,
      };
    case GET_MEDICINES:
      return {
        ...state,
        medicamentos: action.payload.data,
      };
    case GET_MEDICINES_DOCTOR:
      return {
        ...state,
        medicamentosDoctor: action.payload.data,
      };
    case SET_PATIENT_EXAMS:
      return {
        ...state,
        patientExams: action.payload,
      };
    case SET_MEDICAL_SPECIALITIES:
      return {
        ...state,
        medicalSpecialities: action.payload,
      };
    case SET_SPECIALIZED_EXAMS:
      return {
        ...state,
        specializedExams: action.payload,
      };
    case SET_VARIABLE_MEASURES:
      return {
        ...state,
        variableMeasures: action.payload,
      };
    case SET_DATA_TYPES:
      return {
        ...state,
        dataTypes: action.payload,
      };
    case SET_SEARCH_LISTS:
      return {
        ...state,
        searchLists: action.payload,
      };
    case GET_CONSULTATION_TYPES:
      return {
        ...state,
        consultationTypes: action.payload,
      };
    default:
      return state;
  }
}
