/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from 'yup';

const schema = yup.object().shape({
    terminosAceptados: yup.boolean().oneOf([true], 'Debes de aceptar terminos y condiciones'),
    speciality: yup
      .string()
    .required('Especialidad es requerida'),
    ciudad: yup
      .string()
    .required('Ciudad es requerida'),
    clase_atencion: yup
      .string()
    .required('Tipo de consulta es requerido'),
    // range: yup.object().shape({
    //   startDate: yup.string('Fecha de inicio es requerida').required('Fecha de inicio es requerida'),
    //   endDate: yup.string().required('Fecha final es requerida'),
    // })
});

export default schema;
