/* eslint-disable react/jsx-no-target-blank */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Label, Col, Row, FormGroup } from "reactstrap";
import {
  ContainerPersonRegisterForm,
  SubContainerRegisterForm,
  ContainerInputsRegisterForm,
  AddContainerBackLogin,
} from "./personRegisterForm.style";
import PhoneInput from "react-phone-input-2";

// schema
import schema from "./formConfig";

// Utils
import { renderError } from "../../../Utils";
import "react-phone-input-2/lib/style.css";
import Logo from "../../../assets/img/logo/logo.png";
import {
  getCitiesSelect,
  getCountriesRegister,
  getStates,
} from "../../../redux/actions/GenericAction";

/**
 * @author Intelisoft
 * @file index.js
 * @description Application Footer
 */

function RegisterForm({
  openTerms,
  documentTypes,
  submitForm,
  loading,
  allProps,
  allStates,
  goBack,
  // eventOnSelectCountry,
  // eventOnSelectDepartment,
}) {
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("data", data);
    submitForm(data);
  };
  const [eye, setEye] = useState("fa fa-eye-slash");
  const [typePassword, setTypePassword] = useState("password");
  const [username, setUsername] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSelected, setStateSelected] = useState(null);
  const [citySelected, setCitySelected] = useState(null);

  const showPassword = () => {
    if (typePassword == "password") {
      setEye("fa fa-eye");
      setTypePassword("text");
    } else {
      setEye("fa fa-eye-slash");
      setTypePassword("password");
    }
  };

  useEffect(async () => {
    const response = await getCountriesRegister();
    setCountries(response);
  }, []);

  useEffect(async () => {
    setStateSelected(null);
    if (country) {
      const response = await getStates(country.id);
      setStates(response);
    }
  }, [country]);

  useEffect(async () => {
    setCitySelected(null);
    if (stateSelected) {
      const response = await getCitiesSelect(stateSelected.id);
      setCities(response);
    }
  }, [stateSelected]);

  return (
    <>
      <ContainerPersonRegisterForm>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="container-header-form">
            <div className="container-logo">
              <img src={Logo} alt="logo" onClick={goBack} />
            </div>
          </div>

          <SubContainerRegisterForm>
            <ContainerInputsRegisterForm>
              <div className="container-inputs">
                <input
                  type="text"
                  name="name"
                  placeholder="Primer Nombre"
                  className={`form-control ${
                    errors && errors.name && "is-invalid"
                  }`}
                  ref={register}
                />
                {errors.name && renderError(errors.name.message)}
              </div>
              <div className="container-inputs">
                <input
                  type="text"
                  name="second_name"
                  placeholder="Segundo Nombre"
                  className="form-control"
                  ref={register}
                />
              </div>

              <div className="container-inputs">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Primer Apellido"
                  className={`form-control ${errors.lastName && "is-invalid"}`}
                  ref={register}
                />
                {errors.lastName && renderError(errors.lastName.message)}
              </div>
              <div className="container-inputs">
                <input
                  type="text"
                  name="second_last_name"
                  placeholder="Segundo Apellido"
                  className="form-control"
                  ref={register}
                />
              </div>

              <div className="container-inputs">
                <Controller
                  name="document_type"
                  control={control}
                  render={({ onChange }) => {
                    return (
                      <Select
                        options={documentTypes || []}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={onChange}
                        placeholder="Tipo Documento"
                        isClearable
                        styles={{
                          control: (provided) =>
                            errors.document_type
                              ? {
                                  ...provided,
                                  borderColor: "red",
                                }
                              : provided,
                        }}
                      />
                    );
                  }}
                />
                {errors.document_type &&
                  renderError(errors.document_type.message)}
              </div>
              <div className="container-inputs">
                <input
                  type="text"
                  name="document"
                  placeholder="Número Documento *"
                  onChange={(e) => setUsername(e.target.value)}
                  className={`form-control ${errors.document && "is-invalid"}`}
                  ref={register}
                />
                {errors.document && renderError(errors.document.message)}
              </div>

              <div className="container-inputs">
                <Controller
                  name="residence_country"
                  control={control}
                  render={({ onChange }) => {
                    return (
                      <Select
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Pais de residencia"
                        onChange={(pais) => {
                          setCountry(pais);
                          // eventOnSelectCountry(pais);
                          onChange(pais);
                        }}
                        value={country}
                        isClearable
                        styles={{
                          control: (provided) =>
                            errors.residence_country
                              ? {
                                  ...provided,
                                  borderColor: "red",
                                }
                              : provided,
                        }}
                      />
                    );
                  }}
                />
                {errors.residence_country &&
                  renderError(errors.residence_country.message)}
              </div>
              <div className="container-inputs">
                <Controller
                  name="residence_department"
                  control={control}
                  render={({ onChange }) => {
                    return (
                      <Select
                        options={states}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Dpto/Estado de residencia"
                        onChange={(state) => {
                          // eventOnSelectDepartment(state);
                          onChange(state);
                          setStateSelected(state);
                        }}
                        value={stateSelected}
                        isClearable
                        styles={{
                          control: (provided) =>
                            errors.residence_department
                              ? {
                                  ...provided,
                                  borderColor: "red",
                                }
                              : provided,
                        }}
                      />
                    );
                  }}
                />

                {errors.residence_department &&
                  renderError(errors.residence_department.message)}
              </div>
              <div className="container-inputs">
                <Controller
                  name="residence_city"
                  control={control}
                  render={({ onChange }) => {
                    return (
                      <Select
                        options={cities}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder="Ciudad de residencia"
                        value={citySelected}
                        onChange={(city) => {
                          onChange(city);
                          setCitySelected(city);
                        }}
                        isClearable
                        styles={{
                          control: (provided) =>
                            errors.residence_city
                              ? {
                                  ...provided,
                                  borderColor: "red",
                                }
                              : provided,
                        }}
                      />
                    );
                  }}
                />

                {errors.residence_city &&
                  renderError(errors.residence_city.message)}
              </div>

              <div className="container-inputs">
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ onChange, value, ref }) => (
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      placeholder="Teléfono *"
                      country="co"
                      onChange={(phone) => onChange(phone)}
                      value={value}
                      inputRef={ref}
                    />
                  )}
                />
                {errors.phone && renderError(errors.phone.message)}
              </div>
              <div className="container-inputs">
                <input
                  type="text"
                  name="email"
                  placeholder="Email *"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  ref={register}
                />
                {errors.email && renderError(errors.email.message)}
              </div>
              <div className="container-inputs">
                <input
                  type="text"
                  name="address"
                  placeholder="Direccion"
                  className="form-control"
                  ref={register}
                />
              </div>

              <div className="container-inputs">
                <input
                  type="text"
                  name="username"
                  placeholder="Username"
                  readOnly
                  value={username}
                  className={`form-control ${errors.username && "is-invalid"}`}
                  ref={register}
                />
                {errors.username && renderError(errors.username.message)}
              </div>
              <div className="container-inputs">
                {/* <a className="cursor-pointer ml-2" onClick={showPassword}>
          <i className={eye} aria-hidden="true"></i>
        </a> */}
                <input
                  type={typePassword}
                  name="password"
                  placeholder="Contraseña *"
                  className={`form-control ${errors.password && "is-invalid"}`}
                  ref={register}
                />
                {errors.password && renderError(errors.password.message)}
              </div>
              <div className="container-inputs">
                {/* <a className="cursor-pointer ml-2" onClick={showPassword}>
          <i className={eye} aria-hidden="true"></i>
        </a> */}
                <input
                  type={typePassword}
                  name="Pass2Paciente"
                  placeholder="Confirmar Contraseña *"
                  className={`form-control ${
                    errors.Pass2Paciente && "is-invalid"
                  }`}
                  ref={register}
                />
                {errors.Pass2Paciente &&
                  renderError(errors.Pass2Paciente.message)}
              </div>
              <div
                className="container-inputs"
                style={{ visibility: "hidden" }}
              >
                <input readOnly />
              </div>
            </ContainerInputsRegisterForm>

            <div className="container-terms-conditions">
              <div>
                <input
                  name="terminos_aceptados"
                  type="checkbox"
                  style={{ width: 16, height: 16, verticalAlign: "middle" }}
                  ref={register}
                  className={`mr-2 ${
                    errors.terminos_aceptados && "is-invalid"
                  }`}
                />
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  <a
                    href="https://www.doctorhoy.com/terminos-y-condiciones/"
                    target="_blank"
                  >
                    Aceptar Términos y condiciones{" "}
                    <span className="text-danger">*</span>
                  </a>
                </label>
                {errors.terminos_aceptados &&
                  renderError(errors.terminos_aceptados.message)}
              </div>
              <div className="container-btn-register">
                <input
                  type="hidden"
                  name="type_user"
                  ref={register}
                  value={0}
                />
                <button type="submit" color="primary">
                  {loading ? (
                    <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24" />
                  ) : (
                    "Registrarme"
                  )}
                </button>
              </div>
            </div>
          </SubContainerRegisterForm>
        </form>
      </ContainerPersonRegisterForm>
      <AddContainerBackLogin>
        <div>
          <span>
            Ya tienes cuenta DoctorHoy,
            <a href="#" onClick="history.go(-1)">
              Ingresar a la plataforma
            </a>
          </span>
        </div>
        <span style={{ fontWeight: "bold" }}>No tenemos convenios con EPS</span>
      </AddContainerBackLogin>
    </>
  );
}

RegisterForm.propTypes = {
  openTerms: PropTypes.func,
  documentTypes: PropTypes.func,
  submitForm: PropTypes.func,
  loading: PropTypes.bool,
};

export default RegisterForm;
