/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  Button,
  UncontrolledTooltip
} from "reactstrap";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
moment.locale("es");


class AdmonTableSeleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.cellBtnOfertar = this.cellBtnOfertar.bind(this);
    this.dataFormatDate = this.dataFormatDate.bind(this);
    this.dataFormatDateTxt = this.dataFormatDateTxt.bind(this);
    this.dataFormatDateFull = this.dataFormatDateFull.bind(this);
    this.dataFormatHours = this.dataFormatHours.bind(this);
    this.dataEspecialidad = this.dataEspecialidad.bind(this);
    this.dataFormatoInfo = this.dataFormatoInfo.bind(this);

    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: "Aún no tienes resumen de tu solicitud de cita"
    };
  }

  dataFormatDateTxt(cell) {
    return moment(cell).format("MMMM Do YYYY, h:mm:ss a");
  }

  dataFormatDate(cell, row) {
    return 'Desde ' + moment(row.fecha_inicial).format("DD/MM/YYYY") + ' Hasta ' + moment(row.fecha_final).format("DD/MM/YYYY");
  }

  dataFormatDateFull(cell) {
    return moment(cell).format("dd/MM/YYYY, h:mm:ss a");
  }

  dataFormatHours(cell, row) {
    //"hora_inicial" hora_final
    let date1 = new Date(2021, 1, 1, 1, 1, 1, 1);
    let x1 = row.hora_inicial.split(":");
    date1.setHours(x1[0]);
    date1.setMinutes(x1[1]);

    let date2 = new Date(2021, 1, 1, 1, 1, 1, 1);
    let x2 = row.hora_final.split(":");
    date2.setHours(x2[0]);
    date2.setMinutes(x2[1]);

    return 'Desde ' + moment(date1).format("h:mm a") + ' Hasta ' + moment(date2).format("h:mm a");
  }

  dataEspecialidad(cell) {
    var espeTemp = this.props.especialidades.find(x => x.id == cell);
    if (espeTemp != undefined) {
      return this.props.especialidades.find(x => x.id == cell).especiality;
    } else {
      return "[Sin registro]";
    }
  }

  dataFormatoInfo(cell, row) {
    var especialidadTemp = "[Sin registro]";
    var espeTemp = this.props.especialidades.find(x => x.id == row.speciality);
    if (espeTemp != undefined) {
      especialidadTemp = this.props.especialidades.find(x => x.id == row.speciality).especiality;
    }

    var claseAtencion = '';
    switch (row.clase_atencion) {
      case 0: claseAtencion = 'En consultorio'; break;
      case 1: claseAtencion = 'Videoconsulta'; break;
      case 2: claseAtencion = 'Atención en casa'; break;
    }
    const id = `seachIcon${row.id}`;

    return (
      <table className="table striped">
        <tr>
          <td><span className="spanGrey">{`Observaciones: `}</span></td>
          <td>{row.observaciones}</td>
        </tr>
        <tr>
          <td><span className="spanGrey">{`Especialidad: `}</span></td>
          <td>{especialidadTemp}</td>
        </tr>
        <tr>
          <td><span className="spanGrey">{`Fecha Solicitud: `}</span></td>
          <td>{moment(row.fecha_solicitud).format('DD/MM/YYYY hh:mm a')}</td>
        </tr>
        <tr>
          <td><span className="spanGrey">{`Fechas: `}</span></td>
          <td>{this.dataFormatDate(cell, row)}</td>
        </tr>
        <tr>
          <td><span className="spanGrey">{`Horas: `}</span></td>
          <td>{this.dataFormatHours(cell, row)}</td>
        </tr>
        <tr>
          <td><span className="spanGrey">{`Clase atención: `}</span></td>
          <td>{claseAtencion}</td>
        </tr>
        <tr>
          <td colSpan="2" align="center">
            <Button
              id={id}
              color="primary"
              size="sm"
              tooltip="Seleccionar"
              className="btn-success"
              onClick={this.props.onSeleccionar.bind(this, row)}
            >
              
              <i className="cui-magnifying-glass icons"></i>
              <span style={{ margin: '10px' }}>Realizar mi busqueda</span>
            </Button>
            <UncontrolledTooltip placement="bottom" target={id}>
              {`Ver Ofertas`}
            </UncontrolledTooltip>
          </td>
        </tr>
      </table>
    );
  }

  cellBtnOfertar(cell, row) {
    const id = `seachIcon${row.id}`;

    return (
      <div>
        <Button
          id={id}
          color="primary"
          size="sm"
          tooltip="Seleccionar"
          onClick={this.props.onSeleccionar.bind(this, row)}
        >
          <i className="cui-magnifying-glass icons"></i>
        </Button>
        <UncontrolledTooltip placement="bottom" target={id}>
          {`Ver Ofertas`}
        </UncontrolledTooltip>
      </div>
    );
  }

  render() {
    return (
      <div>
        <BootstrapTable
          data={this.props.data}
          version="4"
          striped
          hover
          responsive
          pagination
          search={false}
          options={this.options}
          searchPlaceholder={"Buscar"}
        >
          <TableHeaderColumn dataField="id" isKey hidden>
          </TableHeaderColumn>
          <TableHeaderColumn
            tdStyle={{ whiteSpace: 'normal' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.dataFormatoInfo.bind(this)}
            width='80%'
          >
            Resumen de tu solicitud de cita
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  {
  }
)(AdmonTableSeleccion);
