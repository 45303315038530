import React from "react";

// Assets
import logoFoot from "../../assets/img/logo/logo.png";
import { siteHost } from "../../enviroment";

function Footer() {
  return (
    <footer>
      <div className="container margin_60_35">
        <div className="row">
          <div className="col-lg-3 col-md-12">
            <p>
              <a href={siteHost} title="Findoctor">
                <img
                  src={logoFoot}
                  data-retina="true"
                  alt=""
                  width="163"
                  height="36"
                  className="img-fluid"
                />
              </a>
            </p>
          </div>
          <div className="col-lg-3 col-md-4">
            <h5>{`Guia de Usuario`}</h5>
            <ul className="links">
              <li>
                <a
                  href="https://doctorhoystorage.blob.core.windows.net/preguntas-frecuentes/Preguntas y respuestas para usuarios DoctorHoy.pdf"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  {`Preguntas Frecuentes`}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4">
            <h5>{`Contacto`}</h5>
            <ul className="contacts">
              <li>
                <a href="mailto:contacto@doctorhoy.com">{`contacto@doctorhoy.com`}</a>
              </li>
              <li>
                <a href="mailto:servicioalcliente@doctorhoy.com">{`servicioalcliente@doctorhoy.com`}</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="follow_us">
              <h5>Síguenos en:</h5>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/DoctorHoyCO"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/doctorhoyco/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/doctorhoyco/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <ul id="additional_links">
              <li>
                <a href="#0">Terminos y condiciones</a>
              </li>
              <li>
                <a href="#0">Protección de datos</a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <div id="copy">© 2020 DoctorHoy</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
