import styled, { css } from "styled-components";

export const LoginFormContainer = styled.div`
  display: flex;
  form {
    width: 100%;
    color: #000;
  }

  @media (max-width: 600px) {
    display: flex;
  }

  .sign-in-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 0px 7px 0px;
    button {
      border: none;
      background-color: #E07C2C;
      border-radius: 10px;
      width: 90%;
      height: 30px;
      color: #fff;
      margin-bottom: 10px;
    }
  }

  .forgot-password-text-container {
    display: flex;
    color: orange;
    justify-content: center;
    span {
      font-size: 12px;
      font-weight: lighter;
    }
  }

  .login-form-text-footer {
    border-top: #242424 1px solid;
    padding: 15px 0px 30px 0px;
    .firstText {
      margin-bottom: 10px;
      cursor: pointer;
    }
    .secondText {
      a {
        margin-left: 5px;
        color: #4481B8;
        cursor: pointer;
      }
    }
  }
`;
