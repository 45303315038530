//https://www.meetup.com/es/reactcali/members/
import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

// Actions
import { setLastAction } from "../../../redux/actions/GenericAction";
import { getUserNotification } from "../../../redux/actions/userAction";

//import * as signalR from '@microsoft/signalr';
import UIfx from "uifx";
import Hi from "../Sounds/just-saying.mp3";
import $ from "jquery";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import Swal from 'sweetalert2';
import ManitoLogo from "../../../assets/img/manito.jpeg";

const hubSignalR = require("../../../enviroment").api;
window.jQuery = $;
require("ms-signalr-client");

const tick = new UIfx(Hi, {
  volume: 0.4,
  throttleMs: 100,
});

class ConnectSR extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conexionValidada: false,
      connection: null,
      proxy: null,
      notification: [],
      message: "",
      Username: "",
      data: "",
    };

    this.Crearconexion = this.Crearconexion.bind(this);
    this.htmlToast = this.htmlToast.bind(this);
    this.onRedirect = this.onRedirect.bind(this);
    this.executeAction = this.executeAction.bind(this);
  }

  componentDidMount() {
    //Aca Arranca el mount cada vez que se actualiza la page

    //  if (localStorage.token === undefined) {
    //      console.log("Sin Token");
    //
    //  } else {
    //      console.log("con Token");

    //   if (proxy != null) {

    //       proxy.on("mensajeIndividual", (mensaje) => {
    //           console.log("Recibio mensaje: " + mensaje)
    //           toast.success(mensaje);
    //       });
    //
    //  }

    //  }
    this.Crearconexion();
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    console.log("Se desconecta de signalR en ConnectSR componentWillUnmount");
    if (this.connection !== null && this.conexion !== undefined) {
      this.connection.stop(); //Cierra la conexion
    }
    // this.props.addConnection(null)
  }

  executeAction(message) {
    console.log("message", message);
    if (message.includes("Tiene una nueva solicitud de")) {
      this.props.setLastAction("REFRESH_OFFER_PAGE");
    } else if (message.includes("Has recibido una oferta para tu Cita")) {
      this.props.setLastAction("REFRESH_PATIENT_OFFER_PAGE");
    }
  }

  Crearconexion() {
    if (!this.state.conexionValidada) {
      // console.log("El token es: " + localStorage.token);
      var conexion = null;
      var proxy = null;
      //   if (this.props.conexion === null || this.props.conexion === undefined) {
      conexion = $.hubConnection(hubSignalR);
      proxy = conexion.createHubProxy("ChatHub");

      proxy.on("mensajeIndividual", (mensaje) => {
        this.executeAction(mensaje);
        console.log("Recibio mensaje cambio: " + mensaje);
        // toast.success(mensaje);
        Swal.fire({
          title: mensaje,
          iconHtml: `<img src="${ManitoLogo}"/>`,
        })
        tick.play();
        this.props.getUserNotification();
      });

      proxy.on("notificacionPaciente", (cita) => {
        // this.executeAction(cita);
        cita.type = 'REFRESH_CITA_PAGE'
        this.props.setLastAction(cita);
        // console.log("Nuevo: ", cita);
        // toast.success(mensaje);
        Swal.fire({
          title: cita.message,
          iconHtml: `<img src="${ManitoLogo}"/>`,
        })
        tick.play();
        this.props.getUserNotification();
      });

      proxy.on("notificacionEliminacionCitaOfertada", (cita) => {
        console.log("notificacionEliminacionCitaOfertada: ", cita);
        cita.type = 'DELETE_OFERT_PAGE'
        this.props.setLastAction(cita);
        Swal.fire({
          title: cita.message,
          iconHtml: `<img src="${ManitoLogo}"/>`,
        })
      });
      
      proxy.on("notificacionActualizacionCitaOfertada", (cita) => {
        console.log("notificacionActualizacionCitaOfertada: ", cita);
        cita.type = 'UPDATED_OFERT_PAGE'
        this.props.setLastAction(cita);
        Swal.fire({
          title: cita.message,
          iconHtml: `<img src="${ManitoLogo}"/>`,
        })
      });
      

      //  console.log("El proxy esta OK")
      proxy.on("broadcastMessage", (username, mensaje, id) => {
        console.log(
          "Recibio el mensaje de" + username + " " + mensaje + " " + id
        );
        tick.play();
        
        Swal.fire({
          title: mensaje,
          icon: `error`,
        })
      });

      proxy.on("validarConexion", (mensaje) => {
        // console.log("Conexion validada, msj: " + mensaje);
        this.setState({ conexionValidada: true });
        //    toast.error(mensaje)
      });

      proxy.on("llamadaEntrante", (llamadaId) => {
        //   console.log("Recibio el mensaje de" + username + " " + mensaje + " " + id)
        tick.play();
        let htmlToastLlamada = this.htmlToast(llamadaId);
        
        Swal.fire({
          html: htmlToastLlamada,
          iconHtml: `<img src="${ManitoLogo}"/>`,
          timer: 10000,
          timerProgressBar: true,  
        })
      });

      conexion
        .start()
        .done(function () {
          proxy.invoke("Connect", localStorage.token);
          // proxy.invoke("ValidarCon");          
        })
        .fail(function () {
          console.log("Error On connection");
        });
    }
  }

  htmlToast(llamadaId) {
    console.log(llamadaId);
    return (
      <div style={{ zIndex: 500 }}>
        Tiene una llamada{" "}
        <a color="primary" className="btn btn-primary" href={llamadaId}>
          Ir a consulta
        </a>
      </div>
    );
  }

  onRedirect(url) {
    console.log(url);
    //   this.props.history.push('/posts/');
  }

  render() {
    return (
      <div className="App">
        {this.state.conexionValidada ? "" : "Conectando..."}
      </div>
    );
  }
}

//function mapStateToProps(dispatch) {
//    return {
//        addConnection: (value) => {
//            dispatch({ type: 'SIGNALRCON', payload: value })
//        },
//        addProxy: (value) => {
//            dispatch({ type: 'SIGNALRPROXY', payload: value })
//        },
//
//        //    list:state.list.message,
//        //   noti:state.list.notification,
//           connection:state.list.connection,
//           proxy:state.list.proxy,
//        //    username:state.list.username,
//        //   message:state.list.message,
//    }
//}

ConnectSR.propTypes = {
  setLastAction: PropTypes.func.isRequired,
  getUserNotification: PropTypes.func.isRequired,
};

// function mapDispatch(dispatch) {
//   return {
//     addConnection: (a, value) => {
//       dispatch(SetDatosSignalRConexion(value));
//     },
//     addProxy: (a, value) => {
//       dispatch(SetDatosSignalRProxy(value));
//     },
//   };
// }

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLastAction,
      getUserNotification,
    },
    dispatch
  );

function mapStateToProps(state) {
  return {
    conexion: state.generic.signalRConexion,
    proxy: state.generic.signalRProxy,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectSR);
