/* eslint-disable no-self-assign */
/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import Resizer from "react-image-file-resizer";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import to from "await-to-js";
import moment from "moment";
import { each, split } from "lodash";
import { AppSwitch } from "@coreui/react";
import { Button, Table } from "reactstrap";
import {
  getCountryUrl,
  DocumetTypes,
  getCertificationsUrl,
  userInfo as userInfoEndpoint,
} from "../../redux/actions/types";
import {
  getUsersRecoveryPassword,
  updatePassword,
  getUserInfo,
} from "../../redux/actions/userAction";
import { ToastContainer, toast } from "react-toastify";
import {
  getCountries,
  getDocumentType,
  getCities,
  getCitiesReducer,
  getDepartments,
  getSocialNetworks,
  getEthnicGroups,
  getSexualOrientation,
  getGender,
  getStatesReducer,
} from "../../redux/actions/GenericAction";
import {
  cities,
  departments,
  getEthnicGroupsUrl,
  getGenderUrl,
  getDoctorUrl,
  getEspXMedicoBusquedaUrl,
} from "../../redux/actions/types";
import {
  GetCertifications,
  GetDoctor,
  updateDoctor,
  GetSpecialityByDoctor,
  GetEspXMedicoBusqueda,
  getDoctorCertifications,
  getApiDoctor,
  getDoctorCertification,
  GetDoctorNew,
} from "../../redux/actions/DoctorAction";

// Components
import FormUpdateRegister from "../../Components/Forms/DoctorUpdateRegister";

import "../LoadExams/exams.css";
import { PreloaderDocHoy } from "../../Utils";

let options = [];
let telephones = [];
let celPhones = [];
let telephonesJob = [];

class RegisterDoctor extends Component {
  constructor(props) {
    super(props);

    this.onRegisterDoctor = this.onRegisterDoctor.bind(this);
    this.onSelectDocumentType = this.onSelectDocumentType.bind(this);
    this.fileChangedHandlerImgCompany =
      this.fileChangedHandlerImgCompany.bind(this);
    this.registerTelephone = this.registerTelephone.bind(this);
    this.registerCelPhone = this.registerCelPhone.bind(this);
    this.registerTelephoneJob = this.registerTelephoneJob.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectDepartment = this.onSelectDepartment.bind(this);
    this.onSelectCountryResident = this.onSelectCountryResident.bind(this);
    this.onSelectDepartmentResident =
      this.onSelectDepartmentResident.bind(this);
    this.buildTableCrtifications = this.buildTableCrtifications.bind(this);
    this.fileChangedHandlerCertification =
      this.fileChangedHandlerCertification.bind(this);
    this.onDeleteCertication = this.onDeleteCertication.bind(this);
    this.fileChangedHandlerImgSign = this.fileChangedHandlerImgSign.bind(this);
    this.cellSwitch = this.cellSwitch.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.formatRegisterType = this.formatRegisterType.bind(this);
    this.onViewCertifications = this.onViewCertifications.bind(this);
    this.onChangeFileCertification = this.onChangeFileCertification.bind(this);
    this.resizeFile = this.resizeFile.bind(this);
    this.handlerShowCertification = this.handlerShowCertification.bind(this);
    this.state = {
      userInfoDoctor: null,
      filesDocuments: [],
      imageUrl: null,
      initialValues: false,
      loading: false,
      sendUpdate: false,
      stateUpper: false,
      stateLower: false,
      stateSpecial: false,
      stateMaxLength: false,
      documentTypeName: "Tipo Documento",
      idDocumentType: "",
      loadImage: false,
      imgSrc:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA8AAAAMcAQMAAACl5F6MAAAABlBMVEW8vsDn6OnyCdevAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4AezBMQEAAADCoPVP7WsIoAcAAAAAAAAAAAAAAAAAAAAAAIydO0iOk4eCAGyVXNEuukF0FB1NHE1H0RG0ZEHRf5A99WYMk1T+Kvolw+vVjBf+CkMLoRCFWQkuqCquB3QOOQKrCpwBKJ1i6Jxk/EzTubaArgAHQOeyjhu8aMEafUrYolLjLRMfLrpw5cMYadeBnTLcrwN7ZXi+DhyuCi8GG/xy8Gyw3SRe7n7cLzfnajah5z87keFJ//lYf0WAAq+vv+rDX+fir+zpr2Xqr95qrle/ywr9OxX+/nF19fGRmR/yrzCJCudRqNHiNDHhIlquTBgCF2aX3V2BwYT9nUYdNoNojnqjiKJ56q0xiRaok4EsWqQO2EW0RJ3wQbTMvDc6iFaYswEvkx5Hnf8E0Tx1xhcfj3gh1lhOLHVWnx8vLvBq/FAnYpGBhwEElVfjhyETjVjj5bFanATpLrHIcpDkIstpJRdZLmRykaW63CLLMZKLLGeVXGS5jvlFluZKq8k1lnGMXGMZuck1lnsVu8ZydybXWOYj5BrLDIxcY5lz8mrML/JupPIC02osf3tyjeVqY9dYikys8QlTAUIsFovFYrFYLJaIP8hq8D4GG2ywwQYbbLDBBhtssMEGG2ywwQYb3N9+G/ePwQYbbLDBBhtssMEGG5yhA3ugqcARmFXgDKwqcAGgAsu+J1zYy94UXDjIRxosH+crwUm2euHCWX43Fy7YciUYI9N1YIeRavDpsMdIM9jgs+BOh4PBavA3ras6aMFRC06TEpyrLsy/H5emBKOrwvxZpsOsA3ssOk8SQQ9edZ4WI6DzfJwU4Ym/FCE7ofFXfQoJlnUugRsHdl9GTKBzlxRF6vzVW9kngr5eLTB5hT6MUhPgLfdDRhwQCQ79TQXe756tAWcp10XgIuMYF8aWxoedLtz5sB/wzIeDLrzw4QiRqHBSgOvnwLWFCrc7eCLCbnwrXFh2ZMdHKhEOyx3ciHBch8OHE2TtCZ0IZ0wCz1S4jhGTDhc0ecN9IcLja/qEVwIswqwC+/H3zfgMDw7j95cbPBFg+YEKnIaGWyoNzpvmcEujws0rwGX7LnCnwWOgDLhlZsFuaJEP+zFCC7yw4DCAhFtWFhwHl/lwwpeowRMJzlpw+QpXEgwl2O3gxoH9Du4cOOzgmQNHLTjt4OXF4byDVw5csAsHhhLssM/EgD32qQw4aMER+zQtuDPgpAVn7DMz4IJ9FgYMJdgdwSsB9jgKAQ5acDyEJy24ng8nLTgfwu18uBzC/XwYSrA7hmfCeyCHWU6Hw18Gr4R3fY5zOpy04PwEngj/KfYw9WwYSrB7BjfCW4qH6a8Kh2fwTHgF9jDLyXDSgvMzeKW8UX6Uk2Eowe45PL0m7J/D9VQ4aMG/isH/JwYbbLDBBhtssMEGG2ywtz3K/2tvDmQAAAAABvlbn+NbCSQWi8VisVgsFovFYrFYLBYvAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHVJjR5bJLd8AAAAASUVORK5CYII=",
      signSrc:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA8AAAAMcAQMAAACl5F6MAAAABlBMVEW8vsDn6OnyCdevAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4AezBMQEAAADCoPVP7WsIoAcAAAAAAAAAAAAAAAAAAAAAAIydO0iOk4eCAGyVXNEuukF0FB1NHE1H0RG0ZEHRf5A99WYMk1T+Kvolw+vVjBf+CkMLoRCFWQkuqCquB3QOOQKrCpwBKJ1i6Jxk/EzTubaArgAHQOeyjhu8aMEafUrYolLjLRMfLrpw5cMYadeBnTLcrwN7ZXi+DhyuCi8GG/xy8Gyw3SRe7n7cLzfnajah5z87keFJ//lYf0WAAq+vv+rDX+fir+zpr2Xqr95qrle/ywr9OxX+/nF19fGRmR/yrzCJCudRqNHiNDHhIlquTBgCF2aX3V2BwYT9nUYdNoNojnqjiKJ56q0xiRaok4EsWqQO2EW0RJ3wQbTMvDc6iFaYswEvkx5Hnf8E0Tx1xhcfj3gh1lhOLHVWnx8vLvBq/FAnYpGBhwEElVfjhyETjVjj5bFanATpLrHIcpDkIstpJRdZLmRykaW63CLLMZKLLGeVXGS5jvlFluZKq8k1lnGMXGMZuck1lnsVu8ZydybXWOYj5BrLDIxcY5lz8mrML/JupPIC02osf3tyjeVqY9dYikys8QlTAUIsFovFYrFYLJaIP8hq8D4GG2ywwQYbbLDBBhtssMEGG2ywwQYb3N9+G/ePwQYbbLDBBhtssMEGG5yhA3ugqcARmFXgDKwqcAGgAsu+J1zYy94UXDjIRxosH+crwUm2euHCWX43Fy7YciUYI9N1YIeRavDpsMdIM9jgs+BOh4PBavA3ras6aMFRC06TEpyrLsy/H5emBKOrwvxZpsOsA3ssOk8SQQ9edZ4WI6DzfJwU4Ym/FCE7ofFXfQoJlnUugRsHdl9GTKBzlxRF6vzVW9kngr5eLTB5hT6MUhPgLfdDRhwQCQ79TQXe756tAWcp10XgIuMYF8aWxoedLtz5sB/wzIeDLrzw4QiRqHBSgOvnwLWFCrc7eCLCbnwrXFh2ZMdHKhEOyx3ciHBch8OHE2TtCZ0IZ0wCz1S4jhGTDhc0ecN9IcLja/qEVwIswqwC+/H3zfgMDw7j95cbPBFg+YEKnIaGWyoNzpvmcEujws0rwGX7LnCnwWOgDLhlZsFuaJEP+zFCC7yw4DCAhFtWFhwHl/lwwpeowRMJzlpw+QpXEgwl2O3gxoH9Du4cOOzgmQNHLTjt4OXF4byDVw5csAsHhhLssM/EgD32qQw4aMER+zQtuDPgpAVn7DMz4IJ9FgYMJdgdwSsB9jgKAQ5acDyEJy24ng8nLTgfwu18uBzC/XwYSrA7hmfCeyCHWU6Hw18Gr4R3fY5zOpy04PwEngj/KfYw9WwYSrB7BjfCW4qH6a8Kh2fwTHgF9jDLyXDSgvMzeKW8UX6Uk2Eowe45PL0m7J/D9VQ4aMG/isH/JwYbbLDBBhtssMEGG2ywtz3K/2tvDmQAAAAABvlbn+NbCSQWi8VisVgsFovFYrFYLBYvAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHVJjR5bJLd8AAAAASUVORK5CYII=",
      residentialTelephone: "",
      workPhone: "",
      cellPhones: "",
      ProfessionalCard: "",
      mails: "",
      telephone: "",
      celPhone: "",
      options: [],
      departmentBirth: [],
      CityBirth: [],
      departmentResident: [],
      CityResident: [],
      redes: [],
      certificationId: "",
      certificationsResult: [],
      tableCertifications: "",
      value: [],
      valueTelephones: [],
      valueCelPhones: [],
      valueTelephonesJob: [],
      valueSpeciality: [],
      idUser: "",
      telephoneJob: "",
      edit: false,
      id: "",
      especialidades: [],
      UserName: "",
      academic: "",
      teaching: "",
      newCertifications: null,
      socials: null,
    };
  }

  saveChanges(value) {
    this.setState({ value });
    options = value;
  }

  onDeleteCertication(certification) {
    this.props
      .GetCertifications(getCertificationsUrl, { action: 0 })
      .then((resp) => {
        let certifications = this.state.certifications;
        let certificationRollBack = this.props.certifications.find(
          (x) => x.id === certification.id
        );
        certifications.push(certificationRollBack);
        this.setState({ certifications: certifications });
        let certificationsResult = this.state.certificationsResult;
        certificationsResult.map((currentCertification) => {
          if (currentCertification.id === certification.id) {
            var index = certificationsResult.indexOf(currentCertification);
            certificationsResult.splice(index, 1);
          }
        });
        this.setState({ certificationsResult: certificationsResult });
        this.buildTableCrtifications();
      });
  }

  fileChangedHandlerCertification(event) {
    if (this.state.certificationId === "")
      return toast.error("Seleccione el Certificado a cargar", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    var file = event.target.files[0];

    if (
      (file.size > 1100000 && file.type === "application/pdf") ||
      (file.size > 400000 && file.type !== "application/pdf")
    ) {
      return toast.error(
        "excede el tamaño máximo de 1.1MB en archivos pdf o de 400KB en imágenes",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }

    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      let certification = {
        id: this.state.certificationId,
        name: this.state.certificationName,
        binary: reader.result,
        validate: true,
        observations: "",
        edit: false,
      };

      let certificationsResult = this.state.certificationsResult;
      certificationsResult.push(certification);
      this.setState({ certificationsResult: certificationsResult });
      let certificationState = this.state.certifications;
      certificationState.map((certification) => {
        if (certification.id === this.state.certificationId) {
          var index = certificationState.indexOf(certification);
          certificationState.splice(index, 1);
          this.setState({ [certification.name_state]: reader.result });
        }
        this.setState({ certifications: certificationState });
      });
      this.buildTableCrtifications();
    }.bind(this);
  }

  buildTableCrtifications() {
    let table = "";
    if (this.state.certificationsResult.length > 0)
      table = (
        <Table dark>
          <thead>
            <tr>
              <th>Certificado</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.certificationsResult.map((result) => (
              <tr key={result.id}>
                <td>{result.name}</td>
                <td style={{ cursor: "pointer" }}>
                  {" "}
                  {result.validate ? (
                    <i className="fa fa-check" style={{ color: "#4dbd74" }} />
                  ) : (
                    <i
                      className="fa fa-close"
                      title={result.observations}
                      style={{ color: "#f86c6b" }}
                    />
                  )}{" "}
                </td>
                <td>
                  <Button
                    size="sm"
                    disabled={result.validate && result.edit}
                    color="danger"
                    onClick={this.onDeleteCertication.bind(this, result)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    else table = "";

    this.setState({ tableCertifications: table });
    this.setState({ certificationName: "Seleccione Documento" });
    this.setState({ certificationColor: "primary" });
    this.setState({ certificationId: "" });
  }

  onSelectDepartmentResident(state) {
    if (state) {
      this.props.getCitiesReducer(state.id).then((resp) => {
        this.setState({ CityResident: resp });
      });
    }
  }

  onSelectCountryResident(country) {
    if (country) {
      this.props.getStatesReducer(country.id).then((resp) => {
        this.setState({ departmentResident: resp });
      });
    }
  }

  onSelectDepartment(state) {
    if (state) {
      this.props.getCitiesReducer(state.id).then((resp) => {
        this.setState({ CityBirth: resp });
      });
    }
  }

  onSelectCountry(country) {
    if (country) {
      this.props.getStatesReducer(country.id).then((resp) => {
        this.setState({ departmentBirth: resp });
      });
    }
  }

  registerTelephone() {
    if (this.state.telephone.length > 6) {
      var length = telephones.length;
      telephones.push({ value: length + 1, label: this.state.telephone });
      this.setState({ telephone: "" });
      this.setState({ valueTelephones: telephones });
    } else {
      toast.error("Teléfono inválido", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  registerCelPhone() {
    if (this.state.celPhone.length > 6) {
      var length = celPhones.length;
      celPhones.push({ value: length + 1, label: this.state.celPhone });
      this.setState({ celPhone: "" });
      this.setState({ valueCelPhones: celPhones });
    } else {
      Swal.fire({
        title: "Error",
        text: "Teléfono inválido",
        icon: `error`,
        // timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  registerTelephoneJob() {
    if (this.state.telephoneJob.length > 6) {
      var length = telephonesJob.length;
      telephonesJob.push({ value: length + 1, label: this.state.telephoneJob });
      this.setState({ telephoneJob: "" });
      this.setState({ valueTelephonesJob: telephonesJob });
    } else {
      Swal.fire({
        title: "Error",
        text: "Teléfono inválido",
        icon: `error`,
        // timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  fileChangedHandlerImgSign = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    this.setState({ loadImage: true });
    reader.onloadend = function (e) {
      this.setState({
        signSrc: reader.result,
      });
      this.setState({ loadImage: false });
    }.bind(this);
  };

  fileChangedHandlerImgCompany = (event) => {
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    this.setState({ loadImage: true });
    reader.onloadend = function (e) {
      this.setState({
        imgSrc: reader.result,
      });
      this.setState({ loadImage: false });
    }.bind(this);
  };

  onSelectDocumentType(documentType) {
    this.setState({ idDocumentType: documentType.id });
    this.setState({ documentTypeName: documentType.name });
    this.setState({ documentTypeColor: "success" });
  }

  buildImageUrl(row) {
    const file = row;
    if (!file) {
      Swal.fire({
        title: "Error",
        text: "Problemas cargando el archivo",
        icon: `error`,
        // timer: 3000,
        timerProgressBar: true,
      });
      return null;
    }

    const ext = "pdf";
    // const ext = row.extension_name;

    let mimeTypePrefix = "image";
    // if (ext === 'pdf') {
    mimeTypePrefix = "application";
    // }

    const base64File = `data:${mimeTypePrefix}/${ext};base64,${file.binary}`;
    console.log(base64File);
    return base64File;
  }
  getFileType(file) {
    const mimeType = file.type;
    if (!mimeType) {
      return toast.error("Problemas obteniendo el tipo de archivo", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    let cleanExt = split(mimeType, "/");
    if (cleanExt.length > 0) {
      cleanExt = cleanExt[1];
    }

    return cleanExt;
  }

  onViewCertifications(row) {
    const imgUrl = this.buildImageUrl(row);
    if (!imgUrl) {
      return;
    }

    // if (row.extension_name === 'pdf') {
    const a = document.createElement("a");
    a.href = imgUrl;
    a.download = "file.pdf";
    a.click();
    // return;
    // }

    //  this.setState({ imageUrl: imgUrl });
  }

  componentWillMount() {
    this.setState({ sendUpdate: true });
    this.props.getCountries();
    this.props
      .GetCertifications(getCertificationsUrl, { action: 0 })
      .then((resp) => {
        this.setState({ certifications: this.props.certifications });
      });
    this.props.getEthnicGroups(getEthnicGroupsUrl, { action: 0 });
    this.props.getGender(getGenderUrl, { action: 0 });
    this.props.getDocumentType(DocumetTypes, { action: 0 });
  }

  editingDoctor() {
    return !queryString.parse(this.props.location.search).token;
  }

  componentDidMount() {
    this.getDoctorProfile();
  }

  resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        700,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          // const base64str = uri.split("base64,")[1];
          // const decoded = atob(base64str);
          // console.log("NEW SIZE", decoded.length);
          resolve(uri);
        },
        "base64"
      );
    });
  }

  async onChangeFileCertification(e, item) {
    // console.log(item);
    try {
      if (item.type === "pdf") {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          const ext = this.getFileType(e.target.files[0]);
          let file = {
            id: item.id,
            name: item.name,
            file: {
              binary: reader.result,
              ext,
            },
          };
          let files = this.state.filesDocuments;
          files.push(file);
          this.setState({ filesDocuments: files });
        };
        reader.onerror = () => {
          toast.error("Problemas cargando el archivo", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        };
      } else {
        const file = e.target.files[0];
        const image = await to(this.resizeFile(file));
        // console.log("Tamaño previo", file.size);

        ///
        const ext = this.getFileType(e.target.files[0]);
        let newFile = {
          id: item.id,
          name: item.name,
          file: {
            binary: image[1],
            ext,
          },
        };
        let files = this.state.filesDocuments;
        files.push(newFile);
        console.log(newFile);
        this.setState({ filesDocuments: files });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getDoctorProfile() {
    const values = queryString.parse(this.props.location.search);
    let doctor = null;
    const userInfo = this.props.userInfo;

    if (!values.token) {
      const [error, resp] = await to(this.props.getUserInfo(userInfoEndpoint));
      // set data to edit
      if (resp) {
        this.setState({ userInfoDoctor: resp.data });

        if (resp && resp.data) {
          doctor = {
            action: 6,
            id_user_doctor: resp.data.id,
          };
        }
        const [errordoc, respdoc] = await to(
          this.props.GetDoctorNew(getDoctorUrl, doctor)
        );

        // console.log("respdoc", respdoc);

        let document_type = this.props.documentTypes.filter(
          (option) => option.id === resp.data.document_type.id
        );

        resp.data.document_type = document_type[0];
        resp.data.professional_card = respdoc.data.professional_card;
        resp.data.gender = respdoc.data.gender;
        resp.data.birth_date = respdoc.data.birth_date;
        resp.data.birth_country = respdoc.data.birth_country;
        resp.data.birth_department = respdoc.data.birth_department;
        resp.data.birth_city = respdoc.data.birth_city;
        resp.data.resident_country = respdoc.data.resident_country;
        resp.data.resident_department = respdoc.data.resident_department;
        resp.data.resident_city = respdoc.data.resident_city;
        resp.data.web_site = respdoc.data.web_site;
        resp.data.review = respdoc.data.review;
        resp.data.academic = respdoc.data.academic_achievements;
        resp.data.teaching = respdoc.data.teaching;
        resp.data.social_network_id = respdoc.data.social_network;
        // resp.data.certifications = respdoc.data[0].certifications;
        resp.data.id = respdoc.data.id;
        resp.data.id_user_doctor = respdoc.data.id_user_doctor;
        resp.data.birthdate = moment(resp.data.birth_date).format("YYYY-MM-DD");
        resp.data.secretary_phone = respdoc.data.secretary_phone;
        resp.data.cel_phones =
          respdoc.data.cel_phones[0].label == 0
            ? null
            : respdoc.data.cel_phones[0].label; // [{'value' : resp.data.phone, 'label' : resp.data.phone, 'price' : 0}];
        this.setState({ initialValues: resp.data });
        this.setState({ socials: respdoc.data.social_network });
        // console.log("doctor document_type: ", document_type)
        let doctorApi = {
          id: resp.data.id_user_doctor,
        };
        this.props.getApiDoctor(doctorApi).then((resp2) => {
          resp.data.certifications = resp2.data.certifications;
          this.setState({ initialValues: resp2.data });
          this.props.getDoctorCertifications(doctorApi).then((resp) => {
            this.setState({ newCertifications: resp?.data });
          });
        });
      }
    } else if (userInfo.id) {
      doctor = {
        action: 6,
        id_user_doctor: userInfo.id,
      };
    } else {
      doctor = {
        user: {
          string_recovery_password: values.token,
          document_type: {
            id: "",
          },
          document: "",
        },
        action: 4,
      };
    }

    const [error, resp] = await to(
      this.props.GetDoctorNew(getDoctorUrl, doctor)
    );
    if (resp.data) {
      this.setState({ idUser: resp.data.id_user_doctor });
      this.setState({ UserName: resp.data.user.username });
      this.props
        .GetEspXMedicoBusqueda(getEspXMedicoBusquedaUrl, {
          medico_id: resp.data.id_user_doctor,
        })
        .then((resp) => {
          this.setState({ espxmedicbusqueda: resp.data });
        });

      /* this.setState({ id: resp.data[0].id });
    this.setState({ edit: resp.data[0].edit });
    this.setState({
      name: `${resp.data[0].user.name} ${resp.data[0].user.second_name} ${resp.data[0].user.last_name} ${resp.data[0].user.second_last_name}`,
    });
    this.setState({ UserName: resp.data[0].user.username });
    this.setState({ idUser: resp.data[0].id_user_doctor });
    this.setState({ email: resp.data[0].user.email });
    this.setState({ idDocumentType: resp.data[0].user.document_type.id });
    this.setState({ documentTypeName: resp.data[0].user.document_type.name });
    this.setState({ documentNumber: resp.data[0].user.document });
    this.setState({ ProfessionalCard: resp.data[0].professional_card });
    this.setState({ academic: resp.data[0].academic });
    this.setState({ teaching: resp.data[0].teaching }); */

      resp.data.resident_phones.map(({ value, label }) => {
        if (label !== "") telephones.push({ value: value, label: label });
      });
      this.setState({ valueTelephones: telephones });

      resp.data.cel_phones.map(({ value, label }) => {
        if (label !== "") celPhones.push({ value: value, label: label });
      });
      if (celPhones.length > 0) this.setState({ valueCelPhones: celPhones });

      resp.data.job_phones.map(({ value, label }) => {
        if (label !== "") telephonesJob.push({ value: value, label: label });
      });
      if (telephonesJob.length > 0)
        this.setState({ valueTelephonesJob: telephonesJob });

      resp.data.mails.map(({ value, label }) => {
        if (label !== "") options.push({ value: value, label: label });
      });
      if (options.length > 0) this.setState({ value: options });

      /* if (resp.data[0].gender.name !== "")
    this.onSelectDropDown2(resp.data[0].gender.id, resp.data[0].gender.name); */

      /* if (resp.data[0].ethnic_group.name !== "")
      this.onSelectDropDownRaza(
        resp.data[0].ethnic_group.id,
        resp.data[0].ethnic_group.name
      ); */

      if (new Date(resp.data.birth_date).getFullYear() !== 1900)
        this.setState({
          birthDate: new Date(resp.data.birth_date).toISOString().slice(0, 10),
        });

      if (resp.data.address !== "")
        this.setState({ address: resp.data.address });

      if (resp.data.birth_country.name !== "")
        this.onSelectCountry(resp.data.birth_country);

      if (resp.data.birth_department.name !== "")
        this.onSelectDepartment(resp.data.birth_department);

      /* if (resp.data[0].birth_city.name !== "")
      this.onSelectCity(
        resp.data[0].birth_city.id,
        resp.data[0].birth_city.name
      ); */

      if (resp.data.resident_country.name !== "")
        this.onSelectCountryResident(resp.data.resident_country);

      if (resp.data.resident_department.name !== "")
        this.onSelectDepartmentResident(resp.data.resident_department);

      /* if (resp.data[0].resident_city.name !== "")
      this.onSelectCityResident(
        resp.data[0].resident_city.id,
        resp.data[0].resident_city.name
      ); */

      this.setState({ webSite: resp.data.web_site });

      if (resp.data.simultaneous_appointments !== 0)
        this.setState({
          maxAppointment: resp.data.simultaneous_appointments,
        });

      this.setState({ resena: resp.data.review });

      if (resp.data.certifications) {
        resp.data.certifications.map(
          ({ id, name, binary, validate, observations, edit }) => {
            let certification = {
              id: id,
              name: name,
              binary: binary,
              validate: validate,
              observations: observations,
              edit: edit,
            };

            let certificationsResult = this.state.certificationsResult;
            certificationsResult.push(certification);
            this.setState({ certificationsResult: certificationsResult });
          }
        );
      }
      this.buildTableCrtifications();
      this.setState({ sendUpdate: false });
    }
  }

  onRegisterDoctor(data) {
    data[""] = {};
    let objectEsp = [];
    this.state.especialidades.map(({ especiality, sub_especiality }) => {
      let espTemp = {
        esp_Id: especiality.id,
        subesp_Id: sub_especiality.id,
      };
      objectEsp.push(espTemp);
    });
    data.specialities = objectEsp;
    data.action = 1;
    data.edit = true;
    // data.id_user_doctor =this.state.idUser;
    data.username = this.state.UserName;
    data.filesDocuments = this.state.filesDocuments;

    let documents = this.state.filesDocuments;
    // console.log('documents',documents)
    let certificationsReq = this.filterCertifications().filter(
      (cert) => cert.it_is_mandatory
    );
    let idsDocuments = [];
    documents.map((document) => {
      idsDocuments.push(document.id.toString().toLowerCase());
    });

    let pendUploadCert = certificationsReq.filter(
      (pend) => !idsDocuments.includes(pend.id)
    );

    let uploadDocumentsProvius = this.state.initialValues.certifications;
    let idsUploadDocuments = [];
    if (uploadDocumentsProvius) {
      uploadDocumentsProvius.map((uploads) => {
        if (uploads.path) {
          const loverCase = uploads.certification_id.toString().toLowerCase();
          idsUploadDocuments.push(loverCase);
        }
      });
    }

    const pendUploadCert2 = pendUploadCert.filter(
      (pend) => !idsUploadDocuments.includes(pend.id)
    );

    if (pendUploadCert2.length > 0) {
      Swal.fire({
        title: "Documentos requeridos",
        text: "los documentos marcados con * son obligatorios",
        icon: `error`,
        // timer: 3000,
        timerProgressBar: true,
      });
      return;
    }

    data.edit = true;
    data.cel_phones = [
      { value: data.cel_phones, label: data.cel_phones, price: 0 },
    ];
    data.id_user_doctor = this.state.initialValues.id_user_doctor;
    data.id = this.state.initialValues.id;

    this.setState({ sendUpdate: true });
    // console.log('data',data);
    // return;
    this.props
      .updateDoctor(data)
      .then((resp) => {
        this.setState({ sendUpdate: false });
        if (resp.code === 0) {
          let doctorApi = {
            id: data.id,
          };

          this.props.getDoctorCertifications(doctorApi).then((resp) => {
            this.setState({ newCertifications: resp?.data });
          });

          Swal.fire({
            title: `¡Has realizado con éxito la carga de tus documentos!`,
            html: `Nuestros asesores verificarán que tú información es correcta para continuar con <strong>DoctorHoy</strong>`,
            icon: `success`,
            // timer: 3000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
          // this.context.router.history.push("/Doctor/EditDoctor");
          // if (this.editingDoctor()) {

          // } else {
          //   this.context.router.history.push("/Doctor/RegisterComplete");
          // }
          return;
        }
        if (resp.code === 1) {
          let doctorApi = {
            id: data.id,
          };

          this.props.getDoctorCertifications(doctorApi).then((resp) => {
            this.setState({ newCertifications: resp?.data });
          });

          Swal.fire({
            title: "Error",
            html: `El siguiente archivo: <strong>${resp?.file?.name}</strong> <br /> excede el limite de carga permitido.`,
            icon: `error`,
            timerProgressBar: true,
          });

          return;
        } else {
          Swal.fire({
            title: "Error",
            text: resp.message,
            icon: `error`,
            // timer: 3000,
            timerProgressBar: true,
          });
          // this.pushError(resp.message);
        }
      })
      .catch((error) => {
        this.setState({ sendUpdate: false });
        Swal.fire({
          title: "Error",
          text: "Problemas de conexión, intentelo de nuevo mas tarde",
          icon: `error`,
          // timer: 3000,
          timerProgressBar: true,
        });
        // this.pushError("Problemas de conexión, intentelo de nuevo");
      });
  }

  pushError(error) {
    return toast.error(error, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  updateEspByDoctor(id, espId, subespId, doctor_id, action, especialidad) {
    if (especialidad.enable) {
      let obj = {
        id: id,
        action: action,
        id_doctor: doctor_id,
        especiality: { id: espId },
        sub_especiality: { id: subespId },
      };
      this.setState({ especialidades: this.state.especialidades.concat(obj) });
    } else {
      this.setState({
        especialidades: this.state.especialidades.filter((x) => x.id !== id),
      });
    }
  }

  onChangeSwitch(row) {
    var found = this.props.espxmedicbusqueda.find((x) => x.id === row.id);
    found.enable = !found.enable;

    this.updateEspByDoctor(
      row.id,
      row.esp_Id,
      row.subesp_Id,
      row.medico_id,
      !found.enable ? 1 : 2,
      found
    );
  }

  cellSwitch(cell, row, enumObject, rowIndex) {
    return (
      <AppSwitch
        style={{ marginTop: 30 }}
        className="mx-1"
        color="primary"
        outline="alt"
        label
        dataOn={"\u2713"}
        dataOff={"\u2715"}
        name="estado"
        checked={row.enable}
        onChange={this.onChangeSwitch.bind(this, row)}
      />
    );
  }

  formatRegisterType(cell, row, enumObject, rowIndex) {
    if (row.is_speciallity) return <font color="#f86c6b">Especialidad</font>;
    else return <font color="#20a8d8">Subespecialidad</font>;
  }

  filterCertifications() {
    const pendingCertificates = [];

    each(this.state.certifications, (cert) => {
      let shouldPushIt = true;
      each(this.state.certificationsResult, (addedCert) => {
        if (cert.id === addedCert.id) {
          shouldPushIt = false;
          return false;
        }
      });

      if (shouldPushIt) {
        pendingCertificates.push(cert);
      }
    });
    // console.log('pendingCertificates', pendingCertificates);
    return pendingCertificates;
  }

  handlerShowCertification(certification) {
    this.setState({ sendUpdate: true });
    this.props
      .getDoctorCertification(this.state.initialValues.id, certification)
      .then((resp) => {
        // this.setState({newCertifications: resp?.data?.certifications});
        this.setState({ sendUpdate: false });
        const linkSource = `data:${resp.data.contentType};base64,${resp.data.binary}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "file";
        downloadLink.click();
      });
  }
  render() {
    const { initialValues } = this.state;
    //  console.log('this.state',this.state);
    if (!initialValues) return <div>Loading</div>;

    return (
      <>
        {this.state.sendUpdate && <PreloaderDocHoy />}
        <div className="container-fluid container mt-5">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <FormUpdateRegister
                initialValues={initialValues}
                pendingCertificates={this.filterCertifications()}
                formatRegisterType={this.formatRegisterType}
                espxmedicbusqueda={this.props.espxmedicbusqueda}
                options={this.options}
                cellSwitch={this.cellSwitch}
                submitForm={this.onRegisterDoctor}
                loading={this.state.loading}
                gender={this.props.gender}
                ethnicGroups={this.props.ethnicGroups}
                countries={this.props.countries}
                departmentBirth={this.state.departmentBirth}
                cityBirth={this.state.CityBirth}
                departmentResident={this.state.departmentResident}
                cityResident={this.state.CityResident}
                onSelectCountry={this.onSelectCountry}
                onSelectDepartment={this.onSelectDepartment}
                onSelectCity={this.onSelectCity}
                onSelectDepartmentResident={this.onSelectDepartmentResident}
                onSelectCountryResident={this.onSelectCountryResident}
                documentTypes={this.props.documentTypes}
                onViewCertifications={this.onViewCertifications}
                onChangeFileCertification={this.onChangeFileCertification}
                userInfo={this.state.userInfoDoctor}
                newCertifications={this.state.newCertifications}
                showCertification={this.handlerShowCertification}
                socials={this.state.socials}
              />
            </div>
          </div>
          <ToastContainer />
          {!this.state.imageUrl ? (
            <div></div>
          ) : (
            <div>
              <div
                className={"modalImage"}
                onClick={() => {
                  this.setState({ imageUrl: null });
                }}
              >
                hola mundo
                {/* <img className={'imageModal'} src={this.state.imageUrl} /> */}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

RegisterDoctor.propTypes = {
  GetCertifications: PropTypes.func,
  getCities: PropTypes.func,
  getDepartments: PropTypes.func,
  getDocumentType: PropTypes.func,
  getCountries: PropTypes.func,
  getSocialNetworks: PropTypes.func,
  getEthnicGroups: PropTypes.func,
  getSexualOrientation: PropTypes.func,
  getGender: PropTypes.func,
  GetDoctor: PropTypes.func,
  GetDoctorNew: PropTypes.func,
  updateDoctor: PropTypes.func,
  GetEspXMedicoBusqueda: PropTypes.func,
  getUserInfo: PropTypes.func,
  certifications: PropTypes.array,
  socialNetworks: PropTypes.array,
  location: PropTypes.object,
  espxmedicbusqueda: PropTypes.array,
  documentTypes: PropTypes.array,
  ethnicGroups: PropTypes.array,
  sexualOrientation: PropTypes.array,
  countries: PropTypes.array,
  gender: PropTypes.array,
  doctors: PropTypes.object,
  userInfo: PropTypes.object,
  getStatesReducer: PropTypes.func,
  getCitiesReducer: PropTypes.func,
};

RegisterDoctor.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  documentTypes: state.generic.documentTypes,
  userInfo: state.user.userInfo,
  response: state.user.response,
  setPassword: state.user.setPassword,
  countries: state.generic.countries,
  departments: state.generic.departments,
  cities: state.generic.cities,
  certifications: state.doctor.certifications,
  socialNetworks: state.generic.socialNetworks,
  ethnicGroups: state.generic.ethnicGroups,
  sexualOrientation: state.generic.sexualOrientation,
  gender: state.generic.gender,
  doctors: state.doctor.doctors,
  specialityByDoctor: state.doctor.specialityByDoctor,
  espxmedicbusqueda: state.doctor.espxmedicobusqueda,
});

export default connect(mapStateToProps, {
  getUsersRecoveryPassword,
  updatePassword,
  getDocumentType,
  getCountries,
  getDepartments,
  getCities,
  getCitiesReducer,
  GetCertifications,
  getSocialNetworks,
  getEthnicGroups,
  getSexualOrientation,
  getGender,
  GetDoctor,
  GetDoctorNew,
  updateDoctor,
  getDoctorCertifications,
  getApiDoctor,
  getDoctorCertification,
  GetSpecialityByDoctor,
  GetEspXMedicoBusqueda,
  getUserInfo,
  getStatesReducer,
})(RegisterDoctor);
