import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import { Button } from "reactstrap";
import './tableStyle.css';

moment.locale("es");
let index = 0;
let opt0 = "";
let opt2 = "";
let opt1 = "";

class TableResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.cellDoctor = this.cellDoctor.bind(this);
    this.redireccion = this.redireccion.bind(this);
    this.ingresarConsultaACarro = this.ingresarConsultaACarro.bind(this);
    this.formatNombre = this.formatNombre.bind(this);

    this.options = {
      sortIndicator: false,
      hideSizePerPage: true,
      paginationSize: 1,
      hidePageListOnlyOnePage: true,
      /*  clearSearch: true, */
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      noDataText: 'No se encontraron resultados, puede modificar su busqueda o programar una cita.'
    };
  }

  redireccion() {
    //redireccion a el carro de compras
    this.context.router.history.push("/Summary");
  }

  ingresarConsultaACarro(obj, tipo, /*consulta*/) {
    //   console.log(obj);
    //   console.log(tipo);
    //   switch (tipo) {
    //       case 0:
    //           console.log('Consultorio');
    //           break;
    //       case 1:
    //           console.log('video');
    //           break;
    //       case 2:
    //           console.log('en casa');
    //           break;

    //   }

    this.props.OnAgendar(obj, tipo);
    //  this.context.router.history.push("/Summary");
  }

  formatNombre(/*n1, n2, a1, a2*/) {
  }

  getBoxes(scheduler, opt) {
    scheduler.forEach(element => {
      var exist = element.config_calendar.consulting_type.find(x => x.value === opt);
      if (exist !== undefined) {
        moment.locale("es");
        //  console.log(element);
        switch (opt) {
          case "0":
            opt0 = (
              <ul>
                <li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).format('YYYY-MM-DD')}</span></li>
                <li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm a')}</span></li>
                <li><span className="infodate"><i className="icon_currency"></i> {exist.price}</span></li>
                <li><Button color="primary" size="sm" onClick={this.ingresarConsultaACarro.bind(this, element, 0)}><i className="fa fa-shopping-bag" />{' '}Agendar</Button></li>
              </ul>
            );
            break;
          case "1":
            opt1 = (
              <ul>
                <li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).format('YYYY-MM-DD')}</span></li>
                <li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm a')}</span></li>
                <li><span className="infodate"><i className="icon_currency"></i> {exist.price}</span></li>
                <li><Button color="primary" size="sm" onClick={this.ingresarConsultaACarro.bind(this, element, 1)}><i className="fa fa-shopping-bag" />{' '}Agendar</Button></li>
              </ul>
            );
            break;
          case "2":
            opt2 = (
              <ul>
                <li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).format('YYYY-MM-DD')}</span></li>
                <li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm a')}</span></li>
                <li><span className="infodate"><i className="icon_currency"></i> {exist.price}</span></li>
                <li><Button color="primary" size="sm" onClick={this.ingresarConsultaACarro.bind(this, element, 2)}><i className="fa fa-shopping-bag" />{' '}Agendar</Button></li>
              </ul>
            );
            break;
          default:
            break;
        }
      }
    });
  }

  cellDoctor(cell, row, /*enumObject, rowIndex*/) {
    //  console.log(row);
    opt0 = "";
    opt2 = "";
    opt1 = "";

    return (
      <section key={index++} className="strip_list-2 fadeIn">
        <div className="row">
          <div className="col-md-4 colsmark ">
            <table>
              <tbody>
                <tr style={{ border: 0 }}>
                  <td><img className="img-fluid" src={"data:image/png;base64," + row.doctor.user.binary_photo} alt="" /></td>
                  <td className="padding-celda"><small>{row.doctor.speciality_by_doctor[0].especiality != null ? row.doctor.speciality_by_doctor[0].especiality.especiality : 'General'}</small><br />
                    <span className="rating">
                      <i className="icon_star voted"></i>
                      <i className="icon_star voted"></i>
                      <i className="icon_star voted"></i>
                      <i className="icon_star"></i>
                      <i className="icon_star"></i>
                      <small>(145)</small></span>
                    <br /><a className="masopciones" href={"/#/DoctorPublicProfile?id=" + row.doctor.id_user_doctor}  >Más opciones</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-8">
            <div className="row cajonmedicos">
              <div className="col-md-4 col-xs-4">
                <h3>CONSULTORIO</h3>
                {this.getBoxes(row.scheduler, "0")}
                {opt0}
              </div>
              <div className="col-md-4 col-xs-4 cajonmedicos">
                <h3>EN CASA</h3>
                {this.getBoxes(row.scheduler, "2")}
                {opt2}
              </div>
              <div className="col-md-4 col-xs-4 cajonmedicos">
                <h3>VIDEOCONSULTA</h3>
                {this.getBoxes(row.scheduler, "1")}
                {opt1}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 cajondetitulos">
            <h3>{row.doctor.user.name} {row.doctor.user.second_name} {row.doctor.user.last_name} {row.doctor.user.second_last_name} </h3>
          </div>
        </div>
      </section>);
  }


  render() {
    return (
      <div>
        <BootstrapTable
          data={this.props.data}
          version="4"
          responsive
          pagination
          options={this.options}
          bordered={false}
          headerStyle={{ border: 10 }}
        >
          <TableHeaderColumn dataField="index" isKey hidden />
          <TableHeaderColumn dataFormat={this.cellDoctor.bind(this)} ></TableHeaderColumn>
        </BootstrapTable>
      </div >
    );
  }
}

TableResult.propTypes = {
  data: PropTypes.array,
  OnAgendar: PropTypes.func,
};

TableResult.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = () => ({

});

export default connect(
  mapStateToProps,
  {

  }
)(TableResult);
