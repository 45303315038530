import styled, { css } from "styled-components";

export const LoginFormContainer = styled.div`
  display: flex;
  form {
    width: 100%;
    color: #000;
  }

  @media (max-width: 600px) {
    display: flex;
  }

  .sign-in-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 0px 7px 0px;
    button {
      border: none;
      background-color: #e07c2c;
      border-radius: 10px;
      width: 90%;
      height: 30px;
      color: #fff;
    }
  }

  .forgot-password-text-container {
    display: flex;
    color: orange;
    justify-content: center;
    span {
      margin-top: 10px;
      font-size: 12px;
      font-weight: lighter;
      cursor: pointer;
    }
  }

  .text-medical-login-1 {
    margin-top: 10px;
    font-size: 11.5px;
    text-align: center;
    a {
      color: #4481B8;
      cursor: pointer;
      margin-left: 5px;
      &:hover {
        color: #4481B8;
        text-decoration: underline;
      }
    }
  }
`;
