"use client";

import React from "react";
import useSocket from "../../hooks/useSocket";
import { urlSocketApi } from "../../enviroment";

const ConnectToServer = () => {
  const statusConnection = useSocket(urlSocketApi);

  return (
    <div style={{ visibility: "hidden" }}>
      Status Connection: {statusConnection}
    </div>
  );
};

export default ConnectToServer;
