/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { IoPerson, IoCard } from "react-icons/io5";
import { numberFormatMoney } from "../../../Utils";
import moment from "moment";

/**
 * @author Pedro Camilo Males
 * @file index.js
 * @description lista las ofertas de consultas hoy
 */

const attentionType = ["Consultorio", "Video Consulta", "Atención en casa"];

function Ofertas({
  data,
  imgMetodosPago,
  confirmAndPay,
  countOferts,
  newOfertEntry,
  cities,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const oferta = data;

  const specilityArray =
    oferta.specialities !== "" ? oferta.specialities.split(",") : [];

  return (
    <Col
      className={`pt-4 hover-ofert ${
        oferta.id == newOfertEntry ? "new-ofert" : ""
      }`}
    >
      <Row>
        <Col md={1} className="pr-0">
          <img
            className="rounded-circle"
            src={
              oferta?.photo
                ? `data:image/png;base64,${oferta?.photo}`
                : "/assets/img/avatars/doctor.png"
            }
            style={{ width: "100%" }}
          />
        </Col>
        <Col md={3}>
          <Row>
            <Col md={12} className="mt-3 mt-sm-0">
              <strong className="strong-blue">{oferta.medico_nombre}</strong>
            </Col>
            {oferta.specialities !== "" && (
              <Col md={12}>
                <span className="strong-blue" style={{ fontSize: "12px" }}>
                  Especialista
                </span>
              </Col>
            )}
            <Col md={12}>
              {specilityArray.length > 0 &&
                specilityArray.map((esp) => (
                  <span className="badge badge-primary mr-1">{esp}</span>
                ))}
              {/* <Row>
                <Col md={2} className="mb-3 mb-sm-0">
                  {specilityArray.length > 0 && (
                    specilityArray.map( (esp) => <span className="badge badge-primary mr-1">{esp}</span>)                    
                  )}                  
                </Col>
                
              </Row> */}
            </Col>
          </Row>
        </Col>
        <Col md={5} className="text-left ">
          <Row>
            <Col md={12} className="mt-2 mt-sm-0 mb-1 ">
              <Row>
                <Col md={1} className="text-center col-2"></Col>
                <Col md={11} className="pl-1 col-10">
                  <strong className="strong-blue">
                    {" "}
                    {attentionType[oferta.clase_atencion]}{" "}
                  </strong>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-2 mt-sm-0 ">
              <Row>
                <Col md={1} className="pl-0 text-center col-2">
                  <i
                    className="fa fa-usd span-warning "
                    aria-hidden="true"
                    style={{ fontSize: "x-large", paddingLeft: "2px" }}
                  ></i>
                </Col>
                <Col md={11} className="pl-1 col-10">
                  <strong className="strong-blue"> Valor: </strong>
                  <span className="span-warning">
                    ${numberFormatMoney(oferta.tarifa, 2)}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-2 mt-sm-0 ">
              <Row>
                <Col md={1} className="pl-0 text-center col-2">
                  <i
                    className="fa fa-calendar-o span-warning "
                    aria-hidden="true"
                    style={{ fontSize: "x-large" }}
                  ></i>
                </Col>
                <Col md={11} className="pl-1 col-10">
                  <strong className="strong-blue"> Fecha:</strong>
                  <span className="span-warning">
                    {" "}
                    {moment(oferta.fecha).format("dddd DD")} de{" "}
                    {moment(oferta.fecha).format("MMMM, YYYY")}{" "}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="mt-2 mt-sm-0 ">
              <Row>
                <Col md={1} className="pl-0 text-center col-2">
                  <i
                    className="fa fa-clock-o span-warning "
                    aria-hidden="true"
                    style={{ fontSize: "x-large" }}
                  ></i>
                </Col>
                <Col md={11} className="pl-1 col-10">
                  <strong className="strong-blue"> Hora:</strong>
                  <span className="span-warning">
                    {" "}
                    {moment(oferta.fecha).format("h:mm a")} -{" "}
                    {moment(oferta.fechafin).format("h:mm a")}
                  </span>
                </Col>
              </Row>
            </Col>
            {oferta.office_address && (
              <Col md={12} className="mt-2 mt-sm-0 mb-4 mb-sm-0 ">
                <Row>
                  <Col md={1} className="pl-0 text-center col-2">
                    <i
                      className="fa fa-map-marker span-warning "
                      aria-hidden="true"
                      style={{ fontSize: "x-large" }}
                    ></i>
                  </Col>
                  <Col md={11} className="pl-0 col-10">
                    <strong className="strong-blue"> Dirección:</strong>
                    <span style={{ color: "#21a8d8", fontWeight: "700" }}>
                      {oferta.office_address}
                    </span>
                    {cities &&
                      cities
                        .filter((city) => city.id == oferta.ciudad)
                        .map((filterCity) => (
                          <span style={{ color: "#21a8d8", fontWeight: "700" }}>
                            {" "}
                            - {filterCity.name}
                          </span>
                        ))}
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={3}>
          <Row>
            <Col md={12}>
              <Button
                className={
                  isOpen
                    ? "btn-warning mt-2 mt-sm-0 "
                    : "btn-primary-doc mt-2 mt-sm-0 "
                }
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                style={{ borderTopRightRadius: "10px" }}
              >
                <IoPerson
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />{" "}
                Ver perfil
              </Button>
            </Col>
            <Col md={12} className="mt-1">
              <Button
                className={"btn-warning mt-2 mt-sm-0"}
                onClick={() => confirmAndPay(oferta)}
                style={{ borderTopRightRadius: "10px" }}
              >
                <IoCard
                  style={{
                    width: "22px",
                    height: "22px",
                  }}
                />{" "}
                Agendar y Pagar
              </Button>
            </Col>
          </Row>
        </Col>
        {isOpen && (
          <Col md={11} className="mt-4">
            <Row>
              <Col md={12} className="mb-3">
                <h3 className="strong-blue font-weight-bold">
                  Perfil del{" "}
                  {specilityArray.length > 0 ? "especialista" : "médico"}:
                </h3>
              </Col>
              <Col md={12} className="strong-blue font-weight-light">
                {oferta && <p>{oferta.review}</p>}
                {/* {oferta && <p>{oferta.academic}</p>}
                {oferta && <p>{oferta.teaching}</p>} */}
              </Col>
              <Col md={12} className="mt-3">
                <span className="span-warning ">
                  Serás redirigido a nuestra plataforma de pagos. Si no realizas
                  el pago no queda agendada tu cita.
                </span>
              </Col>
              <Col md={12} className=" mb-2">
                <span className="span-warning ">
                  Después de hecho el pago, tu cita queda inmediatamente
                  agendada.
                </span>
              </Col>
              <Col md={12} className="mt-3">
                <FormGroup>
                  <Button
                    variant="warning"
                    color="warning"
                    className="mr-2"
                    onClick={() => confirmAndPay(oferta)}
                  >
                    Agendar y pagar
                  </Button>
                  {countOferts > 1 && (
                    <Button
                      variant="light"
                      color="light"
                      className="mr-2"
                      onClick={() => {
                        setIsOpen(!isOpen);
                      }}
                      // onClick={()=> alert('no action')}
                    >
                      {" "}
                      Ver otras opciones
                    </Button>
                  )}
                </FormGroup>
              </Col>
              <Col md={12} className="mt-3">
                <span className="grey" style={{ fontSize: "small" }}>
                  * Para tu comodidad se reciben pagos a traves de Payu
                </span>
              </Col>
              <Col md={12}>
                <img className="img-fluid" src={imgMetodosPago} width="800" />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <hr />
    </Col>
  );
}

export default Ofertas;
