import styled from "styled-components";

export const ContainerDoctorRegisterForm = styled.div`
  display: flex;
  background-color: #fff;
  padding: 30px 0px 0px 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  color: #555555;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .container-header-form {
    display: flex;
    flex-direction: row;
    padding: 0px 30px 0px 30px;
    justify-content: space-between;
    p {
      color: red;
    }
    @media (max-width: 780px) {
      display: flex;
      padding: 0px 10px 0px 10px;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        margin: 0px;
      }
    }
    @media (max-width: 980px) {
      font-size: 12px;
    }
  }
  .container-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      color: inherit;
    }
    img {
      width: 150px;
      margin-right: 20px;
      cursor: pointer;
    }
    @media (max-width: 780px) {
      margin-right: 0px;
      flex-direction: column;
    }
  }
  .footer-register-form {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    background-color: #1e63a4;
    margin-top: 30px;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
    span {
      margin-left: 40%;
      @media (max-width: 1480px) {
        margin-left: 10%;
      }
    }
    p {
      padding: 0px;
      margin: 0px 0px 0px 34%;
      @media (max-width: 1480px) {
        margin-left: 10%;
      }
    }
  }
`;

export const SubContainerRegisterForm = styled.div`
  display: flex;
  padding: 0px 30px 0px 30px;
  @media (max-width: 980px) {
    flex-direction: column;
  }
  @media (max-width: 780px) {
    display: flex;
    padding: 0px 30px 0px 30px;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    display: flex;
    padding: 0px 10px 0px 10px;
    flex-direction: column;
  }
  .container-terms-conditions {
    /* border: 1px solid red; */
    display: flex;
    margin-top: 320px;
    align-items: center;
    width: 30%;
    flex-direction: column;
    @media (max-width: 980px) {
      margin-top: 0px;
      align-items: center;
      width: 100%;
      flex-direction: column;
    }
  }
  .container-btn-register {
    margin-bottom: 40px;
    button {
      border-radius: 5px;
      border: none;
      background-color: #E07C2C;
      color: #fff;
      padding: 5px 100px 5px 85px;
      width: 90%;
      margin-left: 20px;
    }
  }
`;

export const ContainerInputsRegisterForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin-top: 20px;
  justify-content: space-around;
  @media (max-width: 780px) {
    width: 100%;
    justify-content: center;
  }

  .container-inputs {
    width: 40%;
    margin: 0px 0px 15px 0px;
    @media (max-width: 780px) {
      width: 100%;
    }
  }
`;
