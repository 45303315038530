import styled, { css } from "styled-components";

export const TableSummaryContainer = styled.div`
  overflow-x: auto;
  table {
    td {
      border: 1px solid #dddddd;
      text-align: center;
      padding: 8px;
    }
    th {
      border: 1px solid #dddddd;
      text-align: center;
      padding: 8px;
    }
  }
`;
