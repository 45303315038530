/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Recaptcha from "react-google-invisible-recaptcha";
import Select from "react-select";
// import { FaUserAlt, FaLock } from "react-icons/fa";
import { Button, Col, Row, FormGroup } from "reactstrap";
import { LoginFormContainer } from "./loginForm.style";

// Scheme
import schema from "./formConfig";

// Assets
import logo from "../../../assets/img/logo/logo.png";

// Config
import { tokenRecaptcha, siteHost } from "../../../enviroment";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file index.js
 * @description Login form component
 */

const renderError = (error) => <div className="error ">{error}</div>;

const userType = [
  // {
  //   id: "1",
  //   name: "Paciente",
  // },
  {
    id: "2",
    name: "Médico",
  },
  {
    id: "3",
    name: "Asistente Médico",
  },
  // {
  //   id: "4",
  //   name: "Administrativo",
  // },
];

function Login({ submitForm, status, openModal, onIraRegistro }) {
  const refRecaptcha = React.useRef(null);
  const [resolved, setResolved] = React.useState(false);
  const [eye, setEye] = React.useState("fa fa-eye-slash");
  const [typePassword, setTypePassword] = React.useState("password");
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    // refRecaptcha.current.callbacks.execute();
    // console.log("resolved: ", resolved);
    submitForm(formData);
  };

  return (
    <LoginFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className="text-muted">Documento de Identidad</label>
        <input
          name="username"
          className={`form-control ${errors.username && "is-invalid"}`}
          type="text"
          // placeholder="user@doctorhoy.com"
          autoComplete="username"
          ref={register}
        />
        {errors.username && renderError(errors.username.message)}

        <label className="text-muted">
          Contraseña
          <a
            className="cursor-pointer ml-2"
            onClick={() => {
              if (typePassword == "password") {
                setEye("fa fa-eye");
                setTypePassword("text");
              } else {
                setEye("fa fa-eye-slash");
                setTypePassword("password");
              }
            }}
          >
            <i className={eye} aria-hidden="true"></i>
          </a>
        </label>
        <div className="input-group" id="show_hide_password">
          <input
            name="password"
            className={`form-control ${errors.password && "is-invalid"}`}
            type={typePassword}
            placeholder="**********"
            autoComplete="current-password"
            ref={register}
          />
        </div>
        {errors.password && renderError(errors.password.message)}

        <label className="text-muted">{"Perfil"}</label>
        <Controller
          name="user_type"
          control={control}
          defaultValue=""
          render={({ onChange, value }) => {
            return (
              <Select
                options={userType}
                onChange={(val) => {
                  onChange(val?.id);
                }}
                styles={{
                  control: (provided) =>
                    errors.user_type
                      ? {
                          ...provided,
                          borderColor: "red",
                        }
                      : provided,
                }}
                placeholder="Seleccione una opción"
                value={
                  userType && userType.filter((option) => option.id === value)
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            );
          }}
        />
        {errors.user_type && renderError(errors.user_type.message)}

        <div className="sign-in-btn-container">
          <button type="submit" disabled={status}>
            {status ? (
              <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24" />
            ) : (
              "Ingrese como medico"
            )}
          </button>
        </div>
        <div className="forgot-password-text-container">
          <span onClick={openModal}>¿Olvidaste tu contraseña?</span>
        </div>
        <div className="text-medical-login-1">
          <span>¿No tienes cuenta DoctorHoy?</span>
          <a onClick={onIraRegistro}>Registrate</a>
        </div>
      </form>
    </LoginFormContainer>
  );
}

Login.propTypes = {
  submitForm: PropTypes.func,
  status: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  onIraRegistro: PropTypes.func,
};

export default Login;
