/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file index.js
 * @description Modal component
 */

function ModalComponent({ title, children, status, toggle }) {
  return (
    <Modal isOpen={status} toggle={toggle} className="modal-danger modal-lg">
      <ModalHeader cssModule={{ "modal-title": "w-100 text-center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div>{title}</div>
          <Button onClick={toggle} color="danger">
            X
          </Button>
        </div>
      </ModalHeader>
      <ModalBody
        style={{ maxHeight: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        {children}
      </ModalBody>
    </Modal>
  );
}

ModalComponent.propTypes = {
  toggle: PropTypes.func,
  children: PropTypes.func,
  title: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
};

export default ModalComponent;
