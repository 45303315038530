import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { recoverySession } from "../../redux/actions/authAction";
import { recoveryPassword } from "../../redux/actions/types";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class RefreshToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      password: "",
      username:
        localStorage.username !== undefined
          ? localStorage.username.replace(/['"]+/g, "")
          : "",
      sendRequest: false,
    };

    this.toggle = this.toggle.bind(this);
    this.restoreToken = this.restoreToken.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeSession = this.closeSession.bind(this);
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  closeSession() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location.reload();
  }

  restoreToken() {
    if (this.state.password === "")
      return toast.error("Ingresa el password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

    this.setState({ sendRequest: true });
    this.props.recoverySession(recoveryPassword, this.state).then((/*res*/) => {
      if (this.props.isAuthenticated) {
        this.toggle();
        window.location.reload();
      }
      if (this.props.messageError !== "") {
        this.toggle();
        this.setState({ sendRequest: false });
        return toast.error(this.props.messageError);
      }
    });
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillMount() {
    this.props.onRef(undefined);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  render() {
    const externalCloseBtn = (
      <button
        className="close"
        style={{ position: "absolute", top: "15px", right: "15px" }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );
    const containerStyle = {
      zIndex: 1999,
    };

    return (
      <div className="animated fadeIn">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={"modal-sm modal-primary " + this.props.className}
          external={externalCloseBtn}
          backdrop="static"
        >
          <ModalHeader>
            <i className="fa fa-plug " />
            {"  "}
            {`Fin de sesión`}
          </ModalHeader>
          <ModalBody>
            <strong>{`oops!!.`}</strong> <br />
            {`Al parecer a pasado mucho tiempo.`}
            <br />
            {`si deseas recuperar tu sesión ingresa tu contraseña.`}
            <hr />
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="icon-lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                placeholder="Password"
                autoComplete="current-password"
                name="password"
                onChange={this.onChange.bind(this)}
              />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            {!this.state.sendRequest ? (
              <div>
                <Button color="danger" onClick={this.closeSession}>
                  {`Cerrar Session`}
                </Button>
                {"  "}
                <Button color="primary" onClick={this.restoreToken}>
                  Restaurar
                </Button>
              </div>
            ) : (
                <div className="sk-wave">
                  <div className="sk-rect sk-rect1" />
                &nbsp;
                  <div className="sk-rect sk-rect2" />
                &nbsp;
                  <div className="sk-rect sk-rect3" />
                &nbsp;
                  <div className="sk-rect sk-rect4" />
                &nbsp;
                  <div className="sk-rect sk-rect5" />
                </div>
              )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RefreshToken.propTypes = {
  className: PropTypes.any,
  messageError: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  errorCode: PropTypes.object.isRequired,
  onRef: PropTypes.func,
  recoverySession: PropTypes.func,
};

RefreshToken.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  messageError: state.auth.messageError,
  isAuthenticated: state.auth.isAuthenticated,
  stateLoading: state.auth.stateLoading,
  errorCode: state.generic.errorCode,
});

export default connect(mapStateToProps, { recoverySession })(RefreshToken);
