/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import $ from "jquery";
import logoFoot from "../../assets/img/logo/logo.png";
import "react-sticky-header/styles.css";
import queryString from "query-string";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BarraSuperior from "../Generic/BarraSuperior";
import BigCalendar from "react-big-calendar";
import {
  getQueryIndex,
  getDetalleCarroComprasUrl,
} from "../../redux/actions/types";
import {
  GetQueryIndex,
  GetDetalleCarroCompras,
} from "../../redux/actions/DoctorAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const localizer = BigCalendar.momentLocalizer(moment);
require("moment/locale/es.js");

let messages = {
  allDay: "El Día",
  previous: "<",
  next: ">",
  today: "Hoy",
  month: "Mes",
  week: "Semana",
  day: "Día",
  agenda: "Agenda",
  date: "Fecha",
  time: "Hora",
  event: "Evento",
  showMore: (total) => `+ Mas (${total})`,
};

let views = ["month", "day"];

const minTime = new Date();
minTime.setHours(0, 0, 0);
const maxTime = new Date();
maxTime.setHours(23, 59, 0);

class DoctorPublicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
      sendRequest: false,
      medicoId: "",
      tituloEspecialidad: "",
      medicoNombre: "",
      medicoFoto: "",
      medicoDireccion: "",
      job_phones: "",
      review: "",
      especialidadesLst: [],
      horariosDisponibles: [],
      modalOpen: false,
      consultaSeleccionada: {},
      tipoConsulta0: false,
      tipoConsulta1: false,
      tipoConsulta2: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.toggle = this.toggle.bind(this);
    this.seleccionTipoConsulta = this.seleccionTipoConsulta.bind(this);
  }

  componentDidMount() {
    $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
    $("#preloader").delay(350).fadeOut("slow"); // will fade out the white DIV that covers the website.
    $("body").delay(350).css({
      overflow: "visible",
    });
    var $hero = $(".hero_home .content");
    var $hero_v = $("#hero_video .content ");
    $hero.find("h3, p, form").addClass("fadeInUp animated");
    $hero.find(".btn_1").addClass("fadeIn animated");
    $hero_v.find(".h3, p, form").addClass("fadeInUp animated");
    $(window).scroll();
  }

  toggle() {
    this.setState({ modalOpen: !this.state.modalOpen });
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.id == undefined) {
      this.context.router.history.push("/login");
      return;
    }

    this.setState({ medicoId: values.id });
    this.setState({ sendRequest: true });
    this.props
      .GetQueryIndex(getQueryIndex, values.id, "PublicProfile", 5, 123)
      .then((resp) => {
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          //this.props.logout();
          this.setState({ sendRequest: false });
          this.context.router.history.push("/login");
        }

        this.setState({ sendRequest: false });
        this.setState({ result: resp.data[0] });
        console.log(resp.data);
        var datos = resp.data[0];

        this.setState({
          tituloEspecialidad:
            datos.doctor.speciality_by_doctor[0].especiality.especiality,
        });
        this.setState({
          medicoNombre:
            datos.doctor.user.name +
            " " +
            datos.doctor.user.second_name +
            " " +
            datos.doctor.user.last_name +
            " " +
            datos.doctor.user.second_last_name,
        });
        this.setState({
          medicoFoto: "data:image/png;base64," + datos.doctor.user.binary_photo,
        });
        this.setState({ medicoDireccion: datos.doctor.address });
        this.setState({ job_phones: datos.doctor.job_phones[0].label });
        this.setState({ review: datos.doctor.review });

        const lstEspTemp = [];
        this.state.result.doctor.speciality_by_doctor.map((obj) =>
          lstEspTemp.push(obj.especiality.especiality)
        );

        this.setState({ especialidadesLst: lstEspTemp });

        //lleno el calendario
        console.log(datos.scheduler);
        let idTemp = 0;
        let lstEventosTemp = [];
        datos.scheduler.map((schedulerTemp) => {
          //	lstEventosTemp.push({ id: schedulerTemp.id, tiempo: schedulerTemp.config_calendar.time, libres: schedulerTemp.free_slot_calendar })
          var fechas = Object.keys(schedulerTemp.free_slot_calendar);

          //console.log(fechas);

          fechas.map((fecha) => {
            //	lstEventosTemp.push({ id: schedulerTemp.id, tiempo: schedulerTemp.config_calendar.time, libres: fs.free_slot_calendar })
            let fechaTemp = schedulerTemp.free_slot_calendar[fecha];

            fechaTemp.map((ft) => {
              //lstEventosTemp.push(fecha + '--' + ft);
              //"03/04/2020--19:15:00"
              //MM/dd/yyyy--hh:mm:ss
              let fechaInicialM = moment.utc(
                fecha + " " + ft,
                "MM/DD/YYYY HH:mm:ss"
              );
              let fechaInicial = new Date(
                fechaInicialM.get("year"),
                fechaInicialM.get("month"),
                fechaInicialM.get("date"),
                fechaInicialM.get("hour"),
                fechaInicialM.get("minute"),
                fechaInicialM.get("second")
              );

              let fechaFinalM = moment
                .utc(fecha + " " + ft, "MM/DD/YYYY HH:mm:ss")
                .add(15, "minutes");
              let fechaFinal = new Date(
                fechaFinalM.get("year"),
                fechaFinalM.get("month"),
                fechaFinalM.get("date"),
                fechaFinalM.get("hour"),
                fechaFinalM.get("minute"),
                fechaFinalM.get("second")
              );

              let titulo = "";
              let cantidad = 0;
              schedulerTemp.config_calendar.consulting_type.map((c) => {
                cantidad++;
                if (cantidad == 1) {
                  titulo = titulo.concat(c.label + " ");
                } else {
                  titulo = cantidad + " tipos de consultas";
                }
              });

              lstEventosTemp.push({
                shedulerId: schedulerTemp.id,
                id: idTemp,
                consulting_type: schedulerTemp.config_calendar.consulting_type,
                title: titulo,
                start: fechaInicial, //moment(fecha + ' ' + ft, 'MM/DD/YYYY HH:mm:ss').format(),
                end: fechaFinal, //moment(fecha + ' ' + ft, 'MM/DD/YYYY HH:mm:ss').add(15, 'minutes').format(),
              });

              idTemp++;
            });
          });

          //	schedulerTemp.free_slot_calendar.map(fs => {
          //		lstEventosTemp.push({ id: schedulerTemp.id, tiempo: schedulerTemp.config_calendar.time, libres: fs.free_slot_calendar })
          //	}
          //	)
        });
        console.log(lstEventosTemp);
        this.setState({ horariosDisponibles: lstEventosTemp });
      });
  }

  handleSelect = () => {
    // console.log('asdasdasdasdasd')
    alert("No esta disponible ese horario.");
  };

  onSelectEvent(obj) {
    this.setState({ tipoConsulta0: false });
    this.setState({ tipoConsulta1: false });
    this.setState({ tipoConsulta2: false });
    console.log(obj);
    //aqui un popup con el tipo de consulta disponible, para que seleccione

    this.setState({ consultaSeleccionada: obj });
    if (obj.consulting_type != undefined) {
      let tieneConsulta0 = obj.consulting_type.find((x) => x.value == "0");
      if (tieneConsulta0 != undefined) {
        this.setState({ tipoConsulta0: true });
      }
      let tieneConsulta1 = obj.consulting_type.find((x) => x.value == "1");
      if (tieneConsulta1 != undefined) {
        this.setState({ tipoConsulta1: true });
      }
      let tieneConsulta2 = obj.consulting_type.find((x) => x.value == "2");
      if (tieneConsulta2 != undefined) {
        this.setState({ tipoConsulta2: true });
      }

      this.toggle();
    } else {
      this.setState({ modalOpen: false });
      this.setState({ tipoConsulta0: false });
      this.setState({ tipoConsulta1: false });
      this.setState({ tipoConsulta2: false });
      this.setState({ consultaSeleccionada: {} });
    }
  }

  seleccionTipoConsulta(tipo) {
    let offset = this.state.consultaSeleccionada.start.getTimezoneOffset();
    var newDateObj = new Date(
      this.state.consultaSeleccionada.start.getTime() - offset * 60000
    );

    this.props
      .GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
        action_type: 1,
        id_calendario: this.state.consultaSeleccionada.shedulerId,
        fecha_inicio: newDateObj, //this.state.consultaSeleccionada.start,
        es_cup: false,
        tipo_cita: tipo,
      })
      .then((resp) => {
        this.setState({ sendRequest: false });
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          this.context.router.history.push("/login?redirect=1");
          return;
        }
        if (resp.code === 1008) {
          //		this.setState({ sendRequest: false });
          return toast.error(
            "Tiene una transacción en proceso de aprobación en la pasarela de pagos, una vez se reciba respuesta podrá adquirir más citas o productos.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          //	this.context.router.history.push("/login");
          //aqui digo que tiene un carro pendiente por terminar
        }
        //	this.setState({ sendRequest: false });
        this.context.router.history.push("/summary");
        this.setState({ consultaSeleccionada: {} });
        this.setState({ modalOpen: false });
        this.setState({ tipoConsulta0: false });
        this.setState({ tipoConsulta1: false });
        this.setState({ tipoConsulta2: false });
        //	this.setState({ result: resp.data });
      });
  }

  render() {
    return (
      <div>
        <div>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="description"
            content="Find easily a doctor and book online an appointment"
          />
          <meta name="author" content="Ansonika" />
          <title>DOCTORHOY - La salud en línea</title>

          <link
            rel="shortcut icon"
            href="img/favicon.ico"
            type="image/x-icon"
          />
          <link
            rel="apple-touch-icon"
            type="image/x-icon"
            href="img/apple-touch-icon-57x57-precomposed.png"
          />
          <link
            rel="apple-touch-icon"
            type="image/x-icon"
            sizes="72x72"
            href="img/apple-touch-icon-72x72-precomposed.png"
          />
          <link
            rel="apple-touch-icon"
            type="image/x-icon"
            sizes="114x114"
            href="img/apple-touch-icon-114x114-precomposed.png"
          />
          <link
            rel="apple-touch-icon"
            type="image/x-icon"
            sizes="144x144"
            href="img/apple-touch-icon-144x144-precomposed.png"
          />

          <div className="layer"></div>

          <div id="preloader">
            <div data-loader="circle-side"></div>
          </div>
          <BarraSuperior></BarraSuperior>
          {/*
          	
            <StickyHeader
          
          header={
            <header className="header_sticky">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-6">
                    <div id="logo_home">
                      <h1><a href="index.html" title="DoctorHoy">DoctorHoy</a></h1>
                    </div>
                  </div>
          
                  <nav className="col-lg-10 col-6">
                    <a className="cmn-toggle-switch cmn-toggle-switch__htx open_close" href="#0"><span>Menu mobile</span></a>
                    <ul id="top_access">
          
                      <li><a href="#"><img style={{ "marginTop": "-17px" }} src={logoDh1} alt="" /></a></li>
                      <li><a href="login.html"><i className="pe-7s-user"></i></a></li>
                      <li><a href="register-doctor.html"><i className="pe-7s-add-user"></i></a></li>
                    </ul>
          
                    <div className="main-menu">
                      <ul>
                        <li><a href="#0" className="">Inicio</a></li>
                        <li className="submenu">
                          <a href="list-2.html" className="show-submenu">Médicos</a>
                        </li>
                        <li className="submenu">
                          <a href="grid-list.html" className="show-submenu">Laboratorios</a>
                        </li>
                        <li className="submenu">
                          <a href="#0" className="show-submenu">Procedimientos</a>
                        </li>
          
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </header>
          }
          
          >
          
          </StickyHeader>
          */}

          <main>
            <div>
              <Modal isOpen={this.state.modalOpen} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>
                  Seleccionar tipo de consulta
                </ModalHeader>
                <ModalBody>
                  El horario tiene varios tipos de consulta, seleccione uno.
                  <br />
                  {this.state.tipoConsulta0 ? (
                    <Button
                      color="secondary"
                      onClick={this.seleccionTipoConsulta.bind(this, "0")}
                    >
                      Consultorio
                    </Button>
                  ) : null}{" "}
                  {this.state.tipoConsulta1 ? (
                    <Button
                      color="secondary"
                      onClick={this.seleccionTipoConsulta.bind(this, "1")}
                    >
                      VideoConsulta
                    </Button>
                  ) : null}{" "}
                  {this.state.tipoConsulta2 ? (
                    <Button
                      color="secondary"
                      onClick={this.seleccionTipoConsulta.bind(this, "2")}
                    >
                      Atención en casa
                    </Button>
                  ) : null}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggle}>
                    Cancelar
                  </Button>
                </ModalFooter>
              </Modal>
            </div>

            <div className="container margin_60">
              <div className="row">
                <div className="col-xl-7 col-lg-7">
                  <nav id="secondary_nav">
                    <div className="container">
                      <ul className="clearfix">
                        <li>
                          <i
                            className={
                              !this.state.sendRequest
                                ? ""
                                : "fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25"
                            }
                          />
                          <a href="#section_1" className="active">
                            Información General
                          </a>
                        </li>
                        <li>
                          <a href="#section_2">Comentarios</a>
                        </li>
                        <li>
                          <a href="#sidebar">Agendamiento</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div id="section_1">
                    <div className="box_general_3">
                      <div className="profile">
                        <div className="row">
                          <div className="col-lg-5 col-md-4">
                            <figure>
                              <img
                                className="img-fluid"
                                src={this.state.medicoFoto}
                                alt=""
                              />
                              {/*	
															<img src={imgDctorPrueba} alt="" className="img-fluid" />
															*/}
                            </figure>
                          </div>
                          <div className="col-lg-7 col-md-8">
                            <small>{this.state.tituloEspecialidad}</small>
                            <h1>{this.state.medicoNombre}</h1>
                            <span className="rating">
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star"></i>
                              <small>(145)</small>
                              <a
                                href="badges.html"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title="Badge Level"
                                className="badge_list_1"
                              >
                                <img
                                  src="img/badges/badge_1.svg"
                                  width="15"
                                  height="15"
                                  alt=""
                                />
                              </a>
                            </span>
                            <ul className="statistic">
                              <li style={{ marginRight: "10px" }}>
                                854 Vistas
                              </li>
                              <li>124 Pacientes atendidos</li>
                            </ul>
                            <ul className="contacts">
                              <li>
                                <h6>Dirección</h6>
                                {this.state.medicoDireccion}
                                <a
                                  href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x0:0xa6a9af76b1e2d899!2sAssistance+%E2%80%93+H%C3%B4pitaux+De+Paris!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361"
                                  target="_blank"
                                >
                                  {" "}
                                  <strong> Ver en el mapa</strong>
                                </a>
                              </li>
                              <li>
                                <h6>Teléfono</h6>
                                {/*
																	<a href="tel://3145856555">314 585 6555</a> - <a href="tel://3177408512">317 740 8512</a>

																	*/}
                                {this.state.job_phones}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="indent_title_in">
                        <i className="pe-7s-user"></i>
                        <h3>Perfil profesional</h3>
                        <p>Mussum ipsum cacilds, vidis litro abertis.</p>
                      </div>
                      <div className="wrapper_indent">
                        <p>{this.state.review}</p>
                        <h6>Especializaciones</h6>
                        <div className="row">
                          <div className="col-lg-6">
                            <ul className="bullets">
                              {this.state.especialidadesLst.map(
                                (obj, index) => (
                                  <li key={index}>{obj}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <hr />

                      <div className="indent_title_in">
                        <i className="pe-7s-news-paper"></i>
                        <h3>Educación</h3>
                        <p>Mussum ipsum cacilds, vidis litro abertis.</p>
                      </div>
                      <div className="wrapper_indent">
                        <p>
                          Phasellus hendrerit. Pellentesque aliquet nibh nec
                          urna. In nisi neque, aliquet vel, dapibus id, mattis
                          vel, nisi. Nullam mollis. Phasellus hendrerit.
                          Pellentesque aliquet nibh nec urna. In nisi neque,
                          aliquet vel, dapi.
                        </p>
                        <h6>Curriculum</h6>
                        <ul className="list_edu">
                          <li>
                            <strong>New York Medical College</strong> - Doctor
                            of Medicine
                          </li>
                          <li>
                            <strong>Montefiore Medical Center</strong> -
                            Residency in Internal Medicine
                          </li>
                          <li>
                            <strong>New York Medical College</strong> - Master
                            Internal Medicine
                          </li>
                        </ul>
                      </div>

                      <hr />

                      <div className="indent_title_in">
                        <i className="pe-7s-cash"></i>
                        <h3>Tarifas &amp; Pagos</h3>
                        <p>Mussum ipsum cacilds, vidis litro abertis.</p>
                      </div>
                      <div className="wrapper_indent">
                        <p>
                          Zril causae ancillae sit ea. Dicam veritus
                          mediocritatem sea ex, nec id agam eius. Te pri facete
                          latine salutandi, scripta mediocrem et sed, cum ne
                          mundi vulputate. Ne his sint graeco detraxit, posse
                          exerci volutpat has in.
                        </p>
                        <table className="table table-responsive table-striped">
                          <thead>
                            <tr>
                              <th>Service - Visit</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>New patient visit</td>
                              <td>$34</td>
                            </tr>
                            <tr>
                              <td>General consultation</td>
                              <td>$60</td>
                            </tr>
                            <tr>
                              <td>Back Pain</td>
                              <td>$40</td>
                            </tr>
                            <tr>
                              <td>Diabetes Consultation</td>
                              <td>$55</td>
                            </tr>
                            <tr>
                              <td>Eating disorder</td>
                              <td>$60</td>
                            </tr>
                            <tr>
                              <td>Foot Pain</td>
                              <td>$35</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div id="section_2">
                    <div className="box_general_3">
                      <div className="reviews-container">
                        <div className="row">
                          <div className="col-lg-3">
                            <div id="review_summary">
                              <strong>4.7</strong>
                              <div className="rating">
                                <i className="icon_star voted"></i>
                                <i className="icon_star voted"></i>
                                <i className="icon_star voted"></i>
                                <i className="icon_star voted"></i>
                                <i className="icon_star"></i>
                              </div>
                              <small>Based on 4 reviews</small>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="row">
                              <div className="col-lg-10 col-9">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width:
                                        "90% aria-valuenow=90 aria-valuemin=0 aria-valuemax=100",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-3">
                                <small>
                                  <strong>5 stars</strong>
                                </small>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-10 col-9">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width:
                                        "90% aria-valuenow=90 aria-valuemin=0 aria-valuemax=100",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-3">
                                <small>
                                  <strong>4 stars</strong>
                                </small>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-10 col-9">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width:
                                        "90% aria-valuenow=90 aria-valuemin=0 aria-valuemax=100",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-3">
                                <small>
                                  <strong>3 stars</strong>
                                </small>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-10 col-9">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width:
                                        "90% aria-valuenow=90 aria-valuemin=0 aria-valuemax=100",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-3">
                                <small>
                                  <strong>2 stars</strong>
                                </small>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-10 col-9">
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width:
                                        "90% aria-valuenow=90 aria-valuemin=0 aria-valuemax=100",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="col-lg-2 col-3">
                                <small>
                                  <strong>1 stars</strong>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="review-box clearfix">
                          <figure className="rev-thumb">
                            <img
                              src="http://via.placeholder.com/150x150.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="rev-content">
                            <div className="rating">
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star"></i>
                            </div>
                            <div className="rev-info">
                              Admin – April 03, 2016:
                            </div>
                            <div className="rev-text">
                              <p>
                                Sed eget turpis a pede tempor malesuada. Vivamus
                                quis mi at leo pulvinar hendrerit. Cum sociis
                                natoque penatibus et magnis dis
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="review-box clearfix">
                          <figure className="rev-thumb">
                            <img
                              src="http://via.placeholder.com/150x150.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="rev-content">
                            <div className="rating">
                              <i className="icon-star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star"></i>
                            </div>
                            <div className="rev-info">
                              Ahsan – April 01, 2016
                            </div>
                            <div className="rev-text">
                              <p>
                                Sed eget turpis a pede tempor malesuada. Vivamus
                                quis mi at leo pulvinar hendrerit. Cum sociis
                                natoque penatibus et magnis dis
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="review-box clearfix">
                          <figure className="rev-thumb">
                            <img
                              src="http://via.placeholder.com/150x150.jpg"
                              alt=""
                            />
                          </figure>
                          <div className="rev-content">
                            <div className="rating">
                              <i className="icon-star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star voted"></i>
                              <i className="icon_star"></i>
                            </div>
                            <div className="rev-info">
                              Sara – March 31, 2016
                            </div>
                            <div className="rev-text">
                              <p>
                                Sed eget turpis a pede tempor malesuada. Vivamus
                                quis mi at leo pulvinar hendrerit. Cum sociis
                                natoque penatibus et magnis dis
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <aside className="col-xl-5 col-lg-5" id="sidebar">
                  <div className="box_general_3 booking">
                    <form>
                      <div className="title">
                        <h3>Agenda una cita</h3>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {/*
													<div className="form-group">
														<input className="form-control" type="date" id="booking_date" data-min-year="2017" data-max-year="2020" />
													</div>
														*/}

                          <div>
                            <BigCalendar
                              selectable
                              defaultView="day"
                              events={this.state.horariosDisponibles}
                              views={views}
                              messages={messages}
                              localizer={localizer}
                              min={minTime}
                              max={maxTime}
                              defaultDate={new Date()}
                              step={15}
                              onSelectEvent={this.onSelectEvent}
                              onSelectSlot={this.handleSelect}
                              //	onEventDrop={this.moveEvent}
                              resizable
                              //	onEventResize={this.resizeEvent}
                              //	onDragStart={console.log}
                            />
                          </div>
                        </div>
                      </div>

                      <hr />
                      <a href="booking-page.html" className="btn_1 full-width">
                        Agendar
                      </a>
                    </form>
                  </div>
                </aside>
              </div>
            </div>
          </main>

          <footer>
            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <p>
                    <a href="index.html" title="Findoctor">
                      <img
                        src={logoFoot}
                        data-retina="true"
                        alt=""
                        width="163"
                        height="36"
                        className="img-fluid"
                      />
                    </a>
                  </p>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Menú principal</h5>
                  <ul className="links">
                    <li>
                      <a href="#0">Especialidad</a>
                    </li>
                    <li>
                      <a href="#0">Procedimientos</a>
                    </li>
                    <li>
                      <a href="blog.html">Programa tu cita</a>
                    </li>
                    <li>
                      <a href="login.html">Inicia Sesión</a>
                    </li>
                    <li>
                      <a href="register.html">Registrate</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Ciudades</h5>
                  <ul className="links">
                    <li>
                      <a href="#0">Cali</a>
                    </li>
                    <li>
                      <a href="#0">Bogotá</a>
                    </li>
                    <li>
                      <a href="#0">Medellín</a>
                    </li>
                    <li>
                      <a href="#0">Barranquilla</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Soporte</h5>
                  <ul className="contacts">
                    <li>
                      <a href="tel://61280932400">
                        <i className="icon_mobile"></i> + 61 23 8093 3400
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@findoctor.com">
                        <i className="icon_mail_alt"></i> info@doctorhoy.com
                      </a>
                    </li>
                  </ul>
                  <div className="follow_us">
                    <h5>Síguenos en:</h5>
                    <ul>
                      <li>
                        <a href="#0">
                          <i className="social_facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <i className="social_twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <i className="social_linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <i className="social_instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-md-8">
                  <ul id="additional_links">
                    <li>
                      <a href="#0">Terminos y condiciones</a>
                    </li>
                    <li>
                      <a href="#0">Protección de datos</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div id="copy">© 2020 DoctorHoy</div>
                </div>
              </div>
            </div>
          </footer>

          <div id="toTop" />
        </div>
      </div>
    );
  }
}

DoctorPublicProfile.propTypes = {};
DoctorPublicProfile.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  getQueryIndex: state.doctor.getQueryIndex,
});

export default connect(mapStateToProps, {
  GetQueryIndex,
  GetDetalleCarroCompras,
})(DoctorPublicProfile);
