import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container } from "reactstrap";
import logo from "../../assets/img/logo/logo.png";

class RegisterComplete extends Component {
  componentWillMount() {
    setTimeout(
      function () {
        //Start the timer
        this.context.router.history.push("/login");
        //window.location.reload();
      }.bind(this),
      5000
    );
  }

  render() {
    return (
      <div className="animated fadeIn">
        <Container style={{ marginTop: 80 }}>
          <Col md="10">
            <Card>
              <CardBody style={{ color: "#73818f" }}>
                <Col md="12" className="text-justify">
                  <h3>
                    Se ha completado tu registro, pronto nuestro equipo de
                    DoctorHoy.com se comunicará contigo para validar tu
                    información.
                  </h3>
                  <br />
                </Col>
                <Col md="12">
                  <img style={{ width: "20%" }} src={logo} />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    );
  }
}

RegisterComplete.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default RegisterComplete;
