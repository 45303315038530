/* eslint-disable react/prop-types */
import React from "react";
import { Button, Col, Row } from "reactstrap";
import moment from "moment";

function PerfilMenu({ userInfo, onRedirect, userInfoApi, today }) {

  return (
    <div>
      <Row
        onClick={() => {
          const profile = userInfo.profile.profile_name;
          if (profile == "Paciente") {
            onRedirect("/Patient/EditProfilePatient");
          }
          if (profile == "Medico") {
            onRedirect("/Doctor/EditDoctor");
          }
        }}              
      >
        <Col md={3} className="col-2 text-center text-sm-right mb-2 mb-sm-0">
          {userInfoApi && userInfoApi?.photo?.length > 50 ? (
            <>
              <img
                src={`data:image/png;base64, ${userInfoApi?.photo}`}
                width="65"
                className="circle"
                style={{ maxHeight: "65px" }}
              ></img>
            </>
          ) : (
            <>
              <i
                className="fa fa-user-circle-o"
                aria-hidden="true"
                style={{ fontSize: "50px", color: "#878888" }}
              ></i>
            </>
          )}
        </Col>
        <Col md={9} className="col-10 pl-sm-0">
          <Row>
            <Col md={12}>
              <span style={{ color: "#4082bc", fontWeight: "bold" }}>
                {userInfo?.name} {userInfo?.second_name} {userInfo?.last_name}{" "}
                {userInfo?.second_last_name}
              </span>
            </Col>
            <Col md={12}>
              <Button
                variant="warning"
                color="primary"
                size="sm"
                onClick={() => {
                  const profile = userInfo.profile.profile_name;
                  if (profile == "Paciente") {
                    onRedirect("/Patient/EditProfilePatient");
                  }
                  if (profile == "Medico") {
                    onRedirect("/Doctor/EditDoctor");
                  }
                }}
              >
                {userInfo.profile.profile_name == "Paciente"
                  ? "Editar mis datos"
                  : "Editar mis datos y documentos"}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="col-12 ml-3 mt-3">
          <div>
            <i
              className="fa fa-calendar-o text-primary"
              aria-hidden="true"
              style={{ fontSize: "larger" }}
            ></i>
            <span className="span-warning ml-1 ">
              {moment().format("MM/DD/YYYY")}
            </span>
          </div>
          <div>
            <i
              className="fa fa-clock-o text-primary"
              aria-hidden="true"
              style={{ fontSize: "larger" }}
            ></i>
            <span className="span-warning ml-1 ">{today}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PerfilMenu;
