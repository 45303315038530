/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { ToastContainer, toast } from "react-toastify";

import {
  getCountryUrl,
  validateTokenRecoveryPassword,
  DocumetTypes,
  getPatientsUrl,
  cities,
  departments,
  getEthnicGroupsUrl,
  getGenderUrl,
  getEscolaridadUrl,
  getOcupacionUrl,
  getParentescoUrl,
} from "../../redux/actions/types";

// Components
import Footer from '../../Components/Templates/Footer';
import FormUpdateRegister from '../../Components/Forms/PersonUpdateRegister';
import BarraSuperior from "../Generic/BarraSuperior";

// Redux
import { GetHealthEntities, GetAgreements } from "../../redux/actions/DoctorAction";
import { getUsersRecoveryPassword } from "../../redux/actions/userAction";

import {
  getCountries,
  getDocumentType,
  getCities,
  getDepartments,
  getRegimeTypes,
  getSocialNetworks,
  getEthnicGroups,
  getSexualOrientation,
  getGender,
  getPlan,
  getRango,
  getAfiliacion,
  getEscolaridad,
  getOcupacion,
  getOrigenContacto,
  getParentesco,
  getPatients
} from "../../redux/actions/GenericAction";

/**
 * @author Intelisoft
 * @file FinishPersonRegister.js
 * @description Update person data
 */

class FinishPersonRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      person: false,
      result: [],
      loadImage: false,
      imgSrc: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA8AAAAMcAQMAAACl5F6MAAAABlBMVEW8vsDn6OnyCdevAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEbUlEQVR4AezBMQEAAADCoPVP7WsIoAcAAAAAAAAAAAAAAAAAAAAAAIydO0iOk4eCAGyVXNEuukF0FB1NHE1H0RG0ZEHRf5A99WYMk1T+Kvolw+vVjBf+CkMLoRCFWQkuqCquB3QOOQKrCpwBKJ1i6Jxk/EzTubaArgAHQOeyjhu8aMEafUrYolLjLRMfLrpw5cMYadeBnTLcrwN7ZXi+DhyuCi8GG/xy8Gyw3SRe7n7cLzfnajah5z87keFJ//lYf0WAAq+vv+rDX+fir+zpr2Xqr95qrle/ywr9OxX+/nF19fGRmR/yrzCJCudRqNHiNDHhIlquTBgCF2aX3V2BwYT9nUYdNoNojnqjiKJ56q0xiRaok4EsWqQO2EW0RJ3wQbTMvDc6iFaYswEvkx5Hnf8E0Tx1xhcfj3gh1lhOLHVWnx8vLvBq/FAnYpGBhwEElVfjhyETjVjj5bFanATpLrHIcpDkIstpJRdZLmRykaW63CLLMZKLLGeVXGS5jvlFluZKq8k1lnGMXGMZuck1lnsVu8ZydybXWOYj5BrLDIxcY5lz8mrML/JupPIC02osf3tyjeVqY9dYikys8QlTAUIsFovFYrFYLJaIP8hq8D4GG2ywwQYbbLDBBhtssMEGG2ywwQYb3N9+G/ePwQYbbLDBBhtssMEGG5yhA3ugqcARmFXgDKwqcAGgAsu+J1zYy94UXDjIRxosH+crwUm2euHCWX43Fy7YciUYI9N1YIeRavDpsMdIM9jgs+BOh4PBavA3ras6aMFRC06TEpyrLsy/H5emBKOrwvxZpsOsA3ssOk8SQQ9edZ4WI6DzfJwU4Ym/FCE7ofFXfQoJlnUugRsHdl9GTKBzlxRF6vzVW9kngr5eLTB5hT6MUhPgLfdDRhwQCQ79TQXe756tAWcp10XgIuMYF8aWxoedLtz5sB/wzIeDLrzw4QiRqHBSgOvnwLWFCrc7eCLCbnwrXFh2ZMdHKhEOyx3ciHBch8OHE2TtCZ0IZ0wCz1S4jhGTDhc0ecN9IcLja/qEVwIswqwC+/H3zfgMDw7j95cbPBFg+YEKnIaGWyoNzpvmcEujws0rwGX7LnCnwWOgDLhlZsFuaJEP+zFCC7yw4DCAhFtWFhwHl/lwwpeowRMJzlpw+QpXEgwl2O3gxoH9Du4cOOzgmQNHLTjt4OXF4byDVw5csAsHhhLssM/EgD32qQw4aMER+zQtuDPgpAVn7DMz4IJ9FgYMJdgdwSsB9jgKAQ5acDyEJy24ng8nLTgfwu18uBzC/XwYSrA7hmfCeyCHWU6Hw18Gr4R3fY5zOpy04PwEngj/KfYw9WwYSrB7BjfCW4qH6a8Kh2fwTHgF9jDLyXDSgvMzeKW8UX6Uk2Eowe45PL0m7J/D9VQ4aMG/isH/JwYbbLDBBhtssMEGG2ywtz3K/2tvDmQAAAAABvlbn+NbCSQWi8VisVgsFovFYrFYLBYvAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAEHVJjR5bJLd8AAAAASUVORK5CYII=",
      Entidades: [],
      celPhone: "",
      state: false,
      dt: "",
      loading: false,
    };

    this.fileChangedHandlerImgCompany = this.fileChangedHandlerImgCompany.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectDepartment = this.onSelectDepartment.bind(this);
    this.onSelectCountryResident = this.onSelectCountryResident.bind(this);
    this.onSelectDepartmentResident = this.onSelectDepartmentResident.bind(this);
    this.onRegisterPerson = this.onRegisterPerson.bind(this);
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    (values.token === "") && this.context.router.history.push("/Dashboard");

    this.props.getUsersRecoveryPassword(validateTokenRecoveryPassword, values.token)
      .then(resp => {
        if (resp.code !== 0) {
          this.context.router.history.push("/Dashboard");
        }
        this.setState({ person: resp.data });
    });

    this.props.getDocumentType(DocumetTypes);
    this.props.getGender(getGenderUrl, { action: 0 });
    this.props.getEthnicGroups(getEthnicGroupsUrl, { action: 0 });
    this.props.getCountries(getCountryUrl, { action: 0 });
    this.props.getEscolaridad(getEscolaridadUrl, { action: 3 });
    this.props.getOcupacion(getOcupacionUrl, { action: 3 });
    this.props.getParentesco(getParentescoUrl, { action: 3 });
  }

  onSelectCountry(country) {
    if (country) {
      this.props.getDepartments(departments, { action: 1, id_country: country.id_country }).then(resp => {
        this.setState({ departmentBirth: resp.data });
      });
    }
  }

  onSelectDepartment(department) {
    if (department) {
      this.props.getCities(`${cities}/${department.id_department}`).then(resp => {
        this.setState({ cityBirth: resp.data });
      });
    }
  }

  onSelectCountryResident(country) {
    if (country) {
      this.props.getDepartments(departments, { action: 1, id_country: country.id_country }).then(resp => {
        this.setState({ departmentResident: resp.data });
      }); 
    }
  }

  onSelectDepartmentResident(department) {
    if (department) {
      this.props.getCities(cities + "/" + department.id_department).then(resp => {
        this.setState({ cityResident: resp.data });
      });
    }
  }

  fileChangedHandlerImgCompany(image) {
    this.setState({
      imgSrc: image
    });
  }

  onRegisterPerson(data) {
    data.photo = this.state.imgSrc;

    this.props.getPatients(getPatientsUrl, data).then(resp => {
      if (resp.code === 0) {
        this.context.router.history.push("/Dashboard");
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
  }

  render() {
    const { loading } = this.state;

    return (
      <div>
        <div>
          <BarraSuperior />
          <main>
            <div className="bg_color_2">
              
              <div className="container margin_60_35">
              <div id="register">
                 <h1>Completa tu registro con información adicional</h1>
                  <h1>Esto nos permitirá ofrecerte un servicio más acorde con tus necesidades</h1>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <FormUpdateRegister
                        submitForm={this.onRegisterPerson}
                        loading={loading}
                        fileChangedHandler={this.fileChangedHandlerImgCompany}
                        {...this}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
          <ToastContainer/>
        </div>
      </div>
    );
  }
}

FinishPersonRegister.propTypes = {
  location: PropTypes.object,
  userInfo: PropTypes.object,
  getUsersRecoveryPassword: PropTypes.func,
  getDocumentType: PropTypes.func,
  GetHealthEntities: PropTypes.func,
  getGender: PropTypes.func,
  getSexualOrientation: PropTypes.func,
  getEthnicGroups: PropTypes.func,
  getCountries: PropTypes.func,
  getPlan: PropTypes.func,
  getRango: PropTypes.func,
  getAfiliacion: PropTypes.func,
  getEscolaridad: PropTypes.func,
  getOcupacion: PropTypes.func,
  getOrigenContacto: PropTypes.func,
  getParentesco: PropTypes.func,
  getRegimeTypes: PropTypes.func,
  getSocialNetworks: PropTypes.func,
  getCities: PropTypes.func,
  getDepartments: PropTypes.func,
  GetAgreements: PropTypes.func,
  getPatients: PropTypes.func,
  socialNetworks: PropTypes.array,
  documentTypes: PropTypes.array,
  countries: PropTypes.array,
  gender: PropTypes.array,
  ethnicGroups: PropTypes.array,
  sexualOrientation: PropTypes.array,
  healthEntities: PropTypes.array,
  plan: PropTypes.array,
  rango: PropTypes.array,
  afiliacion: PropTypes.array,
  ocupacion: PropTypes.array,
  origenContacto: PropTypes.array,
  regimen: PropTypes.array,
  escolaridad: PropTypes.array,
  parentesco: PropTypes.array,
};

FinishPersonRegister.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  gender: state.generic.gender,
  sexualOrientation: state.generic.sexualOrientation,
  ethnicGroups: state.generic.ethnicGroups,
  countries: state.generic.countries,
  departments: state.generic.departments,
  cities: state.generic.cities,
  healthEntities: state.doctor.healthEntities,
  plan: state.generic.plans,
  rango: state.generic.rangos,
  afiliacion: state.generic.afiliaciones,
  escolaridad: state.generic.escolaridades,
  ocupacion: state.generic.ocupaciones,
  origenContacto: state.generic.origenContactos,
  regimen: state.generic.regimeTypes,
  parentesco: state.generic.parentescos,
  socialNetworks: state.generic.socialNetworks,
  userInfo: state.user.userInfo,
  documentTypes: state.generic.documentTypes,
});

export default connect(
  mapStateToProps,
  {
    getGender,
    getSexualOrientation,
    getEthnicGroups,
    getCountries,
    getDepartments,
    getCities,
    GetHealthEntities,
    GetAgreements,
    getPlan,
    getRango,
    getAfiliacion,
    getEscolaridad,
    getOcupacion,
    getOrigenContacto,
    getRegimeTypes,
    getParentesco,
    getSocialNetworks,
    getUsersRecoveryPassword,
    getDocumentType,
    getPatients
  }
)(FinishPersonRegister);
