import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { Button, FormGroup, Col, Card, CardBody } from "reactstrap";

// Redux
import { getRedireccionPorPagoUrl } from "../../redux/actions/types";
import { GetRedireccionXCitaInmediata } from "../../redux/actions/DoctorAction";

// Components
import Footer from "../../Components/Templates/Footer";
import Header from "../../Components/Templates/Header";

import { consApi } from "../../Components/MessageBirdApi/MessageBirdApi";
import RefreshToken from "../Generic/RefreshToken";

class ResponsePage extends Component {
  /*class Index extends Component {*/
  myRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      merchantId: "merchantId",
      merchant_name: "merchant_name",
      merchant_address: "merchant_address",
      telephone: "telephone",
      merchant_url: "merchant_url",
      transactionState: "transactionState",
      lapTransactionState: "lapTransactionState",
      message: "message",
      referenceCode: "referenceCode",
      transactionId: "transactionId",
      description: "description",
      lapPaymentMethod: "lapPaymentMethod",
      lapPaymentMethodType: "lapPaymentMethodType",
      TX_VALUE: "TX_VALUE",
      currency: "currency",
      buyerEmail: "buyerEmail",
      processingDate: "processingDate",
      urlRedireccion: "/",
      visibilidadBotonRedireccion: false,
    };
    this.onContinuar = this.onContinuar.bind(this);
  }

  componentDidMount() {
    let nameUser = localStorage.getItem("name").replace(/['"]+/g, "");
    let body = {
      username: nameUser,
      cellphone: localStorage.getItem("phone"),
      date: localStorage.getItem("date"),
      hour: localStorage.getItem("hour"),
    };

    consApi(body, "/sms");
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    //  merchantId:'',
    if (values.merchantId !== undefined) {
      this.setState({ merchantId: values.merchantId });
    }
    // merchant_name:'',
    if (values.merchant_name !== undefined) {
      this.setState({ merchant_name: values.merchant_name });
    }
    //  merchant_address:'',
    if (values.merchant_address !== undefined) {
      this.setState({ merchant_address: values.merchant_address });
    }
    //  telephone:'',
    if (values.telephone !== undefined) {
      this.setState({ telephone: values.telephone });
    }
    //   merchant_url:'',
    if (values.merchant_url !== undefined) {
      this.setState({ merchant_url: values.merchant_url });
    }
    // transactionState:'',
    if (values.transactionState !== undefined) {
      this.setState({ transactionState: values.transactionState });
    }
    //  lapTransactionState:'',
    if (values.lapTransactionState !== undefined) {
      this.setState({ lapTransactionState: values.lapTransactionState });
    }
    //  message:'',
    if (values.message !== undefined) {
      this.setState({ message: values.message });
    }
    //  referenceCode:'',
    if (values.referenceCode !== undefined) {
      this.setState({ referenceCode: values.referenceCode });
      this.setState({ visibilidadBotonRedireccion: true });
    } else {
      this.setState({ visibilidadBotonRedireccion: false });
      return;
    }
    //  transactionId:'',
    if (values.transactionId !== undefined) {
      this.setState({ transactionId: values.transactionId });
    }
    //  description:'',
    if (values.description !== undefined) {
      this.setState({ description: values.description });
    }
    // lapPaymentMethod:'',
    if (values.lapPaymentMethod !== undefined) {
      this.setState({ lapPaymentMethod: values.lapPaymentMethod });
    }
    //  lapPaymentMethodType:'',
    if (values.lapPaymentMethodType !== undefined) {
      this.setState({ lapPaymentMethodType: values.lapPaymentMethodType });
    }
    //  TX_VALUE:'',
    if (values.TX_VALUE !== undefined) {
      this.setState({ TX_VALUE: values.TX_VALUE });
    }
    //   currency:'',
    if (values.currency !== undefined) {
      this.setState({ currency: values.currency });
    }
    //  buyerEmail:'',
    if (values.buyerEmail !== undefined) {
      this.setState({ buyerEmail: values.buyerEmail });
    }
    //  processingDate:''
    if (values.processingDate !== undefined) {
      this.setState({ processingDate: values.processingDate });
    }

    //aqui se valida a donde debe ir el boton de redireccion del carro
    // values.referenceCode
    //referene_code

    this.props
      .GetRedireccionXCitaInmediata(getRedireccionPorPagoUrl, {
        referene_code: values.referenceCode,
      })
      .then((resp) => {
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          //this.props.logout();
          this.setState({ sendRequest: false });
          this.context.router.history.push("/login");
        }
        this.setState({ urlRedireccion: resp.data });
        console.log(resp.data);
      });
  }

  onContinuar() {
    // this.context.router.history.push("/Index");
    //this.context.router.history.push(this.state.urlRedireccion);
    this.context.router.history.push("/CitasPaciente/CitasPaciente");
  }

  render() {
    // console.log(this.state.searchOption);

    return (
      <div>
        <div>
          <Header
            props={this.props}
            nombreCiudadGps={this.state.nombreCiudadGps}
          />
          <main>
            <div className="content pt-5">
              <div className="container">
                <FormGroup row>
                  <Col md="12" lg="12" sm="12">
                    <Card style={{ margin: "10px" }}>
                      <CardBody className="p-5">
                        <table className="table table-striped">
                          <tr>
                            <td>Comercio</td>
                            <td>{this.state.merchant_name}</td>
                          </tr>
                          <tr>
                            <td>Dirección del comercio</td>
                            <td>{this.state.merchant_address}</td>
                          </tr>
                          <tr>
                            <td>Teléfono</td>
                            <td>{this.state.telephone}</td>
                          </tr>
                          {/*<tr>
                            <td>Merchant url</td>
                            <td>{this.state.merchant_url}</td>
                          </tr>
                          <tr>
                            <td>Transaction State</td>
                            <td>{this.state.transactionState}</td>
                          </tr>
                          <tr>
                            <td>LapTransactionState</td>
                            <td>{this.state.lapTransactionState}</td>
                          </tr>*/}
                          <tr>
                            <td>Estado de la transacción</td>
                            <td>{this.state.message}</td>
                          </tr>
                          <tr>
                            <td>Código de referencia</td>
                            <td>{this.state.referenceCode}</td>
                          </tr>

                          <tr>
                            <td>Descripción</td>
                            <td>{this.state.description}</td>
                          </tr>
                          <tr>
                            <td>Metodo de pago</td>
                            <td>{this.state.lapPaymentMethod}</td>
                          </tr>
                          <tr>
                            <td>Tipo de pago</td>
                            <td>{this.state.lapPaymentMethodType}</td>
                          </tr>
                          <tr>
                            <td>Valor de la transacción</td>
                            <td>
                              ${this.state.TX_VALUE} {this.state.currency}
                            </td>
                          </tr>
                          <tr>
                            <td>Correo electronico</td>
                            <td>{this.state.buyerEmail}</td>
                          </tr>
                          <tr>
                            <td>Fecha de procesamiento</td>
                            <td>{this.state.processingDate}</td>
                          </tr>
                        </table>
                        <Button
                          color="primary"
                          onClick={this.onContinuar}
                          block
                          visible={this.statevisibilidadBotonRedireccion}
                          className="text-center mt-5"
                          style={{ fontSize: "16px" }}
                        >
                          Continuar
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </FormGroup>
              </div>
            </div>
          </main>
          <Footer />
        </div>
        <RefreshToken onRef={(ref) => (this.RefreshToken = ref)} />
      </div>
    );
  }
}

ResponsePage.propTypes = {
  location: PropTypes.object,
  GetRedireccionXCitaInmediata: PropTypes.func,
};

ResponsePage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  GetRedireccionXCitaInmediata,
})(ResponsePage);
