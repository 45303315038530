import styled, { css } from "styled-components";

export const ContainerMenuTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 480px) {
    margin-top: 0px;
  }
  .sub-container-menu-top {
    align-items: center;
    justify-content: center;
    width: 75%;
    @media (max-width: 480px) {
      width: 95%;
    }
  }
  .sub-botones-menu {
    width: 100%;
  }
  .container-text-menu {
    padding: 0px;
    color: #fff;
    @media (max-width: 480px) {
      display: none;
    }
    h1 {
      color: #fff;
      margin: 40px 0px 0px 0px;
      padding: 0px;
      font-size: 42px;
    }
    h3 {
      color: #fff;
      margin: 0px;
      padding: 0px;
      font-size: 28px;
    }
  }
`;
