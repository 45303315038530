import { MANAGE_PRODUCTS, ERROR_API,MANAGE_CATEGORIES, GET_IMAGE } from "../actions/types";

const initialState = {
  products: [],
  categories: [],
  treeView: [],
  imageBase64: "",
  getResponse: false
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_API:
      return {
        ...state,
        products: [],
        errorCode: action.payload
      };
    case MANAGE_PRODUCTS:
      return {
        ...state,
        products: action.payload.data
      };
    case MANAGE_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data.categories,
        treeView: action.payload.data.treeview
      };
      case GET_IMAGE:
      return {
        ...state,
        imageBase64: action.payload.data
      };
    default:
      return state;
  }
}
