import * as yup from 'yup';

const schema = yup.object().shape({
    username: yup
      .string()
    .required('Nombre de usuario es requerido'),
    password: yup
      .string()
    .required('Contraseña es requerida'),
    user_type: yup.string().required('Seleccione un tipo de usuario'),
});

export default schema;