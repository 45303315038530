/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DateRangePicker } from "react-dates";
import { Col, Button, FormGroup, Label } from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

// schema
import schema from './formConfig';

// Utils
import { renderError } from '../../../../Utils';

// Assets
import 'react-dates/initialize';
import "react-dates/lib/css/_datepicker.css";

/**
 * @author Daniel Felipe Lucumi Marin
 * @file index.js
 * @description Solicitud cita
 */

function Form({ optionAttentionType, submitForm, speciality, loading, cities }) {
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState();

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = data => {
    
     submitForm(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        {/* <Col md="12">
          <FormGroup row>
            <Col md="12">
              <Label>Fechas para Agendar la cita <span className="text-danger">*</span></Label>
              <Controller
                name="range"
                control={control}
                render={({ onChange }) => {
                  return (
                    <DateRangePicker
                      startDate={startDate}
                      startDateId="startDate"
                      endDate={endDate}
                      endDateId="endDate"
                      focusedInput={focusedInput}
                      onDatesChange={({ startDate, endDate }) => {
                        startDate && setStartDate(startDate);
                        endDate && setEndDate(endDate);
                        onChange({ startDate: startDate || '', endDate: endDate || '' });
                      }}
                      onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                      small
                      block
                      disableScroll={false}
                      startDatePlaceholderText="Desde"
                      endDatePlaceholderText="Hasta"
                    />
                  );
                }}
              />
              {errors.range?.startDate && renderError(errors.range?.startDate?.message)}
              {errors.range?.endDate && renderError(errors.range?.endDate?.message)}
            </Col>
          </FormGroup>
        </Col> */}
        <Col md="12">
          <FormGroup>
            <Controller
              name="speciality"
              control={control}
              render={({ onChange }) => {
                return (
                  <Select
                    placeholder="Especialista o médico general"
                    onChange={e => onChange(e.id)}
                    options={speciality}
                    getOptionLabel={(option) => option.especiality}
                    getOptionValue={(option) => option.id}
                    isClearable
                    styles={{
                      control: (provided) => (errors.speciality ? {
                        ...provided, borderColor: 'red',
                      } : provided)
                    }}
                  />
                );
              }}
            />
            {errors.speciality && renderError(errors.speciality.message)}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label>Tipo Consulta <span className="text-danger">*</span></Label>
            <Controller
              name="clase_atencion"
              control={control}
              render={({ onChange }) => {
                return (
                  <Select
                    options={optionAttentionType}
                    onChange={e => onChange(e.value)}
                    styles={{
                      control: (provided) => (errors.speciality ? {
                        ...provided, borderColor: 'red',
                      } : provided)
                    }}
                  />
                );
              }}
            />
            {errors.speciality && renderError(errors.speciality.message)}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label>Ciudad <span className="text-danger">*</span></Label>
            <Controller
              name="ciudad"
              control={control}
              render={({ onChange, value }) => {
                return (
                  <Select
                    placeholder="Ciudad"
                    // value={value}
                    options={cities.map(opt => ({ label: opt.name, value: opt.id }))}
                    onChange={e => onChange(e.value)}
                    styles={{
                      control: (provided) => (errors.ciudad ? {
                        ...provided, borderColor: 'red',
                      } : provided)
                    }}
                  />
                );
              }}
            />
            {errors.ciudad && renderError(errors.ciudad.message)}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup row>
            <Col md='12'>
              <Label>Dinos el motivo de tu consulta</Label>
              <textarea className="form-control" name="observaciones" autoComplete="off" ref={register} />
            </Col>
          </FormGroup>
        </Col>
        <Col className="text-center" col="12">
          <Button type="submit" color="warning" className="btn-80">
            {loading ? <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24" /> : 'Enviar solicitud'} 
          </Button>
        </Col>
      </div>
    </form>
  );
}

Form.propTypes = {
  submitForm: PropTypes.func,
  loading: PropTypes.bool,
  optionAttentionType: PropTypes.string,
  speciality: PropTypes.array,
  cities: PropTypes.array,
};

export default Form;