import styled, { css } from "styled-components";
import img from "../../../assets/img/new-background-dh.jpg";

export const BigContainerLogin = styled.div`
  display: flex;
  background-image: url(${img});
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-size: 12px;
  padding: 200px;
  @media (max-width: 1280px) {
    height: inherit;
    padding: 20px;
    flex-direction: column;
  }

  //TEXTO BIENVENIDA
  .welcome-login-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    color: #fff;
    h1 {
      font-size: 35px;
      margin: 0;
      padding: 0;
      color: #fff;
    }
    h3 {
      color: #fff;
    }
    @media (max-width: 1280px) {
      width: 100%;
      margin-right: 0px;
      text-align: center;
    }
  }

  //LOGIN FORM CONTAINER
  .login-card-container {
    display: flex;
    width: 300px;
    flex-direction: column;
    margin-top: 20px;
  }

  .login-card-subcontainer {
    border-radius: 10px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding: 30px;
    height: 50%;
    margin-bottom: 5px;
  }

  .container-logo-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    img {
      width: 60%;
    }
  }

  .login-card-footer {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 50px;
    color: #000;
    font-size: 11.5px;
    a {
      margin-left: 5px;
      color: #2873b7;
      cursor: pointer;
      &:hover {
        color: #2873b7;
        text-decoration: underline;
      }
    }
  }

  .login-card-footer-text {
    display: flex;
    color: #fff;
    justify-content: center;
    margin-top: 5px;
    font-size: 11px;
  }
`;

export const ContainerInfoTextRight = styled.div`
  display: flex;
  color: #fff;
  width: 50%;
  flex-direction: column;
  font-size: 16px;
  @media (max-width: 1280px) {
    width: 70%;
    margin-top: 30px;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
  h1 {
    font-size: 45px;
    color: #fff;
    font-weight: 600;
  }
  h3 {
    font-size: 22px;
    color: #fff;
    margin-bottom: 25px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 5px 12px 5px 12px;
    margin-right: 10px;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 16px;
  }
  .container-info-icons {
    margin-top: 20px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 50px;
        margin: 10px 20px 10px 0px;
      }
      span {
        font-weight: bold;
      }
    }
  }
`;

export const ContainerPopUpLogin = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 15px;
  width: 15%;
  right: 15px;
  top: 15px;
  background-color: #fff;
  border-radius: 15px;
  z-index: 999999;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  @media (max-width: 1780px) {
    width: 20%;
  }
  @media (max-width: 1280px) {
    width: 25%;
  }
  @media (max-width: 800px) {
    left: 30%;
    top: 20%;
    width: 40%;
  }
  @media (max-width: 680px) {
    left: 25%;
    top: 20%;
    width: 50%;
  }
  @media (max-width: 480px) {
    left: 15%;
    top: 25%;
    width: 75%;
  }
  .btnPortalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    font-size: 12px;
  }
  h4 {
    color: blue;
    h4 {
      font-weight: bold;
    }
  }
  button {
    background-color: orange;
    color: #fff;
    border-radius: 15px;
    border: none;
    width: 70%;
    padding: 5px;
  }
  span {
    margin-top: 7px;
    text-align: center;
    :hover {
      cursor: pointer;
    }
  }
`;
