/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Alert } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

import { createNewUser } from "../../redux/actions/userAction";
import { setApiDoctorStore } from "../../redux/actions/DoctorAction";
import { createUser } from "../../redux/actions/types";
import { login } from "../../redux/actions/authAction";
import { getUserInfo } from "../../redux/actions/userAction";
import { userInfo } from "../../redux/actions/types";
import BarraSuperior from "../Generic/BarraSuperior";

// Components
import Footer from "../../Components/Templates/Footer";
import DoctorRegister from "../../Components/Forms/DoctorRegister";
import HeaderIntro from "../../Components/Templates/IntroRegisterDoctor";
import Modal from "../../Components/Modal";
import Swal from "sweetalert2";
import { NewDoctorRegisterContainer } from "./newDoctorRegister.style";

// Types
import {
  DocumetTypes,
  getCountryUrl,
  departments,
  cities,
} from "../../redux/actions/types";

// actions
import {
  getDocumentType,
  getCountries,
  getCities,
  getDepartments,
} from "../../redux/actions/GenericAction";

// Constans
import { siteHost } from "../../enviroment";
import { PatientTermsConditions } from "../../enviroment";
import "react-phone-input-2/lib/style.css";

class NewDoctorRegister extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recaptcha: false,
      display: "",
      sendRequest: false,
      modalState: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendInfo = this.sendInfo.bind(this);
    this.recaptchaVerify = this.recaptchaVerify.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    // this.onSelectCountry = this.onSelectCountry.bind(this);
    // this.onSelectDepartment = this.onSelectDepartment.bind(this);
  }

  recaptchaVerify(response) {
    if (response) {
      this.setState({ recaptcha: true });
    }
  }

  setButton() {
    this.setState({
      display: (
        <Button
          onClick={this.sendInfo}
          style={{ zIndex: 1000 }}
          color="primary"
        >
          Registrarme
        </Button>
      ),
    });
  }

  sendInfo(data) {
    delete data.Pass2Paciente;

    let user = Object.assign({}, data, {
      action_type: 1,
      profile: {
        // profile_id: "50613943-3B2C-4B92-BDAC-1BE82F973585" patient register
        profile_id: "5EE86B52-2738-4188-AA2F-7E4748C96AE5", // Doctor Profile
      },
      document_type: {
        id: "CC",
      },
    });

    this.setState({ sendRequest: true });

    this.props
      .setApiDoctorStore(
        // createUser, user
        user
      )
      .then((resp) => {
        if (resp.code === 0) {
          this.setState({
            display: "",
            //  <Alert color="success">¡Ya estás registrado!. También enviamos a tu correo la instrucciones para que completes la segunda parte del registro.</Alert>
          });

          // let timerInterval
          Swal.fire({
            title: "Ya te has registrado!",
            html: "Ingresa a tu cuenta y actualiza tus datos.",
            howCancelButton: false,
            confirmButtonText: `Confirmar`,
            timer: 5000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            window.location = "/#/medicalLogin";
          });

          // Aqui mando a hacer el login
          this.props
            .login(
              {
                grant_type: "password",
                username: data.username,
                password: "",
              },
              "token"
            )
            .then((/*res*/) => {
              this.setState({ sendRequest: false });
              if (this.props.isAuthenticated) {
                this.props.getUserInfo(userInfo).then(() => {
                  this.context.router.history.push(siteHost);
                });
              }
              if (this.props.messageError !== "") {
                this.setState({ sendRequest: false });
                return toast.error(this.props.messageError, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            });
        } else {
          this.setState({ sendRequest: false });
          return toast.error(resp.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeCheckBox() {
    this.setState({ terminosAceptados: !this.state.terminosAceptados });
  }

  toggle() {
    this.setState({ modalState: !this.state.modalState });
  }

  goBack() {
    window.history.back();
  }

  componentDidMount() {
    this.props.getDocumentType(DocumetTypes);
  }

  render() {
    return (
      <>
        {/* <BarraSuperior /> */}

        <NewDoctorRegisterContainer>
          <div>
            <h1>¡Bienvenido a DoctorHoy!</h1>
            <h3>Tu consultorio virtual en donde quieras</h3>
            <DoctorRegister
              openTerms={this.toggle}
              submitForm={this.sendInfo}
              loading={this.state.sendRequest}
              {...this.props}
              display={this.state.display}
              allProps={this.props}
              allStates={this.state}
              goBack={this.goBack}
              // eventOnSelectCountry={this.onSelectCountry}
              // eventOnSelectDepartment={this.onSelectDepartment}
            />
          </div>
        </NewDoctorRegisterContainer>

        {/* <Footer /> */}
        <ToastContainer />
        <Modal
          title="Términos y Condiciones de Uso"
          status={this.state.modalState}
          toggle={this.toggle}
        >
          <iframe
            src={PatientTermsConditions}
            title="Terminos y condiciones"
            style={{ width: "100%", height: 600 }}
          >
            Presss me: <a href={PatientTermsConditions}>Descargar PDF</a>
          </iframe>
        </Modal>
      </>
    );
  }
}

NewDoctorRegister.propTypes = {
  getDocumentType: PropTypes.func,
  createNewUser: PropTypes.func,
  setApiDoctorStore: PropTypes.func,
  getCountries: PropTypes.func,
  login: PropTypes.func.isRequired,
  messageError: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  documentTypes: PropTypes.func.isRequired,
};

NewDoctorRegister.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userInfo: state.user.userInfo,
  documentTypes: state.generic.documentTypes,
  countries: state.generic.countries,
  departments: state.generic.departments,
  cities: state.generic.cities,
});

export default connect(mapStateToProps, {
  createNewUser,
  setApiDoctorStore,
  login,
  getUserInfo,
  getDocumentType,
  getCountries,
  getDepartments,
  getCities,
})(NewDoctorRegister);
