/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import $ from 'jquery';

import 'react-sticky-header/styles.css';
import BarraSuperior from "../Generic/BarraSuperior";
import MapContainerGeneric from "../Generic/MapContainerGeneric";
import {
  Popover,
  PopoverBody,
  FormGroup,
  Col,
  ListGroup,
  ListGroupItem,
  Label,
} from 'reactstrap';
import queryString from "query-string";
import {
  cities,
  getAll,
  getQueryIndex,
  getDetalleCarroComprasUrl
} from "../../redux/actions/types";
import { getCities } from "../../redux/actions/GenericAction";
import { GetCup, GetCie, GetSpeciality, GetAll, GetQueryIndex, GetDetalleCarroCompras } from "../../redux/actions/DoctorAction";
import { getFindDoctor } from "../../redux/actions/GenericAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RefreshToken from "../../views/Generic/RefreshToken";
import moment from 'moment';

// Components
import TableResult from './TableResult';

// Assets
import doctor2 from '../../assets/img/doctor2.png';
import doctor1 from '../../assets/img/doctor1.png';
import doctor3 from '../../assets/img/doctor3.png';
import logoFoot from '../../assets/img/logo/logo.png';

let opt0 = "";
let opt1 = "";

moment.locale("es");

class BusquedaMedico extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: [],
      search: "",
      option: 4,
      searchId: "",
      idCity: "",
      sendRequest: false,
      searchOption: [],
      popoverOpen: false,
      markers:
        [
          {
            title: "a",
            name: "aa",
            position: { lat: 3.42385, lng: -76.54264 }
          }
        ]
    };

    this.onBuscar = this.onBuscar.bind(this);
    this.onClickCity = this.onClickCity.bind(this);
    this.ValidateInput = this.ValidateInput.bind(this);
    this.changePopover = this.changePopover.bind(this);
    this.setIdBusqueda = this.setIdBusqueda.bind(this);
    this.OnSelectOption = this.OnSelectOption.bind(this);
    this.OnAgendar = this.OnAgendar.bind(this);
    this.mapClicked = this.mapClicked.bind(this);
    this.pintarMarcadores = this.pintarMarcadores.bind(this);
  }

  OnSelectOption(opt) {
    $('#especialistas').attr("checked", false);
    $('#procedimientos').attr("checked", false);
    $('#enfermedades').attr("checked", false);
    $('#all').attr("checked", false);
    this.setState({ option: opt });
    switch (opt) {
      case 1:
        $('#search').attr("placeholder", "Especialidad");
        break;
      case 2:
        $('#search').attr("placeholder", "Procedimiento");
        break;
      case 3:
        $('#search').attr("placeholder", "Enfermedad");
        break;
      case 4:
        $('#search').attr("placeholder", "Médico, Especialidad, Procedimiento, Enfermedad, Exámenes ....");
        break;
    }

  }

  changePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  setIdBusqueda(idSeleccionado) {
    this.setState({ idSearch: idSeleccionado });

    //	var objeto = this.state.searchOption.find(x => x.id == this.state.idSearch);
    //	if (objeto != undefined) {
    //		$('#search').val(objeto.name);
    //		this.setState({ opt: objeto.option });
    //		this.setState({ search: objeto.name });
    //	}


    this.setState({
      idSearch: idSeleccionado
    }, () => {

      //	this.setState({ seleccionProcedimientos: opcionSelect == 2 });//aqui valido que la opcion seleccionada eso no un procedimiento
      var objeto = this.state.searchOption.find(x => x.id == this.state.idSearch);
      if (objeto != undefined) {
        $('#search').val(objeto.name);
        this.setState({ opt: objeto.option });
        this.setState({ search: objeto.name });
      }

    });

  }

  // eslint-disable-next-line no-unused-vars
  mapClicked(t, map, coord) {

    //    console.log("Click en el mapa");
    // const { latLng } = coord;
    // const lat = latLng.lat();
    // const lng = latLng.lng();

    //     this.setState({
    //         marker: {
    //             title: "",
    //             name: "",
    //             position: { lat, lng }
    //         }
    //     });
  }

  onBuscar() {

    if ($('#search').val() == "") {
      return toast.error("¡Debe ingresar un parametro para la busqueda!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }

    if ($('#search').val() !== "") {
      this.setState({ result: [] });
      this.setState({ sendRequest: true });
      //var objeto = this.state.searchOption.find(x => x.name.toLowerCase().match($('#search').val().trim().toLowerCase()));

      if (this.state.searchId != '') {
        this.props.GetQueryIndex(getQueryIndex, this.state.searchId, this.state.search, this.state.option, this.state.idCity).then(resp => {
          this.setState({ sendRequest: false });
          if (resp.code === 1001) this.RefreshToken.toggle();
          if (resp.code === 2001) {
            this.context.router.history.push("/login");
          }
          this.setState({ sendRequest: false });
          this.setState({ result: resp.data });

          this.pintarMarcadores(resp.data);

        });
      } else {
        return toast.error("¡Debe seleccionar un parametro para la busqueda!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      this.setState({ popoverOpen: false });

      // for (let index = 0; index < this.state.searchOption.length; index++) {
      //   const element = this.state.searchOption[index];
      //   var word = $('#search').val().replace(element.name_option, "");
      //   if (element.name.toLowerCase().search(word.toLowerCase()) >= 0) {
      //     this.setState({ idSearch: element.id });
      //     this.setState({ opt: element.option });
      //     this.setState({ search: element.name });
      //     //   this.context.router.history.push("/BusquedaMedico?option=" + element.option + "&id=" + element.id + '&search=' + element.name + '&idCity= ' + this.state.idCity);
      //     return;
      //   }

      //   //   for (let index = 0; index < this.state.searchOption.length; index++) {
      //   //     const element = this.state.searchOption[index];
      //   //     var word = $('#search').val().replace(element.name_option, "");
      //   //     if (element.name.toLowerCase().search(word.toLowerCase()) >= 0) {
      //   //       this.setState({ idSearch: element.id });
      //   //       this.setState({ opt: element.option });
      //   //       this.setState({ search: element.name });
      //   //       flag = true;
      //   //       this.context.router.history.push("/BusquedaMedico?option=" + element.option + "&id=" + element.id + '&search=' + element.name + '&idCity= ' + this.state.idCity);
      //   //       return;
      //   //     }
      // }

      return;
    }
    this.context.router.history.push("/BusquedaMedico?option=" + this.state.option + "&id=" + this.state.idSearch + '&search=' + this.state.search + '&idCity= ' + this.state.idCity);
  }

  pintarMarcadores(datos) {

    if (datos != undefined) {
      var lstMarkers = [];
      
      if (datos.length > 0) {
        datos.map((objTemp) => {
          objTemp.scheduler.map((shedulerTemp) => {
            if (shedulerTemp.consulting_room != undefined) {
              lstMarkers.push(
                {
                  title: shedulerTemp.consulting_room.name,
                  name: shedulerTemp.consulting_room.name,
                  position: { lat: shedulerTemp.consulting_room.lat, lng: shedulerTemp.consulting_room.lng }
                }

              );
            }

            //	lstMarkers.push({ id: id, name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(), doctor: doctor, option: option, name_option: name_option });
          });
        });
        this.setState({ markers: lstMarkers });
      }
    }
  }


  validateSearch(word) {
    var result = false;
    for (let index = 0; index < this.state.searchOption.length; index++) {
      const element = this.state.searchOption[index];
      word = word.replace(element.name_option, "");
      if (element.name.toLowerCase().search(word.toLowerCase()) >= 0) {
        this.setState({ idSearch: element.id });
        this.setState({ opt: element.option });
        this.setState({ search: element.name });
        return true;
      }
      this.setState({ idSearch: "123" });
      this.setState({ search: word });
    }
    console.log(result);
    return result;
  }


  ValidateInput() {
    console.log($('#search').val());
    this.setState({ textoBuscado: $('#search').val() });
    if ($('#search').val().length == 4) {
      //  if (!this.validateSearch2($('#search').val())) {
      this.setState({ sendRequest: true });
      //document.getElementById("search").disabled = true;
      this.props.GetAll(getAll, $('#search').val(), 7).then(resp => {
        if (resp.code == 0) {
          if (resp.data.length == 0) {
						/*  toast.error("Lo sentimos, en este momento no encontramos médicos con el criterio de consulta ingresado, te invitamos a modificar el criterio de consulta o dirigirte a la opción ‘Programa tu cita’ para otras opciones!!", {
						   position: toast.POSITION.BOTTOM_RIGHT
						 }); */
          }
          var searchOption = this.state.searchOption;
          searchOption = [];
          resp.data.map(({ id, name, doctor, option, name_option }) => {
            searchOption.push({ id: id, name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(), doctor: doctor, option: option, name_option: name_option });
          });
          this.setState({ searchOption: searchOption });
          //document.getElementById("search").disabled = false;
        }
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          //this.props.logout();
          this.context.router.history.push("/login");
        }
        this.setState({ sendRequest: false });
        this.setState({ popoverOpen: true });
      });


    } else if ($('#search').val().length < 4) {
      this.setState({ searchOption: [] });
      this.setState({ search: '' });
      this.setState({ sendRequest: false });
      this.setState({ display: "" });
      this.setState({ popoverOpen: false });
      this.setState({ idSearch: "123" });


      // this.setState({ idSearch: element.id });
      // this.setState({ opt: element.option });
      // this.setState({ search: element.name });
    }
  }

  onClickCity(e) {
    this.setState({ idCity: e.target.value });
  }

  getBoxes(scheduler, opt) {

    scheduler.forEach(element => {
      var exist = element.config_calendar.consulting_type.find(x => x.value === opt);
      moment.locale("es");
      if (exist !== undefined) {
        switch (opt) {
          case "0":
            opt0 = (
              <ul>
                <li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).calendar()}</span></li>
                <li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm:ss a')}</span></li>
                <li><span className="infodate"><i className="icon_currency"></i> {element.price}</span></li>
              </ul>
            );
            break;
          case "1":
            opt1 = (
              <ul>
                <li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).calendar()}</span></li>
                <li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm:ss a')}</span></li>
                <li><span className="infodate"><i className="icon_currency"></i> {element.price}</span></li>
              </ul>
            );
            break;

          default:
            break;
        }
      }
    });


  }

  componentDidMount() {
    $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
    $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(350).css({
      'overflow': 'visible'
    });
    var $hero = $('.hero_home .content');
    var $hero_v = $('#hero_video .content ');
    $hero.find('h3, p, form').addClass('fadeInUp animated');
    $hero.find('.btn_1').addClass('fadeIn animated');
    $hero_v.find('.h3, p, form').addClass('fadeInUp animated');
    $(window).scroll();

    document.getElementById("search").value = this.state.search;
    switch (this.state.option) {
      case 1:
        $('#especialistas').attr("checked", true);
        break;
      case 2:
        $('#procedimientos').attr("checked", true);
        break;
      case 3:
        $('#enfermedades').attr("checked", true);
        break;
      default:
        $('#all').attr("checked", true);
        break;
    }
    this.setState({ sendRequest: true });
    this.props.GetQueryIndex(getQueryIndex, this.state.searchId, this.state.search, this.state.option, this.state.idCity).then(resp => {
      if (resp.code === 1001) this.RefreshToken.toggle();
      if (resp.code === 2001) {
        //this.props.logout();
        this.context.router.history.push("/login");
      }
      this.setState({ sendRequest: false });
      this.setState({ result: resp.data });
      console.log(resp.data);
      this.pintarMarcadores(resp.data);
    });

  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.search !== undefined) {
      this.setState({ search: values.search });
    }

    if (values.id !== undefined) {
      this.setState({ searchId: values.id });
    }
    if (values.idCity !== undefined) {
      this.setState({ idCity: values.idCity });
    }

    if (values.option !== undefined) {
      this.setState({ option: parseInt(values.option) });
      $('#especialistas').attr("checked", false);
      $('#procedimientos').attr("checked", false);
      $('#enfermedades').attr("checked", false);
      $('#all').attr("checked", false);
    }

    //	this.props.getCities(cities + "/-1");
    this.props.getCities(cities + "/-2");
  }

  OnAgendar(obj, tipo) {
    console.log('-------------------------------------------');
    console.log(obj);
    console.log(tipo);

    this.props.GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
      action_type: 1,
      id_calendario: obj.id,
      fecha_inicio: obj.appointment_web,
      es_cup: false,
      tipo_cita: tipo
      //verificar en pruebas si falta mas datos

    }).then(resp => {
      this.setState({ sendRequest: false });
      if (resp.code === 1001) this.RefreshToken.toggle();
      if (resp.code === 2001) {
        this.context.router.history.push("/login?redirect=1");
        return;
      }
      if (resp.code === 1008) {
        this.setState({ sendRequest: false });
        return toast.error('Tiene una transacción en proceso de aprobación en la pasarela de pagos, una vez se reciba respuesta podrá adquirir más citas o productos.', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        //	this.context.router.history.push("/login");
        //aqui digo que tiene un carro pendiente por terminar
      }
      this.setState({ sendRequest: false });
      this.context.router.history.push("/summary");
      //	this.setState({ result: resp.data });
    });
  }

  render() {

    const containerStyle = {
      zIndex: 1999
    };
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
        <div>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="Find easily a doctor and book online an appointment" />
          <meta name="author" content="Ansonika" />
          <title>DOCTORHOY - La salud en línea</title>


          <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon" />
          <link rel="apple-touch-icon" type="image/x-icon" href="img/apple-touch-icon-57x57-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="72x72" href="img/apple-touch-icon-72x72-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="114x114" href="img/apple-touch-icon-114x114-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="144x144" href="img/apple-touch-icon-144x144-precomposed.png" />

          <div className="layer"></div>

          <div id="preloader">
            <div data-loader="circle-side"></div>
          </div>
          <BarraSuperior></BarraSuperior>

          <main>
            <div id="results">
              <div className="container">
                <div className="row">
                  {
										/*
								<div className="col-md-3">
												<h4><strong>Mostrando {this.state.result.length > 5 ? 5 : this.state.result.length} resultados</strong> de {this.state.result.length}</h4>
												</div>
	*/
                  }

                  <div className="col-md-6">
                    <div className="filters_listing" style={{ position: "absolute", right: '0px' }}>
                      <div className="input-group">
                        <ul className="clearfix">
                          <li style={{ "float": "left" }}>

                            <select name="orderby" className="selectbox" onChange={this.onClickCity.bind(this)}>
                              <option key="123" value="123">Seleccione Ciudad</option>
                              {
                                this.props.cities.map(({ id, name }) => (
                                  <option key={id} value={id}>{name}</option>
                                ))
                              }
                            </select>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="search_bar_list">

                      {/* <input type="text" className="form-control" id="search" placeholder="Ejemplo: Nombre, Especialidad, Enfermedad ...." /> 
											
											
											<input
												type="text"
												className="form-control"
												id="search"
												list="options"
												placeholder="Ejemplo: Nombre, Especialidad, Enfermedad ...."
												name="search"
												onChange={this.ValidateInput}
											/>
											
											*/}

                      <div className="input-group">
                        <input
                          type="text"
                          className=" search-query"
                          id="search"
                          list="options"
                          placeholder="Médico, Especialidad, Procedimiento, Enfermedad, Exámenes ...."
                          name="search"
                          autoComplete="off"

                          onFocus={this.onFocus}
                          ref={this.myRef}

                          style={{ with: '100%' }}
                          onChange={this.ValidateInput}
                        />


                        <input type="submit" className="btn_search" value="Buscar" onClick={this.onBuscar.bind()} />

                        <i className={
                          !this.state.sendRequest
                            ? ""
                            : "fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25"
                        } />
                      </div>


                      <Popover id="popoverResultados" placement="bottom" className="popover-container" isOpen={this.state.popoverOpen} target="search" toggle={this.state.changePopover} style={{ maxWidth: '100% !important' }}>

                        <PopoverBody>
                          <Col md="12">
                            <FormGroup row>

                              <Col md={this.state.numCols} style={{ position: 'relative', overflow: 'hidden', display: this.state.displayEsp }}>
                                <Label>Especialidades </Label>
                                <ListGroup style={{ position: 'sticky', bottom: 0, left: 0, right: '-17px', overflowY: 'scroll', cursor: 'pointer', overflow: 'auto' }}>
                                  {
                                    this.state.searchOption.map(({ id, name, option }) => {
                                      if (option == 1) {//Especialidades
                                        if ((name.toLowerCase().includes(this.state.textoBuscado.toLowerCase())) || (id.includes(this.state.textoBuscado.toLowerCase()))) {
                                          return <ListGroupItem key={id} id={id} onClick={this.setIdBusqueda.bind(this, id, 1)}> {name}</ListGroupItem>;
                                        }
                                      }
                                    }
                                    )
                                  }
                                </ListGroup>
                              </Col>
                              <Col md={this.state.numCols} style={{ position: 'relative', overflow: 'hidden', display: this.state.displayEnf }}>
                                <Label>Enfermedades </Label>
                                <ListGroup style={{ position: 'sticky', bottom: 0, left: 0, right: '-17px', overflowY: 'scroll', cursor: 'pointer', overflow: 'auto' }}>
                                  {
                                    this.state.searchOption.map(({ id, name, option }) => {
                                      if (option == 3) {//Enfermedades
                                        if ((name.toLowerCase().includes(this.state.textoBuscado.toLowerCase())) || (id.includes(this.state.textoBuscado.toLowerCase()))) {
                                          return <ListGroupItem key={id} id={id} onClick={this.setIdBusqueda.bind(this, id, 3)}> {name}</ListGroupItem>;
                                        }
                                      }
                                    }
                                    )
                                  }
                                </ListGroup>
                              </Col>
                              <Col md={this.state.numCols} style={{ position: 'relative', overflow: 'hidden', display: this.state.displayMed }}>
                                <Label>Médicos </Label>
                                <ListGroup style={{ position: 'sticky', bottom: 0, left: 0, right: '-17px', overflowY: 'scroll', cursor: 'pointer', overflow: 'auto' }}>
                                  {
                                    this.state.searchOption.map(({ id, name, option }) => {
                                      if (option == 4) {//Medicos
                                        if (name.toLowerCase().includes(this.state.textoBuscado.toLowerCase())) {
                                          return <ListGroupItem key={id} id={id} onClick={this.setIdBusqueda.bind(this, id, 4)}> {name}</ListGroupItem>;
                                        }
                                      }
                                    }
                                    )
                                  }
                                </ListGroup>
                              </Col>

                            </FormGroup>
                          </Col>
                        </PopoverBody>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {

							/*
							
							
							<div className="row" style={{ height: '50px', paddingTop: '20px' }} >
								<div className="filters_listing" style={{ position: "absolute", right: '10%' }}>
									<div className="container">
										<ul className="clearfix">
							
											<li>
												<h6>Organizar por</h6>
												<select name="orderby" className="selectbox">
													<option value="Mas cercanos">Más cercanos</option>
													<option value="Mejor Calificados">Mejor calificados</option>
													<option value="Hombre">Hombre</option>
													<option value="Mujer">Mujer</option>
												</select>
											</li>
										</ul>
									</div>
								</div>
							</div>
							
							
							*/
            }

            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-8">

                  <section className="conteresults fadeIn">
                    <div className="row">
                      <div className="colsmark col1"></div>

                      <div className="colsmark colsmarkresultstitle col2">
                        <div className="cajonconsul2">
                          <img src={doctor2} alt="" />
                          <h3>MÉDICO EN <br />CONSULTORIO </h3>
                        </div>
                      </div>
                      <div className="colsmark colsmarkresultstitle col3">
                        <div className="cajonconsul2">
                          <img src={doctor1} alt="" />
                          <h3>MÉDICO EN <br />CASA</h3>
                        </div>
                      </div>
                      <div className="colsmark colsmarkresultstitle col4">
                        <div className="cajonconsul2">
                          <img src={doctor3} alt="" />
                          <h3>ORIENTACIÓN <br />VIDEOCONSULTA</h3>
                        </div>
                      </div>
                    </div>
                  </section>

                  {
                    this.state.sendRequest ? <div className="col-md-4 col-xs-4">
                      <i className="fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25" />
                    </div> :

                      <TableResult data={this.state.result}
                        OnAgendar={this.OnAgendar}
                      />
                  }
                </div>

                <aside className="col-lg-4" id="sidebar">
                  <div id="map_listing" className="normal_list">
                    <MapContainerGeneric
                      onClick={this.mapClicked.bind(this)}
                      markers={this.state.markers}
                    >

                    </MapContainerGeneric>

                  </div>
                </aside>


              </div>

            </div>

          </main>

          <footer>
            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <p>
                    <a href="index.html" title="Findoctor">
                      <img src={logoFoot} data-retina="true" alt="" width="163" height="36" className="img-fluid" />
                    </a>
                  </p>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Menú principal</h5>
                  <ul className="links">
                    <li><a href="#0">Especialidad</a></li>
                    <li><a href="#0">Procedimientos</a></li>
                    <li><a href="blog.html">Programa tu cita</a></li>
                    <li><a href="login.html">Inicia Sesión</a></li>
                    <li><a href="register.html">Registrate</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Ciudades</h5>
                  <ul className="links">
                    <li><a href="#0">Cali</a></li>
                    <li><a href="#0">Bogotá</a></li>
                    <li><a href="#0">Medellín</a></li>
                    <li><a href="#0">Barranquilla</a></li>

                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Soporte</h5>
                  <ul className="contacts">
                    <li><a href="tel://61280932400"><i className="icon_mobile"></i> + 61 23 8093 3400</a></li>
                    <li><a href="mailto:info@findoctor.com"><i className="icon_mail_alt"></i> info@doctorhoy.com</a></li>
                  </ul>
                  <div className="follow_us">
                    <h5>Síguenos en:</h5>
                    <ul>
                      <li><a href="#0"><i className="social_facebook"></i></a></li>
                      <li><a href="#0"><i className="social_twitter"></i></a></li>
                      <li><a href="#0"><i className="social_linkedin"></i></a></li>
                      <li><a href="#0"><i className="social_instagram"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-md-8">
                  <ul id="additional_links">
                    <li><a href="#0">Terminos y condiciones</a></li>
                    <li><a href="#0">Protección de datos</a></li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div id="copy">© 2020 DoctorHoy</div>
                </div>
              </div>
            </div>
          </footer>
          <div id="toTop" />
        </div>
        <RefreshToken onRef={ref => (this.RefreshToken = ref)} />
      </div >

    );
  }


}


BusquedaMedico.propTypes = {};

BusquedaMedico.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  findDoctors: state.generic.findDoctors,
  cities: state.generic.cities,
  cup: state.doctor.cups,
  cie: state.doctor.cies,
  speciality: state.doctor.speciality,
  getAll: state.doctor.getAll,
  getQueryIndex: state.doctor.getQueryIndex
});

export default connect(
  mapStateToProps,
  {
    getFindDoctor,
    getCities,
    GetCie,
    GetCup,
    GetSpeciality,
    GetAll,
    GetQueryIndex,
    GetDetalleCarroCompras
  }
)(BusquedaMedico);
