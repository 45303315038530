// Libraries
import { each, findIndex, head, indexOf, map, pullAt } from 'lodash';
import { createSelector } from 'reselect';

// Utils

// Single Selectors
const getDoctors = (state) => state.doctors;

// Selectors
export const doctorDetails = createSelector([getDoctors], (doctors) => {
  const doctor = head(doctors);
  let doctorName = '';

  if (!doctor) {
    return {};
  }

  if (doctor.user) {
    const doctorUser = doctor.user;
    doctorName = buildName(doctorUser);
  }

  const { specialities, subEspecialities } = buildSpecialities(doctor.speciality_by_doctor);

  const profilePhotoIndex = findIndex(doctor.certifications, { name: 'Foto' });
  let profilePhoto = null;
  if (profilePhotoIndex !== -1) {
    profilePhoto = doctor.certifications[profilePhotoIndex].binary;
    pullAt(doctor.certifications, profilePhotoIndex);
  }

  const expertIn = getExpertIn(doctor);

  return {
    doctorName,
    specialities,
    rating: doctor.rating,
    profilePhoto,
    certificates: doctor.certifications,
    professionalCard: doctor.professional_card,
    review: doctor.review,
    expertIn,
    subEspecialities,
    teaching: doctor.teaching ? [doctor.teaching] : [],
    academicAchievements: doctor.academic ? [doctor.academic] : [],
    privateMedicalAssociations: map(doctor.associations, (asociation) => asociation.association),
    languages: map(doctor.languages, (language) => language.language),
    sicknessTest: [],
  };
});

const buildName = (doctorUser) => {
  let buildName = '';

  if (doctorUser.name) {
    buildName = doctorUser.name;
  }

  if (doctorUser.second_name) {
    buildName += ` ${doctorUser.second_name}`;
  }

  if (doctorUser.last_name) {
    buildName += ` ${doctorUser.last_name}`;
  }

  if (doctorUser.second_last_name) {
    buildName += ` ${doctorUser.second_last_name}`;
  }

  return buildName;
};

const buildSpecialities = (specialitiesByDoctor) => {
  const specialities = [];
  const subEspecialities = [];
  each(specialitiesByDoctor, (doctor) => {
    if (doctor.especiality) {
      const speciality = doctor.especiality.especiality;
      if (indexOf(specialities, speciality) === -1) {
        specialities.push(speciality);
      }
    }

    if (doctor.sub_especiality) {
      const subEspeciality = doctor.sub_especiality.sub_especiality;
      if (indexOf(subEspecialities, subEspeciality) === -1) {
        subEspecialities.push(doctor.sub_especiality.sub_especiality);
      }
    }
  });

  return {
    specialities,
    subEspecialities,
  };
};

const getExpertIn = (doctor) => {
  const cies = doctor.cies;
  const cups = doctor.cups;

  const expertIn = [];

  each(cies, (cie) => {
    if (cie.cie_name) {
      expertIn.push(cie.cie_name);
    }
  });

  each(cups, (cup) => {
    if (cup.cup_name) {
      expertIn.push(cup.cup_name);
    }
  });

  return expertIn;
};
