/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Button,
  FormGroup,
  Row,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import swal from "sweetalert";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-dates";
import {sendSMS} from '../../Components/MessageBirdApi/MessageBirdApi'
// Redux
import {
  getSpecialityUrl,
  getAgreementUrl,
  cities,
  getCitasEnEsperaUrl,
} from "../../redux/actions/types";
import {
  GetSpeciality,
  GetAgreements,
  GetCitaEnEspera,
} from "../../redux/actions/DoctorAction";
import { getCities } from "../../redux/actions/GenericAction";

import AdmonTable from "./AdmonTable";
import Seleccion from "../../views/CitaEspera/Seleccion";

// Assets
import ManitoLogo from "../../assets/img/manito.jpeg";
import logo from "../../assets/img/logo/logo.png";
import metodosdepago from "./img/metodos-pago.png";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

const minTime = new Date();
minTime.setHours(0, 0, 0);
const maxTime = new Date();
maxTime.setHours(23, 59, 0);

class SolicitudVideoConsulta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editObject: false,
      btnEditCreateSpecialityText: "Enviar Solicitud",
      estado: true,
      startDate: moment(), // set your initial start date here
      endDate: moment().add(20, "minutes"),
      fromTime: "",
      toTime: "",
      min: "00:00",
      max: "23:59",
      idEspecialidad: "",
      nombreEspecialidad: "",
      idAgreement: "",
      nombreAgreement: "Seleccione",
      idCiudad: "",
      nombreCiudad: "Seleccione",
      observaciones: "",
      btnDeshabilitado: false,
      redirect: false,
      initSearch: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSelectSpeciality = this.onSelectSpeciality.bind(this);
    this.onSelectAgreement = this.onSelectAgreement.bind(this);
    this.onSelectCiudad = this.onSelectCiudad.bind(this);
    this.handleMinTime = this.handleMinTime.bind(this);
    this.handleMaxTime = this.handleMaxTime.bind(this);
    this.saveChangesattentionType = this.saveChangesattentionType.bind(this);
    this.onObservacionesChange = this.onObservacionesChange.bind(this);
    this.validarDatos = this.validarDatos.bind(this);
    this.onBlurEspecialidad = this.onBlurEspecialidad.bind(this);
  }

  componentDidMount() {}

  componentWillMount() {
    this.props.GetCitaEnEspera(getCitasEnEsperaUrl, { action_type: 7 });
    this.props.GetSpeciality(getSpecialityUrl, { especiality: "", action: 0 });
    this.props.GetAgreements(getAgreementUrl, { action: -1 });
    this.props.getCities(cities + "/-1");
  }

  onSelectSpeciality(id, nombre) {
    console.log(id + "    " + nombre);
    this.setState({ nombreEspecialidad: nombre });
    this.setState({ idEspecialidad: id });
  }

  onSelectAgreement(id, nombre) {
    this.setState({ nombreAgreement: nombre });
    this.setState({ idAgreement: id });
  }

  onSelectCiudad(id, nombre) {
    this.setState({ nombreCiudad: nombre });
    this.setState({ idCiudad: id });
  }

  saveChangesattentionType(attentionType) {
    this.setState({ attentionType });
  }

  onObservacionesChange(e) {
    let { value } = e.target;
    this.setState({ observaciones: value });
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleMinTime(e) {
    let { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
    minTime.setHours(
      moment(value, "HH:mm:ss").format("HH"),
      moment(value, "HH:mm:ss").format("mm"),
      0
    );
  }

  handleMaxTime(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    maxTime.setHours(
      moment(value, "HH:mm:ss").format("HH"),
      moment(value, "HH:mm:ss").format("mm"),
      0
    );
  }

  validarDatos() {
    var flag = true;
    if (this.state.startDate === "") {
      toast.error("Ingresa la fecha de inicio", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (this.state.endDate === "") {
      toast.error("Ingresa la fecha final", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (this.state.min === "") {
      toast.error("Ingresa la hora de inicio", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (this.state.max === "") {
      toast.error("Ingresa la hora de finalización", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (this.state.idEspecialidad === "") {
      toast.error("Ingresa la especialidad", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }
    return true;
  }

  guardarSolicitud() {
    console.log(this.state);
    if (this.validarDatos()) {
      this.setState({ btnDeshabilitado: true });

      this.props
        .GetCitaEnEspera(getCitasEnEsperaUrl, {
          action_type: 1,
          speciality: this.state.idEspecialidad,
          fecha_inicial: new Date(this.state.startDate),
          fecha_final: new Date(this.state.endDate),
          clase_atencion: 1,
          plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C", //this.state.idAgreement,
          //     ciudad:this.state.idCiudad,
          observaciones: this.state.observaciones,
          hora_inicial: this.state.min,
          hora_final: this.state.max,
        })
        .then((resp) => {
          if (resp.code === 1001) this.RefreshToken.toggle();
          if (resp.code === 2001) {
            //this.props.logout();
            this.context.router.history.push("/login");
          }
          let phones = [];
          if(resp.data[1].length > 0){
            resp.data[1].forEach( function(valor, indice) {
              phones.push(valor.celular)
            });
          }
          //un vez se pase a produccion se debe quitar el numero de abajo y el array
          phones = [];
          phones.push('573154921664');
          //phones.push('573003438359');
          console.log('phones', phones);
          const body = {
            username: 'Oferta videoconsulta',
            cellphone: phones,
            msg: 'Buenos días, Tienes una nueva solicitud de videoconsulta para ofertar en DoctorHoy.'
          };
        
          sendSMS(body, '/send-sms').then(() => {
            console.log('mensaje enviado');
          }).catch((errorMsg) => {
            console.log('Message bird Error', errorMsg);
          });

          swal({
            title:
              "Buscamos un médico para tu atención, espera oferta o respuesta en 15 minutos",
            text: "",
            icon: ManitoLogo,
            button: "Ok",
          });

          setTimeout(
            function () {
              //Start the timer
              if (window.innerWidth <= 760) {
                this.context.router.history.push("/CitaEspera/Seleccion");
              }
              this.setState({ initSearch: true });
            }.bind(this),
            4000
          );
        });
    }
  }

  onBlurEspecialidad() {
    console.log(this.state.nameSpeciality.toLowerCase());

    var data = this.props.speciality.find(
      (x) =>
        x.especiality.toLowerCase() == this.state.nameSpeciality.toLowerCase()
    );
    if (data !== undefined) {
      this.setState({ idEspecialidad: data.id });
    }
  }
  changeTypeAppointment(e) {
    let { value } = e.target;

    if (value == 1) {
      this.context.router.history.push("/CitaEspera/Solicitud");
    }
  }

  render() {
    //    console.log(this.props.speciality);

    return (
      <div className="px-3 animated fadeIn">
        
            <Row>
              <Col md="4" xs="12" xl="4">
                
                  {/* <CardHeader style={{ fontSize: 18 }}>
                    Programa tu videoconsulta ahora
                  </CardHeader> */}
                  <Row>
                    <Col md="12">
                      <FormGroup row>
                        <Col md="12" hidden>
                          <Label>Fechas para Agendar* (dd/mm/aaaa)</Label>
                          <DateRangePicker
                            isOutsideRange={() => false}
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => {
                              this.setState({ startDate, endDate });
                              this.setState({ enabledStartDate: false });
                              this.setState({ enabledEndDate: false });
                              this.setState({ enabledDays: false });
                              this.setState({ enabledTypeAttention: false });
                              this.setState({ enabledRestriction: false });
                            }}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={(focusedInput) =>
                              this.setState({ focusedInput })
                            }
                            orientation={this.state.orientation}
                            openDirection={this.state.openDirection}
                            startDatePlaceholderText="Desde"
                            endDatePlaceholderText="Hasta"
                            small
                            block
                            disabled={this.state.enabledDates}
                          />
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup row>
                        <Col md="6" hidden>
                          <Label htmlFor="exampleDate">Hora Inicio*</Label>
                          <Input
                            type="time"
                            name="min"
                            id="min"
                            value={this.state.min}
                            onChange={this.handleMinTime}
                            /* disabled={this.state.enabledMinDate} */
                          />
                        </Col>
                        <Col md="6" hidden>
                          <Label htmlFor="exampleDate">Hora Fin*</Label>
                          <Input
                            type="time"
                            name="max"
                            id="max"
                            value={this.state.max}
                            onChange={this.handleMaxTime}
                            /* disabled={this.state.enabledMaxDate} */
                          />
                        </Col>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <div>
                        <span className="text-agenda">
                          ¡Agenda tu consulta hoy!&nbsp;
                        </span>
                        <img
                          src={logo}
                          width="100"
                          height="35"
                          alt={`DoctorHoy`}
                        />
                      </div>
                      <Row className="section-types">
                        <Col md="6" xs="6" xl="6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="checkTypeCita"
                              id="checkPresencial"
                              value="1"
                              onClick={this.changeTypeAppointment.bind(this)}
                            />
                            <label
                              className="label-check-type btn btn-success"
                              htmlFor="checkPresencial"
                            >
                              Consulta Presencial
                            </label>
                          </div>
                        </Col>
                        <Col md="6" xs="6" xl="6">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="checkTypeCita"
                              id="checkVideo"
                              value="2"
                              checked
                              onClick={this.changeTypeAppointment.bind(this)}
                            />
                            <label
                              className="label-check-type btn btn-info"
                              htmlFor="checkVideo"
                            >
                              Videoconsulta
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Select
                        placeholder="Especialista o médico general"
                        id="nombreEspecialidad"
                        name="nombreEspecialidad"
                        // value={this.state.nombreEspecialidad}
                        onChange={(opt) =>
                          this.onSelectSpeciality(opt.value, opt.label)
                        }
                        options={this.props.speciality.map((opt) => ({
                          label: opt.especiality,
                          value: opt.id,
                        }))}
                      />
                    </Col>
                    <br />

                    <Col md="12" style={{ display: "none" }}>
                      <Label>Plan de Pago*</Label>

                      <UncontrolledDropdown>
                        <DropdownToggle
                          caret
                          color={"primary"}
                          style={{ width: 100 + "%" }}
                        >
                          {this.state.nombreAgreement}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ width: 100 + "%" }}
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: (data) => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: 250,
                                  },
                                };
                              },
                            },
                          }}
                        >
                          {this.props.agreements.map(({ id, name }) => (
                            <DropdownItem
                              key={id}
                              onClick={this.onSelectAgreement.bind(
                                this,
                                id,
                                name
                              )}
                            >
                              {name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>

                    <Col md="12">
                      <FormGroup row>
                        <Col md="12">
                          <Label>Dinos el motivo de tu consulta</Label>
                          <Input
                            type="textarea"
                            name="observaciones"
                            autoComplete="off"
                            onChange={this.onObservacionesChange}
                            value={this.state.observaciones}
                          ></Input>
                        </Col>
                      </FormGroup>

                      <Col md="12" lg="12" sm="12">
                        <strong style={{ color: "red" }}>
                          *Puedes realizar tus pagos a través de PayU
                        </strong>
                        <img className="img-fluid" src={metodosdepago} />
                      </Col>

                      <FormGroup row>
                        <Col md="12">
                          <strong>&nbsp;</strong>
                          <br />
                          <Button
                            color="warning"
                            name="btnCrearGuardar"
                            onClick={this.guardarSolicitud.bind(this)}
                          >
                            {" "}
                            {this.state.btnEditCreateSpecialityText}{" "}
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                
              </Col>

              <Col md="8" xs="12" xl="8">
                <Card>
                  <span style={{ fontSize: 18, color: "#03416e" }}>
                    Tu historial de citas en espera
                  </span>
                  <CardBody>
                    <Row>
                      <Col md="12" align="center">
                        <div>
                          <AdmonTable data={this.props.citas} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          
        {this.state.initSearch ? (
          <div>
            <hr></hr>
            <Seleccion></Seleccion>
          </div>
        ) : (
          " "
        )}
      </div>
    );
  }
}

SolicitudVideoConsulta.propTypes = {};

SolicitudVideoConsulta.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  speciality: state.doctor.speciality,
  agreements: state.doctor.agreements,
  cities: state.generic.cities,
  citas: state.doctor.citasEnEspera,
});

export default connect(mapStateToProps, {
  GetSpeciality,
  GetAgreements,
  getCities,
  GetCitaEnEspera,
})(SolicitudVideoConsulta);
