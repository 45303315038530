/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { urlHC, siteHost } from "../../enviroment";
import { receiveOffers } from "../../redux/actions/DoctorAction";
import PerfilMenu from "./PerfilMenu";
import BotonesMenu from "./BotonesMenu";
import { ContainerMenuTop } from "./menu.style";

function Menu({ userInfo, userInfoApi, actions }) {
  const [today, setDate] = useState(moment().format("hh:mm:ss a"));
  const [idMedico, setIdMedico] = useState(null);
  const [receiveOffersState, setReceiveOffersState] = useState("na");

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(moment().format("hh:mm:ss a"));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onRedirect = (route) => {
    actions.goRedirectPatient(route);
  };
  const onGoUrl = () => {
    //  console.log('hc', `${urlHC}#/bearer/${localStorage.token.replace(/"/g, '')}`);
    window.location.href = `${urlHC}check/${localStorage.token}`;
  };

  const goToFinancial = () => {
    //  console.log('hc', `${urlHC}#/bearer/${localStorage.token.replace(/"/g, '')}`);
    window.location.href = `https://financialdev.doctorhoy.com/?token=${localStorage.token}&user=${localStorage.financialUser}&key=${localStorage.userKey}`;
  };

  const handleChangeSwitch = () => {
    receiveOffers(idMedico, !receiveOffersState);
    setReceiveOffersState(!receiveOffersState);
  };

  useEffect(() => {
    if (
      userInfo &&
      userInfo?.profile &&
      userInfo.profile?.profile_name == "Medico"
    ) {
      setIdMedico(userInfo.id);
    }
    if (userInfoApi && userInfoApi?.receive_offers == "1") {
      setReceiveOffersState(true);
    } else {
      setReceiveOffersState(false);
    }
  }, [userInfo, userInfoApi]);

  return (
    <>
      {userInfo &&
        userInfo.profile &&
        (userInfo.profile.profile_name == "Paciente" ||
          userInfo.profile.profile_name == "Medico") && (
          <ContainerMenuTop>
            <div className="sub-container-menu-top">
              <div className="sub-botones-menu">
                <BotonesMenu
                  onGoUrl={onGoUrl}
                  goToFinancial={goToFinancial}
                  userInfo={userInfo}
                  onRedirect={onRedirect}
                  idMedico={idMedico}
                  receiveOffersState={receiveOffersState}
                  handleChangeSwitch={handleChangeSwitch}
                  today={today}
                />
              </div>
              {userInfo.profile.profile_name == "Paciente" ? (
                <div className="container-text-menu">
                  <h1>Tu salud es primero</h1>
                  <h3>Consigue citas médicas HOY mismo</h3>
                </div>
              ) : (
                <div className="container-text-menu">
                  <h1>Bienvenido a tu Consultorio DoctorHoy</h1>
                  <h3>Tu consultorio en cualquier lugar</h3>
                </div>
              )}
            </div>
          </ContainerMenuTop>
        )}
    </>
  );
}
Menu.contextTypes = {
  router: PropTypes.object.isRequired,
};
Menu.propTypes = {
  userInfo: PropTypes.object,
};

export default Menu;
