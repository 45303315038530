/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import { combineReducers } from "redux";
import auth from "./auth";
import userInfo from "./user";
import generic from "./Generic";
import products from "./Products";
import contacts from "./Contact";
import doctor from "./Doctor";

/**
 * @author Intelisoft
 * @file NewPersonRegister.js
 * @description Person register
 */

export default combineReducers({
  auth: auth,
  user: userInfo,
  generic: generic,
  products: products,
  contacts: contacts,
  doctor: doctor
});
