/* eslint-disable react/prop-types */
/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import to from "await-to-js";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  FormGroup,
  Row,
  Alert,
} from "reactstrap";

import Swal from "sweetalert2";
import ManitoLogo from "../../assets/img/manito.jpeg";

import AdmonTableSeleccion from "./AdmonTableSeleccion";
import AdmonTableResultOferta from "./AdmonTableResultOferta";

//views components
import ResumenSolicitud from "./ResumenSolicitud";
import OfertasASolicitud from "./OfertasASolicitud";

// Redux
import {
  getUserInfo,
  GetCitaEnEsperaLaravelPatient,
} from "../../redux/actions/userAction";
import {
  getCities,
  setLastAction,
  newInsertPaymentQuoteOffered,
} from "../../redux/actions/GenericAction";
import {
  getSpecialityUrl,
  cities,
  getCitasEnEsperaUrl,
  getCitasEnOfertaUrl,
  getConsultoriosPorCitaEsperaUrl,
  userInfo,
} from "../../redux/actions/types";

import {
  GetSpeciality,
  GetAgreements,
  GetCitaEnEspera,
  GetConsultingRoomByDoctor,
  GetDoctor,
  GetCitaEnOferta,
  GetConsultoriosPorCitaEspera,
  GetValidacionIngresoCarro,
  NewGetCitaEnOferta,
  NewGetCitaEnEsperaNotRedux,
  PendingAppointments,
} from "../../redux/actions/DoctorAction";
import DocHoyLoading from "../../assets/img/DrHoy_loading.gif";
import { PreloaderDocHoy } from "../../Utils";
/**
 * @author Intelisoft
 * @file Seleccion.js
 * @description Seleccion de cita
 */

const minTime = new Date();
minTime.setHours(0, 0, 0);
const maxTime = new Date();
maxTime.setHours(23, 59, 0);

class Seleccion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      citaEsperaId: "",
      editObject: false,
      btnGuardar: "Guardar",
      estado: true,
      startDate: moment(), // set your initial start date here
      endDate: moment().add(20, "minutes"), // set your initial end date here
      fromTime: "",
      toTime: "",
      min: "00:00",
      max: "23:59",
      fechaPropuesta: "",
      nombreConsultorio: "Seleccione",
      consultorioId: "",
      tipoAtencionTexto: "",
      tipoAtencionId: 0,
      tarifa: 0,
      consultoriosPorCita: [],
      permiteComprar: false,
      loadingOfertas: false,
      newSearch: false,
      citaEsperaSelected: null,
      newOfertEntry: null,
      tieneCitas: false,
      initialValues: {},
      newCitaEnOferta: {},
      careType: "",
      nuevasCitasEnOferta: [],
    };
    this.handleChange = this.handleChange.bind(this);

    this.onSelectConsultingRoom = this.onSelectConsultingRoom.bind(this);
    this.onSelectAgreement = this.onSelectAgreement.bind(this);
    this.onSelectCiudad = this.onSelectCiudad.bind(this);
    this.handleMinTime = this.handleMinTime.bind(this);
    this.handleMaxTime = this.handleMaxTime.bind(this);
    this.saveChangesattentionType = this.saveChangesattentionType.bind(this);
    this.onObservacionesChange = this.onObservacionesChange.bind(this);
    this.validarDatos = this.validarDatos.bind(this);
    this.onSeleccionar = this.onSeleccionar.bind(this);
    this.onCancelChanges = this.onCancelChanges.bind(this);
    this.guardarOferta = this.guardarOferta.bind(this);
    this.onSeleccionarResult = this.onSeleccionarResult.bind(this);
    this.onObtenerConsultoriosPorCitaEspera =
      this.onObtenerConsultoriosPorCitaEspera.bind(this);
    this.confirmAndPay = this.confirmAndPay.bind(this);
  }

  async confirmAndPay(params) {
    this.setState({ loadingOfertas: true });

    const response = await newInsertPaymentQuoteOffered(params.id);
    if (response) {
      this.setState({ loadingOfertas: false });
      if (response.title === "success") {
        this.context.router.history.push("/summary");
      } else {
        Swal.fire({
          title: "Error en agendar y pagar",
          text: "Por favor contacte con soporte",
          icon: `error`,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    }
  }
  componentWillMount() {
    this.props.getUserInfo(userInfo).then((infoUsuario) => {
      if (infoUsuario.data) {
        this.props
          .GetCitaEnEsperaLaravelPatient(infoUsuario.data.id)
          .then((awaitingDates) => {
            const citasEspera = awaitingDates?.data;
            // console.log(citasEspera);
            const citasEnEsperaOrdenadas = citasEspera.sort(function (a, b) {
              if (a.hora_inicial > b.hora_inicial) {
                return -1;
              }
              if (a.hora_inicial < b.hora_inicial) {
                return 1;
              }
              // a must be equal to b
              return 0;
            });

            // console.log("citasEnEsperaOrdenadas", citasEnEsperaOrdenadas);
            this.setState({ loadingOfertas: false });
            if (citasEspera.length > 0) {
              this.setState({
                initialValues: {
                  motivo: citasEnEsperaOrdenadas[0].observaciones,
                  attention_type: citasEnEsperaOrdenadas[0].clase_atencion,
                  speciality: citasEnEsperaOrdenadas[0].speciality,
                  speciality_name: citasEnEsperaOrdenadas[0].especialidad,
                  city_name: citasEnEsperaOrdenadas[0]?.city_name,
                  city:
                    citasEnEsperaOrdenadas[0].ciudad !==
                    "00000000-0000-0000-0000-000000000000"
                      ? citasEnEsperaOrdenadas[0].ciudad
                      : null,
                },
              });
            }
            if (awaitingDates?.data.length > 0) {
              this.props
                .NewGetCitaEnOferta(
                  awaitingDates?.data[0].id,
                  awaitingDates?.data[0].clase_atencion,
                  awaitingDates?.data[0].ciudad
                )
                .then((citasOferta) =>
                  this.setState({ nuevasCitasEnOferta: citasOferta })
                );
            }
          });
      }
    });

    this.setState({ loadingOfertas: true });

    //accion 3 filtra por las citas que tienen ofertas validas y por fecha aun valida
    this.props.GetSpeciality(getSpecialityUrl, {
      especiality: this.state.initialValues.speciality,
      action: 0,
    });
    this.props.getCities(cities + "/-2");
  }

  componentDidUpdate() {
    if (this.props.lastAction) {
      if (
        this.props.lastAction.type &&
        this.props.lastAction.type == "REFRESH_CITA_PAGE"
      ) {
        this.props.setLastAction(null);
      }
      if (
        this.props.lastAction.type &&
        this.props.lastAction.type == "DELETE_OFERT_PAGE"
      ) {
        this.props.setLastAction(null);
      }
      if (
        this.props.lastAction.type &&
        this.props.lastAction.type == "UPDATED_OFERT_PAGE"
      ) {
        const cita =
          this.props.lastAction.idCita == "00000000-0000-0000-0000-000000000000"
            ? this.state.citaEsperaSelected
            : this.props.lastAction.idCita;
        const oferta = this.props.lastAction.idOferta;
        this.props.setLastAction(null);
        // this.props
        //   .GetCitaEnOferta(getCitasEnOfertaUrl, {
        //     action_type: 3,
        //     cita_espera_id: cita,
        //   })
        //   .then(() => {
        //     console.log("CITA 2", cita);
        //     this.setState({ citaEsperaSelected: cita });
        //     this.setState({ newOfertEntry: oferta });
        //   });
      }
    }
    if (this.props.citas && this.props.citas[0]) {
      // console.log('tiene citas creadas');
      // console.log('antes',this.state.tieneCitas);
      // this.setState({tieneCitas: true})
      // console.log('despues',this.state.tieneCitas);
      // this.props.GetCitaEnOferta(getCitasEnOfertaUrl, { action_type: 3, cita_espera_id: this.props.citas[0].id }).then(() => { });
      // this.onObtenerConsultoriosPorCitaEspera(this.props.citas[0].id);
    }
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSelectConsultingRoom(id, nombre) {
    this.setState({ nombreConsultorio: nombre });
    this.setState({ consultorioId: id });
  }

  onSelectAgreement(id, nombre) {
    this.setState({ nombreAgreement: nombre });
    this.setState({ idAgreement: id });
  }

  onSelectCiudad(id, nombre) {
    this.setState({ nombreCiudad: nombre });
    this.setState({ idCiudad: id });
  }

  saveChangesattentionType(attentionType) {
    this.setState({ attentionType });
  }

  onObservacionesChange(e) {
    let { value } = e.target;
    this.setState({ observaciones: value });
  }

  handleMinTime(e) {
    let { name, value } = e.target;
    console.log(value);
    this.setState({ [name]: value });
    minTime.setHours(
      moment(value, "HH:mm:ss").format("HH"),
      moment(value, "HH:mm:ss").format("mm"),
      0
    );
  }

  handleMaxTime(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
    maxTime.setHours(
      moment(value, "HH:mm:ss").format("HH"),
      moment(value, "HH:mm:ss").format("mm"),
      0
    );
  }

  validarDatos() {
    var flag = true;
    if (this.state.fechaPropuesta === "") {
      toast.error("Ingresa la fecha de la consulta", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (this.state.min === "") {
      toast.error("Ingresa la hora de la consulta", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    if (
      moment().diff(
        moment(
          this.state.fechaPropuesta + " " + this.state.min,
          "YYYY-MM-DD hh:mm"
        ),
        "minutes"
      ) >= -2
    ) {
      Swal.fire({
        title: "La fecha debe ser mayor a la actual",
        icon: `error`,
        timer: 3000,
        timerProgressBar: true,
      });
      flag = false;
      return flag;
    }

    // aqui se debe validar el consultorio, pero solo si el tipo de cita es en consultorio
    if (this.state.tipoAtencionId === 0) {
      if (this.state.consultorioId === "") {
        toast.error(
          "Para el tipo de atención es obligatorio seleccionar un consultorio",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        flag = false;
        return flag;
      }
    }

    if (this.state.tarifa < 0) {
      toast.error("La tarifa debe ser mayor o igual a cero", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      flag = false;
      return flag;
    }

    return true;
  }

  guardarOferta(data) {
    this.setState({ careType: data.attention_type });

    this.setState({ loadingOfertas: true });

    this.props
      .PendingAppointments({
        patient_id: this.props.userInfo?.id,
        // type: "specialty",
        specialty_id: data.speciality,
        care_type: data.attention_type,
        observations: data.motivo,
        city_id: data?.city,
        plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
        address_id: data?.address?.id,
      })
      .then((resp) => {
        this.setState({ loadingOfertas: false });
        // if (resp.code === 1001) {
        //   this.RefreshToken.toggle();
        //   return;
        // }
        // if (resp.code === 2001) {
        //   this.context.router.history.push("/login");
        //   return;
        // }

        if (resp.code == 1) {
          if (this.props.citas.length > 1) {
            Swal.fire({
              title:
                "Ya realizaste esta busqueda. Consulta tu historial de solicitudes ",
              iconHtml: `<img src="${ManitoLogo}"/>`,
              timer: 3000,
              timerProgressBar: true,
            });
            return;
          } else {
            Swal.fire({
              title:
                "Ya realizaste esta busqueda. Seguimos buscando un médico para tu cita",
              iconHtml: `<img src="${ManitoLogo}"/>`,
              timer: 3000,
              timerProgressBar: true,
            });
            return;
          }
        }
        this.setState({ loadingOfertas: true });
        this.props
          .GetCitaEnEspera(getCitasEnEsperaUrl, { action_type: 3 })
          .then(() => {
            this.setState({ loadingOfertas: false });
          });
        this.setState({ newSearch: true });
        Swal.fire({
          title:
            "Buscamos un médico para tu atención, espera oferta o respuesta en 15 minutos",
          iconHtml: `<img src="${ManitoLogo}"/>`,
          timer: 5000,
          timerProgressBar: true,
        });
      });
  }

  onSeleccionar(obj) {
    this.setState({ loadingOfertas: true });
    this.props
      .GetCitaEnOferta(getCitasEnOfertaUrl, {
        action_type: 3,
        cita_espera_id: obj.id,
      })
      .then((citasEnOferta) => {
        if (!citasEnOferta?.data?.length) {
          if (!this.state.newSearch) {
            Swal.fire({
              title: "Estamos buscando un médico para tu cita",
              icon: `success`,
              timer: 2500,
              timerProgressBar: true,
            });
          } else {
            this.setState({ newSearch: true });
          }
        }
        this.setState({ citaEsperaSelected: obj.id });

        this.setState({ loadingOfertas: false });
      });

    this.onObtenerConsultoriosPorCitaEspera(obj.id);
  }

  onSeleccionarResult(obj) {
    if (this.state.permiteComprar) {
      this.props
        .GetCitaEnOferta(getCitasEnOfertaUrl, {
          action_type: 4,
          id: obj.id,
          cita_espera_id: obj.cita_espera_id,
        })
        .then((resp) => {
          Swal.fire({
            title:
              "La oferta se ha seleccionado, ingrese al carro de compras y finalice el pago",
            iconHtml: `<img src="${ManitoLogo}"/>`,
            timer: 3000,
            timerProgressBar: true,
          });

          setTimeout(
            function () {
              //Start the timer
              this.context.router.history.push("/Summary");
            }.bind(this),
            4000
          );
        });
    } else {
      Swal.fire({
        title:
          "Tiene un carro de compras en proceso, debe finalizarlo par realizar mas compras.",
        icon: "error",
        timer: 3000,
        timerProgressBar: true,
      });
    }
  }

  onCancelChanges() {
    this.setState({
      citaEsperaId: "",
      observaciones: "",
      fechaPropuesta: "",
      min: "00:00",
      nombreConsultorio: "Seleccione",
      consultorioId: "",
      tipoAtencionTexto: "",
      tipoAtencionId: 0,
      tarifa: 0,
    });
  }

  onObtenerConsultoriosPorCitaEspera(citaEsperaId) {
    //hago el llamado a la cita y modifico el estado de consultoriosPorCita
    this.props
      .GetConsultoriosPorCitaEspera(getConsultoriosPorCitaEsperaUrl, {
        cita_id: citaEsperaId,
      })
      .then((resp) => {
        this.setState({ consultoriosPorCita: resp.data });
      });
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className={this.state.loadingOfertas ? "" : "d-none"}>
          <PreloaderDocHoy></PreloaderDocHoy>
        </div>
        <FormGroup>
          <Col md="12" xs="12" xl="12" className="mt-3">
            <Row>
              <Col md={12} xs="12" xl={12}>
                <OfertasASolicitud
                  ofertas={this.props.ofertas}
                  loading={this.state.loadingOfertas}
                  onSeleccionar={this.onSeleccionar}
                  citaEsperaSelected={this.state.citaEsperaSelected}
                  citas={this.props.pendingAppointments}
                  specialities={this.props.speciality}
                  confirmAndPay={this.confirmAndPay}
                  newOfertEntry={this.state.newOfertEntry}
                  cities={this.props.cities}
                  nuevasCitasEnOferta={this.state.nuevasCitasEnOferta}
                />
              </Col>
            </Row>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

Seleccion.propTypes = {
  lastAction: PropTypes.string,
};

Seleccion.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  lastAction: state.generic.lastAction,
  speciality: state.doctor.speciality,
  agreements: state.doctor.agreements,
  cities: state.generic.cities,
  citas: state.doctor.citasEnEspera,
  pendingAppointments: state.user.pendingAppointmentsPatient,
  consultorios: state.doctor.consultingRoomByDoctor,
  userInfo: state.user.userInfo,
  ofertas: state.doctor.citasEnOferta,
});

export default connect(mapStateToProps, {
  GetSpeciality,
  GetAgreements,
  getCities,
  GetCitaEnEspera,
  getUserInfo,
  GetDoctor,
  GetConsultingRoomByDoctor,
  GetCitaEnOferta,
  GetConsultoriosPorCitaEspera,
  GetValidacionIngresoCarro,
  setLastAction,
  NewGetCitaEnOferta,
  NewGetCitaEnEsperaNotRedux,
  PendingAppointments,
  GetCitaEnEsperaLaravelPatient,
})(Seleccion);
