import styled, { css } from "styled-components";

export const ContainerBotonesMenu = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 100px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1680px) {
    height: fit-content;
    flex-wrap: wrap;
    padding: 15px 10px 10px 10px;
  }
  button {
    color: #2873b7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 2px;
    margin: 0px;
    border: none;
    background-color: transparent;
    width: 11%;
    @media (max-width: 1480px) {
      width: 25%;
      margin-bottom: 20px;
    }
    @media (max-width: 780px) {
      width: 30%;
      margin-bottom: 20px;
    }
    @media (max-width: 430px) {
      width: 50%;
      margin-bottom: 20px;
    }
    i {
      margin-bottom: 3px;
    }
  }
`;

export const ContainerBotonesMenuPatient = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 100px;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1680px) {
    height: fit-content;
    flex-wrap: wrap;
    padding: 15px 10px 10px 10px;
  }
  @media (max-width: 1480px) {
    justify-content: flex-start;
  }
  @media (max-width: 780px) {
    justify-content: space-between;
  }
  button {
    color: #2873b7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 2px;
    margin: 0px;
    border: none;
    background-color: transparent;
    width: 11%;
    @media (max-width: 1480px) {
      width: 20%;
      margin-bottom: 20px;
    }
    @media (max-width: 780px) {
      width: 30%;
      margin-bottom: 20px;
    }
    @media (max-width: 430px) {
      width: 50%;
      margin-bottom: 20px;
    }
    i {
      margin-bottom: 3px;
    }
  }
`;
export const OnTopBarSwitchAndHour = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 480px) {
    margin-bottom: 20px;
    padding: 0px 5px 0px 5px;
  }
  .container-switch {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    span {
      color: #fff;
      margin-left: 8px;
      font-size: 13px;
    }
  }
  .container-date-hour {
    display: flex;
    @media (max-width: 480px) {
      display: none;
    }
    span {
      color: #fff;
      margin-left: 5px;
    }
    i {
      font-size: medium;
      margin-left: 10px;
    }
  }
  .container-open-menu-btn {
    display: none;
    @media (max-width: 480px) {
      display: flex;
    }
    i {
      padding: 0px 5px 5px 0px;
    }
  }
`;
export const ContainerWelcomeName = styled.div`
  display: none;
  color: #fff;
  margin-bottom: 10px;
  padding-left: 5px;
  @media (max-width: 480px) {
    display: flex;
  }
`;

export const OnTopBarSwitchAndHourPatient = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  @media (max-width: 480px) {
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0px 5px 0px 3px;
  }

  .container-date-hour {
    display: flex;
    span {
      color: #fff;
      margin-left: 5px;
    }
    i {
      font-size: medium;
      margin-left: 10px;
    }
  }
  .container-open-menu-btn {
    display: none;
    @media (max-width: 480px) {
      display: flex;
    }
    i {
      padding: 0px 5px 5px 0px;
    }
  }
`;
