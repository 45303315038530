/* eslint-disable react/jsx-no-target-blank */

import React, { Component } from "react";
import $ from 'jquery';
import logoFoot from "../../assets/img/logo/logo.png";
import 'react-sticky-header/styles.css';
import BarraSuperior from "../Generic/BarraSuperior";
import queryString from "query-string";
import MapContainerGeneric from "../Generic/MapContainerGeneric";
import {
  cities,
  getAll,
  getQueryIndex,
  getDetalleCarroComprasUrl,
} from "../../redux/actions/types";
import { getCities } from "../../redux/actions/GenericAction";
import { GetCup, GetCie, GetSpeciality, GetAll, GetQueryIndex, GetDetalleCarroCompras } from "../../redux/actions/DoctorAction";
import { getFindDoctor } from "../../redux/actions/GenericAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RefreshToken from "../Generic/RefreshToken";
import {
  Popover,
  PopoverBody,
  FormGroup,
  Col,
  ListGroup,
  ListGroupItem,
  Label,
} from 'reactstrap';
import moment from 'moment';

moment.locale("es");

class BusquedaEntidades extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: [],
      search: "",
      searchId: "",
      option: 2,
      idCity: "",
      sendRequest: false,
      searchOption: [],
      popoverOpen: false,
      markers:
        [
          {
            title: "a",
            name: "aa",
            position: { lat: 3.42385, lng: -76.54264 }
          }
        ]
    };

    this.redireccion = this.redireccion.bind(this);
    this.OnAgendar = this.OnAgendar.bind(this);
    this.pintarMarcadores = this.pintarMarcadores.bind(this);
    this.mapClicked = this.mapClicked.bind(this);
    this.centrarMarcador = this.centrarMarcador.bind(this);
    this.onBuscar = this.onBuscar.bind(this);
    this.ValidateInput = this.ValidateInput.bind(this);
    this.changePopover = this.changePopover.bind(this);
    this.setIdBusqueda = this.setIdBusqueda.bind(this);
    //	this.OnSelectOption = this.OnSelectOption.bind(this);
  }

  componentDidMount() {
    $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
    $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
    $('body').delay(350).css({
      'overflow': 'visible'
    });
    var $hero = $('.hero_home .content');
    var $hero_v = $('#hero_video .content ');
    $hero.find('h3, p, form').addClass('fadeInUp animated');
    $hero.find('.btn_1').addClass('fadeIn animated');
    $hero_v.find('.h3, p, form').addClass('fadeInUp animated');
    $(window).scroll();

    document.getElementById("search").value = this.state.search;

    this.setState({ sendRequest: true });
    this.props.GetQueryIndex(getQueryIndex, this.state.searchId, this.state.search, this.state.option, this.state.idCity).then(resp => {
      if (resp.code === 1001) this.RefreshToken.toggle();
      if (resp.code === 2001) {
        //this.props.logout();
        this.context.router.history.push("/login");
      }
      this.setState({ sendRequest: false });
      this.setState({ result: resp.data });
      console.log(resp.data);
      this.pintarMarcadores(resp.data);
    });
  }

  redireccion() {
    //redireccion a el carro de compras
    //	this.context.router.history.push("/Summary");
  }

  pintarMarcadores(datos) {
    if (datos != undefined) {
      var lstMarkers = [];
      if (datos.length > 0) {
        datos.map((objTemp) => {
          objTemp.scheduler.map((shedulerTemp) => {
            if (shedulerTemp.consulting_room != undefined) {

              lstMarkers.push(
                {
                  title: shedulerTemp.consulting_room.name,
                  name: shedulerTemp.consulting_room.name,
                  position: { lat: shedulerTemp.consulting_room.lat, lng: shedulerTemp.consulting_room.lng }
                }

              );
            }

            //	lstMarkers.push({ id: id, name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(), doctor: doctor, option: option, name_option: name_option });
          });
        });

        this.setState({ markers: lstMarkers });
      }
    }
  }

  mapClicked(/*t, map, coord*/) {
    //    console.log("Click en el mapa");
    // const { latLng } = coord;
    // const lat = latLng.lat();
    // const lng = latLng.lng();

    //     this.setState({
    //         marker: {
    //             title: "",
    //             name: "",
    //             position: { lat, lng }
    //         }
    //     });
  }

  centrarMarcador(obj) {
    //busco las coordenadas en el obj
    console.log(obj.consulting_room);
    if (obj.consulting_room != undefined) {
      if (obj.consulting_room.lat != undefined) {
        this.setState({ centerMap: { lat: obj.consulting_room.lat, lng: obj.consulting_room.lng } });
      }
    }
  }

  OnAgendar(medico, scheduler) {
    console.log('---medico----------------------------------------');
    console.log(medico);
    console.log('----scheduler---------------------------------------');
    console.log(scheduler);
    console.log('-----busquedaId--------------------------------------');
    console.log(this.state.searchId);
    //	switch (tipo) {
    //		case 0:
    //			console.log('--Consultorio');
    //			break;
    //		case 1:
    //			console.log('--Video');
    //			break;
    //		case 2:
    //			console.log('--En casa');
    //			break;
    //
    //	}

    this.props.GetDetalleCarroCompras(getDetalleCarroComprasUrl, {
      action_type: 1,
      id_calendario: scheduler.id,
      fecha_inicio: scheduler.appointment_web,
      es_cup: true,
      tipo_cita: 0,
      id_cup: this.state.searchId
      //verificar en pruebas si falta mas datos
    }).then(resp => {
      console.log(resp);
      this.setState({ sendRequest: false });
      if (resp.code === 1001) this.RefreshToken.toggle();
      if (resp.code === 2001) {
        this.context.router.history.push("/login?redirect=1");
        return;
      }
      if (resp.code === 1008) {
        return toast.error('Tiene una transacción en proceso de aprobación en la pasarela de pagos, una vez se reciba respuesta podrá adquirir más citas o productos', {
          position: toast.POSITION.BOTTOM_RIGHT
        });

        // this.setState({ sendRequest: false });
        // //	this.context.router.history.push("/login");
        // //aqui digo que tiene un carro pendiente por terminar
        // return;
      }
      this.setState({ sendRequest: false });
      this.context.router.history.push("/summary");
      //	this.setState({ result: resp.data });
    });
  }

  getBoxes(scheduler) {
    console.log(scheduler);
    var element = scheduler[0];
    //	scheduler.forEach(element => {
    console.log(element);

    return (
      <div>
        <div className="col-12"><i className="icon_calendar"></i> {moment(element.appointment_web).calendar()}  </div>
        <div className="col-12"><i className="icon_clock"></i>  {moment(element.appointment_web).format('h:mm a')} </div>
        <div className="col-12"><i className="icon_currency"></i>  {element.price}  </div>
      </div>
    );

    //		<ul>
    //			<li><span className="infodate"><i className="icon_calendar"></i> {moment(element.appointment_web).calendar()}</span></li>
    //			<li><span className="infodate"><i className="icon_clock"></i> {moment(element.appointment_web).format('h:mm a')}</span></li>
    //			<li><span className="infodate"><i className="icon_currency"></i> {element.price}</span></li>
    //			<li><Button color="primary" size="sm" onClick={this.redireccion.bind(this)}><i className="fa fa-shopping-bag" />{' '}Agenda</Button></li>
    //		</ul>
    //	}
    //	);
  }

  componentWillMount() {
    const values = queryString.parse(this.props.location.search);

    if (values.search !== undefined) {
      this.setState({ search: values.search });
    }
    if (values.id !== undefined) {
      this.setState({ searchId: values.id });
    }
    if (values.idCity !== undefined) {
      this.setState({ idCity: values.idCity });
    }

    if (values.option !== undefined) {
      this.setState({ option: 2 });
      $('#especialistas').attr("checked", false);
      $('#procedimientos').attr("checked", false);
      $('#enfermedades').attr("checked", false);
      $('#all').attr("checked", false);
    }

    //	this.props.getCities(cities + "/-1");
    this.props.getCities(cities + "/-2");
  }

  pintarCuadro(/*doctor, scheduler*/) {
  }

  ValidateInput() {
    console.log($('#search').val());
    this.setState({ textoBuscado: $('#search').val() });
    if ($('#search').val().length == 4) {
      //  if (!this.validateSearch2($('#search').val())) {
      this.setState({ sendRequest: true });
      //document.getElementById("search").disabled = true;
      this.props.GetAll(getAll, $('#search').val(), 7).then(resp => {
        if (resp.code == 0) {
          if (resp.data.length == 0) {
						/*  toast.error("Lo sentimos, en este momento no encontramos médicos con el criterio de consulta ingresado, te invitamos a modificar el criterio de consulta o dirigirte a la opción ‘Programa tu cita’ para otras opciones!!", {
						   position: toast.POSITION.BOTTOM_RIGHT
						 }); */
          }
          var searchOption = this.state.searchOption;
          searchOption = [];
          resp.data.map(({ id, name, doctor, option, name_option }) => {
            searchOption.push({ id: id, name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(), doctor: doctor, option: option, name_option: name_option });
          });
          this.setState({ searchOption: searchOption });
          //document.getElementById("search").disabled = false;
        }
        if (resp.code === 1001) this.RefreshToken.toggle();
        if (resp.code === 2001) {
          //this.props.logout();
          this.context.router.history.push("/login");
        }
        this.setState({ sendRequest: false });
        this.setState({ popoverOpen: true });
      });
    } else if ($('#search').val().length < 4) {
      this.setState({ searchOption: [] });
      this.setState({ search: '' });
      this.setState({ sendRequest: false });
      this.setState({ display: "" });
      this.setState({ popoverOpen: false });
      this.setState({ idSearch: "123" });

      // this.setState({ idSearch: element.id });
      // this.setState({ opt: element.option });
      // this.setState({ search: element.name });
    }
  }

  onBuscar() {
    if ($('#search').val() == "") {
      return toast.error("¡Debe ingresar un parametro para la busqueda!", {
        position: toast.POSITION.BOTTOM_RIGHT
      });
    }

    if ($('#search').val() !== "") {
      this.setState({ result: [] });
      this.setState({ sendRequest: true });
      //var objeto = this.state.searchOption.find(x => x.name.toLowerCase().match($('#search').val().trim().toLowerCase()));

      if (this.state.searchId != '') {
        this.props.GetQueryIndex(getQueryIndex, this.state.searchId, this.state.search, 2, this.state.idCity).then(resp => {
          this.setState({ sendRequest: false });
          if (resp.code === 1001) this.RefreshToken.toggle();
          if (resp.code === 2001) {
            this.context.router.history.push("/login");
          }
          this.setState({ sendRequest: false });
          this.setState({ result: resp.data });

          this.pintarMarcadores(resp.data);
        });
      } else {
        return toast.error("¡Debe seleccionar un parametro para la busqueda!", {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }

      this.setState({ popoverOpen: false });
      return;
    }
    this.setState({ sendRequest: false });
    //this.context.router.history.push("/BusquedaMedico?option=" + this.state.option + "&id=" + this.state.idSearch + '&search=' + this.state.search + '&idCity= ' + this.state.idCity);
  }

  setIdBusqueda(idSeleccionado) {
    this.setState({ idSearch: idSeleccionado });

    //	var objeto = this.state.searchOption.find(x => x.id == this.state.idSearch);
    //	if (objeto != undefined) {
    //		$('#search').val(objeto.name);
    //		this.setState({ opt: objeto.option });
    //		this.setState({ search: objeto.name });
    //	}

    this.setState({
      idSearch: idSeleccionado
    }, () => {
      //	this.setState({ seleccionProcedimientos: opcionSelect == 2 });//aqui valido que la opcion seleccionada eso no un procedimiento
      var objeto = this.state.searchOption.find(x => x.id == this.state.idSearch);
      if (objeto != undefined) {
        $('#search').val(objeto.name);
        this.setState({ opt: objeto.option });
        this.setState({ search: objeto.name });
      }
    });
  }

  changePopover() {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  render() {
    const containerStyle = {
      zIndex: 1999
    };

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          style={containerStyle}
        />
        <div>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="Find easily a doctor and book online an appointment" />
          <meta name="author" content="Ansonika" />
          <title>DOCTORHOY - La salud en línea</title>
          <link rel="shortcut icon" href="img/favicon.ico" type="image/x-icon" />
          <link rel="apple-touch-icon" type="image/x-icon" href="img/apple-touch-icon-57x57-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="72x72" href="img/apple-touch-icon-72x72-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="114x114" href="img/apple-touch-icon-114x114-precomposed.png" />
          <link rel="apple-touch-icon" type="image/x-icon" sizes="144x144" href="img/apple-touch-icon-144x144-precomposed.png" />
          <div className="layer"></div>

          <div id="preloader">
            <div data-loader="circle-side"></div>
          </div>

          <BarraSuperior></BarraSuperior>

          <main>
            <div id="results">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h4></h4>
                  </div>
                  {
										/*
										<div className="col-md-6">
											<div className="search_bar_list">
												<input type="text" className="form-control" id="search" placeholder="Ejemplo: Nombre, Especialidad, Enfermedad ...." />
												<input type="submit" value="Buscar" />
											</div>
										</div>
										*/
                  }

                  <div className="col-md-6">
                    <div className="search_bar_list">
                      <div className="input-group">
                        <input
                          type="text"
                          className=" search-query"
                          id="search"
                          list="options"
                          placeholder="Procedimiento"
                          name="search"
                          autoComplete="off"
                          onFocus={this.onFocus}
                          ref={this.myRef}
                          style={{ with: '100%' }}
                          onChange={this.ValidateInput}
                        />
                        <input type="submit" className="btn_search" value="Buscar" onClick={this.onBuscar.bind()} />

                        <i className={
                          !this.state.sendRequest ? "" : "fa fa-circle-o-notch fa-lg fa-spin mt-24 ml-25"
                        } />
                      </div>

                      <Popover id="popoverResultados" placement="bottom" className="popover-container" isOpen={this.state.popoverOpen} target="search" toggle={this.state.changePopover} style={{ maxWidth: '100% !important' }}>
                        <PopoverBody>
                          <Col md="12">
                            <FormGroup row>
                              <Col md={this.state.numCols} style={{ position: 'relative', overflow: 'hidden', display: this.state.displayProc }}>
                                <Label>Procedimientos </Label>
                                <ListGroup style={{ position: 'sticky', bottom: 0, left: 0, right: '-17px', overflowY: 'scroll', cursor: 'pointer', overflow: 'auto' }}>
                                  {
                                    this.state.searchOption.map(({ id, name, option, /*name_option*/ }) => {
                                      if (option == 2) {//Procedimientos
                                        if ((name.toLowerCase().includes(this.state.textoBuscado.toLowerCase())) || (id.includes(this.state.textoBuscado.toLowerCase()))) {
                                          return <ListGroupItem key={id} id={id} onClick={this.setIdBusqueda.bind(this, id, 2)}> {name}</ListGroupItem>;
                                        }
                                      }
                                    }
                                    )
                                  }
                                </ListGroup>
                              </Col>
                            </FormGroup>
                          </Col>
                        </PopoverBody>
                      </Popover>

                    </div>
                  </div>

                </div>

              </div>

            </div>

            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-8">
                  <div className="row">

                    {
                      this.state.result.map(({ doctor, scheduler }) => (

                        <div className="col-md-4" id={doctor.id_user_doctor} key={doctor.id_user_doctor}>
                          <div className="box_list  fadeIn">
                            <a href={"/#/DoctorPublicProfile?id=" + doctor.id_user_doctor} className="wish_bt"></a>
                            <figure>
                              <a href={"/#/DoctorPublicProfile?id=" + doctor.id_user_doctor} ><img src={"data:image/png;base64," + doctor.user.binary_photo} className="img-fluid" alt="" />
                                <div className="preview"></div> </a>
                            </figure>
                            <div className="wrapper">
                              <small>Nombre</small>
                              <h3>  {doctor.user.name} {doctor.user.second_name} {doctor.user.last_name} {doctor.user.second_last_name}</h3>

                              <h6 className="citadisponible">Cita disponible</h6>
                              <div className="row resultadosexams">

                                {this.getBoxes(scheduler)}
                              </div>

                            </div>
                            <ul>
                              <li><a style={{ cursor: 'pointer' }} onClick={this.centrarMarcador.bind(this, scheduler[0])} ><i className="icon_pin_alt"></i>Ver en el mapa</a></li>
                              <li><a href="https://www.google.com/maps/dir//Assistance+%E2%80%93+H%C3%B4pitaux+De+Paris,+3+Avenue+Victoria,+75004+Paris,+Francia/@48.8606548,2.3348734,14z/data=!4m15!1m6!3m5!1s0x0:0xa6a9af76b1e2d899!2sAssistance+%E2%80%93+H%C3%B4pitaux+De+Paris!8m2!3d48.8568376!4d2.3504305!4m7!1m0!1m5!1m1!1s0x47e67031f8c20147:0xa6a9af76b1e2d899!2m2!1d2.3504327!2d48.8568361" target="_blank"><i className="icon_pin_alt"></i>Directions</a></li>
                              <li><a style={{ cursor: 'pointer' }} onClick={this.OnAgendar.bind(this, doctor, scheduler[0])}>Agendar</a></li>
                            </ul>
                          </div>
                        </div>
                      ))

                    }
                  </div>

                  {
                    this.state.result.length == 0 ? <div className="row">No se encontraron resultados, puede modificar su busqueda o programar una cita.</div> : ""
                  }

                </div>

                <aside className="col-lg-4" id="sidebar">
                  <div id="map_listing" className="normal_list">
                    <MapContainerGeneric
                      onClick={this.mapClicked.bind(this)}
                      markers={this.state.markers}
                      centerMap={this.state.centerMap}
                    >
                    </MapContainerGeneric>
                  </div>
                </aside>

              </div>

            </div>

          </main>

          <footer>
            <div className="container margin_60_35">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <p>
                    <a href="index.html" title="Findoctor">
                      <img src={logoFoot} data-retina="true" alt="" width="163" height="36" className="img-fluid" />
                    </a>
                  </p>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Menú principal</h5>
                  <ul className="links">
                    <li><a href="#0">Especialidad</a></li>
                    <li><a href="#0">Procedimientos</a></li>
                    <li><a href="blog.html">Programa tu cita</a></li>
                    <li><a href="login.html">Inicia Sesión</a></li>
                    <li><a href="register.html">Registrate</a></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Ciudades</h5>
                  <ul className="links">
                    <li><a href="#0">Cali</a></li>
                    <li><a href="#0">Bogotá</a></li>
                    <li><a href="#0">Medellín</a></li>
                    <li><a href="#0">Barranquilla</a></li>

                  </ul>
                </div>
                <div className="col-lg-3 col-md-4">
                  <h5>Soporte</h5>
                  <ul className="contacts">
                    <li><a href="tel://61280932400"><i className="icon_mobile"></i> + 61 23 8093 3400</a></li>
                    <li><a href="mailto:info@findoctor.com"><i className="icon_mail_alt"></i> info@doctorhoy.com</a></li>
                  </ul>
                  <div className="follow_us">
                    <h5>Síguenos en:</h5>
                    <ul>
                      <li><a href="#0"><i className="social_facebook"></i></a></li>
                      <li><a href="#0"><i className="social_twitter"></i></a></li>
                      <li><a href="#0"><i className="social_linkedin"></i></a></li>
                      <li><a href="#0"><i className="social_instagram"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-md-8">
                  <ul id="additional_links">
                    <li><a href="#0">Terminos y condiciones</a></li>
                    <li><a href="#0">Protección de datos</a></li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div id="copy">© 2020 DoctorHoy</div>
                </div>
              </div>
            </div>
          </footer>
          <div id="toTop" />
        </div>
        <RefreshToken onRef={ref => (this.RefreshToken = ref)} />
      </div>
    );
  }
}

BusquedaEntidades.propTypes = {
  GetAll: PropTypes.func,
  GetDetalleCarroCompras: PropTypes.func,
  getCities: PropTypes.func,
  GetQueryIndex: PropTypes.func,
  location: PropTypes.object,
};

BusquedaEntidades.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  findDoctors: state.generic.findDoctors,
  cities: state.generic.cities,
  cup: state.doctor.cups,
  cie: state.doctor.cies,
  speciality: state.doctor.speciality,
  getAll: state.doctor.getAll
});

export default connect(
  mapStateToProps,
  {
    getFindDoctor,
    getCities,
    GetCie,
    GetCup,
    GetSpeciality,
    GetAll,
    GetQueryIndex,
    GetDetalleCarroCompras
  }
)(BusquedaEntidades);
