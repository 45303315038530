import { ERROR_API, MANAGE_CONTACTS } from "../actions/types";

const initialState = {
  contacts: [],
  errorCode: {}
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case ERROR_API:
      return {
        ...state,
        contacts: [],
        errorCode: action.payload
      };
    case MANAGE_CONTACTS:
      return {
        ...state,
        contacts: action.payload.data,
        response: true
      };
    default:
      return state;
  }
}
