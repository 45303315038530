/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { toast } from "react-toastify";
import Modal from "react-modal";

import Swal from "sweetalert2";

// Components
import LoginForm from "../../../Components/Forms/LoginMedical";

// Redux
import {
  login,
  forgetPassword,
  recoverPasswordLaravel,
} from "../../../redux/actions/authAction";
import { forgetPasswordUrl, userInfo } from "../../../redux/actions/types";
import { getUserInfo, loginLaravel } from "../../../redux/actions/userAction";
import {
  GetCitaEnEspera,
  PendingAppointments,
} from "../../../redux/actions/DoctorAction";
import { PreloaderDocHoy } from "../../../Utils";

// Assets
import "react-toastify/dist/ReactToastify.css";
import "spinkit/css/spinkit.css";
import logo from "../../../assets/img/logo/logo.png";
import {
  BigContainerLogin,
  ContainerInfoTextRight,
  ContainerPopUpLogin,
} from "./medicalLogin.style";
import loginIcon1 from "../../../assets/img/icons/DH_icono_360.svg";
import loginIcon2 from "../../../assets/img/icons/DH_icono_agenda.svg";
import loginIcon3 from "../../../assets/img/icons/DH_icono_facturacion.svg";

// Config
import { urlHC, apiLaravel } from "../../../enviroment";

const CryptoJS = require("crypto-js");

class MedicalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      sendRequest: false,
      recaptcha: true,
      modal: false,
      gotoPrevious: false,
      gotoRedirect: false,
      gotoUrl: "",
      hasSearch: false,
      view: false,
      hasFinderSearch: false,
      showPopUpLogin: true,
      documentRecover: "",
      errorMessage: null,
      showErrorMessage: false,
    };

    this.onRecoveryPassword = this.onRecoveryPassword.bind(this);
    this.handleRecoverPassword = this.handleRecoverPassword.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleShowPopUpLogin = this.handleShowPopUpLogin.bind(this);
    this.onSendFogetPassword = this.onSendFogetPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSendFogetPassword(formData) {
    this.setState({ sendRequest: true });
    console.log("formData", formData);
    this.props
      .forgetPassword(forgetPasswordUrl, {
        username: formData.user,
        document: formData.document,
      })
      .then((resp) => {
        this.setState({ sendRequest: false });
        this.toggleModal();
        if (resp.code === 1000)
          return Swal.fire({
            title:
              "Lo sentimos, pero no hemos encontrado un usuario con el email ingresado!",
            icon: `error`,
          });

        if (resp.code === 0) {
          Swal.fire({
            title:
              "Hemos enviado a tu correo el enlace para recuperar tu contraseña!",
            icon: `success`,
            timer: 3000,
            timerProgressBar: true,
          });
          const body = {
            username: "camilo",
            cellphone: "+573003438359",
            msg: " Buenos días, Camilo. Hemos enviado a tu correo el enlace para recuperar tu contraseña!",
          };

          // sendSMS(body, '/send-sms').then(() => {
          //   console.log('mensaje enviado');
          // }).catch((errorMsg) => {
          //   console.log('Message bird Error', errorMsg);
          // });
          return;
        }

        if (resp.code === 3000)
          return Swal.fire({
            title: "Error interno del servidor",
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
      });
  }

  componentDidMount() {
    if (localStorage.token) {
      if (localStorage.profile) {
        switch (localStorage.profile) {
          case "Paciente":
            this.context.router.history.push("/Dashboard");
            break;
          case "Medico":
            this.context.router.history.push("/CitasMedico/MisCitas");
            break;
          default:
            this.context.router.history.push("/Dashboard");
            break;
        }
      }
    }
  }

  componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tokenFromFinder = params.get("tokenfinder");
    const searchFinder = params.get("findersearch");

    // console.log("LAST CHANGES 123");

    if (tokenFromFinder) {
      this.setState({ hasFinderSearch: true });
      if (!searchFinder) {
        localStorage.setItem("token", tokenFromFinder);
        setTimeout(() => {
          this.props
            .getUserInfo(userInfo)
            .then((res) => {
              const userInfo = res.data;
              localStorage.setItem("profile", userInfo?.profile?.profile_name);
              localStorage.setItem("full_name", userInfo?.full_name);
              localStorage.setItem("DHusername", userInfo?.username);
              localStorage.setItem("DoctorId", userInfo?.id);
            })
            .then(() => {
              this.context.router.history.push("/CitasPaciente/CitasPaciente");
            });
        }, 1000);
      } else {
        const decodeBase = atob(searchFinder);
        console.log("decodeBase", decodeBase);
        localStorage.setItem("findersearch", decodeBase);
        localStorage.setItem("token", tokenFromFinder);

        setTimeout(() => {
          this.props.getUserInfo(userInfo).then((res) => {
            const userInfo = res.data;
            localStorage.setItem("profile", userInfo?.profile?.profile_name);
            localStorage.setItem("full_name", userInfo?.full_name);
            localStorage.setItem("DHusername", userInfo?.username);
            localStorage.setItem("DoctorId", userInfo?.id);

            const findersearch = localStorage.getItem("findersearch");
            const finderObjectSearch = JSON.parse(findersearch);
            console.log("finderObjectSearch", finderObjectSearch);
            if (
              finderObjectSearch &&
              finderObjectSearch.typeSearch &&
              finderObjectSearch.typeSearch == 1
            ) {
              const userIdfinder = localStorage.getItem("userIdFinder");
              console.log("Finder crear busqueda y enviar a busqueda");
              console.log("userIdfinder", userIdfinder);
              this.props
                .PendingAppointments({
                  patient_id: userIdfinder,
                  // type: "specialty",
                  specialty_id: finderObjectSearch.speciality,
                  care_type: finderObjectSearch.attention_type,
                  observations: finderObjectSearch.reason,
                  city_id: finderObjectSearch?.city,
                  plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                  address_id: "",
                })
                .then(() => {
                  this.context.router.history.push("/citaEspera/Seleccion");
                });
            }
          });
        }, 1000);
      }
    }

    const isMobile = () => {
      const match = window.matchMedia("(pointer:coarse)");
      if (match && match.matches) {
        this.setState({ view: "Mobile" });
      } else {
        this.setState({ view: "Desktop" });
      }
    };

    isMobile();

    const values = queryString.parse(this.props.location.search);

    if (values.redirect !== undefined) {
      this.setState({ gotoPrevious: true });

      if (values.redirect == 2 && values.gotoUrl !== undefined) {
        this.setState({ gotoUrl: values.gotoUrl, gotoRedirect: true });
      }
    }
    if (values.search !== undefined) {
      this.setState({ hasSearch: true });

      const base64 = values.search;

      const decodeBase = atob(base64);
      console.log("base64", decodeBase);

      localStorage.setItem("search", decodeBase);
    }
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleRecoverPassword() {
    if (this.state.documentRecover === "") {
      this.setState({
        errorMessage: "Por favor digite su numero de documento",
      });
      this.setState({
        showErrorMessage: true,
      });
    } else {
      this.props
        .recoverPasswordLaravel({
          document: this.state.documentRecover,
        })
        .then((res) => {
          console.log(res);
          Swal.fire({
            title: "Te hemos enviado un correo",
            text: "Recuerda verificar tambien en tu bandeja de spam",
            icon: `success`,
          }).then(() => {
            this.setState({
              modal: false,
            });
          });
        });
    }
  }

  handleShowPopUpLogin() {
    this.setState({
      showPopUpLogin: !this.state.showPopUpLogin,
    });
  }

  onRecoveryPassword() {
    this.toggleModal();
  }

  onSubmit(data) {
    data.name = "chrome";

    const key = "d0ct0rH0yk3y";

    // Definimos el mensaje a cifrar
    const message = data.password;

    // Ciframos el mensaje usando AES
    const ciphertext = CryptoJS.AES.encrypt(message, key).toString();

    localStorage.setItem("userKey", ciphertext);
    localStorage.setItem("financialUser", data.username);

    const search = localStorage.getItem("search");

    this.setState({ sendRequest: true });
    this.props
      .loginLaravel("api/login", data)
      .then((res) => {
        // console.log("entro a peticion login");
        // this.props.login(data, "token").then(() => {
        if (res.access_token) {
          localStorage.setItem("token", res.access_token);

          if (res.type == 1 || res.type == 2) {
            window.location.href = `${apiLaravel}check/${res.access_token}`;
            return;
          }

          // if (this.props.isAuthenticated) {
          this.props.getUserInfo(userInfo).then((res) => {
            if (!res) {
              this.setState({ sendRequest: false });
              return;
            }

            if (this.state.gotoPrevious) {
              if (this.state.gotoRedirect) {
                this.context.router.history.push(this.state.gotoUrl);
              } else {
                window.history.back();
              }
            } else {
              const userInfo = res.data;
              localStorage.setItem("profile", userInfo?.profile?.profile_name);
              localStorage.setItem("full_name", userInfo?.full_name);
              localStorage.setItem("DHusername", userInfo?.username);
              localStorage.setItem("DoctorId", userInfo?.id);
              if (
                userInfo &&
                userInfo.profile &&
                userInfo.profile.profile_name &&
                userInfo.profile.profile_name === "Medico"
              ) {
                if (userInfo.state_create == "PV") {
                  this.context.router.history.push("/Doctor/EditDoctor");
                } else {
                  this.context.router.history.push("/CitasMedico/MisCitas");
                }
                //
              } else if (
                userInfo &&
                userInfo.profile &&
                userInfo.profile.profile_name &&
                userInfo.profile.profile_name === "Paciente"
              ) {
                if (search) {
                  const objectSearch = JSON.parse(search);
                  console.log("objectSearch", objectSearch);
                  // return;
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch == 1
                  ) {
                    console.log("crear busqueda y enviar a busqueda");
                    this.props
                      .PendingAppointments({
                        patient_id: this.props.userInfo?.id,
                        // type: "specialty",
                        specialty_id: objectSearch.speciality,
                        care_type: objectSearch.attention_type,
                        observations: objectSearch.reason,
                        city_id: objectSearch?.city,
                        plan_id: "6C701785-34AC-47ED-ACE2-D957894B183C",
                        address_id: "",
                      })
                      .then(() => {
                        this.context.router.history.push(
                          "/citaEspera/Seleccion"
                        );
                      });
                  }
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch === 2
                  ) {
                    let typeConsultSearch = "";
                    const typeSearch = objectSearch.typeSearchProgramada;
                    switch (objectSearch.typeSearchProgramada) {
                      case 1:
                        typeConsultSearch = objectSearch.speciality;
                        break;
                      case 2:
                        typeConsultSearch = objectSearch.cies;
                        break;
                      case 3:
                        typeConsultSearch = objectSearch.doctor;
                        break;
                      default:
                        break;
                    }

                    this.context.router.history.push(
                      `/Buscador/Busqueda/${typeConsultSearch}/${objectSearch.city}/${typeSearch}`
                    );
                  }
                  if (
                    objectSearch &&
                    objectSearch.typeSearch &&
                    objectSearch.typeSearch === 3
                  ) {
                    this.context.router.history.push(
                      `/Buscador/Busqueda/${objectSearch.service}/${objectSearch.city}/${objectSearch.typeSearchProgramada}`
                    );
                  }
                } else {
                  this.context.router.history.push("/Dashboard");
                }
              } else {
                this.context.router.history.push("/Dashboard");
              }
            }
          });
        }

        if (this.props.messageError && this.props.messageError !== "") {
          this.setState({ sendRequest: false });
          return toast.error(this.props.messageError, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((e) => {
        this.setState({ sendRequest: false });
        if (e.response?.status == 401) {
          Swal.fire({
            title: e?.response?.data?.message,
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Error interno",
            icon: `error`,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        // console.log("aqui error", e.response?.status);
      });
  }

  render() {
    const { sendRequest, hasFinderSearch } = this.state;
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
      overlay: {
        backgroundColor: "rgba(255, 255, 255, 0.75)",
      },
    };
    const goBackHome = () => {
      window.location.href = "https://www.doctorhoy.com/";
    };
    if (hasFinderSearch) {
      return (
        <>
          <PreloaderDocHoy />
        </>
      );
    }

    return (
      <BigContainerLogin>
        {this.state.modal && (
          <Modal
            // shouldCloseOnOverlayClick={false}
            isOpen={this.state.modal}
            onRequestClose={this.toggleModal}
            style={customStyles}
          >
            <div>
              <h3>Recupera tu cuenta</h3>
              <hr />
              <span>
                Por favor ingresa tu numero de cedula, a continuación enviaremos
                un <br /> correo electronico a tu cuenta asociada para que
                continues el proceso.
              </span>
              <br />
              <br />
              <input
                style={{ padding: "5px" }}
                placeholder="Documento de identidad"
                onChange={(e) =>
                  this.setState({ documentRecover: e.target.value })
                }
              />
              {this.state.showErrorMessage && (
                <>
                  <br />
                  <span style={{ fontSize: "12px", color: "red" }}>
                    {this.state.errorMessage}
                  </span>
                </>
              )}
              <hr />
              <button
                onClick={this.handleRecoverPassword}
                style={{
                  backgroundColor: "#e07c2c",
                  padding: "5px 15px 5px 15px",
                  color: "#fff",
                  borderRadius: "10px",
                  border: "none",
                }}
              >
                Enviar correo electronico
              </button>
            </div>
          </Modal>
        )}
        <div className="welcome-login-text-container">
          <div className="login-card-container">
            <div className="login-card-subcontainer">
              <div className="container-logo-login">
                <img src={logo} alt="Logo" onClick={goBackHome} />
              </div>
              <LoginForm
                onIraRegistro={() =>
                  this.context.router.history.push("/NewDoctorRegister")
                }
                submitForm={this.onSubmit}
                status={sendRequest}
                openModal={this.toggleModal}
              />
            </div>
            <div className="login-card-footer">
              <span>¿Eres usuario paciente? </span>
              <a onClick={() => this.context.router.history.push("/")}>
                Ingresa a DoctorHoy Aquí
              </a>
            </div>
            {/* <div className="login-card-footer-text">
              <span>No tenemos convenios con EPS</span>
            </div> */}
          </div>
        </div>
        <ContainerInfoTextRight>
          <h1>
            Solución Integral
            <span style={{ color: "#FF8B0D", margin: "0px", padding: "0px" }}>
              360
            </span>
          </h1>
          <h3>para Optimizar tu Práctica Médica</h3>

          <div>
            <ul>
              <li>Agenda en línea 24/7</li>
              <li>Historia clínica interoperable</li>
              <li>Consulta presencial</li>
              <li>Videoconsulta</li>
              <li>Atención domiciliaria</li>
              <li>Interconsulta médica</li>
              <li>Exámenes diagnósticos y procedimientos</li>
              <li>Canal exclusivo de telemedicina</li>
            </ul>
          </div>
          <span>Todo esto sin costos anuales.</span>

          <div className="container-info-icons">
            <div>
              <div>
                <img src={loginIcon2} alt="icon2" />
              </div>
              <p>
                <span>
                  Agenda compartida, ¡sin preocuparte por cruce de horarios!
                </span>{" "}
                Agenda los pacientes de tu consultorio y los que se autoagendan
                por la plataforma 24/7.
              </p>
            </div>
            <div>
              <div>
                <img src={loginIcon3} alt="icon3" />
              </div>
              <p>
                <span>Solo paga por la Intermediación (15%)</span> de los
                servicios que los pacientes pagan directamente en la plataforma.
                DoctorHoy se encarga de cumplir con los requisitos de la
                Resolución 2275 de 2023, facilitando la generación de factura
                electrónica en salud,{" "}
                <span>
                  FEV+RIPS+CUV (consigue esta herramienta por un pago anual).
                </span>
              </p>
            </div>
            <div>
              <div>
                <img src={loginIcon1} alt="icon1" />
              </div>
              <p>
                Haz que tus pacientes se registren en{" "}
                <span>www.doctorhoy.com.</span> Permíteles autoagendar contigo y
                forma parte de una red médica para solucionar sus problemas de
                salud integralmente.
              </p>
            </div>
          </div>
        </ContainerInfoTextRight>
      </BigContainerLogin>
    );
  }
}

MedicalLogin.propTypes = {
  login: PropTypes.func.isRequired,
  messageError: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  getUserInfo: PropTypes.func,
  loginLaravel: PropTypes.func,
  recoverPasswordLaravel: PropTypes.func,
  forgetPassword: PropTypes.func,
  className: PropTypes.string,
};

MedicalLogin.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  messageError: state.auth.messageError,
  isAuthenticated: state.auth.isAuthenticated,
  stateLoading: state.auth.stateLoading,
  forgetPasswordResponse: state.auth.forgetPasswordResponse,
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, {
  login,
  forgetPassword,
  getUserInfo,
  loginLaravel,
  GetCitaEnEspera,
  PendingAppointments,
  recoverPasswordLaravel,
})(MedicalLogin);
