/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from "yup";

const schema = yup.object().shape({
  terminos_aceptados: yup
    .boolean()
    .oneOf([true], "Debes de aceptar terminos y condiciones"),

  name: yup.string().required("Nombre es requerido"),

  last_name: yup.string().required("Apellido es requerido"),

  username: yup.string().required("Username es requerido"),

  phone: yup.string().required("Telefono es requerido"),

  email: yup
    .string()
    .email("No es un formato valido")
    .required("Email es requerido"),

  document_type: yup
    .object()
    .required("Tipo de documento es requerido")
    .nullable(),

  document: yup.string().required("Documento es requerido"),

  password: yup.string().required("Contraseña es requerido"),

  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Debe coincidir con tu contraseña"),

  residence_country: yup.object().required("Pais es requerido").nullable(),
  residence_department: yup
    .object()
    .required("Departamento es requerido")
    .nullable(),
  residence_city: yup.object().required("Departamento es requerido").nullable(),
});

export default schema;
