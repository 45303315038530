/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import React, { Component } from "react";
import { Provider } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";

// Containers
import { DefaultLayout } from "./containers";
import { Login, Page404, Page500, MedicalLogin } from "./views/Pages";
import RecoveryPassword from "./views/RecoveryPassword/RecoveryPassword";
import DoctorRegister from "./views/Doctor/RegisterDoctor";
import DoctorRegisterComplete from "./views/Doctor/RegisterComplete";
import BusquedaMedico from "./views/BusquedaMedico/BusquedaMedico";
import BusquedaEntidades from "./views/BusquedaMedico/BusquedaEntidades";
import Summary from "./views/Payment/Summary";
import ResponsePage from "./views/Payment/ResponsePage";
import requiereAuth from "./Auth/requireAuth";
import NewPersonRegister from "./views/NewPersonRegister/NewPersonRegister";
import FinishPersonRegister from "./views/NewPersonRegister/FinishPersonRegister";
import NewDoctorRegister from "./views/NewPersonRegister/NewDoctorRegister";
import DoctorPublicProfile from "./views/Doctor/DoctorPublicProfile";
import Solicitud from "./views/CitaEspera/Solicitud";
import SolicitudVideoConsulta from "./views/CitaEspera/SolicitudVideoConsulta";
import packageJson from "../package.json";
import { getBuildDate } from "./Utils/index";
import withClearCache from "./ClearCache";

// Styles
import "./assets/css/app.css";
import "@coreui/icons/css/coreui-icons.min.css";
import "flag-icon-css/css/flag-icon.min.css";
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "./scss/newstyles.css";

// Redux
import store from "./redux/store";

class App extends Component {
  render() {
    function MainApp() {

      console.log("Build Date: ", getBuildDate(packageJson.buildDate));
      return (
        <Provider store={store}>
          <HashRouter>
            <Switch>
              <Route exact path="/" name="Index" component={Login} />
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route
                exact
                path="/MedicalLogin"
                name="Medical Login"
                component={MedicalLogin}
              />
              <Route exact path="/index" name="Index" component={Login} />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route
                exact
                path="/BusquedaMedico"
                name="BusquedaMedico"
                component={BusquedaMedico}
              />
              <Route
                exact
                path="/BusquedaEntidades"
                name="BusquedaEntidades"
                component={BusquedaEntidades}
              />
              <Route
                exact
                path="/NewPersonRegister"
                name="NewPersonRegister"
                component={NewPersonRegister}
              />
              <Route
                exact
                path="/NewDoctorRegister"
                name="NewDoctorRegister"
                component={NewDoctorRegister}
              />
              <Route
                exact
                path="/FinishPersonRegister"
                name="FinishPersonRegister"
                component={FinishPersonRegister}
              />
              <Route
                exact
                path="/DoctorPublicProfile"
                name="DoctorPublicProfile"
                component={DoctorPublicProfile}
              />
              <Route exact path="/Summary" name="Summary" component={Summary} />
              <Route
                exact
                path="/ResponsePage"
                name="ResponsePage"
                component={ResponsePage}
              />
              <Route
                exact
                path="/Solicitud"
                name="Solicitud"
                component={Solicitud}
              />
              <Route
                exact
                path="/SolicitudVideoConsulta"
                name="SolicitudVideoConsulta"
                component={SolicitudVideoConsulta}
              />
              <Route
                exact
                path="/RecoveryPassword/RecoveryPassword"
                name="Recovery Password"
                component={RecoveryPassword}
              />
              <Route
                exact
                path="/Doctor/RegisterDoctor"
                name="Register Doctor User"
                component={DoctorRegister}
              />
              <Route
                exact
                path="/Doctor/RegisterComplete"
                name="Register Completed"
                component={DoctorRegisterComplete}
              />
              <Route
                path="/"
                name="Home"
                component={requiereAuth(DefaultLayout)}
              />
            </Switch>
          </HashRouter>
        </Provider>
      );
    }
    const ClearCacheComponent = withClearCache(MainApp);
    return <ClearCacheComponent />;
  }
}

export default App;
