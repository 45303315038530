/**
 * This source code is the confidential, proprietary information of
 * DoctorHoy Technology LLC Limited, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with DoctorHoy Technology LLC Limited.
 *
 * DoctorHoy Technology LLC Limited.
 * All Rights Reserved.
 */

import * as yup from "yup";

const schema = yup.object().shape({
  // motivo: yup.string().required("El motivo de la consulta es requerido"),
  attention_type: yup.string().required("El tipo de atención es requerido"),
  address: yup.object().when("attention_type", {
    is: (attention_type) => (attention_type == 2 ? true : false),
    then: yup.object().required("La direcccion es requerida es requerida"),
  }),
  speciality: yup.string().required("La especialidad es requerida"),
  city: yup.string().when("attention_type", {
    is: (attention_type) => (attention_type == 0 ? true : false),
    then: yup.string().required("La ciudad es requerida").nullable(),
  }),
  // .required('La ciudad es requerida'),
});

export default schema;
