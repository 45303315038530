// Libraries
import { each } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

// Utils
import { searchDataInLists } from '../Utils';

// Single Selectors
const getNotifications = (state) => state.notifications;
const getTabValue = (state) => state.tabValue;
const getSearchTerm = (state) => state.searchTerm;

// Selectors
export const filterNotifications = createSelector([getNotifications, getTabValue, getSearchTerm], (notifications, tabValue, searchTerm) => {
  let filteredNotification = [];

  each(notifications, (notification) => {
    if (!notification.dateParsed) {
      notification.dateParsed = true;
      // notification.date = moment(notification.date).format('MMMM DD, YYYY - hh:mm a');
      notification.dateN = moment(notification?.created_at).format('MMMM DD')+' del '+moment(notification?.created_at).format('YYYY');
      notification.dateH = moment(notification?.created_at).format('hh:mm a');
    }

    if (notification?.data) {
      filteredNotification.push(notification);
    }
  });

  // if (searchTerm) {
  //   filteredNotification = searchDataInLists(filteredNotification, searchTerm, ['description', 'date', 'user_name_from']);
  // }

  return filteredNotification;
});
