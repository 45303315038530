export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CONSULTATION_TYPES = "GET_CONSULTATION_TYPES";
export const GET_ASSISTANTS = "GET_ASSISTANTS";
export const CREATE_ASSISTANTS = "CREATE_ASSISTANTS";
export const SET_CURRENT_ERROR_USER = "SET_CURRENT_ERROR_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_API = "GET_USER_INFO_API";
export const GET_USER_MENU = "GET_USER_MENU";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES_BY_PAGE = "GET_PROFILES_BY_PAGE";
export const DELETE_PROFILES_BY_PAGE = "DELETE_PROFILES_BY_PAGE";
export const GET_PAGES = "GET_PAGES";
export const GET_MASTER_PAGES = "GET_MASTER_PAGES";
export const GET_GROUPS = "GET_GROUPS";
export const GET_ICONS = "GET_ICONS";
export const GET_GROUPS_BY_PARENTS = "GET_GROUPS_BY_PARENTS";
export const GET_USERS = "GET_USERS";
export const GET_DOCUMET_TYPES = "GET_DOCUMET_TYPES";
export const GET_BLOOD_TYPE = "GET_BLOOD_TYPE";
export const GET_USERS_SET_PASSWORD = "GET_USERS_SET_PASSWORD";
export const RESEND_MAIL = "RESEND_MAIL";
export const ERROR_API = "ERROR_API";
export const ERROR_API_CONNECTION = "ERROR_API_CONNECTION";
export const SET_CURRENT_ERROR_USER_API = "SET_CURRENT_ERROR_USER_API";
export const MANAGE_CATEGORIES = "MANAGE_CATEGORIES";
export const MANAGE_PRODUCTS = "MANAGE_PRODUCTS";
export const GET_DISCOUNTS_TYPES = "GET_DISCOUNTS_TYPES";
export const GET_CITIES = "GET_CITIES";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_CONTACTS = "GET_CONTACTS";
export const MANAGE_CONTACTS = "MANAGE_CONTACTS";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const GET_IMAGE = "GET_IMAGE";
export const MANAGE_RESOLUTIONS = "MANAGE_RESOLUTIONS";
export const GET_PAYMENT_FORMS = "GET_PAYMENT_FORMS";
export const GET_CURRENCIES = "GET_CURRENCIES";
export const GET_REGIME_TYPE = "GET_REGIME_TYPE";
export const GET_PERSON_TYPE = "GET_PERSON_TYPE";
export const GET_REPORT = "GET_REPORT";
export const GET_ORIENTATION_REPORT = "GET_ORIENTATION_REPORT";
export const GET_CREDIT_NOTE_CONCEPTS = "GET_CREDIT_NOTE_CONCEPTS";
export const GET_VALUES = "GET_VALUES";
export const GET_CONFIG_CALENDAR = "GET_CONFIG_CALENDAR";
export const GET_SPECIALITY_BY_DOCTOR = "GET_SPECIALITY_BY_DOCTOR";
export const GET_SPECIALITY = "GET_SPECIALITY";
export const GET_SUB_SPECIALITY = "GET_SUB_SPECIALITY";
export const GET_CONSULTNG_ROOM = "GET_CONSULTNG_ROOM";
export const GET_SCHEDULERS = "GET_SCHEDULERS";
export const DELETE_SCHEDULERS = "DELETE_SCHEDULERS";
export const DELETE_ALL_SCHEDULERS = "DELETE_ALL_SCHEDULERS";
export const GET_INFO_USER_BY_TYPE = "GET_INFO_USER_BY_TYPE";
export const GET_PRODUCT_RATES_BY_DOCTOR = "GET_PRODUCT_RATES_BY_DOCTOR";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_HEALTH_ENTITIES = "GET_HEALTH_ENTITIES";
export const GET_AGREEMENTS = "GET_AGREEMENTS";
export const GET_PATIENTS = "GET_PATIENTS";
export const GET_PATIENT_LARAVEL = "GET_PATIENT_LARAVEL";
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_CERTIFICATIONS = "GET_CERTIFICATIONS";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_ANNOTATIONS = "GET_ANNOTATIONS";
export const GET_SOCIAL_NETWORKS = "GET_SOCIAL_NETWORKS";
export const GET_ETHNIC_GROUPS = "GET_ETHNIC_GROUPS";
export const GET_SEXUAL_ORIENTATION = "GET_SEXUAL_ORIENTATION";
export const GET_GENDER = "GET_GENDER";
export const GET_DOCTOR = "GET_DOCTOR";
export const GET_BANK = "GET_BANK";
export const GET_CIE = "GET_CIE";
export const GET_CUP = "GET_CUP";
export const GET_CIEXMEDICO = "GET_CIEXMEDICO";
export const GET_CUPXMEDICO = "GET_CUPXMEDICO";
export const GET_FINDDOCTOR = "GET_FINDDOCTOR";
export const GET_PLAN = "GET_PLAN";
export const GET_RANGO = "GET_RANGO";
export const GET_AFILIACION = "GET_AFILIACION";
export const GET_ESCOLARIDAD = "GET_ESCOLARIDAD";
export const GET_OCUPACION = "GET_OCUPACION";
export const GET_ORIGENCONTACTO = "GET_ORIGENCONTACTO";
export const GET_PARENTESCO = "GET_PARENTESCO";
export const GET_ESPXMEDICO_BUSQUEDA = "GET_ESPXMEDICO_BUSQUEDA";
export const GET_USER_SET_APPOINTMENT = "GET_USER_SET_APPOINTMENT";
export const GET_FUNCIONALIDAD = "GET_FUNCIONALIDAD";
export const GET_FUNCIONALIDAD_X_USUARIO = "GET_FUNCIONALIDAD_X_USUARIO";
export const GET_DOCTOR_PERMISSION = "GET_DOCTOR_PERMISSION";
export const GET_USER_AGREEMENT_BUSQUEDA = "GET_USER_AGREEMENT_BUSQUEDA";
export const GET_USER_AGREEMENT = "GET_USER_AGREEMENT";
export const GET_DOCTOR_CONSULTING_ROOM = "GET_DOCTOR_CONSULTING_ROOM";
export const GET_UNIDADES_MEDIDA = "GET_UNIDADES_MEDIDA";
export const GET_CITAS_EN_ESPERA = "GET_CITAS_EN_ESPERA";
export const GET_PENDING_APPOINTMENTS = "GET_PENDING_APPOINTMENTS";
export const GET_PENDING_APPOINTMENTS_PATIENT =
  "GET_PENDING_APPOINTMENTS_PATIENT";
export const GET_CITAS_EN_OFERTA = "GET_CITAS_EN_OFERTA";
export const GET_ALL = "GET_ALL";
export const GET_QUERY_INDEX = "GET_QUERY_INDEX";
export const GET_CITAS_SIN_PAGO = "GET_CITAS_SIN_PAGO";
export const GET_TARIFA_PRODUCTOS = "GET_TARIFA_PRODUCTOS";
export const GET_PRODUCTOS_SERVICIOS = "GET_PRODUCTOS_SERVICIOS";
export const GET_DATOS_COMPRA = "GET_DATOS_COMPRA";
export const GET_DETALLE_CARRO_COMPRAS = "GET_DETALLE_CARRO_COMPRAS";
export const GET_REPORTE_1 = "GET_REPORTE_1";
export const SET_ACTUALIZAR_PERFIL_DOCTOR = "SET_ACTUALIZAR_PERFIL_DOCTOR";
export const GET_CONSULTORIOS_POR_CITA_ESPERA =
  "GET_CONSULTORIOS_POR_CITA_ESPERA";
export const GET_REDIRECCION_X_PAGO = "GET_REDIRECCION_X_PAGO";
export const GET_VALIDACION_INGRESO_CARRO = "GET_VALIDACION_INGRESO_CARRO";
export const GET_CITAS_PACIENTE_ACTIVAS = "GET_CITAS_PACIENTE_ACTIVAS";
export const GET_DATOS_VIDEO = "GET_DATOS_VIDEO";
export const GET_CITAS_PROXIMAS_DOCTOR = "GET_CITAS_PROXIMAS_DOCTOR";
export const GET_CANCELAR_CITA = "GET_CANCELAR_CITA";
export const GET_REPROGRAMAR_CITA = "GET_REPROGRAMAR_CITA";
export const GET_CITAS_MEDICO_ACTIVAS = "GET_CITAS_MEDICO_ACTIVAS";
export const GET_PETICION_REPROGRAMAR_CITA = "GET_PETICION_REPROGRAMAR_CITA";
export const GET_LLAMAR_HUB_PRUEBA = "GET_LLAMAR_HUB_PRUEBA";
export const SIGNALRCON = "SIGNALRCON";
export const SIGNALRPROXY = "SIGNALRPROXY";
export const SET_EXECUTING_REQUEST = "doctor/SET_EXECUTING_REQUEST";
export const SET_EXECUTING_REQUEST_USER = "user/SET_EXECUTING_REQUEST_USER";
export const SET_EXECUTING_REQUEST_GENERIC =
  "generic/SET_EXECUTING_REQUEST_GENERIC";
export const SET_LAST_ACTION = "doctor/SET_LAST_ACTION";
export const SET_CONSULTATION_PATIENT = "doctor/SET_CONSULTATION_PATIENT";
export const SET_LAST_ACTION_USER = "user/SET_LAST_ACTION";
export const SET_NOTIFICATION_TAB_VALUE = "user/SET_NOTIFICATION_TAB_VALUE";
export const SET_NOTIFICATIONS = "user/SET_NOTIFICATIONS";
export const SET_SEARCH_TERM = "user/SET_SEARCH_TERM";
export const CREATE_BODY_SYSTEM = "generic/CREATE_BODY_SYSTEM"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_BODY_SYSTEM = "generic/DELETE_BODY_SYSTEM";
export const GET_BODY_SYSTEMS = "generic/GET_BODY_SYSTEMS";
export const CREATE_LISTS_SEARCHXEXAMS = "generic/CREATE_LISTS_SEARCHXEXAMS"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_LISTS_SEARCHXEXAMS = "generic/DELETE_LISTS_SEARCHXEXAMS";
export const GET_LISTS_SEARCHXEXAMS = "generic/GET_LISTS_SEARCHXEXAMS";
export const CREATE_TYPES_DATAXEXAMS = "generic/CREATE_TYPES_DATAXEXAMS"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_TYPES_DATAXEXAMS = "generic/DELETE_TYPES_DATAXEXAMS";
export const GET_TYPES_DATAXEXAMS = "generic/GET_TYPES_DATAXEXAMS";
export const CREATE_VARIABLE_UNITSXEXAMS =
  "generic/CREATE_VARIABLE_UNITSXEXAMS"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_VARIABLE_UNITSXEXAMS =
  "generic/DELETE_VARIABLE_UNITSXEXAMS";
export const GET_VARIABLE_UNITSXEXAMS = "generic/GET_VARIABLE_UNITSXEXAMS";
export const CREATE_DIAGNOSTICOS_CIE = "generic/CREATE_DIAGNOSTICOS_CIE"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_DIAGNOSTICOS_CIE = "generic/DELETE_DIAGNOSTICOS_CIE";
export const GET_DIAGNOSTICOS_CIE = "generic/GET_DIAGNOSTICOS_CIE";
export const CREATE_SYMPTOM = "generic/CREATE_SYMPTOM";
export const DELETE_SYMPTOM = "generic/DELETE_SYMPTOM";
export const GET_SYMPTOMS = "generic/GET_SYMPTOMS";
export const CREATE_VALUESXSEARCHLIST = "generic/CREATE_VALUESXSEARCHLIST";
export const DELETE_VALUESXSEARCHLIST = "generic/DELETE_VALUESXSEARCHLIST";
export const GET_VALUESXSEARCHLIST = "generic/GET_VALUESXSEARCHLIST";
export const GET_ANTHROPOMETRIC_MEASURES = "GET_ANTHROPOMETRIC_MEASURES";
export const GET_ANTHROPOMETRICS_DOCTOR = "GET_ANTHROPOMETRICS_DOCTOR";
export const CREATE_ANTHROPOMETRIC_DOCTOR = "CREATE_ANTHROPOMETRIC_DOCTOR";
export const DELETE_ANTHROPOMETRIC_DOCTOR = "DELETE_ANTHROPOMETRIC_DOCTOR";
export const GET_DIAGNOSES_CIE = "GET_DIAGNOSES_CIE";
export const GET_DIAGNOSES_DOCTOR = "GET_DIAGNOSES_DOCTOR";
export const CREATE_DIAGNOSES_DOCTOR = "CREATE_DIAGNOSES_DOCTOR";
export const DELETE_DIAGNOSES_DOCTOR = "DELETE_DIAGNOSES_DOCTOR";
export const GET_DIAGNOSES_CUPS = "GET_DIAGNOSES_CUPS";
export const GET_DIAGNOSES_CUPS_DOCTOR = "GET_DIAGNOSES_CUPS_DOCTOR";
export const CREATE_DIAGNOSES_CUPS_DOCTOR = "CREATE_DIAGNOSES_CUPS_DOCTOR";
export const DELETE_DIAGNOSES_CUPS_DOCTOR = "DELETE_DIAGNOSES_CUPS_DOCTOR";
export const GET_TEMPLATES_DOCTOR = "GET_TEMPLATES_DOCTOR";
export const GET_HISTORY_SECTIONS = "GET_HISTORY_SECTIONS";
export const CREATE_TEMPLATE_DOCTOR = "CREATE_TEMPLATE_DOCTOR";
export const UPDATE_TEMPLATE_DOCTOR = "UPDATE_TEMPLATE_DOCTOR";
export const DELETE_TEMPLATE_DOCTOR = "DELETE_TEMPLATE_DOCTOR";
export const GET_VITAL_SIGNS = "GET_VITAL_SIGNS";
export const GET_VITAL_SIGNS_DOCTOR = "GET_VITAL_SIGNS_DOCTOR";
export const CREATE_VITAL_SIGN_DOCTOR = "CREATE_VITAL_SIGN_DOCTOR";
export const DELETE_VITAL_SIGN_DOCTOR = "DELETE_VITAL_SIGN_DOCTOR";
export const GET_MEDICINES = "GET_MEDICINES";
export const GET_MEDICINES_DOCTOR = "GET_MEDICINES_DOCTOR";
export const CREATE_MEDICINES_DOCTOR = "CREATE_MEDICINES_DOCTOR";
export const DELETE_MEDICINES_DOCTOR = "DELETE_MEDICINES_DOCTOR";
export const SET_PATIENT_EXAMS = "SET_PATIENT_EXAMS";
export const GET_GROUP_CUPS = "generic/GET_GROUP_CUPS";
export const CREATE_CUPSTECNICA = "generic/CREATE_CUPSTECNICA";
export const DELETE_CUPSTECNICA = "generic/DELETE_CUPSTECNICA";
export const GET_CUPSTECNICA = "generic/GET_CUPSTECNICA";
export const GET_PHYSICAL_REGION = "generic/GET_PHYSICAL_REGION";
export const CREATE_PHYSICAL_REGION = "generic/CREATE_PHYSICAL_REGION";
export const DELETE_PHYSICAL_REGION = "generic/DELETE_PHYSICAL_REGION";
export const CREATE_BACKGROUND = "generic/CREATE_BACKGROUND"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_BACKGROUND = "generic/DELETE_BACKGROUND";
export const GET_BACKGROUND = "generic/GET_BACKGROUND";
export const CREATE_ANTECEDENT_GROUP = "generic/CREATE_ANTECEDENT_GROUP"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_ANTECEDENT_GROUP = "generic/DELETE_ANTECEDENT_GROUP";
export const GET_ANTECEDENT_GROUP = "generic/GET_ANTECEDENT_GROUP";
export const GET_ANTHROPOMETRICA_MEASURES =
  "generic/GET_ANTHROPOMETRICA_MEASURES";
export const CREATE_ANTHROPOMETRICA_MEASURES =
  "generic/CREATE_ANTHROPOMETRICA_MEASURES";
export const DELETE_ANTHROPOMETRICA_MEASURES =
  "generic/DELETE_ANTHROPOMETRICA_MEASURES";
export const CREATE_SIGNOS_VITALES = "generic/CREATE_SIGNOS_VITALES"; // estas tipos no permitiran actualizar los key del reducer de Generic
export const DELETE_SIGNOS_VITALES = "generic/DELETE_SIGNOS_VITALES";
export const GET_SIGNOS_VITALES = "generic/GET_SIGNOS_VITALES";
export const SET_MEDICAL_SPECIALITIES = "SET_MEDICAL_SPECIALITIES";
export const SET_SPECIALIZED_EXAMS = "SET_SPECIALIZED_EXAMS";
export const SET_SEARCH_LISTS = "SET_SEARCH_LISTS";
export const SET_DATA_TYPES = "SET_DATA_TYPES";
export const SET_VARIABLE_MEASURES = "SET_VARIABLE_MEASURES";
export const SEND_APPOINTMENT_REMINDER = "SEND_APPOINTMENT_REMINDER";
export const GET_MARITAL_STATUS = "GET_MARITAL_STATUS";
export const GET_BLOOD_TYPES = "GET_BLOOD_TYPES";

export const userInfo = "/User/get_info";
export const users = "/User/get_users";
export const userMenu = "/Generic/get_menu";
export const UpdateAccessProfileUrl = "/Generic/update_profiles_access";
export const Profiles = "/Generic/get_profiles";
export const Pages = "/Generic/get_pages/free";
export const MasterPages = "/Generic/get_master_pages";
export const PagesParents = "/Generic/get_pages/parents";
export const PagesAll = "/Generic/get_pages";
export const Groups = "/Generic/get_groups";
export const Icons = "/Generic/get_icons";
export const GroupsByParents = "/Generic/get_groups_by_parents";
export const ProfileByPages = "/Generic/get_profile_by_page";
export const CreateProfileByPages = "/Generic/create_profile_by_page";
export const createNode = "/Generic/create_node";
export const CreateProfile = "/Generic/create_profiles";
export const CreateGroup = "/Generic/create_group";
export const RemoveProfileByPages = "/Generic/delete_profile_by_page";
export const RemoveProfile = "/Generic/delete_profile";
export const RemoveGroup = "/Generic/delete_group";
export const RemoveGroupByPage = "/Generic/delete_groups_by_parents";
export const CreateGroupByPage = "/Generic/create_groups_by_parents";
export const DocumetTypes = "/Generic/get_documents_types";
export const BloodType = "/Generic/get_blood_type";
export const createUser = "/User/create_user";
export const validateTokenRecoveryPassword = "/User/recovery_password";
export const updatePasswordUrl = "/User/update_password";
export const reSendMail = "/User/send_mail_recovery";
export const recoveryPassword = "/User/recovery_session";
export const manageCategoryUrl = "/Product/manage_categories";
export const manageProductsUrl = "/Product/manage_products";
export const getImageUrl = "/Product/get_image";
export const manageContactsUrl = "/Contacts/manage_contacts";
export const discountTypes = "/Generic/get_discounts_types";
export const cities = "/Generic/get_cities";
export const departments = "/Generic/get_departments";
export const contactsType = "/Generic/get_contacts_type";
export const forgetPasswordUrl = "/User/forget_password";
export const getPaymentFormsUrl = "/Generic/get_payment_forms";
export const GetCurrenciesUrl = "/Generic/get_currency";
export const GetRegimeTypeUrl = "/Generic/get_regime_type";
export const GetPersonTypeUrl = "/Generic/get_person_type";
export const CompanyInfoUrl = "/Company/manage_company";
export const getValuesUrl = "/Generic/get_values";
export const getConfigCalendarUrl = "/Doctor/get_config_calendar";
export const getSpecialityByDoctorUrl = "/Doctor/get_speciality_by_doctor";
export const getSpecialityUrl = "/Doctor/get_speciality";
export const getSubSpecialityUrl = "/Doctor/get_sub_speciality";
export const getConsultingRoomUrl = "/Doctor/get_consulting_room";
export const getSchedulersUrl = "/Doctor/get_scheduler";
export const deleteScheduleUrl = "/Doctor/delete_calendar";
export const deleteAllScheduleUrl = "/Doctor/delete_calendar_all";
export const getCountryUrl = "/Generic/get_country";
export const getHealthEntitieUrl = "/Doctor/get_health_entities";
export const getAgreementUrl = "/Doctor/get_agreements";
export const getPatientsUrl = "/Generic/get_patient";
export const getHoliDaysUrl = "/Doctor/get_holidays";
export const getCertificationsUrl = "/Doctor/get_certifications";
export const getAppointmentsUrl = "/Doctor/get_appointments";
export const getAnnotationsUrl = "/Doctor/get_annotations";
export const getSocialNetworksUrl = "/Generic/get_social_networks";
export const getEthnicGroupsUrl = "/Generic/get_ethnic_groups";
export const getBloodTypeUrl = "/Generic/get_blood_type";
export const getSexualOrientationUrl = "/Generic/get_sexual_orientation";
export const getGenderUrl = "/Generic/get_gender";
export const getDoctorUrl = "/Doctor/get_doctor";
export const getBankUrl = "/Generic/get_banks";
export const getCieUrl = "/Doctor/get_cies";
export const getCupUrl = "/Doctor/get_cups";
export const getCieXMedicoUrl = "/Doctor/get_cies_medico";
export const getCupXMedicoUrl = "/Doctor/get_cups_medico";
export const getFindDoctorUrl = "/Generic/busqueda_medicos";
export const getPlanUrl = "/Generic/get_plan";
export const getRangoUrl = "/Generic/get_rango";
export const getAfiliacionUrl = "/Generic/get_afiliacion";
export const getEscolaridadUrl = "/Generic/get_escolaridad";
export const getOcupacionUrl = "/Generic/get_ocupacion";
export const getOrigenContactoUrl = "/Generic/get_origencontacto";
export const getParentescoUrl = "/Generic/get_parentesco";
export const getEspXMedicoBusquedaUrl = "/Doctor/get_esp_medico_busqueda";
export const getUsersSetAppointmenUrl = "/User/get_users_set_appointment";
export const getFuncionalidadUrl = "/Generic/get_funcionalidad";
export const getFuncionalidadXUsuarioUrl =
  "/Generic/get_funcionalidad_x_usuario";
export const getDoctorPermissionUrl = "/Doctor/get_doctor_permission";
export const getUserAgreementBusquedaUrl =
  "/Doctor/get_user_agreements_busqueda";
export const getUserAgreementUrl = "/Doctor/get_user_agreements";
export const getConsultingRoomByDoctortUrl =
  "/Doctor/get_consulting_room_by_doctor";
export const getUnidadesMedidaUrl = "/Generic/get_unidad_medida";
export const getCitasEnEsperaUrl = "/Doctor/get_cita_en_espera";
export const getCitasEnOfertaUrl = "/Doctor/get_cita_en_oferta";
export const getAll = "/Doctor/get_all";
export const getQueryIndex = "/Doctor/get_query_index";
export const getQueryDoctor = "/Doctor/get_query_doctor";
export const getCitasSinPagoUrl = "/Doctor/get_cita_sin_pago";
export const getTarifaProductosUrl = "/Doctor/get_tarifa_productos";
export const getProductosServiciosUrl = "/Doctor/get_productos_y_servicios";
export const getDatosCompraUrl = "/Doctor/get_datos_compra";
export const getDetalleCarroComprasUrl = "/Doctor/get_detalle_carro";
export const getReporte1Url = "/Doctor/get_reporte1";
export const getActualizarPerfilDoctorUrl =
  "/Doctor/set_actualizar_perfil_doctor";
export const getConsultoriosPorCitaEsperaUrl =
  "/Doctor/get_consultorios_por_cita_en_espera";
export const getRedireccionPorPagoUrl =
  "/Doctor/get_redireccion_cita_inmediata";
export const getValidacionIngresoCarroUrl =
  "/Doctor/get_validacion_ingreso_carro";
export const getCitasPacienteActivasUrl = "/Doctor/get_citas_paciente_activas";
export const getDatosVideoUrl = "/Doctor/get_datos_video";
export const getCitasDoctorProximasUrl = "/Doctor/get_citas_doctor_proximas";
export const getCancelarCitasUrl = "/Doctor/cancelar_cita";
export const getInfoUserByTypeUrl = "/Generic/get_info_user_by_type";
export const getProductRatesByDoctorUrl = "/Doctor/get_product_rates_by_doctor";
export const getReprogramarCitasUrl = "/Doctor/reprogramar_cita";
export const getCitasMedicoActivasUrl = "/Doctor/get_citas_medico_activas";
export const getPeticionReprogramarCitasUrl =
  "/Doctor/peticion_reprogramar_cita";
export const getLlamarHubUrl = "/Doctor/llamada_hub_prueba";
export const insertPaymentQuoteOfferedUrl =
  "Doctor/insert_payment_quote_offered";
export const createBodySystemUrl = "/Generic/create_body_system"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteBodySystemUrl = "/Generic/delete_body_system";
export const getBodySystemsUrl = "/Generic/get_body_systems";
export const updateBodySystemUrl = "/Generic/update_body_system";
export const createListsSearchxExamsUrl = "/Generic/create_lists_searchxexams"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteListsSearchxExamsUrl = "/Generic/delete_lists_searchxexams";
export const getListsSearchxExamsUrl = "/Generic/get_lists_searchxexams";
export const updateListsSearchxExamsUrl = "/Generic/update_lists_searchxexams";
export const createTypesDataxExamsUrl = "/Generic/create_types_dataxexams"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteTypesDataxExamsUrl = "/Generic/delete_types_dataxexams";
export const getTypesDataxExamsUrl = "/Generic/get_types_dataxexams";
export const updateTypesDataxExamsUrl = "/Generic/update_types_dataxexams";
export const createVariableUnitsxExamsUrl =
  "/Generic/create_variable_unitsxexams"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteVariableUnitsxExamsUrl =
  "/Generic/delete_variable_unitsxexams";
export const getVariableUnitsxExamsUrl = "/Generic/get_variable_unitsxexams";
export const updateVariableUnitsxExamsUrl =
  "/Generic/update_variable_unitsxexams";
export const createDiagnosticosCieUrl = "/Generic/create_diagnosticos_cie"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteDiagnosticosCieUrl = "/Generic/delete_diagnosticos_cie";
export const getDiagnosticosCieUrl = "/Generic/get_diagnosticos_cie";
export const updateDiagnosticosCieUrl = "/Generic/update_diagnosticos_cie";
export const createSymptomUrl = "/Generic/create_symptom";
export const deleteSymptomUrl = "/Generic/delete_symptom";
export const getSymptomsUrl = "/Generic/get_symptoms";
export const updateSymptomUrl = "/Generic/update_symptom";
export const createValuesxSearchListUrl = "/Generic/create_valuesxSearchList";
export const deleteValuesxSearchListUrl = "/Generic/delete_valuesxSearchList";
export const getValuesxSearchListUrl = "/Generic/get_valuesxSearchList";
export const updateValuesxSearchListUrl = "/Generic/update_valuesxSearchList";
export const getAnthropometricMeasuresUrl =
  "/Doctor/get_anthropometric_measures";
export const getAnthropometricXDoctorUrl =
  "/Doctor/get_anthropometric_measures_by_doctor";
export const createAnthropometricXDoctorUrl =
  "/Doctor/create_anthropometric_measures_by_doctor";
export const deleteAnthropometricXDoctorUrl =
  "/Doctor/delete_anthropometric_measure_by_doctor";
export const getDiagnosesCieUrl = "Doctor/get_diagnostics_cie";
export const getDiagnosesDoctorUrl = "/Doctor/get_diagnostics_cie_by_doctor";
export const createDiagnosesDoctorUrl =
  "/Doctor/create_diagnostic_cie_by_doctor";
export const deleteDiagnosesDoctorUrl =
  "/Doctor/delete_diagnostic_cie_by_doctor";
export const getDiagnosesCupsUrl = "/Doctor/get_diagnostic_cups";
export const getDiagnosesCupsDoctorUrl = "Doctor/get_diagnostic_cups_by_doctor";
export const createDiagnosesCupsDoctorUrl =
  "/Doctor/create_diagnostic_cup_by_doctor";
export const deleteDiagnosesCupsDoctorUrl =
  "/Doctor/delete_diagnostic_cup_by_doctor";
export const getTemplatesDoctorUrl = "/Doctor/get_templates_by_doctor";
export const getHistorySectionsUrl = "/Doctor/get_medical_history_sections";
export const createTemplateDoctorUrl = "/Doctor/create_template_by_doctor";
export const updateTemplateDoctorUrl = "/Doctor/update_template_by_doctor";
export const deleteTemplateDoctorUrl = "/Doctor/delete_template_by_doctor";
export const getVitalSignsUrl = "/Doctor/get_vital_sings";
export const getVitalSignsDoctorUrl = "/Doctor/get_vital_signs_by_doctor";
export const createVitalSignsDoctorUrl = "/Doctor/create_vital_sign_by_doctor";
export const deleteVitalSignsDoctorUrl = "/Doctor/delete_vital_sign_by_doctor";
export const getMedicinesUrl = "/Doctor/get_medicines";
export const getMedicinesDoctorUrl = "/Doctor/get_medicines_by_doctor";
export const createMedicinesDoctorUrl = "/Doctor/create_medicine_by_doctor";
export const deleteMedicinesDoctorUrl = "/Doctor/delete_medicine_by_doctor";
export const createNewConsultingAppoinmentUrl =
  "/Doctor/create_consulting_appoinment";
export const getGroupCupsUrl = "/Generic/get_group_cups";
export const createCupsTecnicaUrl = "/Generic/create_cupsTecnica";
export const deleteCupsTecnicaUrl = "/Generic/delete_cupsTecnica";
export const getCupsTecnicaUrl = "/Generic/get_cupsTecnica";
export const updateCupsTecnicaUrl = "/Generic/update_cupsTecnica";
export const createPhysicalRegionUrl = "/Generic/create_physical_region"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deletePhysicalRegionUrl = "/Generic/delete_physical_region";
export const getPhysicalRegionUrl = "/Generic/get_physical_region";
export const updatePhysicalRegionUrl = "/Generic/update_physical_region";
export const createBackgroundUrl = "/Generic/create_background"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteBackgroundUrl = "/Generic/delete_background";
export const getBackgroundUrl = "/Generic/get_background";
export const updateBackgroundUrl = "/Generic/update_background";
export const createAntecedentGroupUrl = "/Generic/create_antecedent_group"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteAntecedentGroupUrl = "/Generic/delete_antecedent_group";
export const getAntecedentGroupUrl = "/Generic/get_antecedent_group";
export const updateAntecedentGroupUrl = "/Generic/update_antecedent_group";
export const createAnthropometricaMeasuresUrl =
  "/Generic/create_anthropometrica_measures"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteAnthropometricaMeasuresUrl =
  "/Generic/delete_anthropometrica_measures";
export const getAnthropometricaMeasuresUrl =
  "/Generic/get_anthropometrica_measures";
export const updateAnthropometricaMeasuresUrl =
  "/Generic/update_anthropometrica_measures";
export const createSignosVitalesUrl = "/Generic/create_signos_vitales"; // los endpoints que seran utilizados para lanzar las solicitudes hacia el servidor
export const deleteSignosVitalesUrl = "/Generic/delete_signos_vitales";
export const getSignosVitalesUrl = "/Generic/get_signos_vitales";
export const updateSignosVitalesUrl = "/Generic/update_signos_vitales";
// export const sendAppointmentReminder = "/Generic/sendAppointmentReminder";
